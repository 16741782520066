import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import * as FileSaver from 'file-saver';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Translate, I18n } from 'react-redux-i18n';
import { Table, Button, Col, FormGroup, FormControl, ControlLabel, Form, Row, Alert, Checkbox } from 'react-bootstrap';

import { actionCreators as actionCreatorsLog } from '../../store/Log';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';

import { formatDateYYYYDDMM, formatDateTime, getNameLocalize } from '../Constants';

const LogAudit = (props) => {
    const [] = useState(() => {
        if (props.nsi.allobls.length === 0) {
            props.getNs('allobls');
        }
        if (props.nsi.allraions.length === 0) {
            props.getNs('allraions');
        }
        return true;
    }, props.nsi.allobls.length > 0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [action, setAction] = useState("1");
    const [list, setList] = useState([]);
    const [koblFlt, setKodlFlt] = useState("-1");
    const [error, setError] = useState();
    const onLoadAudit = (e) => {
        e.preventDefault();
        const loadLogs = async () => {
            setList([]);
            setError(null);
            var ret = await props.getLog({ startDate: formatDateYYYYDDMM(startDate), endDate: formatDateYYYYDDMM(endDate), action, obl: props.userInfo.user.kodObl });
            if (ret.success == true) {
                setList(ret.logItems);
            } else {
                setError(ret.error);
            }
        }
        loadLogs();
    }
    const setTableBody = () => {
        if (props.userInfo.user.accessLevel === 1)
            return <tbody>
                {list.filter(f => koblFlt==="-1" || f.obj.obl === koblFlt).map((m, i) => <tr key={i}>
                    <td>{formatDateTime(m.logged)}</td>
                    <td>{m.action}</td>
                    <td>{m.message}<br /><strong className="text-primary">{getNameLocalize(props.i18n, m.obj)}</strong></td>
                    <td>{m.user}</td>
                    <td>{m.ipAddress}</td>
                </tr>)}
            </tbody>
        else
            return <tbody>
                {list.map((m, i) => <tr key={i}>
                    <td>{formatDateTime(m.logged)}</td>
                    <td>{m.action}</td>
                    <td>{m.message}<br /><strong className="text-primary">{getNameLocalize(props.i18n, m.obj)}</strong></td>
                    <td>{m.user}</td>
                    <td>{m.ipAddress}</td>
                </tr>)}
            </tbody>
    }
    const filterObl=(f) => {
        if (startDate == null) return true;
        let ye = startDate.getFullYear();
        let dtBeg = f.dtBeg == null ? null : new Date(f.dtBeg);
        if (dtBeg != null && dtBeg.getFullYear() > ye) return false;
        let dtEnd = f.dtEnd == null ? null : new Date(f.dtEnd);
        if (dtEnd != null && ye >= dtEnd.getFullYear()) return false;
        return true;
    }
    let ac = props.userInfo.user.accessLevel;
    if (!(ac === 1 || ac === 2) || props.userInfo.user.roles.filter(r => r === 'UserReadData').length > 0) return <div className="text-danger"><Translate value="noAccess" /></div>;
    return <><Form onSubmit={onLoadAudit} horizontal>
        <h5 style={{ textAlign: "center" }}><strong><Translate value="audit.tit" /></strong></h5>
        <FormGroup controlId="formStartDt">
            <Col sm={2} bsSize='small' componentClass={ControlLabel}><Translate value='audit.startDate' />{':'}</Col>
            <Col xs={12} sm={10}>
                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="dd.MM.yyyy" id="formStartDt" disabled={props.log.isLoading === true} />
            </Col>
        </FormGroup>
        <FormGroup controlId="formEndDt" >
            <Col sm={2} bsSize='small' componentClass={ControlLabel}><Translate value='audit.endDate' />{':'}</Col>
            <Col xs={12} sm={10}>
                <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} dateFormat="dd.MM.yyyy" id="formEndDt" disabled={props.log.isLoading === true} />
            </Col>
        </FormGroup>
        <FormGroup controlId="formAction" >
            <Col sm={2} bsSize='small' componentClass={ControlLabel}><Translate value='audit.action' />{':'}</Col>
            <Col xs={12} sm={10}>
                <FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={action}
                    onChange={(evt) => setAction(evt.target.value)} style={{ minWidth: '100%' }} disabled={props.log.isLoading === true}>
                    <option value="1">{I18n.t("audit.actionDelete")}</option>
                </FormControl>
            </Col>
        </FormGroup>
        <FormGroup>
            <Col smOffset={2} sm={10}>
                <Button className='btn btn-primary text-center' type="submit" disabled={props.log.isLoading === true}><Translate value="msgButtons.Load" /></Button>
            </Col>
        </FormGroup>
    </Form>
        {error != null ? <Row>
            <Col smOffset={2} sm={10}>
                <Alert bsStyle="danger">{error}</Alert>
            </Col>
        </Row> : ""}
        {list.length > 0 ? <>
            <FormGroup controlId="formObl" bsSize='small'>
                <Col sm={2} className="text-right"><ControlLabel><Translate value='obl' />{':'}</ControlLabel></Col>
                <Col xs={12} sm={10}>
                    {ac > 1 ? <strong className="text-primary">{props.i18n.locale[0] === 'r' ? props.userInfo.user.nameObl : props.userInfo.user.nameOblKz}</strong> :
                        <FormControl className='input-sm' componentClass="select" placeholder="select" value={koblFlt}
                            onChange={(evt) => setKodlFlt(evt.target.value)} style={{ width: "auto" }}>
                            <option value="-1">{I18n.t("all")}</option>
                            <option value="00">{I18n.t("rk")}</option>
                            {props.nsi.allobls.filter(f => filterObl(f)).map(it => <option key={it.kod} value={it.kod}>{getNameLocalize(props.i18n, it)}</option>)}
                        </FormControl>}
                </Col>
            </FormGroup>
            <Table striped bordered condensed hover>
                <thead>
                    <tr>
                        <th><Translate value="audit.logged" /></th>
                        <th><Translate value="audit.action" /></th>
                        <th><Translate value="audit.message" /></th>
                        <th><Translate value="audit.user" /></th>
                        <th><Translate value="audit.ipAddress" /></th>
                    </tr>
                </thead>
                {setTableBody()}
                </Table></> : ""}
    </>
}
export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionCreatorsLog }, dispatch)
)(LogAudit);