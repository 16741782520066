import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
//import { ReactSVG } from 'react-svg';

function MapSvgItem(props) {
    const [textSvg, setTextSvg] = useState()
    useEffect(() => {
        const loadSvg = async () => {
            const response = await fetch(props.urlMap, {
                credentials: 'include',
                method: 'GET',
                headers: {
                    /* "Accept": "application/xml",*/
                    "Authorization": `Bearer ${props.userInfo.user.token}`,
                    //"Content-Type": "application/json"
                }
            });
            if (response.ok) { // если HTTP-статус в диапазоне 200-299
                // получаем тело ответа (см. про этот метод ниже)
                const text = await response.text();
                setTextSvg(text);
            } else {
                alert("Ошибка HTTP: " + response.status);
            }
        }
        loadSvg();
    }, [props.urlMap]);
    return textSvg!=null?renderHTML(textSvg):"";
    //const [urlMap, setUrlMap] = useState()
    //useEffect(() => {
    //    setUrlMap(`${document.location.protocol}//${document.location.host}/api/MedinfoApi/GetMapSvg2/${props.year}/${props.pageId}/${props.typePer}/${props.tobj}/${props.kobj}/${props.columnNo}/${props.bokNo}/${props.lang}`);
    //}, [props.year, props.pageId, props.typePer, props.tobj, props.kobj, props.columnNo, props.bokNo, props.lang]);
    /*
    const attachClickEvent = (elements = []) => {
        elements.forEach((element) => {
            element.addEventListener('click', (e) => {
                console.log('clicked', e);
            });
        });
    };
    return props.urlMap != null ? <ReactSVG
        src={props.urlMap} 
        httpRequestWithCredentials={true}
        afterInjection={(svg) => {
               console.info("afterInjection");
               const anchors = svg != null ? svg.querySelectorAll('path') : [];
               console.dir(anchors);
               attachClickEvent(anchors);
            }
        } /> : "";
       */

}
export default connect(
    state => state
)(MapSvgItem);