import React, { useState, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Button, Form, Table, Modal, Row, Col, Alert, OverlayTrigger, Tooltip, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import * as FileSaver from 'file-saver';
import { arTypePers, arTypeDiagram, getNameLocalize, periods, years, getCurrentYear } from './Constants';

import { actionCreators } from '../store/Medinfo/FormulMedinfo';
import { actionCreators as actionCreatorsNsi } from '../store/Nsi';


import MapSvgItem from './Map/MapSvgItem';

function MapSvg(props) {
    const [isLoading, setIsLoading] = useState(false);
    useState(() => {
        const loadParts = async () => {
            setIsLoading(true);
            let parts = await props.getParts();
            let selPart = (parts != null && parts.length > 0) ? parts[0] : undefined;
            setSelectPart(selPart);
            let pages = await props.getPagesOfPart(selPart.partId);
            let page0 = (pages != null && pages.length > 0) ? pages[0] : undefined;
            setSelectPage(page0);
            setIsLoading(false);
        }
        if (props.nsi.allobls.length === 0) {
            props.getNs('allobls');
        }
        loadParts();
    });
    /*const [] = useState(() => {
        if (props.nsi.allobls.length === 0) {
            props.getNs('allobls');
        }
        if (props.nsi.allraions.length === 0) {
            props.getNs('allraions');
        }
        return true;
    }, props.nsi.allobls.length > 0);*/
    
    const [selectPart, setSelectPart] = useState();
    const [selectPage, setSelectPage] = useState();
    //const [pageId, setPageId] = useState(630/*1026*/);//533,552, 553, 554, 630
    const [year, setYear] = useState(getCurrentYear());
    const [typePer, setTypePer] = useState(4);//4-год, 101- январь
    //const [oneMonth, setOneMonth] = useState("101");
    //const [tobj, setTobj] = useState(1);
    const [obl, setObl] = useState(props.userInfo.user.kodObl);
    //const [raion, setRaion] = useState(null);
    const [columnNo, setColumnNo] = useState(1);
    const [bokNo, setBokNo] = useState(1);
    const [lang, setLang] = useState(props.i18n.locale.substr(0, 2));
    const [urlMapRk, setUrlMapRk] = useState();
    const [urlMapObl, setUrlMapObl] = useState();
    const [orgsData, setOrgsData] = useState();
    const saveDataSvg = (e) => {
        e.preventDefault();
        //fetchExcel();
        setParamsRk();
    }
    const setParamsRk = () => {
        let url = `${document.location.protocol}//${document.location.host}/api/MedinfoApi/GetMapSvg2/${year}/${selectPage.pageId}/${typePer}/1/99/${columnNo}/${bokNo}/${lang}`;
        setUrlMapRk(url);
        let kobl = obl == null ? props.nsi.allobls.filter(f => filterObl(f))[0].kod : obl;
        setObl(kobl);
        let urlObl = `${document.location.protocol}//${document.location.host}/api/MedinfoApi/GetMapSvg2/${year}/${selectPage.pageId}/${typePer}/2/${kobl}/${columnNo}/${bokNo}/${lang}`;
        setUrlMapObl(urlObl);
        //getOblsData(kobl);
    }
    const changeObl = (kobl) => {
        setObl(kobl);
        let urlObl = `${document.location.protocol}//${document.location.host}/api/MedinfoApi/GetMapSvg2/${year}/${selectPage.pageId}/${typePer}/2/${kobl}/${columnNo}/${bokNo}/${lang}`;
        setUrlMapObl(urlObl);
        //getOblsData(kobl);
    }
    const savePngRk = (e) => {
        e.preventDefault();
        fetchPng(1);
    }
    const savePngObl = (e) => {
        e.preventDefault();
        fetchPng(2);
    }
    const getOblsData = async (kobl) => {
       setOrgsData(null);
       let oData = await props.getDataOrgsByObl({ pageId: selectPage.pageId, year, kobl, columnNo: columnNo, typePer, lang });
       setOrgsData(oData);
    }
    const fetchPng = async (tobj) => {
        let colCnt = columnNo - 1;
        if (selectPage.typePage === 2) {
            colCnt = (bokNo - 1) * selectPage.columns.length + columnNo - 1;//selectPage.columns.lenght
        }
        let absoluteUrl = `${document.location.protocol}//${document.location.host}/api/MedinfoApi/GetMapPng`;
        let kodObj = null;
        switch (tobj) {
            case 1:
                kodObj = "99";
                break;
            case 2:
                kodObj = obl;
                break;
            //case 3:
            //    kodObj = raion.kraion;
            //    break;
            default:
        }
        let fileName = `${selectPage.pageId}_${year}_${tobj}_${kodObj}_${colCnt}_${lang}.png`;
        let json = JSON.stringify({
            PageId: selectPage.pageId,
            Year: year,
            Tobj: tobj,
            Kobj: kodObj,
            ColumnNo: colCnt,
            Lang: lang//,
            //TypePer: typePer
        });
        const response = await fetch(absoluteUrl, {
            credentials: 'include',
            method: 'POST',
            headers: {
                /* "Accept": "application/xml",*/
                "Authorization": `Bearer ${props.userInfo.user.token}`,
                "Content-Type": "application/json"
            },
            body: json,
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const blob = await response.blob();
            FileSaver.saveAs(blob, fileName);
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    }
    const fetchExcel = async (tobj) => {
        let colCnt = columnNo - 1;
        if (selectPage.typePage === 2) {
            colCnt = (bokNo - 1) * selectPage.columns.length + columnNo - 1;//selectPage.columns.lenght
        }
        let absoluteUrl = `${document.location.protocol}//${document.location.host}/api/MedinfoApi/GetMapSvg`;
        let kodObj = null;
        switch (tobj) {
            case 1:
                kodObj = "99";
                break;
            case 2:
                kodObj = obl;
                break;
            //case 3:
            //    kodObj = raion.kraion;
            //    break;
            default:
        }
        let fileName = `${selectPage.pageId}_${year}_${tobj}_${kodObj}_${colCnt}_${lang}.svg`;
        let json = JSON.stringify({
            PageId: selectPage.pageId,
            Year: year,
            Tobj: tobj,
            Kobj: kodObj,
            ColumnNo: colCnt,
            Lang: lang//,
            //TypePer: typePer
        });
        const response = await fetch(absoluteUrl, {
            credentials: 'include',
            method: 'POST',
            headers: {
                /* "Accept": "application/xml",*/
                "Authorization": `Bearer ${props.userInfo.user.token}`,
                "Content-Type": "application/json"
            },
            body: json,
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const blob = await response.blob();
            FileSaver.saveAs(blob, fileName);
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    }
    const onClickPart = (e) => {
        let partId = parseInt(e.target.value, 10);
        setSelectPart(props.formulmedinfo.parts.filter(t => t.partId === partId)[0]);
        const loadPages = async () => {
            setIsLoading(true);
            let pages = await props.getPagesOfPart(partId);
            let page0 = (pages != null && pages.length > 0) ? pages[0] : undefined;
            setSelectPage(page0);
            if (page0 != null) {
                let bno = 0;
                if (page0.typePage === 2)
                    bno = 1;
                setBokNo(bno);
                setColumnNo(1);
            }
            setIsLoading(false);
        }
        loadPages();
    }
    const showListParts = () => {
        if (props.formulmedinfo.parts == null || props.formulmedinfo.parts.length === 0) return '';
        return <FormGroup controlId="formParts" bsSize='small'>
            <Col sm={2} componentClass={ControlLabel}><Translate value="part" />{':'}</Col>
            <Col xs={12} sm={10}>
                <FormControl className='input-sm' componentClass="select" placeholder="select" style={{ width: "100%" }}
                    defaultValue={selectPart} onChange={onClickPart}>
                    {props.formulmedinfo.parts.map(l => <option value={l.partId} key={l.partId}>{l.kod} {getNameLocalize(props.i18n, l)}</option>)}
                </FormControl></Col>
        </FormGroup>;
    }
    const onClickPage = (e) => {
        let pageId = parseInt(e.target.value, 10);
        let page = props.formulmedinfo.partPages.filter(t => t.pageId === pageId)[0];
        let bno = 0;
        if (page.typePage === 2)
            bno = 1;
        setBokNo(bno);
        setColumnNo(1);
        setSelectPage(page);
    }
    const showListPages = () => {
        if (selectPart == null || props.formulmedinfo.partPages == null || props.formulmedinfo.partPages.length === 0) return '';
        return <FormGroup controlId="formTypePage" bsSize='small'>
            <Col sm={2} componentClass={ControlLabel}><Translate value="page" />{':'}</Col>
            <Col xs={12} sm={10}>
                <FormControl className='input-sm' componentClass="select" placeholder="select" style={{ width: "100%" }}
                    defaultValue={selectPage} onChange={onClickPage}>
                    {props.formulmedinfo.partPages.map(p => <option value={p.pageId} key={p.pageId}>{p.kod} {getNameLocalize(props.i18n, p)}</option>)}
                </FormControl></Col>
        </FormGroup>;
    }
    const filterObl = (f) => {
        if (f.kod === "01") return false;
        if (year == null) return true;
        let ye = typeof year === 'string' ? parseInt(year, 10) : year;
        let dtBeg = f.dtBeg == null ? null : new Date(f.dtBeg);
        if (dtBeg != null && dtBeg.getFullYear() > ye) return false;
        let dtEnd = f.dtEnd == null ? null : new Date(f.dtEnd);
        if (dtEnd != null && ye >= dtEnd.getFullYear()) return false;
        return true;
    }
    return <Fragment>
        <Row>
            <Col md={12} lg={6}>
                <Form onSubmit={saveDataSvg} horizontal>
                    <Row>
                        <Col xs={12} sm={12}>
                            <FormGroup controlId="formYear" bsSize='small'>
                                <Col sm={2} componentClass={ControlLabel}><Translate value='year' />{':'}</Col>
                                <Col xs={12} sm={10}>
                                    <FormControl className='input-sm' componentClass="select" placeholder="select"
                                        defaultValue={year} onChange={(e) => setYear(parseInt(e.target.value, 10))} bsSize="small" style={{ width: "auto" }}>
                                        {years.map(it => <option key={it} value={it}>{it}</option>)}
                                    </FormControl></Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12}>
                            <FormGroup controlId="formMonth" bsSize='small'>
                                <Col sm={2} componentClass={ControlLabel}><Translate value="months" />{':'}</Col>
                                <Col xs={12} sm={8}>
                                    <FormControl className='input-sm' componentClass="select" placeholder="select" style={{ width: "auto" }}
                                        defaultValue={typePer} onChange={(e) => setTypePer(e.target.value)}>
                                        {periods.filter(p => p.kper == 4 || p.kper > 100).map(it => <option key={it.kper} value={it.kper}>{I18n.t(it.nameT)}</option>)}
                                    </FormControl>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    {/*<SelectObject2 isNotOrg={true} 
                        tobj={tobj}
                        obl={obl}
                        raion={raion}
                        setTobj={(typetobj) => setTobj(typetobj)}
                        setObl={(obl) => setObl({ obl })}
                        setRaion={(raion) => setRaion({ raion })}
                    />*/}
                    <Row>
                        <Col xs={12} sm={12}>
                            {showListParts()}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12}>
                            {showListPages()}
                        </Col>
                    </Row>
                    {selectPage != null ? <Fragment>
                        <Row>
                            <Col xs={12} sm={12}>
                                <FormGroup controlId="formIndicator" bsSize='small'>
                                    <Col sm={2} componentClass={ControlLabel}><Translate value="indicator" />{':'}</Col>
                                    <Col xs={12} sm={10}>
                                        <FormControl className='input-sm' componentClass="select" placeholder="select" style={{ width: "100%" }}
                                            value={columnNo} onChange={(e) => setColumnNo(parseInt(e.target.value,10))}>
                                            {selectPage.columns.map(it => <option key={it.npp} value={it.npp}>{getNameLocalize(props.i18n, it)}</option>)}
                                        </FormControl>
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        {selectPage.typePage === 2 ? <Row>
                            <Col xs={12} sm={12}>
                                <FormGroup controlId="formIndicatorBok" bsSize='small'>
                                    {/*<Col sm={2} componentClass={ControlLabel}><Translate value="indicator" />{':'}</Col>*/}
                                    <Col xs={12} sm={10} smOffset={2}>
                                        <FormControl className='input-sm' componentClass="select" placeholder="select" style={{ width: "100%" }}
                                            value={bokNo} onChange={(e) => setBokNo(parseInt(e.target.value, 10))}>
                                            {selectPage.boks.map(it => <option key={it.npp} value={it.npp}>{getNameLocalize(props.i18n, it)}</option>)}
                                        </FormControl>
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row> : ""}
                    </Fragment> : ""}
                    <Row>
                        <Col xs={12} sm={12}>
                            <FormGroup controlId="formLangDiagr" bsSize='small'>
                                <Col sm={2} componentClass={ControlLabel}><Translate value="lang" />{':'}</Col>
                                <Col xs={12} sm={10}>
                                    <FormControl className='input-sm' componentClass="select" placeholder="select" style={{ width: "auto" }}
                                        defaultValue={lang} onChange={(e) => setLang(e.target.value)}>
                                        <option key={'kk'} value={'kk'}>Қаз</option>)
                                        <option key={'ru'} value={'ru'}>Рус</option>)
                                    </FormControl>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Button type="submit" bsStyle="primary" disabled={isLoading===true}><Translate value="indicatorsMap.run" /></Button>{' '}
                </Form>
            </Col>
            <Col md={12} lg={6}>
                {urlMapRk != null ? <div style={{ border: "1px solid lightgray", padding: "3px" }}><Button type="button" bsSize="xsmall" onClick={savePngRk}><i className="bi bi-save"></i></Button><MapSvgItem urlMap={urlMapRk} /></div> : ""}
            </Col>
        </Row>
        {urlMapRk != null ? <Row>
            <Col md={12} lg={6}>
                <div style={{ border: "1px solid lightgray", padding: "3px" }}>
                    <FormGroup controlId="formObl" bsSize='small'>
                        <Col sm={2} componentClass={ControlLabel}><Translate value='obl' />{':'}</Col>
                        <Col xs={12} sm={10}>
                            <FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={obl} disabled={isLoading === true}
                                onChange={(e) => changeObl(e.target.value)} style={{ width: "auto" }}>
                                {props.nsi.allobls.filter(f => filterObl(f)).map(it => <option key={it.kod} value={it.kod}>{getNameLocalize(props.i18n, it)}</option>)}
                            </FormControl>
                        </Col>
                    </FormGroup>
                    {urlMapObl != null ? <Row><Col md={12} lg={6}><Button type="button" bsSize="xsmall" onClick={savePngObl}><i className="bi bi-save"></i></Button><MapSvgItem urlMap={urlMapObl} /></Col></Row> : ""}
                </div>
            </Col>
            <Col md={12} lg={6}>
                {orgsData != null && orgsData.objValues != null && orgsData.objValues.length > 0 ? <Table striped bordered condensed hover>
                    <thead>
                        <tr>
                            <th colSpan={2}>{getNameLocalize(props.i18n, orgsData)}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orgsData.objValues.map(m => <tr key={m.obj.kod}><td>{getNameLocalize(props.i18n, m.obj)}</td><td>{m.value > 0 ? new Intl.NumberFormat("ru-RU").format(m.value) : m.value}</td></tr>)}
                    </tbody></Table>:""}
            </Col>
        </Row> : ""}
    </Fragment>
}
export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionCreators }, dispatch)
)(MapSvg);