import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { i18nReducer } from 'react-redux-i18n';

import * as UserInfo from './UserInfo';
import * as AdminUsers from './AdminUsers';
import * as EditControl from './EditControl';
import * as Nsi from './Nsi';
import * as FinPaidData from './FinPaidData';
import * as Work from './Work';
import * as WorkPstr from './WorkPstr'; 
import * as Svod from './Svod';
import * as ControlForm from './ControlForm';
import * as Counter from './Counter';
import * as ClosingPeriod from './ClosingPeriod';
import * as FormulMedinfo from './Medinfo/FormulMedinfo';
import * as Gen47 from './Gen47';
import * as Log from './Log';
import * as MedstatTemplates from './Medstat/Templates';
import * as Register from './Register';
import * as WeatherForecasts from './WeatherForecasts';

export default function configureStore(history, initialState) {
    const reducers = {
        userInfo: UserInfo.reducer,
        adminUsers: AdminUsers.reducer,
        editFormul: EditControl.reducer,
        nsi: Nsi.reducer,
        finPaidData: FinPaidData.reducer,
        work: Work.reducer,
        workPstr: WorkPstr.reducer,
        svod: Svod.reducer,
        control: ControlForm.reducer,
        counter: Counter.reducer,
        closingPeriod: ClosingPeriod.reducer,
        register: Register.reducer,
        formulmedinfo: FormulMedinfo.reducer,
        gen47: Gen47.reducer,
        log: Log.reducer,
        medstatTemplates:MedstatTemplates.reducer,
        weatherForecasts: WeatherForecasts.reducer
    };

    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
        enhancers.push(window.devToolsExtension());
    }

    const rootReducer = combineReducers({
        ...reducers,
        routing: routerReducer,
        i18n: i18nReducer
    });

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
