import { cloneDeep } from 'lodash';
import { getToken } from './UserInfo';
const requestGen47FormAction = 'REQUEST_GEN47_FORM';
const receiveGen47FormAction = 'RECEIVE_GEN47_FORM';
const requestOrgsParamAction = 'REQUEST_ORGS_PARAM';
const receiveOrgsParamAction = 'RECEIVE_ORGS_PARAM';
const requestGen47UpdateOrgAction = 'REQUEST_GEN47_UPDATE_ORG';
const receiveGen47UpdateOrgAction = 'RECEIVE_GEN47_UPDATE_ORG';

const initialState = {
    isLoading: false,
    orgsParam: undefined,
    statusGen47: undefined,
}

export const actionCreators = {
    gen47Form: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestGen47FormAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/Gen47Api/Gen47Form', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveGen47FormAction, status: data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    loadParamOrgsByObl: (kobl) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestOrgsParamAction });
        const response = await fetch(`api/Gen47Api/LoadOrgsParam/${kobl}`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveOrgsParamAction, data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    updateOrg: (orgParam) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestGen47UpdateOrgAction });
        let json = JSON.stringify(orgParam);
        const response = await fetch('api/Gen47Api/UpdateOrg', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveGen47UpdateOrgAction, status: data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    } 
}
export const reducer = (state, action) => {
    state = state || initialState;
    //generate 47
    if (action.type === requestGen47FormAction) {
        return {
            ...state,
            isLoading: true,
            statusGen47: undefined,
        };
    }
    if (action.type === receiveGen47FormAction) {
        return {
            ...state,
            isLoading: false,
            statusGen47: action.status,
        };
    }
    //orgs params
    if (action.type === requestOrgsParamAction) {
        return {
            ...state,
            isLoading: true,
            orgsParam: undefined,
            statusGen47: undefined,
        };
    }
    if (action.type === receiveOrgsParamAction) {
        return {
            ...state,
            isLoading: false,
            statusGen47: { successed: action.data.successed, error: action.data.error},
            orgsParam: action.data.rows
        };
    }
    //update org
    if (action.type === requestGen47UpdateOrgAction) {
        return {
            ...state,
            isLoading: true,
            statusGen47: undefined,
        };
    }
    if (action.type === receiveGen47UpdateOrgAction) {
        if (action.status.error == null) {
            let orgsParam = cloneDeep(state.orgsParam);
            let index = orgsParam.map(e => e.kod).indexOf(action.status.orgParam.kod);
            orgsParam.splice(index, 1, action.status.orgParam);
            return {
                ...state,
                isLoading: false,
                orgsParam,
                statusGen47: action.status,
            };
        } else {
            return {
                ...state,
                isLoading: false,
                statusGen47: action.status,
            };
        }
    }
    return state;
}

