import React, { Component, Fragment, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { Button } from 'react-bootstrap';
import { actionCreators as actionCreatorsWork } from '../../store/Work';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';

import { getTikcs } from '../Constants';

import InfoSelectedForEdit from '../common/InfoSelectedForEdit';
import SelectTableForEdit from '../Modals/SelectTableForEdit';
import TableView2 from './TableView2';
class OpenForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSelectTable: true,
            selectParam: undefined,
            isAvariableDelete: false,
            childOrgs: [],
            tiks: getTikcs()
        };
        this.showModalSelect = this.showModalSelect.bind(this);
        this.onHideSelectModal = this.onHideSelectModal.bind(this);
        this.fetchFunc = this.fetchFunc.bind(this);
        this.commitModal = this.commitModal.bind(this);
        if (props.userInfo.user.nomenklKod === '008' || props.userInfo.user.nomenklKod === '049' || props.userInfo.user.nomenklKod === '043') {
            this.getSubOrgs();
        }
    }
    getSubOrgs() {
        var self = this;
        const fetchData = async () => {
            const childOrgs = await self.props.getSubOrgs();
            self.setState({ childOrgs });
        }
        fetchData();
    }
    showModalSelect() {
        this.setState({ showSelectTable: true });
    }
    onHideSelectModal() {
        this.setState({ showSelectTable: false });
    }
    getKobj = (selectParam) => {
        switch (selectParam.tobj.kod) {
            case 1://РК
                return "00";
            case 2://Область
                return selectParam.obl.kobl;
            case 3://Район
                return selectParam.raion.kraion;
            case 4://Организация
                return selectParam.org.korg;
            default:
        }
        return null;
    }
    commitModal(selectParam) {
        const com = async () => {
            let kobj = this.getKobj(selectParam);
            /*
            let isAvariableDelete = false;
            if (this.props.userInfo.user.accessLevel === 4 ||
                (this.props.userInfo.user.accessLevel === 3 && this.props.userInfo.user.nomenklKod === '008' || this.props.userInfo.user.nomenklKod === '049' || this.props.userInfo.user.nomenklKod === '043')) {
                if (selectParam.tobj.kod === 4 && (this.props.userInfo.user.kodOrg === kobj || this.state.childOrgs.filter(f => f.kod === kobj).length > 0)) {
                    isAvariableDelete = true;
                }
            } else if (this.props.userInfo.user.accessLevel === 2) {
                if (this.props.userInfo.user.kodOrg === kobj) {
                    isAvariableDelete = true;
                } else if (selectParam.year === 2022) {
                    if (selectParam.tobj.kod === 2 && kobj === this.props.userInfo.user.kodObl) {
                        isAvariableDelete = true;
                    } else if (selectParam.tobj.kod === 3 && kobj === this.props.userInfo.user.kodRaion) {
                        isAvariableDelete = true;
                    }
                } else {
                    isAvariableDelete= await props.getIsAvariableDelete({
                        year: selectParam.year,
                        period: selectParam.period.kper,
                        raz: selectParam.raz.kod,
                        tobj: selectParam.tobj.kod,
                        kobj: kobj,
                        kf: selectParam.form.kf,
                        kt: selectParam.table.ktab
                    });
                }
            }
            */
            let isAvariableDelete = await this.props.getIsAvariableDelete({
                year: selectParam.year,
                period: selectParam.period.kper,
                raz: selectParam.raz.kod,
                tobj: selectParam.tobj.kod,
                kobj: kobj,
                kf: selectParam.form.kf,
                kt: selectParam.table.ktab
            });
            this.setState({ selectParam, isAvariableDelete });
            await this.fetchFunc({
                year: selectParam.year,
                period: selectParam.period.kper,
                raz: selectParam.raz.kod,
                tobj: selectParam.tobj.kod,
                kobj: kobj,
                kf: selectParam.form.kf,
                kt: selectParam.table.ktab
            }, "getData");
        }
        com();
    }
    fetchFunc = async (p, func) => {
        await this.props[func](p);
        this.setState({ tiks: getTikcs() });
    }
    saveDataTable = (dataStore) => {
        this.props.updateData(dataStore);
    }
    printData = () => {
        window.print();
    }
    deleteDataTable = () => {
        let kobj = this.getKobj(this.state.selectParam);
        this.fetchFunc({
            Year: this.state.selectParam.year,
            Raz: this.state.selectParam.raz.kod,
            Period: this.state.selectParam.period.kper,
            Tobj: this.state.selectParam.tobj.kod,
            Kobj: kobj,
            Kf: this.state.selectParam.form.kf,
            Kt: this.state.selectParam.table.ktab
        }, "deleteData");
        this.setState({ tiks: getTikcs() });
        /*this.props.deleteData({
            Year: this.state.selectParam.year,
            Raz: this.state.selectParam.raz.kod,
            Period: this.state.selectParam.period.kper,
            Tobj: this.state.selectParam.tobj.kod,
            Kobj: kobj,
            Kf: this.state.selectParam.form.kf,
            Kt: this.state.selectParam.table.ktab
        });
        */
    }
    render() {
        return <Fragment>
            <h4><Translate value="openForm" /></h4>
            {this.state.selectParam == null ? '' :
                <InfoSelectedForEdit {...this.state.selectParam} i18n={this.props.i18n} />}
            {this.props.work.data !== undefined && this.props.work.data.rows !== null ?
                <TableView2 storeData={this.props.work.data}
                    isAvariableDelete={this.state.isAvariableDelete}
                    statusUpdate={this.props.work.statusUpdate}
                    statusDelete={this.props.work.statusDelete}
                    selectParam={this.showModalSelect}
                    saveData={this.saveDataTable}
                    isPostr={this.state.selectParam != null ? this.state.selectParam.isPostr : false}
                    printData={this.printData}
                    printControl={this.printControl}
                    deleteData={this.deleteDataTable}
                    tiks={this.tiks}
                /> : <Button onClick={this.showModalSelect}><Translate value="select" /></Button>}

            <SelectTableForEdit showModal={this.state.showSelectTable} selectParam={this.state.selectParam}
                handleHide={this.onHideSelectModal} handleCommit={this.commitModal} />
        </Fragment>;
    }
}
export default connect(
    state => state,
    //dispatch => bindActionCreators(actionCreators, dispatch)
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionCreatorsWork }, dispatch)
)(OpenForm);