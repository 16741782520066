import { I18n } from 'react-redux-i18n';
const tikcs1970 = (new Date(1970, 0, 1)).getTime();
export function getTikcs() {
    return (new Date()).getTime() - tikcs1970;
}
export const btnsMsgBox = {
    OK: 1,
    Yes: 2,
    No: 4,
    Retry: 8,
    Cancel: 16,
    Request: 32,
    Submit: 64,
    Save: 128
}
export const SpObl = [
    { kod: '10', lat: 48.554943, lon: 58.527792, nameKz: 'Абай облысы', nameRu: 'Абайская область' },
    { kod: '15', lat: 48.554943, lon: 58.527792, nameKz: 'Ақтөбе облысы', nameRu: 'Актюбинская область' },
    { kod: '11', lat: 53.115914, lon: 72.34160, nameKz: 'Ақмола облысы', nameRu: 'Акмолинская область' },
    { kod: '19', lat: 44.932466, lon: 78.953177, nameKz: 'Алматы облысы', nameRu: 'Алматинская область' },
    { kod: '23', lat: 47.449727, lon: 52.093519, nameKz: 'Атырау облысы', nameRu: 'Атырауская область' },
    { kod: '27', lat: 49.710363, lon: 50.653474, nameKz: 'Батыс Қазақстан облысы', nameRu: 'Западно - Казахстанская область' },
    { kod: '31', lat: 44.260678, lon: 71.160436, nameKz: 'Жамбыл облысы', nameRu: 'Жамбылская область' },
    { kod: '33', lat: 44.260678, lon: 71.160436, nameKz: 'Жетысуская область', nameRu: 'Жетісу облысы' },
    { kod: '35', lat: 48.212600, lon: 70.978720, nameKz: 'Қарағанды облысы', nameRu: 'Карагандинская область' },
    { kod: '39', lat: 51.538478, lon: 63.984541, nameKz: 'Қостанай облысы', nameRu: 'Костанайская область' },
    { kod: '43', lat: 44.532092, lon: 65.794929, nameKz: 'Қызылорда облысы', nameRu: 'Кызылординская область' },
    { kod: '47', lat: 44.019581, lon: 53.743994, nameKz: 'Маңғыстау облысы', nameRu: 'Мангистауская область' },
    //{ kod: '51', lat: 43.123301, lon: 68.522537, nameKz: 'Оңтүстік Қазақстан облысы', nameRu: 'Южно - Казахстанская область' },
    { kod: '55', lat: 52.007516, lon: 76.264524, nameKz: 'Павлодар облысы', nameRu: 'Павлодарская область' },
    { kod: '59', lat: 54.645258, lon: 68.023873, nameKz: 'Солтүстік Қазақстан облысы', nameRu: 'Северо - Казахстанская область' },
    { kod: '61', lat: 43.259836, lon: 76.855078, nameRu: 'Түркістан', nameKz: 'Туркестанская' },
    { kod: '62', lat: 44.260678, lon: 71.160436, nameKz: 'Ұлытау облысы', nameRu: 'Улытауская область' },
    { kod: '63', lat: 48.684012, lon: 81.510883, nameKz: 'Шығыс Қазақстан облысы', nameRu: 'Восточно - Казахстанская область' },
    { kod: '71', lat: 51.148792, lon: 71.310570, nameRu: 'г.Астана', nameKz: 'Астана қ.' },
    { kod: '75', lat: 43.259836, lon: 76.855078, nameRu: 'Алматы', nameKz: 'қ.Алматы Г.А.' },
    { kod: '79', lat: 43.259836, lon: 76.855078, nameRu: 'Шымкент', nameKz: 'Шымкент қ.' },
];
export const SpRoles = [
    { kod: 'EditAdmins', nameRu: 'Администраторы администраторов', nameKz: 'Әкімшілер әкімшілері', commentRu: "Создание администраторов пользователей и данных" },
    { kod: 'EditUsers', nameRu: 'Администраторы пользователей', nameKz: 'Пайдаланушы әкімшілері', commentRu: "Создание простых пользователей" },
    { kod: 'AdminEditData', nameRu: 'Администратор данных(Supervisor)', nameKz: 'Деректер әкімшісі(Supervisor)', commentRu: "Администратор данных(ввод и редактипрование данных)" },
    // { kod: 'CommonNsi', nameRu: 'Справочники', nameKz: 'Анықтамалар' },
    { kod: 'EditFin', nameRu: 'Учет оплаты за использование портала', nameKz: 'Порталды пайдаланғаны үшін төлемдерді есепке алу', commentRu: "Учет оплаты за использование портала" },
    { kod: 'UserEditData', nameRu: 'Ввод/Коорекция данных пользователем', nameKz: 'Пайдаланушы деректерді енгізу / түзету', commentRu: "Ввод/Коорекция данных простым пользователем" },
    { kod: 'UserReadData', nameRu: 'Только чтение данных простым пользователем', nameKz: 'Қарапайым пайдаланушының мәліметтерін ғана оқыңыз', commentRu: "Только чтение данных простым пользователем" }
];
export const years = [/*2000, 2001, 2002,*/ 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025];
export const periods = [
   /* { kper: 1, nameT: "period1" },
    { kper: 2, nameT: "period2" },
    { kper: 3, nameT: "period3" },*/
    { kper: 4, nameT: "period4" },
    { kper: 10, nameT: "period10" },
    { kper: 20, nameT: "period20" },
    { kper: 21, nameT: "period21" },
    { kper: 30, nameT: "period30" },
    { kper: 31, nameT: "period31" },
    { kper: 40, nameT: "period40" },
    { kper: 101, nameT: "period101" },
    { kper: 102, nameT: "period102" },
    { kper: 103, nameT: "period103" },
    { kper: 104, nameT: "period104" },
    { kper: 105, nameT: "period105" },
    { kper: 106, nameT: "period106" },
    { kper: 107, nameT: "period107" },
    { kper: 108, nameT: "period108" },
    { kper: 109, nameT: "period109" },
    { kper: 110, nameT: "period110" },
    { kper: 111, nameT: "period111" },
    { kper: 112, nameT: "period112" }
];
export const arTypePers = [
    { id: 1, nameT: "years" },
    { id: 2, nameT: "quarter" },
    { id: 3, nameT: "months" },
    { id: 4, nameT: "quarters" },
    { id: 5, nameT: "quartersone" }
];
export const arTypeDiagram = [
    { id: 1, nameT: "diagrGist" },
    { id: 2, nameT: "diagrGraph" },
    { id: 3, nameT: "diagrCircle" },
    { id: 4, nameT: "diagrline" },
    { id: 5, nameT: "diagrMap" }
];
export const version = "2024.0.16";
export function getTextVersion() {
    if (document.location.host === "medstat.minzdrav.kz") {
        return `Версия: ${version}`;
    } else {
        return `Тестовая версия: ${version}`;
    }
}
export function getNameLocalize(i18n, object) {
    if (object == null) return '';
    if (i18n.locale[0] === 'r') {
        return object['nameRu'];
    } else {
        return object['nameKz'];
    }
}
export function getNameSprLocalize(i18n, spr, kod, fldkey) {
    if (kod == null) return '';
    let k = "kod";
    if (fldkey != null)
        k = fldkey;
    let objs = spr.filter(f => f[k] === kod);
    if (objs.length === 0) return '';
    let object = objs[0];
    if (i18n.locale[0] === 'r') {
        return object['nameRu'];
    } else {
        return object['nameKz'];
    }
}
export function getNamePeriod(kper) {
    if (kper == null) return '';
    let per = periods.filter(f => f.kper === kper);
    if (per.length === 0) return '';
    return I18n.t(per[0].nameT);
}
export function getCurrentYear() {
    return parseInt(new Date().getFullYear(), 10);// - 1;
}
export function formatDateYYYYDDMM(date) {
    if (date == null)
        return "";
    else if (date instanceof Date)
        ;
    else if (typeof date === "string" && date.length > 0)
        date = new Date(date);
    else
        return "";
    var yyyy = date.getFullYear().toString();
    var mm = (date.getMonth() + 1).toString();
    var dd = date.getDate().toString();

    var mmChars = mm.split('');
    var ddChars = dd.split('');

    return yyyy + '-' + (mmChars[1] ? mm : "0" + mmChars[0]) + '-' + (ddChars[1] ? dd : "0" + ddChars[0]);
}
export function formatDate(date) {
    if (date == null)
        return "";
    if (date instanceof Date)
        return date.toLocaleDateString('ru-RU');
    if (typeof date === "string" && date.length > 0)
        return (new Date(date)).toLocaleDateString('ru-RU');
    return "";
}
export function formatDateTime(date) {
    if (date == null)
        return "";
    if (date instanceof Date)
        return `${date.toLocaleDateString('ru-RU')} ${date.toLocaleTimeString('ru-RU')}`;
    if (typeof date === "string" && date.length > 0) {
        let dt = new Date(date);
        return `${dt.toLocaleDateString('ru-RU')} ${dt.toLocaleTimeString('ru-RU')}`;
    }
    return "";
}
export const getFirstRaz = (kper, props) => {
    if (props.userInfo.user.accessRaz.length > 0) {
        if (kper === 4) {
            let arR = props.userInfo.user.accessRaz.filter(f => f.isGod === true);
            if (arR.length > 0) {
                return arR[0].raz;
            }
        } else {
            let arR = props.userInfo.user.accessRaz.filter(f => f.isMes === true);
            if (arR.length > 0) {
                return arR[0].raz;
            }
        }
    }
    return null;
}
export const getFirstFormTable = (kper, props) => {
    let accessForm = props.userInfo.user.accessForm;
    if (accessForm.length > 0) {
        if (kper === 4) {
            let aform = accessForm.filter(f => f.isGod === true);
            if (aform.length > 0) {
                return { form: aform[0], table: props.nsi.allsprtab.filter(spr => spr.kf === aform[0].kf)[0] };
            } else {
                return { form: props.userInfo.user.firstFormTable.formGod, table: props.userInfo.user.firstFormTable.tableGod }
            }
        } else {
            let aform = accessForm.filter(f => f.isMes === true);
            if (aform.length > 0) {
                return { form: aform[0], table: props.nsi.allsprtab.filter(spr => spr.kf === aform[0].kf)[0] };
            } else {
                return { form: props.userInfo.user.firstFormTable.formMes, table: props.userInfo.user.firstFormTable.tableMes }
            }
        }
    } else {
        return { form: null, table: null };
        //if (kper === 4) {
        //    return { form: props.userInfo.user.firstFormTable.formGod, table: props.userInfo.user.firstFormTable.tableGod }
        //} else {
        //    return { form: props.userInfo.user.firstFormTable.formMes, table: props.userInfo.user.firstFormTable.tableMes }
        //}
    }
}
export const getRazItems = (props, kper) => {
    const filterRaz = (accessRaz, kod) => {
        if (accessRaz.length > 0) {
            if (kper === 4) {
                return accessRaz.filter(f => f.isGod === true && f.raz === kod).length > 0;
            } else {
                return accessRaz.filter(f => f.isMes === true && f.raz === kod).length > 0;
            }
        }
        return true;
    }
    if (props.userInfo.user.accessRaz.length === 0) return props.nsi.sprraz.map(it => <option key={it.kod} value={it.kod}>{getNameLocalize(props.i18n, it)}</option>);
    if (kper === 4) {
        if (props.userInfo.user.accessRaz.filter(f => f.isGod === true).length === 0)
            return props.nsi.sprraz.map(it => <option key={it.kod} value={it.kod}>{getNameLocalize(props.i18n, it)}</option>);
    } else {
        if (props.userInfo.user.accessRaz.filter(f => f.isMes === true).length === 0)
            return props.nsi.sprraz.map(it => <option key={it.kod} value={it.kod}>{getNameLocalize(props.i18n, it)}</option>);
    }
    return props.nsi.sprraz.filter(f => filterRaz(props.userInfo.user.accessRaz, f.kod)).map(it => <option key={it.kod} value={it.kod}>{getNameLocalize(props.i18n, it)}</option>);
}
export const filterForms = (accessForm, kf, kper) => {
    if (accessForm.length > 0) {
        if (kper === 4) {
            if (accessForm.filter(f => f.isGod === true).length === 0) return true;
            return accessForm.filter(f => f.isGod === true && f.kf === kf).length > 0;
        } else {
            if (accessForm.filter(f => f.isMes === true).length === 0) return true;
            return accessForm.filter(f => f.isMes === true && f.kf === kf).length > 0;
        }
    }
    return true;
}
export const filterRazdels = (sprForm, accessForm, krazd, kper) => {
    if (accessForm.length > 0) {
        if (kper === 4) {
            return sprForm.filter(spr => spr.razdels.length > 0 && spr.razdels.includes(krazd) &&
                (accessForm.filter(f => f.isGod === true).length === 0 || accessForm.filter(f => f.isGod === true && f.kf === spr.kf).length > 0)
            ).length > 0;
        } else {
            return sprForm.filter(spr => spr.razdels.length > 0 && spr.razdels.includes(krazd) &&
                (accessForm.filter(f => f.isMes === true).length === 0 || accessForm.filter(f => f.isMes === true && f.kf === spr.kf).length > 0)
            ).length > 0;
        }
    } else {
        return sprForm.filter(spr => spr.razdels.length > 0 && spr.razdels.includes(krazd)).length > 0;
    }
    //return true;
}
export const forms12Quart = ["70","71","72","73","74"];
//export function filterRaz(kod){
//    return kod === 3 || kod === 4 || kod === 5 || kod === 6 || kod === 12;
//}
