import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Button, Col, FormGroup, FormControl, ControlLabel, Form, Row, Alert } from 'react-bootstrap';

import { actionCreators as actionCreatorsSvod } from '../../store/Svod';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';
import SelectMultiObjects from '../common/SelectMultiObjects';
import SelectMultiForms from '../common/SelectMultiForms';


import { getNameLocalize, btnsMsgBox } from '../Constants';
import FilterRazOptions from '../common/FilterRazOptions';
import MessageBox from '../common/MessageBox';

class Copy12MonthToYear extends Component {
    constructor(props) {
        super(props);
        let nowYear = parseInt(new Date().getFullYear(), 10);//-1;
        this.state = {
            year: props.year !== undefined ? props.year : nowYear-1,
            kraz: 1,//this.props.userInfo.user.accessLevel !== 4 ? 1 : 3, //разрез
            org:{ kod: props.userInfo.user.kodOrg, nameRu: props.userInfo.user.nameOrg, nameKz: props.userInfo.user.nameOrgKz },
            kforms: [],
            isSvodComplete: false,
            showMsg:false
        };
        //props.getNs('periods');
        if (props.nsi.sprraz.length === 0) props.getNs('sprraz');
        this.onSvodForm = this.onSvodForm.bind(this)
    }
    onSvodForm = (e) => {
        e.preventDefault();
        this.setState({ isSvodComplete: false });
        if (this.state.kforms.length === 0) {
            alert(I18n.t("selectForms"));
            return;
        }
        this.setState({ showMsg: true });
    }
    messageAction = (act) => { 
      switch(act){
            case btnsMsgBox.Yes:
              this.props.copyData12MonthToYear({
                    year: this.state.year,
                    kraz: this.state.kraz,
                    korg:this.state.org.kod,
                    kforms: this.state.kforms,
               });
             this.setState({ isSvodComplete: true });
             break;
            default:
                break;
        }    
       this.setState({ showMsg: false });
   }
   onSelectKraz = (e) => {
        let kraz = parseInt(e.target.value, 10);
        this.setState({ kraz });
    }

    render() {
        let isReadOnLy = this.props.userInfo.user.roles.filter(r => r === 'UserReadData').length > 0;
        if (isReadOnLy === true)
            return <Translate value="noAccess" />
        else
        return <Form onSubmit={this.onSvodForm} horizontal>
            <h5 style={{ textAlign: "center" }}><strong><Translate value="copy12MonthYoYear" /></strong></h5>
            <Row>
                <Col smOffset={2} sm={10}>
                    <Alert bsStyle="info"><p dangerouslySetInnerHTML={{ __html: I18n.t("copy12MonthYoYearComment", {year:this.state.year}) }}></p></Alert>
                </Col>      
            </Row>
            <FormGroup controlId="formRaz" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='razres' />{':'}</Col>
                <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={this.state.kraz}
                    onChange={this.onSelectKraz} bsSize='small' style={{ width: "auto" }}>
                    <FilterRazOptions />
                </FormControl></Col>
            </FormGroup>
           <FormGroup controlId="formOrg" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='org' />{':'}</Col>
                <Col sm={10}><strong>{this.state.org.kod} {getNameLocalize(this.props.i18n, this.state.org)}</strong></Col>
            </FormGroup>
            <SelectMultiForms setForms={(kforms) => this.setState({ kforms })} no47={this.state.tobj === 3} />
            <FormGroup>
                <Col smOffset={2} sm={10}>
                    <Button className='btn btn-primary text-center' type="submit" disabled={this.props.isLoading}><Translate value="copyData"/></Button>
                </Col>
            </FormGroup>
            {this.props.isLoading === true ? <Row>
                <Col smOffset={2} sm={10}>
                    <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('waitsvod')} width={20} />{' '}<strong><Translate value="waitsvod" /></strong></div>
                </Col>
            </Row> : ''}
            {this.state.isSvodComplete === true && this.props.svod.statusSvod != null && this.props.svod.statusSvod.successed === true ? <Row>
                <Col smOffset={2} sm={10}>
                    <Alert bsStyle="success"><Translate value="copyDataComplete" /></Alert>
                </Col>
            </Row> : ''}
            {this.state.isSvodComplete === true && this.props.svod.statusSvod != null && this.props.svod.statusSvod.error != null ? <Row>
                <Col smOffset={2} sm={10}>
                    <Alert bsStyle="danger">{this.props.svod.statusSvod.error}</Alert>
                </Col>
            </Row> : ''}
            <MessageBox show={this.state.showMsg} titleMsg={I18n.t("msgCopyData")} warningHtml={I18n.t("textCopyData", {year:this.state.year})} message={I18n.t("textCopyData2")} buttons={btnsMsgBox.Yes | btnsMsgBox.Cancel} close={(action) => this.messageAction(action)} />
        </Form>;
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionCreatorsSvod }, dispatch)
)(Copy12MonthToYear);