import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Button, Col, FormGroup, FormControl, ControlLabel, Form, Row, Alert, Checkbox, Radio } from 'react-bootstrap';

import { actionCreators as actionCreatorsGen47 } from '../../store/Gen47';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';



import { years, getCurrentYear } from '../Constants';

class Gen47Form extends Component {
    constructor(props) {
        super(props);
        let nowYear = getCurrentYear();
        this.state = {
            year: props.year !== undefined ? props.year : nowYear,
            kper: 4,//период
            kraz: 1, //разрез
            tobj: 3,//props.userInfo.user.accessLevel, // тип обьекта 1 – РК; 2 – область; 3 - район; 4 - организация (доступ зависит от пользователя)
            obl: undefined,
            kobl: props.userInfo.user.kodObl,
            raion: undefined,
            kobls: [],
            kraions: [],
            kform: '47',
            ktabs: [],
            isAllTabs:false,
            isGen47Complete: false
        };
        if (props.nsi.allobls.length === 0) props.getNs('allobls');
        if (props.nsi.allraions.length === 0) props.getNs('allraions');
        //if (props.nsi.sprform.length === 0) {
        //    props.getNs('sprform');
        //}
        if (props.nsi.allsprtab.length === 0) {
            props.getNs('allsprtab');
        }
        if (props.nsi.sprraz.length === 0) props.getNs('sprraz');
        if (props.nsi.periods.length === 0) props.getNs('periods');
        this.onGen47Form = this.onGen47Form.bind(this);
        this.onSelectKobl = this.onSelectKobl.bind(this);
        this.onCheckRaion = this.onCheckRaion.bind(this);
        this.onCheckTab = this.onCheckTab.bind(this);
        this.onChangeIsAllTabs = this.onChangeIsAllTabs.bind(this);
        this.filterRaion = this.filterRaion.bind(this);
    }
    onGen47Form = (e) => {
        e.preventDefault();
        this.setState({ isSvodComplete: false });
        /*
        let kobjs = null;
        switch (this.state.tobj) {
            case 1:
                kobjs = ["00"];
                break;
            case 2:
                if (this.props.userInfo.user.accessLevel === 2) {
                    kobjs = [];
                    kobjs.push(this.state.obl.kobl);
                } else {
                    kobjs = this.state.kobls;
                }
                break;
            case 3:
                if (this.props.userInfo.user.accessLevel === 3) {
                    kobjs = [];
                    kobjs.push(this.state.raion.kraion);
                } else {
                    kobjs = this.state.kraions;
                }
                break;
            default:
        }
        */
        if (this.state.kraions.length === 0) {
            alert("Выберте районы");
            return;
        }
        
        if (this.state.ktabs.length === 0 && this.state.isAllTabs === false) {
            alert("Выберте таблицы");
            return;
        }
        this.props.gen47Form({
            year: this.state.year,
            kper: this.state.kper,
            kraz: this.state.kraz,
            tobj: this.state.tobj,//всегда 3 - районы
            kobjs: this.state.kraions,
            kform: this.state.kform,
            ktabs: this.state.ktabs,
            isAllTabs: this.state.isAllTabs
        });
        this.setState({ isGen47Complete: true });
    }
    onSelectYear = (e) => {
        let year = parseInt(e.target.value, 10);
        this.setState({ year });
    }
    onSelectKper = (e) => {
        let kper = parseInt(e.target.value, 10);
        this.setState({ kper });
    }
    onSelectKraz = (e) => {
        let kraz = parseInt(e.target.value, 10);
        this.setState({ kraz });
    }
    onSelectKobl(e) {
        let kobl = e.target.value;
        let first_raion = this.props.nsi.allraions.filter(r => r.obl === kobl);
        let nameRu = this.props.nsi.allobls.filter(r => r.kod === kobl)[0].nameRu;
        this.setState({
            kobl,
            obl: { kobl, nameRu },
            kraion: first_raion === undefined || first_raion.length === 0 ? undefined : first_raion[0].idRaion,
            kobls: [], kraions: []
        });
    }
    onSelectKForm = (e) => {
        let kform = e.target.value;
        this.setState({ kform, ktabs:[] });
    }
    getValidationStateRaions() {
        const length = this.state.kraions.length;
        if (length > 0)
            return 'success';
        if (length === 0) {
            return 'error';
        }
        return null;
    }
    onCheckRaion = (e) => {
        let checked = e.target.checked;
        let kraion = e.target.value;
        let kraions = [...this.state.kraions];
        if (checked) {
            kraions.push(kraion);
        } else {
            let ind = kraions.indexOf(kraion);
            kraions.splice(ind, 1);
        }
        this.setState({ kraions });
    }
    getValidationStateTables() {
        const length = this.state.ktabs.length;
        if (length > 0 || this.state.isAllTabs===true)
            return 'success';
        if (length === 0) {
            return 'error';
        }
        return null;
    }
    onCheckTab = (e) => {
        let checked = e.target.checked;
        let kf = e.target.value;
        let ktabs = [...this.state.ktabs];
        if (checked) {
            ktabs.push(kf);
        } else {
            let ind = ktabs.indexOf(kf);
            ktabs.splice(ind, 1);
        }
        this.setState({ ktabs });
    }
    onChangeIsAllTabs(e) {
        let vl = e.target.value;
        let checked = e.target.checked;
        if (vl === "1" && checked)
            this.setState({ isAllTabs: true, ktabs: [] });
        else
            this.setState({ isAllTabs: false });
    }
    renderErrors = () => {
        //console.dir(this.props.gen47.statusGen47);
        if (this.props.gen47.statusGen47 != null && this.props.gen47.statusGen47.dictErrors != null
            && Object.entries(this.props.gen47.statusGen47.dictErrors).length>0
            /*this.props.gen47.statusGen47.successed === false*/) {
            return (<Row>
                <Col smOffset={2} sm={10}>
                    <h5>При генерации были обнаружены следующие ошибки в формулах (обратитесь к разаботчику):</h5>
                    {Object.entries(this.props.gen47.statusGen47.dictErrors).map((val) => <div key={val[0]} style={{ color: "red" }}>{val[0]} ошибка: {val[1]}</div>)}
            </Col>
            </Row>);
        } else {
            return '';
        }
    }
    filterRaion(rai, year) {
        if (year == null) return true;
        let ye = typeof year === 'string' ? parseInt(year, 10) : year;
        let dtBeg = rai.dtBeg == null ? null : new Date(rai.dtBeg);
        if (dtBeg != null && dtBeg.getFullYear() > ye) return false;
        let dtEnd = rai.dtEnd == null ? null : new Date(rai.dtEnd);
        if (dtEnd != null && ye >= dtEnd.getFullYear()) return false;
        return true;
    }
    //<Row bsSize='small' style={{paddingBottom:"5px"}}><Col smOffset={2}><a href="downloads/gen47Instr.pdf" style={{ fontWeight: "bold", textDecoration: "underline" }} download><Translate value="gen47Instr" /></a></Col></Row>
    render() {
        let isReadOnLy = this.props.userInfo.user.roles.filter(r => r === 'UserReadData').length > 0;
        if (isReadOnLy === true)
            return <Translate value="noAccess" />
        else
        return <Form onSubmit={this.onGen47Form} horizontal>
            <h5 style={{ textAlign: "center" }}><strong>Генерация 47 формы</strong></h5>
            <FormGroup controlId="formYear" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='year' />{':'}</Col>
                <Col sm={10}>
                    <FormControl className='input-sm' componentClass="select" placeholder="select"
                        defaultValue={this.state.year} onChange={this.onSelectYear} bsSize="small" style={{ width: "auto" }}>
                        {years.map(it => <option key={it} value={it}>{it}</option>)}
                    </FormControl></Col>
            </FormGroup>
            <FormGroup controlId="formPer" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='period' />{':'}</Col>
                <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={`${this.state.kper}`}
                    onChange={this.onSelectKper} bsSize='small' style={{ width: "auto" }}>
                    {this.props.nsi.periods.map(it => <option key={it.kper} value={it.kper}>{it.nameRu}</option>)}
                </FormControl></Col>
            </FormGroup>
            <FormGroup controlId="formRaz" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='razres' />{':'}</Col>
                <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={this.state.kraz} 
                    onChange={this.onSelectKraz} bsSize='small' style={{ width: "auto" }}>
                    {this.props.nsi.sprraz.map(it => <option key={it.kod} value={it.kod}>{it.nameRu}</option>)}
                </FormControl></Col>
            </FormGroup>
            <FormGroup controlId="formObl" bsSize='small'>
                <Col sm={2} className="text-right"><ControlLabel><Translate value='obl' />{':'}</ControlLabel></Col>
                <Col sm={10}>
                    {this.props.userInfo.user.accessLevel > 1 ? <div>{this.state.kobl} {this.props.userInfo.user.nameObl}</div> :
                        <select value={this.state.kobl} onChange={this.onSelectKobl} className="input-sm form-control input-sm" style={{ width:'auto'}}>
                            {this.props.nsi.allobls/*.filter(f=> f.kod!=='01')*/.map(it => <option key={it.kod} value={it.kod}>{it.nameRu}</option>)}
                        </select>}
                </Col>
            </FormGroup>
            <FormGroup controlId="formObl" bsSize='small' validationState={this.getValidationStateRaions()}>
                <Col sm={2} className="text-right"><ControlLabel><Translate value='raion' />{':'}</ControlLabel></Col>
                <Col sm={10}>
                    {this.props.userInfo.user.accessLevel > 2 ? <div>{this.state.kraion} {this.props.userInfo.user.nameRaion}</div> :
                        this.props.nsi.allraions.filter(r => r.obl === this.state.kobl && this.filterRaion(r, this.state.year)).map(it => <Checkbox onChange={this.onCheckRaion} key={it.kod} value={it.idRaion} inline>{it.nameRu}</Checkbox>)}
                </Col>
            </FormGroup>
            <FormGroup controlId="formKf" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='forma' />{':'}</Col>
                <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={this.state.kform}
                    onChange={this.onSelectKform} bsSize='small' style={{ width: "auto" }} disabled={true}>
                    <option key={'47'} value={'47'}>Форма 47</option>
                    <option key={'65'} value={'65'} disabled={true}>Форма 1-здрав</option>
                    <option key={'80'} value={'80'} disabled={true}>Форма 15</option>
                </FormControl></Col>
            </FormGroup>
            <FormGroup  onChange={this.onChangeIsAllTabs}>
                <Col smOffset={2} sm={10}>
                    <Radio name="radioGroupTabls" value={1} defaultChecked={this.state.isAllTabs === true} inline>Все таблицы</Radio>{' '}
                    <Radio name="radioGroupTabls" value={2} defaultChecked={this.state.isAllTabs === false} inline>Выбор таблицы</Radio>{' '}
                </Col>
            </FormGroup>
            <FormGroup controlId="formKtab" bsSize='small' validationState={this.getValidationStateTables()}>
                <Col sm={2} className="text-right"><ControlLabel><Translate value='table' />{':'}</ControlLabel></Col>
                <Col sm={10}>
                    {this.props.nsi.allsprtab.filter(f => f.kf === this.state.kform).map(it => <Checkbox onChange={this.onCheckTab} key={`${it.kf}${it.ktab}`} value={`${it.ktab}`} inline>{it.ktab} {it.nameRu}</Checkbox>)}
                </Col>
            </FormGroup>
           
            <FormGroup>
                <Col smOffset={2} sm={10}>
                    <Button className='btn btn-primary text-center' type="submit">Сгенерировать форму</Button>
                </Col>
            </FormGroup>
            {this.props.gen47.isLoading === true ? <Row>
                <Col smOffset={2} sm={10}>
                    <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('wait')} width={20} />{' '}<strong><Translate value="wait" /></strong></div>
                </Col>
            </Row> : ''}
            {this.state.isGen47Complete === true && this.props.gen47.statusGen47 != null && this.props.gen47.statusGen47.successed === true ? <Row>
                <Col smOffset={2} sm={10}>
                    <Alert bsStyle="success">Форма сфомирована!</Alert>
                </Col>
            </Row> : ''}
            {this.renderErrors()}
        </Form>;
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionCreatorsGen47 }, dispatch)
)(Gen47Form);
/*
  <Row>
                <Col smOffset={2} sm={10}> <a href="downloads/generate_47.pdf" download><strong>Инструкция по генерации 47 формы</strong></a></Col>
            </Row>
 */