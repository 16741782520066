import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import * as FileSaver from 'file-saver';
import { Collapse, Form, Panel, Row, Col, FormGroup, FormControl, ControlLabel, Button, ButtonGroup } from 'react-bootstrap';

import SelectFormTable from '../common/SelectFormTable';
import SelectObject from '../common/SelectObject';
import SelectClassMkb from '../common/SelectClassMkb';
import { actionCreators as actionCreatorsWorkPstr } from '../../store/WorkPstr';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';
import TableViewPstr from './TableViewPstr';
import { getNameLocalize, /*periods,*/ years, getCurrentYear, forms12Quart } from '../Constants';

import FilterRazOptions from '../common/FilterRazOptions';
import { string } from 'prop-types';


class OpenFormPstr extends Component {
    constructor(props) {
        super(props);
        let nowYear = getCurrentYear();
        this.state = {
            year: props.year !== undefined ? props.year : nowYear,
            kper: 4,//период
            kraz: 1,//this.props.userInfo.user.accessLevel !== 4 ? 1 : 3, //разрез
            form: { kf: "01", nameRu: "Численность постоянного населения РК", nameKz: "Қазақстан Республикасының тұрақты тұрғындары" },
            table: { kf: "01", ktab: "0001", nameRu: "Постоянное население Республики Казахстан", nameKz: "Қазақстан Республикасының тұрақты тұрғындары" },
            tobj: props.userInfo.user.accessLevel, // тип обьекта 1 – РК; 2 – область; 3 - район; 4 - организация (доступ зависит от пользователя)
            obl: undefined,
            raion: undefined,
            strObj: 0, //0- районы, 1- организации, построчку показывать в разрезе организаций, иначе в разрезе районов, только для tobj=2 - область  
            isChilds: 0, //0- по району, 1- по подчиненым организациям
            isShowParams: true,
            isLoadingExcel: false,
            krazd: undefined,
            classMkb: undefined
        };
        if (props.nsi.allsprtab.length === 0) {
            props.getNs('allsprtab');
        }
        if (props.nsi.sprform.length === 0) {
            props.getNs('sprform');
        }
        if (props.nsi.periods.length === 0) {
            props.getNs('periods');
        }
        if (props.nsi.sprraz.length === 0) {
            props.getNs('sprraz');
        }
        if (props.nsi.sprrazdel.length === 0) {
            props.getNs('sprrazdel');
        }
        this.onOpenTable = this.onOpenTable.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.printExcel = this.printExcel.bind(this);
        this.selectMkbClass = this.selectMkbClass.bind(this);
        this.selTable = this.selTable.bind(this);
        this.setTobj = this.setTobj.bind(this);
        //this.onControlForm = this.onControlForm.bind(this)
        //this.onSelectForm = this.onSelectForm.bind(this);
    }
    mode() {
        if (this.state.isShowParams === true) {
            return "show";
        }
        return "hide";
    }
    handleShow() {
        let isShowParams = !this.state.isShowParams;
        this.setState({ isShowParams });
    }
    handleHide() {
        this.setState({ isShowParams: false });
    }
    getNamePeriod = () => {
        switch (this.state.kper) {
            case 1:
                return <Fragment><strong><Translate value="period" />{':'}</strong>за 1-й квартал {this.state.year} года</Fragment>;
            case 2:
                return <Fragment><strong><Translate value="period" />{':'}</strong>за 1-ое полугодие {this.state.year} года</Fragment>;
            case 3:
                return <Fragment><strong><Translate value="period" />{':'}</strong>за 9 месяцев {this.state.year} года</Fragment>;
            case 4:
                return <Fragment><strong><Translate value="period" />{':'}</strong><Translate value="year3" year={this.state.year} /></Fragment>;
            default:
                var per = this.props.nsi.periods.filter(it => it.kper === this.state.kper)[0];
                var month = getNameLocalize(this.props.i18n, per);
                return <Fragment><strong><Translate value="period" />{':'}</strong> <Translate value="yearMonth" year={this.state.year} month={month} /></Fragment>;
        }
        return '';
    }
    getNameRazres = () => {
        if (this.props.nsi.sprraz != null && this.props.nsi.sprraz.length > 0) {
            return this.props.nsi.sprraz.filter(p => p.kod === this.state.kraz)[0];
        }
        return null;
    }
    /*this.props.nsi.sprraz.*/
    showSelected() {
        let ntobj = "";
        switch (this.state.tobj) {
            case 1:
                ntobj = <Fragment><strong><Translate value="object" />{':'}</strong >РК</Fragment>;
                break;
            case 2:
                ntobj = <Fragment><strong><Translate value="obl" />{':'}</strong >{this.state.obl != null ? getNameLocalize(this.props.i18n, this.state.obl) : ''}</Fragment>;
                break;
            case 3:
                if (this.state.isChilds === 1)
                    ntobj = <Fragment><strong><Translate value="raion" />{':'}</strong >{this.state.raion != null ? getNameLocalize(this.props.i18n, this.state.raion) : ''}, &nbsp;&nbsp;
                        {this.props.i18n.locale[0] === 'r' ? this.props.userInfo.user.nameOrg : this.props.userInfo.user.nameOrgKz}</Fragment>;
                else
                    ntobj = <Fragment><strong><Translate value="raion" />{':'}</strong >{this.state.raion != null ? getNameLocalize(this.props.i18n, this.state.raion) : ''}</Fragment>;
                break;
            default:
        }
        return <Fragment><strong><Translate value="selectedParams" />{':'}</strong>
            &nbsp;{this.getNamePeriod()}&nbsp;<strong><Translate value="razres" />{':'}</strong>{getNameLocalize(this.props.i18n, this.getNameRazres())}&nbsp;{ntobj}&nbsp;<br />
            <strong><Translate value="forma" />{':'}</strong>{this.state.form.kf} {getNameLocalize(this.props.i18n, this.state.form)}&nbsp;
            <strong><Translate value="table" />{':'}</strong>{this.state.table.ktab} {getNameLocalize(this.props.i18n, this.state.table)}&nbsp;
            {forms12Quart.indexOf(this.state.table.kf) > -1 ? <><strong><Translate value='classMkb' />{':'}</strong>{this.state.classMkb}</> : ""}
            {this.state.isChilds === 1 ? <><br /><strong className="text-info"><Translate value='orgSubordinates' /></strong></> : ""}
        </Fragment>;
    }
    onOpenTable = (e) => {
        e.preventDefault();
        let kobj = "";
        switch (this.state.tobj) {
            case 1:
                kobj = "00";
                break;
            case 2:
                kobj = this.state.obl.kobl;
                break;
            case 3:
                kobj = this.state.raion.kraion;
                break;
            case 4:
                kobj = this.props.userInfo.user.accessLevel === 4 ? this.props.userInfo.user.kodOrg : this.state.org.korg;
                break;
            default:
        }
        if (forms12Quart.indexOf(this.state.table.kf) > -1)
            this.props.getDataF12(
                {
                    year: this.state.year,
                    period: this.state.kper,
                    raz: this.state.kraz,
                    tobj: this.state.tobj,
                    kobj,
                    kf: this.state.form.kf,
                    kt: this.state.table.ktab,
                    strObj: this.state.strObj,
                    isChilds: this.state.isChilds,
                    korg: this.props.userInfo.user.kodOrg,
                    classMkb: this.state.classMkb
                }
            );
        else
            this.props.getData(
                {
                    year: this.state.year,
                    period: this.state.kper,
                    raz: this.state.kraz,
                    tobj: this.state.tobj,
                    kobj,
                    kf: this.state.form.kf,
                    kt: this.state.table.ktab,
                    strObj: this.state.strObj,
                    isChilds: this.state.isChilds,
                    korg: this.props.userInfo.user.kodOrg,
                }
            );
        this.setState({ isShowParams: false });
    }
    printExcel = async () => {
        let kobj = "";
        switch (this.state.tobj) {
            case 1://РК
                kobj = "00";
                break;
            case 2://Область
                kobj = this.state.obl.kobl;
                break;
            case 3://Район
                kobj = this.state.raion.kraion;
                break;
            case 4://Организация
                kobj = this.props.userInfo.user.accessLevel === 4 ? this.props.userInfo.user.kodOrg : this.state.org.korg;
                break;
            default:
                break;

        }
        let korg = this.props.userInfo.user.kodOrg;
        let absoluteUrl = `${document.location.protocol}//${document.location.host}/generate/Excel/Pstr/${this.state.year}/${this.state.kper}/${this.state.kraz}/${this.state.tobj}/${kobj}/${this.state.strObj}/${this.state.form.kf}/${this.state.table.ktab}/${this.state.isChilds}/${korg}`;
        let fileName = `pstr_${this.state.year}_${this.state.kper}_${this.state.kraz}_${this.state.tobj}_${kobj}_${this.state.strObj}_${this.state.form.kf}_${this.state.table.ktab}_${this.state.isChilds}.xlsx`;

        if (forms12Quart.indexOf(this.state.table.kf) > -1) {
            absoluteUrl = `${document.location.protocol}//${document.location.host}/generate/Excel/PstrF12/${this.state.year}/${this.state.kper}/${this.state.kraz}/${this.state.tobj}/${kobj}/${this.state.strObj}/${this.state.form.kf}/${this.state.table.ktab}/${this.state.isChilds}/${korg}/${this.state.classMkb}`;
            fileName = `pstr_${this.state.year}_${this.state.kper}_${this.state.kraz}_${this.state.tobj}_${kobj}_${this.state.strObj}_${this.state.form.kf}_${this.state.table.ktab}_${this.state.classMkb}_${this.state.isChilds}.xlsx`;
        }
        var token = this.props.userInfo.user.token;
        this.setState({ isLoadingExcel: true });
        const response = await fetch(absoluteUrl, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        this.setState({ isLoadingExcel: false });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const blob = await response.blob();
            FileSaver.saveAs(blob, fileName);
            //this.setState({ isLoading: false });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    }
    onSelectYear = (e) => {
        let year = parseInt(e.target.value, 10);
        this.setState({ year });
    }
    onSelectKper = (e) => {
        let kper = parseInt(e.target.value, 10);
        this.setState({ kper });
    }
    onSelectKraz = (e) => {
        let kraz = parseInt(e.target.value, 10);
        this.setState({ kraz });
    }
    printData = () => {
        window.print();
    }
    selectMkbClass = (classMkb) => {
        this.setState({ classMkb });
    }
    selTable = (table) => {
        if (forms12Quart.indexOf(table.kf) > -1) {
            this.setState({ table });
        } else {
            this.setState({ table, classMkb: undefined });
        }
    }
    setTobj = (tobj) => {
        this.setState({ tobj, isChilds:0  })
    }
    render() {
        return <Fragment>
            <h4><Translate value="openFormPstr" /></h4>
            <Row>
                <Collapse in={this.mode() === 'hide'}>
                    <Col md={12}>
                        <Button bsSize='xs' bsStyle="success" onClick={this.handleShow}><Translate value="selectParams" /></Button>
                        {this.showSelected()}
                        <TableViewPstr storeData={this.props.workPstr.data}
                            statusUpdate={this.props.workPstr.statusUpdate}
                            selectParam={this.showModalSelect}
                            saveDataTable={this.saveDataTable}
                            isPostr={this.state.selectParam != null ? this.state.selectParam.isPostr : false}
                            printData={this.printData}
                            printControl={this.printControl}
                            printExcel={this.printExcel}
                            isLoadingExcel={this.state.isLoadingExcel}
                            deleteDataTable={this.deleteDataTable} />
                    </Col>
                </Collapse>
            </Row>
            <Collapse in={this.mode() === 'show'}>
                <Col md={12}>
                    <Panel bsClass='small'>
                        <Panel.Body>
                            <Form onSubmit={this.onOpenTable}>
                                <Row>
                                    <Col xs={12} sm={12}>
                                        <FormGroup controlId="formYear" className='input-group-sm'>
                                            <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='year' />{':'}</ControlLabel></Col>
                                            <Col xs={12} sm={10}>
                                                <FormControl className='input-sm' componentClass="select" placeholder="select"
                                                    defaultValue={this.state.year} onChange={this.onSelectYear}>
                                                    {years.map(it => <option key={it} value={it}>{it}</option>)}
                                                </FormControl></Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12}>
                                        <FormGroup controlId="formPer" className='input-group-sm'>
                                            <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='period' />{':'}</ControlLabel></Col>
                                            <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={`${this.state.kper}`}
                                                onChange={this.onSelectKper} style={{ minWidth: '100%' }}>
                                                {this.props.nsi.periods.map(it => <option key={it.kper} value={it.kper}>{getNameLocalize(this.props.i18n, it)}</option>)}
                                            </FormControl></Col>
                                        </FormGroup></Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12}>
                                        <FormGroup controlId="formRaz" className='input-group-sm'>
                                            <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='razres' />{':'}</ControlLabel></Col>
                                            <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={this.state.kraz}
                                                onChange={this.onSelectKraz} style={{ minWidth: '100%' }}>
                                                <FilterRazOptions />
                                            </FormControl></Col>
                                        </FormGroup></Col>
                                </Row>
                                <SelectFormTable setForm={(form) => this.setState({ form })}
                                    setTable={this.selTable}
                                    kper={this.state.kper}
                                    form={this.state.form} table={this.state.table}
                                    noFilter={true}
                                    krazd={this.state.krazd}
                                    setKrazd={(krazd) => this.setState({ krazd })}
                                />
                                {forms12Quart.indexOf(this.state.table.kf) > -1 ? <SelectClassMkb
                                    kf={this.state.table.kf} ktab={this.state.table.ktab} selectMkbClass={this.selectMkbClass}></SelectClassMkb> : ""}
                                <SelectObject
                                    tobj={this.state.tobj}
                                    obl={this.state.obl}
                                    raion={this.state.raion}
                                    setTobj={(tobj) => this.setTobj(tobj)/*this.setState({ tobj })*/}
                                    setObl={(obl) => this.setState({ obl })}
                                    setRaion={(raion) => this.setState({ raion })} org={this.state.org}
                                    isNotOrg={true}
                                    year={this.state.year}
                                />
                                {this.state.tobj === 2 ? <Row>
                                    <Col xs={12} sm={12}>
                                        <FormGroup controlId="formStrObj" className='input-group-sm' onChange={(e) => this.setState({ strObj: parseInt(e.target.value, 10) })}>
                                            <Col xs={12} sm={2} className="text-right"><ControlLabel style={{ marginTop: 3 }} > <Translate value='strocs' />{':'}</ControlLabel></Col>
                                            <Col xs={12} sm={10}>
                                                <input type="radio" value={0} name="StrObj" defaultChecked={this.state.strObj === 0} />&nbsp;<Translate value='raions' />&nbsp;&nbsp;&nbsp;&nbsp;
                                                <input type="radio" value={1} name="StrObj" defaultChecked={this.state.strObj === 1} />&nbsp;<Translate value='orgs' />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row> : ''}
                                {this.state.tobj === 3 && (this.props.userInfo.user.nomenklKod === '008' || this.props.userInfo.user.nomenklKod === '049' || this.props.userInfo.user.nomenklKod === '043') ? <Row>
                                    <Col xs={12} sm={12}>
                                        <FormGroup controlId="formIsChilds" className='input-group-sm' onChange={(e) => this.setState({ isChilds: parseInt(e.target.value, 10) })}>
                                            <Col xs={12} sm={2} className="text-right"><ControlLabel style={{ marginTop: 3 }} > {/*<Translate value='strocs' />{':'}*/}</ControlLabel></Col>
                                            <Col xs={12} sm={10}>
                                                <input type="radio" value={0} name="isChilds" defaultChecked={this.state.isChilds === 0} />&nbsp;<Translate value='forRaion' />&nbsp;&nbsp;&nbsp;&nbsp;
                                                <input type="radio" value={1} name="isChilds" defaultChecked={this.state.isChilds === 1} />&nbsp;<Translate value='orgSubordinates' />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row> : ""}
                                {this.state.tobj === 1 && this.props.userInfo.user.nomenklKod === '039' ? <Row>
                                    <Col xs={12} sm={12}>
                                        <FormGroup controlId="formIsChilds2" className='input-group-sm' onChange={(e) => this.setState({ isChilds: e.target.checked===true?1:0})}>
                                            <Col xs={12} sm={2} className="text-right"><ControlLabel style={{ marginTop: 3 }} > {/*<Translate value='strocs' />{':'}*/}</ControlLabel></Col>
                                            <Col xs={12} sm={10}>
                                                <input type="checkbox" value={1} name="isChilds2" defaultChecked={this.state.isChilds === 1} />&nbsp;<Translate value='orgSubordinates' />
                                            </Col>
                                        </FormGroup>
                                    </Col></Row> : ""}
                                <ButtonGroup>
                                    <Button className='btn btn-primary text-center' type="submit"><Translate value="load" /></Button>
                                    <Button className='btn btn-default text-center' onClick={this.handleHide} type="button"><Translate value="hide" /></Button>
                                </ButtonGroup>
                            </Form>
                        </Panel.Body>
                    </Panel>
                </Col>
            </Collapse>
            {this.props.workPstr.isLoading === true ? <Row>
                <Col smOffset={2} sm={10}>
                    <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('wait')} width={20} />{' '}<strong><Translate value="wait" /></strong></div>
                </Col>
            </Row> : ''}
        </Fragment>;
    }
}
export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionCreatorsWorkPstr }, dispatch)
)(OpenFormPstr);