import React, { useEffect, useState, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { Row, Button, Col, FormGroup, FormControl, ControlLabel, Panel, Form } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';
import { getNameLocalize } from '../Constants';
import EditOrgModal from './EditOrgModal'; 
import './EditOrgsOnObl.css';


const EditOrgsOnObl = (props) => {
    const [kobl, setKobl] = useState(() => props.userInfo.user.kodObl);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [selRa, setRa] = useState('');
    const [kodName, setKodName] = useState('');
    const [isMedstat, setIsMedstat] = useState('');
    const [] = useState(() => {
        if (props.nsi.allobls.length === 0) {
            props.getNs('allobls');
        }
        //if (props.nsi.allraions.length === 0) {
        //    getAllRaions();
        //    //props.getNs('allraions');
        //}
        return true;
    }, props.nsi.allobls.length > 0);
    useEffect(() => {
        //загрузка организаций
        getOrgs(props.userInfo.user.kodObl);
    }, [props.userInfo.kodObl]);
    //const getAllRaions = async () => {
    //    //загрузка районов
    //    setLoading(true);
    //    await props.getNs('allraions');
    //    setLoading(false);
    //}
    const getOrgs = async (kobl) => {
        setData([]);
        setLoading(true);
        if (props.nsi.allraions.length === 0) {
            await props.getNs('allraions');
        }
        let orgs = await props.getOkpolus(kobl);
        setData(orgs);
        setLoading(false);
    }
    const changeObl = (kodObl) => {
        setKobl(kodObl);
        getOrgs(kodObl);
    }

    const [selOrg, setSelOrg] = useState();
    const [showEditOrg, setShowEditOrg] = useState(false);
    const btEditItem = (evt, kod) => {
        evt.preventDefault();
        let o = data.filter(f => f.kod === kod)[0];
        setSelOrg(o);
        setShowEditOrg(true);
    }
    const closeOrgEdit = (param) => {
        if (param.isNotCancel == true) {
            updateOrg(param.org);
        }
        setShowEditOrg(false);
        setSelOrg(null);
    }
    const updateOrg = async (org) => {
        setLoading(true);
        let ret = await props.updateOkpoluItem(org);
        if (ret.isOk === true) {
            let d = cloneDeep(data)
            let ind = d.map(m => m.kod).indexOf(org.kod);
            d.splice(ind, 1, org);
            setData(d);
        } else {
            alert(ret.error);
        }
        setLoading(false);
    }
    const getRaionName = (kodObl, kodRaion) => {
        let ra = props.nsi.allraions.filter(r => r.obl === kodObl && r.idRaion === kodRaion);
        if (ra.length > 0) return getNameLocalize(props.i18n, ra[0]);
        return "";
    }
    const getOblName = (kodObl) => {
        let ob = props.nsi.allobls.filter(o => o.kod === kodObl);
        if (ob.length > 0) return getNameLocalize(props.i18n, ob[0]);
        return "";
    }
    const filtrOrg = (org) => {
        if (selRa === '' && kodName === '' && isMedstat === '') return true;
        if (isMedstat != '') {
            let bIsMedstat = isMedstat === "1" ? true : false;
            if(org.isMedstat != bIsMedstat) return false;
        }
        if (selRa != '' && org.raion != selRa) return false;
        if (kodName != '' && (org.kod != kodName && !org.nameRu.toLowerCase().includes(kodName.toLowerCase()) && !org.nameKz.toLowerCase().includes(kodName.toLowerCase()))) return false;
        return true;
    }
    const btClearFiltr = (evt) => {
        evt.preventDefault();
        setRa('');
        setKodName('');
    }
    const getStyleTe = (te) => {
        try {
            if (te.substr(2, 2) === "00") {
                return { color: "red" };
            } else {
                return {color: "green"};
            }
        }
        catch (e) {
            return { color: "red" };
        }
    }
    if ((props.userInfo.user.accessLevel != 1 && props.userInfo.user.accessLevel != 2) || props.userInfo.user.roles.filter(r => r === 'UserReadData').length > 0) return <div className="text-danger"><Translate value="noAccess" /></div>;

    return (<div style={{ padding:"0px 15px" }}>
        <Row bsSize="sm">
            <Col sm={4} className="text-right"><strong><Translate value="obl" /></strong></Col>
            <Col>
                <FormControl className='input-sm' componentClass="select" placeholder="select"
                    value={kobl} disabled={props.userInfo.user.accessLevel > 1 || loading===true}
                    onChange={(evt) => changeObl(evt.target.value)} style={{ width: "auto" }}>
                    {props.nsi.allobls.map(it => <option key={it.kod} value={it.kod}>{getNameLocalize(props.i18n, it)}</option>)}
                </FormControl>
            </Col>
        </Row>
        <Panel bsSize="sm" style={{ width:"auto"}}>
            <Panel.Heading style={{ padding: "5px"}}>
                <Panel.Title style={{ color: "black", fontSize: "9pt"}}><Translate value="editOrgs.filt" /></Panel.Title>
            </Panel.Heading>
            <Panel.Body><Form inline style={{ fontSize: "9pt" }}>
                <FormGroup bsSize="small">
                    <ControlLabel><Translate value="editOrgs.raion" /></ControlLabel>
                    <FormControl className='input-sm' componentClass="select" placeholder="select" value={selRa} disabled={loading === true}
                    onChange={(evt) => setRa(evt.target.value)} style={{ width: "auto" }} style={{ display: "inline-block", width: "auto", marginRight: "5px" }}>
                        <option value={''}></option>
                        <option value={`${kobl}00`}>{I18n.t("editOrgs.notRaion")}</option>
                    {props.nsi.allraions.filter(r => r.obl === kobl).map(it => <option key={it.kod} value={it.idRaion}>{it.idRaion} {getNameLocalize(props.i18n, it)}</option>)}
                    </FormControl>
                </FormGroup>
                <FormGroup bsSize="small">
                    <ControlLabel><Translate value="editOrgs.kodName" /></ControlLabel>
                    <FormControl type="text" value={kodName} onChange={(evt) => setKodName(evt.target.value != null ? evt.target.value : '')} disabled={loading === true}/>
                </FormGroup>
                <FormGroup bsSize="small">
                    <ControlLabel><Translate value="editOrgs.isMedstat" /></ControlLabel>
                    <FormControl className='input-sm' componentClass="select" placeholder="select" value={isMedstat} disabled={loading === true}
                        onChange={(evt) => setIsMedstat(evt.target.value)} style={{ width: "auto" }} style={{ display: "inline-block", width: "auto", marginRight: "5px" }}>
                        <option value={''}></option>
                        <option value={'1'}>{I18n.t("yes")}</option>
                        <option value={'2'}>{I18n.t("no")}</option>
                    </FormControl>
                </FormGroup>
                <Button type="button" onClick={btClearFiltr} disabled={loading === true} bsSize="sm"><Translate value="editOrgs.clearFilter" /></Button>
            </Form></Panel.Body>
        </Panel>
        {loading === true ? <Row>
            <Col sm={12}>
                <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('editOrgs.loading')} width={20} />{' '}<strong><Translate value="editOrgs.loading" /></strong></div>
            </Col>
        </Row> : ''}
        <div id="editOkpolu" style={{ fontSize: "9pt" }}>
            <table>
                <thead>
                    <tr>
                        <th><Translate value="editOrgs.kod"/></th>
                        <th><Translate value="editOrgs.nameRu" /></th>
                        <th><Translate value="editOrgs.nameKz" /></th>
                        <th><Translate value="editOrgs.parentOrg" /></th>
                        <th><Translate value="editOrgs.te" /></th>
                        <th><Translate value="editOrgs.obl" /></th>
                        <th><Translate value="editOrgs.raion" /></th>
                        <th><Translate value="editOrgs.isMedstat" /></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data != null ? data.filter(filtrOrg).map(it => <tr key={it.kod}>
                        <td>{it.kod}</td>
                        <td>{it.nameRu}</td>
                        <td>{it.nameKz}</td>
                        <td>{it.parentOrg != null ? <Fragment>{it.parentOrg.kod} {getNameLocalize(props.i18n, it.parentOrg)}</Fragment> : ""}</td>
                        <td style={getStyleTe(it.te)}><strong>{it.te}</strong></td>
                        <td>{it.obl} {getOblName(it.obl)}</td>
                        <td>{it.raion} {getRaionName(it.obl, it.raion)}</td>
                        <td>{it.isMedstat === true ? I18n.t("yes") : I18n.t("no")}</td>
                        <td><Button type="button" bsSize="sm" onClick={(evt) => btEditItem(evt, it.kod)} disabled={loading === true}><i className="bi bi-pencil-fill"></i></Button></td>
                    </tr>) : ""}
                </tbody>
            </table>
        </div>
        {selOrg != null ? <EditOrgModal org={selOrg} show={showEditOrg} handleCloseDlg={closeOrgEdit}/> : ""}
    </div>
    );
}
export default connect(
    state => state,
    dispatch => bindActionCreators(actionCreatorsNsi, dispatch)
)(EditOrgsOnObl);