import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom';

import './Register.css';
//import { actionCreators } from '../store/Register';
import { actionCreators as actionCreatorsNsi } from '../store/Nsi';
import SelectOrgModal from './Modals/SelectOrgModal';
import { SpObl} from './Constants';
class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accessLevel: 4,
            typeUser: 5,
            email: undefined,
            fio: undefined,
            obl: SpObl[0],
            raion: undefined,
            org: undefined,
            password: undefined,
            passwordConfirm: undefined,
            showSelectOrgModal: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeFio = this.onChangeFio.bind(this);
        this.accessOblHandleChange = this.accessOblHandleChange.bind(this);
        this.accessRaionHandleChange = this.accessRaionHandleChange.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangePasswordConfirm = this.onChangePasswordConfirm.bind(this);
        this.setOrg = this.setOrg.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        let obl, raion;
        if (!Array.prototype.find) {
            obl = SpObl.filter(o => o !== undefined && o.kod === nextProps.user.obl.kod)[0];
            raion = this.props.nsi.allraions.filter(o => o !== undefined && o.kod === nextProps.user.raion.kod)[0];
        } else {
            obl = SpObl.find(o => o !== undefined && o.kod === nextProps.user.obl.kod);
            raion = this.props.nsi.allraions.find(o => o !== undefined && o.kod === nextProps.user.raion.kod);
        }/*
        let org = {
            kod: nextProps.user.org.kod, nameKz: nextProps.user.org.nameKz, nameRu: nextProps.user.org.nameRu
        };
        */
        this.setState({
            email: nextProps.user.email,
            fio: nextProps.user.fio,
            obl: obl ? obl : { kod: nextProps.user.obl.kod, nameKz: nextProps.user.obl.nameKz, nameRu: nextProps.user.obl.nameRu },
            raion: raion ? raion : { kod: nextProps.user.raion.kod, nameKz: nextProps.user.raion.nameKz, nameRu: nextProps.user.raion.nameRu },
            //org: org
        });
    }
    setOrg(org) {
        this.setState({ org: org, showSelectOrgModal: false });
        //this.forceUpdate();
    }
    onChangeEmail(e) {
        var val = e.target.value;
        //TODO check email
        this.setState({ email: val });
    }
    onChangeFio(e) {
        var val = e.target.value;
        this.setState({ fio: val });
    }
    accessOblHandleChange(e) {
        var val = e.target.value;
        var pobl;
        if (!Array.prototype.find)
            pobl = SpObl.filter(o => o !== undefined && o.kod === val)[0];
        else
            pobl = SpObl.find(o => o !== undefined && o.kod === val);
        if (pobl !== undefined) {
            this.setState({ obl: { kod: pobl.kod, nameKz: pobl.nameKz, nameRu: pobl.nameRu }, raion: undefined, org: undefined });
            //this.props.getNs('orgs', [{ name: "obl", value: val }]);
        }
    }
    accessRaionHandleChange(e) {
        var val = e.target.value;
        var praion;
        if (!Array.prototype.find)
            praion = this.props.nsi.allraions.filter(o => o !== undefined && o.kod === val)[0];
        else
            praion = this.props.nsi.allraions.find(o => o !== undefined && o.kod === val);
        if (praion !== undefined) {
            this.setState({ raion: { kod: praion.kod, nameKz: praion.nameKz, nameRu: praion.nameRu, idRaion: praion.idRaion }, org: undefined });
            //this.props.getNs('orgs', [{ name: "raion", value: val }]);
        }
    }
    onChangePassword(e) {
        var val = e.target.value;
        this.setState({ password: val });
    }
    onChangePasswordConfirm(e) {
        var val = e.target.value;
        this.setState({ passwordConfirm: val });
    }
    handleSubmit(e) {
        e.preventDefault();
        if (this.state.org === undefined) {
            alert('Выберите организацию');
            return;
        }
        if (this.state.isNew && this.state.password !== this.state.passwordConfirm) {
            alert('Пароли не совпадают');
            return;
        }
        // console.trace();
     //   this.props.updateUser({ ...this.state });
    }
    render() {
        let raions = this.props.nsi.allraions.filter(f => f.obl === this.state.obl.kod).map(o => { return { kod: o.kod, nameRu: o.nameRu, nameKz: o.nameKz }; });
        return <Fragment>
            <div className="background-image"></div>
            <div className="cont">
                <div className="panel">
                    <div className="panel-heading">Регистрация</div>
                    <div className="panel-body">
                        <form className='form-horizontal' name='changeUser' id='changeUser' onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label className="col-sm-4 control-label">Email</label>
                                <div className="col-sm-8">
                                    <input className="form-control" id="inputEmail3" type='text' required
                                        value={this.state.email} onChange={this.onChangeEmail} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-4 control-label">Ф.И.О.</label>
                                <div className="col-sm-8">
                                    <input className="form-control" id="inputFio" type='text' required
                                        value={this.state.fio} onChange={this.onChangeFio} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-4 control-label">Область</label>
                                <div className="col-sm-8">
                                    <select className="form-control" value={this.state.obl.kod} onChange={this.accessOblHandleChange} required>
                                        {SpObl.map(o => {
                                            return o !== undefined ?
                                                <option key={o.kod} value={o.kod}>{o.nameRu}</option> : '';
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-4 control-label">Район</label>
                                <div className="col-sm-8">
                                    <select className="form-control" value={this.state.raion ? this.state.raion.kod : undefined} onChange={this.accessRaionHandleChange} required>
                                        {raions.map(o => {
                                            return o !== undefined ?
                                                <option key={o.kod} value={o.kod}>{o.nameRu}</option> : '';
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-4 control-label">Организация</label>
                                <div className="col-sm-8">
                                    <div className="form-control-static">{this.state.org ? this.state.org.nameRu : <span className="text-danger">Выберите организацию</span>}</div>
                                    <button type="button" className="btn btn-default btn-xs" onClick={() => this.setState({ showSelectOrgModal: true })} style={{ marginTop: "20px!Important" }}>Выбрать</button>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-4 control-label">Пароль</label>
                                <div className="col-sm-8">
                                    <input className="form-control" id="inputPassword" type='password'
                                        value={this.state.password} onChange={this.onChangePassword} required />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-4 control-label">Подтверждение пароля</label>
                                <div className="col-sm-8">
                                    <input className="form-control" id="inputPasswordConfirm" type='password'
                                        value={this.state.passwordConfirm} onChange={this.onChangePasswordConfirm} required />
                                    {this.state.password !== this.state.passwordConfirm ? <div className='text-danger'>Пароли не совпадают!</div> : ''}
                                </div>
                            </div>
                            <button className='btn btn-primary text-center' type="submit">Регистрация</button>
                        </form>
                    </div>
                </div>
            </div>
            <SelectOrgModal raion={this.state.raion} setOrg={this.setOrg} showSelectOrgModal={this.state.showSelectOrgModal}
                handleHide={() => this.setState({ showSelectOrgModal: false })} />
        </Fragment>;
    }
}
export default connect(
    state => state,
    dispatch => bindActionCreators(actionCreatorsNsi, dispatch)
)(Register);
