import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Alert, Row, Modal, Button, Col } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';


import { actionCreators as actionCreatorsAdminUsers } from '../../store/AdminUsers';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';
import SelectObject from '../common/SelectObject';
import { UserItemList } from './UserItemList';
import UserEdit from './UserEdit';
import loadingImg from '../../images/loading.gif';//'../images/loading.gif';


class AdminUsersPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            currentPage: 1,
            selectedUserId: undefined,
            selectedUser: undefined,
            showModalDelete: false,
            userDelete: undefined,
            tobj: props.userInfo.user.accessLevel, // тип обьекта 1 – РК; 2 – область; 3 - район; 4 - организация (доступ зависит от пользователя)
            obl: undefined,
            raion: undefined,
            org: undefined,
        };

        this.onChange = this.onChange.bind(this);
        this.seacrhOnClick = this.seacrhOnClick.bind(this);
        this.AddOnClick = this.AddOnClick.bind(this);
        this.selectUser = this.selectUser.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.changePassUser = this.changePassUser.bind(this);
        this.changeUserName = this.changeUserName.bind(this);

        this.deleteUser = this.deleteUser.bind(this);
        this.handleCloseModalDeleteUser = this.handleCloseModalDeleteUser.bind(this);
        this.handleDeleteUserFromModal = this.handleDeleteUserFromModal.bind(this);
        this.props.getNs('allraions');
    }
    componentWillMount() {
        // This method runs when the component is first added to the page
        this.props.requestGetUsers(this.state.searchText, this.state.currentPage, 10);
    }
    onChange(e) {
        var val = e.target.value;
        this.setState({ searchText: val });
    }
    seacrhOnClick(event) {
        this.props.ClearUserUpdateResult();
        this.setState({ selectedUserId: undefined, selectedUser: undefined })
        if (this.state.tobj === 1) {
            this.props.requestGetUsers(this.state.searchText, this.state.currentPage, 10);
        } else {
            this.props.requestSearchUsers({
                searchText: this.state.searchText,
                startPage: this.state.currentPage,
                sizePage: 10,
                tobj: this.state.tobj,
                kobl: this.state.obl != null ? this.state.obl.kobl : null,
                kraion: this.state.raion != null ? this.state.raion.kraion : null,
                korg: this.state.org != null ? this.state.org.korg : null,
            });
        }
    }
    AddOnClick(event) {
        this.props.ClearUserUpdateResult();
        this.setState({
            selectedUserId: undefined,
            selectedUser: {
                id: undefined,
                accessLevel: 4,
                typeUser: 5,
                username: '',
                email: '',
                fio: '',
                obl: this.props.userInfo.user.kodObl ?
                    { kod: this.props.userInfo.user.kodObl, nameRu: this.props.userInfo.user.nameObl, nameKz: this.props.userInfo.user.nameOblKz }
                    : { kod: '75', nameKz: 'Алматы  қ.', nameRu: 'г.Алматы' },
                org: this.props.userInfo.user.kodOrg ?
                    { kod: this.props.userInfo.user.kodOrg, nameRu: this.props.userInfo.user.nameOrg, nameKz: this.props.userInfo.user.nameOrgKz }
                    : { kod: '028W', nameKz: 'ГККП "ГОРОДСКАЯ ПОЛИКЛИHИКА N7"', nameRu: 'ГККП "ГОРОДСКАЯ ПОЛИКЛИHИКА N7"' },
                raion: this.props.userInfo.user.kodRaion ?
                    { kod: this.props.userInfo.user.kodRaion, nameRu: this.props.userInfo.user.nameRaion, nameKz: this.props.userInfo.user.nameRaionKz }
                    : { kod: '751110000', nameKz: 'Алмалинский район', nameRu: 'Алмалинский район' },
                roles: ["UserEditData"],
                isNew: true,
                password: '',//undefined,
                passwordConfirm: '',//undefined,
                oldPassword: '',//undefined,
                newPassword: '',//undefined,
            }
        });
    }
    selectUser(userId) {
        this.props.ClearUserUpdateResult();
        let selUser = undefined;
        if (this.props.adminUsers.usersResult) {
            let findUs;
            if (!Array.prototype.find) //iE11
                findUs = this.props.adminUsers.usersResult.users.filter(u => u.id === userId)[0];
            else
                findUs = this.props.adminUsers.usersResult.users.find(u => u.id === userId);
            if (findUs !== undefined) {
                selUser = { ...findUs, isNew: false, password: undefined, passwordConfirm: undefined };
            }
        }
        this.setState({
            selectedUserId: userId, selectedUser: selUser,
            password: undefined,
            passwordConfirm: undefined
        });
    }

    updateUser(user) {
        //using add or update store
        this.props.requestInsertOrUpdateUser(user);
        this.setState({ selectedUser: user });
    }
    changePassUser(username, oldPassword, newPassword) {
        this.props.changePassUser({ username, oldPassword, newPassword });
    }
    changeUserName(data) {
        this.props.changeUserName(data);
    }
    renderUpdateResult(userUpdateResult) {
        if (userUpdateResult) {
            if (userUpdateResult.result) {
                return <div className='text-success'>
                    <div className="alert alert-success alert-dismissible" role="alert">
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <strong>Данные сохранены!</strong>
                    </div>
                </div>;
            } else {
                return <div className="alert alert-warning alert-dismissible" role="alert">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <strong>Ошибка сохранения!</strong> {userUpdateResult.error}.
                    </div>;
                //<div className='text-danger'>Произошла ошибка: <span>{userUpdateResult.error}</span></div>
            }
        }
        return '';
    }
    deleteUser(userDelete) {
        this.setState({ showModalDelete: true, userDelete });
    }
    handleCloseModalDeleteUser() {
        this.setState({ showModalDelete: false, userDelete: undefined });
    }
    handleDeleteUserFromModal() {
        this.setState({ showModalDelete: false, selectedUserId: undefined, selectedUser: undefined });
        this.props.deleteUser(this.state.userDelete.id);
        this.setState({ userDelete: undefined });
    }
    render() {
        let adminInfo = this.props.userInfo.user;
        let isAdminAdmin = false; //Админисратор админисраторов?
        if (adminInfo) {
            if (adminInfo.roles.indexOf('Administrators') > -1) {
                isAdminAdmin = true;
            }
        }
        let errorsChangePass = this.props.adminUsers.errorsChangePass;
        let passUpdateResult = this.props.adminUsers.passUpdateResult;
        let errorsChangeUserName = this.props.adminUsers.errorsChangeUserName;
        let userNameUpdateResult = this.props.adminUsers.userNameUpdateResult;
        let userNameNew = this.props.adminUsers.userNameNew;
        return (<div className='small'>
            <Row>
                <Col md={12}><h4 className='h4 text-info'>
                    {isAdminAdmin ? <Translate value="textAdminAdmins" /> : <Translate value="textAdminUsers" />}</h4>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <SelectObject
                        tobj={this.state.tobj}
                        obl={this.state.obl}
                        raion={this.state.raion}
                        org={this.state.org}
                        setTobj={(tobj) => this.setState({ tobj })}
                        setObl={(obl) => this.setState({ obl })}
                        setRaion={(raion) => this.setState({ raion })}
                        setOrg={(org) => this.setState({ org })}
                    />
                </Col>
            </Row>
            <form className="form-inline">
                <div className="form-group">
                    <label className="control-label">Поиск(Логин/Email/Ф.И.О.) &nbsp;</label>
                    <input type="text" className="form-control" id="inputSearchFio" onChange={this.onChange}></input>
                </div>
                <button type='button' className='btn btn-primary btn-sm' onClick={this.seacrhOnClick}>
                    <span className='glyphicon glyphicon-search'></span></button>
                <button type='button' className='btn btn-success btn-sm' onClick={this.AddOnClick}>
                    <span className='glyphicon glyphicon-plus'></span></button>
            </form>
            <Row>
                {this.props.adminUsers.isLoading ? <Alert bsStyle="info"><img src={loadingImg} width={20} alt={I18n.t('wait')} />{' '}<strong>Подождите</strong>Идет загрузка данных...</Alert> : ''}</Row>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <div className="panel panel-default" style={{ width: 'auto' }}>
                    <div className="panel-body">
                        <ul className="list-group">
                            {this.props.adminUsers.usersResult ? this.props.adminUsers.usersResult.users.map(user =>
                                <UserItemList key={user.id} user={user} selectedUserId={this.state.selectedUserId} selectUser={this.selectUser} deleteUser={this.deleteUser} />
                            ) : ''}
                        </ul>
                    </div>
                </div>
            </div>
            <div className='col-sm-6 col-md-8 col-lg-9'>
                {this.renderUpdateResult(this.props.adminUsers.userUpdateResult)}
                {this.state.selectedUser ? <UserEdit user={this.state.selectedUser} updateUser={this.updateUser} changeUserName={this.changeUserName} changePass={this.changePassUser} errorsChangeUserName={errorsChangeUserName} userNameUpdateResult={userNameUpdateResult} userNameNew={userNameNew} passUpdateResult={passUpdateResult} errorsChangePass={errorsChangePass} isAdminAdmin={isAdminAdmin} /> : ''}
            </div>
            <Modal show={this.state.showModalDelete} onHide={this.handleCloseModalDeleteUser}>
                <Modal.Header closeButton>
                    <Modal.Title>Удаление пользователя</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Вы дествительно хотите удалить пользователя {this.state.userDelete !== undefined ? this.state.userDelete.fio : ''}?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleCloseModalDeleteUser}>Закрыть</Button>
                    <Button onClick={this.handleDeleteUserFromModal}>Удалить</Button>
                </Modal.Footer>
            </Modal>
        </div>);
    }

}
export default connect(
    state => state,
    dispatch => bindActionCreators(Object.assign({}, actionCreatorsAdminUsers, actionCreatorsNsi), dispatch)
)(AdminUsersPage);