import React, { /*useEffect,*/ Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { Translate, I18n } from 'react-redux-i18n';
import { Button, Col, FormGroup, FormControl, ControlLabel, Form, Radio, Checkbox, Row, Alert, Modal } from 'react-bootstrap';

import { actionCreators as actionCreatorsSvod } from '../../store/Svod';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';
import SelectFormTable from '../common/SelectFormTable';
import SelectObject from '../common/SelectObject';
import { getNameLocalize, getRazItems, getFirstRaz } from '../Constants';
//import { getToken } from './UserInfo';


import { years, getCurrentYear } from '../Constants';
import FilterRazOptions from '../common/FilterRazOptions';
import { useState } from 'react';

const EditFormPeriod = (props) => {
    useState(() => {
        if (props.nsi.allsprtab.length === 0) props.getNs('allsprtab');
        if (props.nsi.sprrazdel.length === 0) props.getNs('sprrazdel');
        if (props.nsi.sprform.length === 0) props.getNs('sprform');
        if (props.nsi.allobls.length === 0) props.getNs('allobls');
        if (props.nsi.sprraz.length === 0) props.getNs('sprraz');
        if (props.nsi.periods.length === 0) props.getNs('periods');
        //if (props.nsi.allraions.length === 0) props.getNs('allraions');
        if (props.userInfo.user.nomenklKod === '008' || props.userInfo.user.nomenklKod === '049' || props.userInfo.user.nomenklKod === '043') {
            const fetchData = async () => {
                const chOrgs = await props.getSubOrgs();
                setChildOrgs(chOrgs);
            }
            fetchData();
        }
    });
    /*
    useEffect(() => {
        props.getNs('orgs', [{ name: "obl", value: props.userInfo.user.kodObl }]);
    }, [props.userInfo.user.kodObl])
    */
    const [year, setYear] = useState(() => getCurrentYear());
    const [kperSrc, setKperSrc] = useState(4);
    const [kperDest, setKperDest] = useState(4);
    const [kraz, setKraz] = useState(() => {
        let raz = getFirstRaz(4, props);
        return raz != null ? raz : 1;
    });
    const [krazd, setKrazd] = useState();
    const [form, setForm] = useState(props.userInfo.user.firstFormTable.formGod);
    const [table, setTable] = useState(props.userInfo.user.firstFormTable.tableGod);
    const [tobj, setTobj] = useState(props.userInfo.user.accessLevel);
    const [obl, setObl] = useState();
    const [raion, setRaion] = useState();
    const [org, setOrg] = useState();
    //const [korg, setKorg] = useState(props.userInfo.user.kodOrg);
    const [errors, setErrors] = useState([]);
    const [successMessage, setSuccessMessage] = useState();
    const [svodRunning, setSvodRunning] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    //const handleConfirmClose = (e) => {
    //    e.preventDefault();
    //    setShowConfirm(false);
    //}
    const getSvodParams = (fnErr) => {
        let errs = [];
        let kobj = "00";
        switch (tobj) {
            case 2://obl
                kobj = obl.kobl;
                break;
            case 3://raion
                kobj = raion.kraion;
                break;
            case 4://org
                kobj = org.korg
                break;
            default:
        }
        if (form == null) {
            errs.push("selectForm");
        }
        if (table == null) {
            errs.push("selectForm");
        }
        if (kperSrc === kperDest) {
            errs.push("perSrcEqDest");
        }
        if (errs.length > 0)
            fnErr(errs);
        return {
            year,
            kraz,
            kperSrc,
            kperDest,
            tobj,
            kobj,
            kf: form.kf,
            ktab:table.ktab
        };
    }
    const onSvodForm = (e) => {
        e.preventDefault();
        const isExistPeriod = async () => {
            let errs = [];
            let param = getSvodParams((ers) => errs = ers);
            if (errs.length > 0) {
                setErrors(errs);
                return;
            };
            let retData = await props.isExistDataPeriod(param);
            if (retData.successed === true) {
                if (retData.isNotExistsSrc == true) {
                    setErrors(["notExistsDataSrcPer"]);
                    return;
                }
                if (retData.isExists === true) {
                    //данные уже есть в базе данных, спросить создать свод?
                    setShowConfirm(true);
                    return;
                } else {
                    await runEditPeriod(param);
                }
            }
        }
        isExistPeriod();
    }
    const runEditPeriod = async (param) => {
        setSvodRunning(true);
        var ret = await props.editFormPeriod(param);
        if (ret.successed === false) {
            setErrors([ret.error]);
        } else {
            setSuccessMessage(I18n.t("periodChanged"));
        }
        setSvodRunning(false);
    }
    const btSubmitConfirmSvod = (e) => {
        e.preventDefault();
        setShowConfirm(false);
        const changePeriod = async () => {
            let errs = [];
            let param = getSvodParams((ers) => errs = ers);
            if (errs.length > 0) {
                setErrors(errs);
                return;
            };
            await runEditPeriod(param);
        }
        changePeriod();
    }
    let isReadOnLy = props.userInfo.user.roles.filter(r => r === 'UserReadData').length > 0;
    if (isReadOnLy === true) return <Translate value="noAccess" />
    return <Fragment><Form onSubmit={onSvodForm} horizontal>
        <h5 style={{ textAlign: "center" }}><strong><Translate value='editFormPeriod' /></strong></h5>
        <FormGroup controlId="formYear" bsSize='small'>
            <Col xs={12} sm={2} componentClass={ControlLabel}><Translate value='year' />{':'}</Col>
            <Col xs={12} sm={10}>
                <FormControl className='input-sm' componentClass="select" placeholder="select" disabled={svodRunning}
                    defaultValue={year} onChange={(e) => setYear("year", parseInt(e.target.value, 10))} bsSize="small" style={{ width: "auto" }}>
                    {years.map(it => <option key={it} value={it}>{it}</option>)}
                </FormControl></Col>
        </FormGroup>
        <FormGroup bsSize='small'>
            <Col xs={12} sm={2} componentClass={ControlLabel}><Translate value="period" />{':'}</Col>
            <Col xs={12} sm={10}>
                <div style={{ float: "left", width: "auto" }}><ControlLabel><Translate value="periodSrc" /></ControlLabel>
                    <FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={`${kperSrc}`} disabled={svodRunning}
                        onChange={(e) => setKperSrc(parseInt(e.target.value, 10))} bsSize='small' style={{ width: "auto" }}>
                        {props.nsi.periods/*.filter(f => f.kper == 4 || f.kper > 100)*/.map(it => <option key={it.kper} value={it.kper}>{it.nameRu}</option>)}
                    </FormControl>
                </div>
                <div><ControlLabel><Translate value="periodDest" /></ControlLabel>
                    <FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={`${kperDest}`} disabled={svodRunning}
                        onChange={(e) => setKperDest(parseInt(e.target.value, 10))} bsSize='small' style={{ width: "auto" }}>
                        {props.nsi.periods/*.filter(f=>f.kper==4 || f.kper>100)*/.map(it => <option key={it.kper} value={it.kper}>{it.nameRu}</option>)}
                    </FormControl>
                </div>
            </Col>
        </FormGroup>
        <FormGroup controlId="formRaz" bsSize='small'>
            <Col xs={12} sm={2} componentClass={ControlLabel}><Translate value='razres' />{':'}</Col>
            <Col xs={12} sm={10}>
                <FormControl className='input-sm' componentClass="select" placeholder="select" value={`${kraz}`} disabled={svodRunning}
                    onChange={(e) => setKraz(parseInt(e.target.value, 10))} style={{ minWidth: '100%' }}>
                    {getRazItems(props, kperSrc)}
                </FormControl></Col>
        </FormGroup>
        <SelectFormTable setForm={(form) => setForm(form)}
            setTable={(table) => setTable(table)}
            kper={kperSrc}
            form={form} table={table}
            krazd={krazd}
            setKrazd={(krazd) => setKrazd(krazd)}
        />
        <SelectObject
            tobj={tobj}
            obl={obl}
            raion={raion}
            org={org}
            setTobj={(tobj) => setTobj(tobj)}
            setObl={(obl) => setObl(obl)}
            setRaion={(raion) => setRaion(raion)}
            setOrg={(org) => setOrg(org)}
            year={year}
        />
        {errors.length > 0 ? <Alert bsStyle="danger"><ul className="text-danger">{errors.map(msg => <li><Translate value={msg} /></li>)}</ul></Alert> : ""}
        {svodRunning === true ? <Row>
            <Col smOffset={2} sm={10}>
                <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('waitsvod')} width={20} />{' '}<strong><Translate value="waitsvod" /></strong></div>
            </Col>
        </Row> : ''}
        {svodRunning === false && successMessage != null ? <Row bsSize='small'>
            <Col smOffset={2} sm={10}>
                <Alert bsStyle="success"><Translate value="svodComplete" /></Alert>
            </Col>
        </Row> : ''}
        <FormGroup>
            <Col smOffset={2} sm={10}>
                <Button className='btn btn-primary text-center' type="submit" disabled={svodRunning}><Translate value="changePeriod" /></Button>
            </Col>
        </FormGroup>
    </Form>
        <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
            <Modal.Header closeButton>
                <Modal.Title><Translate value="warning" /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert bsStyle="warning">
                    <strong><Translate value="warning" /></strong> <Translate value="perEditConfirmMsg" />
                </Alert>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={btSubmitConfirmSvod}><Translate value="changePeriod" /></Button>
                <Button onClick={() => setShowConfirm(false)}><Translate value="msgButtons.Cancel" /></Button>
            </Modal.Footer>
        </Modal>
    </Fragment>
}

export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionCreatorsSvod }, dispatch)
)(EditFormPeriod);