import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { Alert, Row, Table, Button, Col, Modal, ControlLabel, Form, FormGroup, FormControl, ListGroup, ListGroupItem } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import TableScrollbar from 'react-table-scrollbar';
import { Typeahead } from 'react-bootstrap-typeahead';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';
import { getNameLocalize } from '../Constants';
//import SelectOrg from '../common/SelectOrg';

const ListChildsOrgs = (props) => {
    const [] = useState(() => {
        if (props.nsi.torg.length === 0) {
            props.getNs('torg');
        }
        return true;
    }, props.nsi.torg.length > 0);
    const [] = useState(() => {
        if (props.nsi.nomenkl.length === 0) {
            props.getNs('nomenkl');
        }
        return true;
    }, props.nsi.nomenkl.length > 0);
    const [parentOrg, setParentOrg] = useState([]);
    useEffect(() => {
        if (props.kodParentOrg != null) {
            const fetch = async () => {
                let orgs = await props.getChildsOrgsByParent(props.kodParentOrg);
                if (orgs != null)
                    setChildsOrgs(orgs);
                else
                    setChildsOrgs([]);
                let orgP = await props.getOrgByKod(props.kodParentOrg);
                setParentOrg(orgP);
            }
            fetch();
        } else
            setChildsOrgs([]);
    }, [props.kodParentOrg]);
    const [childsOrgs, setChildsOrgs] = useState([]);
    const [showModalAdd, setShowModalAdd] = useState(false);
    const [childAddOrg, setChildAddOrg] = useState();
    const addChild = (evt) => {
        evt.preventDefault();
        setChildAddOrg(null);
        setShowModalAdd(true);
    }
    const removeChild = (evt, org) => {
        evt.preventDefault();
        const fetch = async () => {
            let ret = await props.removeChildOrgByParent(org.kod, props.kodParentOrg);
            if (ret.successed === false) {
                alert(ret.error)
            } else {
                let ar = cloneDeep(childsOrgs);
                let index1 = ar.map(e => e.kod).indexOf(org.kod);
                ar.splice(index1, 1);
                setChildsOrgs(ar);
            }
        }
        fetch();
    }
    const handleCloseDlg = (isNotCancel) => {
        if (isNotCancel === false) {
            setShowModalAdd(false);
        } else if (childAddOrg != undefined) {
            const fetch = async () => {
                let ret = await props.addChildOrgByParent(childAddOrg.kod, props.kodParentOrg);
                if (ret.successed === false) {
                    alert(ret.error)
                } else {
                    let ar = cloneDeep(childsOrgs);
                    ar.push(childAddOrg);
                    setChildsOrgs(ar);
                }
                setChildAddOrg(null);
            }
            fetch();
            setShowModalAdd(false);
        }
    };
    const [kodOrg, setKodOrg] = useState();
    const [nameOrg, setNameOrg] = useState();
    const [foundOrgs, setFoundOrgs] = useState([]);
    const btSubmitSearcOrg = (evt) => {
        evt.preventDefault();
        const fetch = async () => {
            setChildAddOrg(null);
            setFoundOrgs([]);
            try {
                let ret = await props.getOrgsParentKodAndKodName({ kodParentOrg: props.kodParentOrg, kodOrg: kodOrg != null ? kodOrg : "", nameOrg: nameOrg != null ? nameOrg : "" });
                setFoundOrgs(ret);
            } catch (e) {
                alert(e);
            }
        }
        fetch();
    }
    const [showAddNew, setShowAddNew] = useState(false);
    const [newOrg, setNewOrg] = useState({});
    const [errorsNewOrg, setErrorsNewOrg] = useState([]);
    const handleAddNewOrg = (evt) => {
        evt.preventDefault();
        setChildAddOrg(null);
        setNewOrg({
            kod: "",
            parentKod: props.kodParentOrg,
            te: parentOrg.te,
            obl: parentOrg.kobl,
            raion: parentOrg.raion,
           /* rnn: "",
            bin: "",
            idSur: "",
            */
            rusName: "",
            kazName: "",
            adr: "",
            isMedstat: true,
            torgKod: null,
            nomenKod: null
        });
        setShowAddNew(true);
    }
    const onChangeNomen = (selected) => {
        let editOrg = cloneDeep(newOrg);
        if (selected.length > 0) {
            editOrg.nomenKod = {
                kod: selected[0].kod,
                nameRu: selected[0].nameRu,
                nameKz: selected[0].nameRu,
            }
        } else {
            editOrg.nomenKod = null;
        }
        setNewOrg(editOrg);
    }
    const onChangeTorg = (selected) => {
        let editOrg = cloneDeep(newOrg);
        if (selected.length > 0) {
            editOrg.torgKod = {
                kod: selected[0].kod,
                nameRu: selected[0].nameRu,
                nameKz: selected[0].nameRu,
            }
        } else {
            editOrg.torgKod = null;
        }
        setNewOrg(editOrg);
    }
    const changeNewOrgFld = (fld, value) => {
        let editOrg = cloneDeep(newOrg);
        editOrg[fld] = value;
        setNewOrg(editOrg);
    }
    const handleAddOrgToOkpolu = (evt,isNotCancel) => {
        evt.preventDefault();
        if (isNotCancel === true) {
            setErrorsNewOrg([]);
            let validate = async () => {
                let errors = [];
                //validate
                let kod = newOrg.kod != null ? newOrg.kod.trim() : null;
                if (kod == null || kod === "") {
                    errors.push("Введите код организации");
                } else if (!await verifyKodOrg()) {
                    errors.push(`Данный код организации:${kod} уже используется! Введите другой`);
                }
                let rus = newOrg.rusName != null ? newOrg.rusName.trim() : null;
                if (rus==null || rus==="") {
                    errors.push("Введите наименование на русском");
                }
                let kaz = newOrg.kazName != null ? newOrg.kazName.trim() : null;
                if (kaz == null || kaz === "") {
                    errors.push("Введите наименование на казахском");
                }
                let adr = newOrg.adr != null ? newOrg.adr.trim() : null;
                if (adr == null || adr === "") {
                    errors.push("Введите адрес организации");
                }
                let nomenKod = newOrg.nomenKod != null ? newOrg.nomenKod.kod : null;
                if (nomenKod == null) {
                    errors.push("Введите номенклатуру организации");
                }
                let torgKod = newOrg.torgKod != null ? newOrg.torgKod.kod : null;
                //if (torgKod == null) {
                //    errors.push("Введите тип организации");
                //}
                if (errors.length === 0) {
                    //todo add org to okpolu
                    let ret = await props.addOkpoluItem({
                        kod, parentKod: newOrg.parentKod, nameRu: rus, nameKz: kaz, adr, te: newOrg.te
                        , obl: newOrg.obl, raion: newOrg.raion, isMedstat: newOrg.isMedstat
                        , nomenKod: newOrg.nomenKod.kod, torgKod: newOrg.torgKod != null ? newOrg.torgKod.kod : undefined
                    });
                    if (ret.isOk) {
                        let orgs = await props.getChildsOrgsByParent(newOrg.parentKod);
                        if (orgs != null)
                            setChildsOrgs(orgs);
                        else
                            setChildsOrgs([]);
                        setShowAddNew(false);
                        setShowModalAdd(false);
                        setChildAddOrg(null);
                    } else {
                        errors.push(ret.error);
                        setErrorsNewOrg(errors);
                    }
                } else {
                    setErrorsNewOrg(errors);
                }
            }
            validate();
        } else
            setShowAddNew(false);
    }
    const [showEditNameOrg, setShowEditNameOrg] = useState(false)
    const [editOrg, setEditOrg] = useState({});
    const [errorsEditOrg, setErrorsEditOrg] = useState([])
    const editChild = (evt, org) => {
        evt.preventDefault();
        setEditOrg(cloneDeep(org));
        setShowEditNameOrg(true);
    }
    const changeEditOrgFld = (fld, value) => {
        let edOrg = cloneDeep(editOrg);
        edOrg[fld] = value;
        setEditOrg(edOrg);
    }
    const handleEditNameOrg = (evt, isNotCancel) => {
        evt.preventDefault();
        if (isNotCancel === true) {
            setErrorsEditOrg([]);
            let valid = async () => {
                let errors = [];
                //validate
                let rus = editOrg.nameRu != null ? editOrg.nameRu.trim() : null;
                if (rus == null || rus === "") {
                    errors.push("Введите наименование на русском");
                }
                let kaz = editOrg.nameKz != null ? editOrg.nameKz.trim() : null;
                if (kaz == null || kaz === "") {
                    errors.push("Введите наименование на казахском");
                }
                if (errors.length === 0) {
                    let ret = await props.editNameOkpoluItem({
                        kod: editOrg.kod, nameRu: rus, nameKz: kaz});
                    if (ret.isOk) {
                        let orgs = await props.getChildsOrgsByParent(editOrg.parentKod);
                        if (orgs != null)
                            setChildsOrgs(orgs);
                        else
                            setChildsOrgs([]);
                       setShowEditNameOrg(false);
                    } else {
                        errors.push(ret.error);
                        setErrorsEditOrg(errors);
                    }
                } else {
                    setErrorsEditOrg(errors);
                }
            }
            valid();
        } else
            setShowEditNameOrg(false);
    }
    const verifyKodOrg = async () => {
        //evt.preventDefault();
        try {
            let ret = await props.checkKodOrg(newOrg.kod);
            if (ret.exists === true) {
                return false;//alert("Данный код организации уже используется! Введите другой");
            } else {
                return true//alert("Данный код можно использовать!");
            }
        } catch (e) {
            alert(e);
            return false;
        }
    }
    return <>
        <Row bsSize="sm">
            <Col>
                <Button bsSize="small" className='btn btn-primary text-center'
                    type="button" onClick={addChild}><Translate value="childOrgs.addChild" /></Button>
            </Col>
        </Row>
        <Row>
            <Col>
                <Table striped bordered condensed hover>
                    <thead>
                        <tr>
                            <th><Translate value="childOrgs.kodOrg" /></th>
                            <th><Translate value="childOrgs.nameOrg" /></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {childsOrgs.map(r => <tr key={r.kod}>
                            <td>{r.kod}</td>
                            <td><Button bsSize="small" onClick={(evt) => editChild(evt, r)}><i className="bi bi-pencil-fill"></i></Button>&nbsp;{getNameLocalize(props.i18n, r)}</td>
                            <td><Button bsSize="small"
                                onClick={(evt) => removeChild(evt, r)} className='btn btn-danger text-center' type="button"><i className="bi bi-x-lg"></i>&nbsp;<Translate value="childOrgs.del" /></Button>
                            </td>
                        </tr>)}
                    </tbody>
                </Table>
            </Col>
        </Row>
        <Modal
            show={showModalAdd}
            onHide={() => handleCloseDlg(false)}
            backdrop="static"
            keyboard={false}
            bsSize="large">
            <Modal.Header closeButton>
                <Modal.Title><Translate value="childOrgs.addChild" /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ControlLabel><Translate value="org" /></ControlLabel>{' '}
                <Form inline onSubmit={btSubmitSearcOrg} style={{ paddingBottom: "5px" }}>
                    <FormGroup controlId="formInlineKod">
                        <ControlLabel><Translate value="kod" /></ControlLabel>{' '}
                        <FormControl type="text" value={kodOrg} onChange={(evt) => setKodOrg(evt.target.value)} />
                    </FormGroup>{' '}
                    <FormGroup controlId="formInlineOrgBr">
                        <ControlLabel><Translate value="nameOrgBr" /></ControlLabel>{' '}
                        <FormControl type="text" value={nameOrg} onChange={(evt) => setNameOrg(evt.target.value.trim())} />
                    </FormGroup>{' '}
                    <Button type="submit"><Translate value="search" /></Button>
                </Form>

                {/* 
                <SelectOrg setOrg={setChildAddOrg} org={childAddOrg} />
                */}
                <TableScrollbar height="300px">
                    <Table striped bordered condensed hover>
                        {/*    <thead>
                            <tr style={{ color: "dark!important", backgroundColor: "lightgray!important" }}>
                            <th><Translate value="kod" /></th>
                            <th><Translate value="nameOrgBr" /></th>
                        </tr>
                    </thead>*/}
                        <tbody>
                            {foundOrgs != null && foundOrgs.map(r => <tr key={r.kobj} onClick={() => setChildAddOrg({ ...r, kod: r.kobj })}
                                className={childAddOrg != null && childAddOrg.kod === r.kobj ? "success" : ""}>
                                <td>{r.kobj}</td>
                                <td>{getNameLocalize(props.i18n, r)}</td>
                            </tr>)}
                        </tbody>
                    </Table>
                </TableScrollbar>
                {childAddOrg != null && childAddOrg.parentKod != null ? <p className="text-danger" style={{ paddingTop: "5px" }}><strong><Translate value="childOrgs.warnOrgIsExistParent" kodOrg={childAddOrg.parentKod} /></strong></p> : ""}
            </Modal.Body>
            <Modal.Footer>
                {props.userInfo.user.accessLevel === 1 ? <Button type="button" variant="primary" onClick={handleAddNewOrg}><Translate value="childOrgs.addOrgToOkpolu" /></Button> : ""}
                <Button type="button" variant="primary" onClick={() => handleCloseDlg(true)} disabled={childAddOrg == null || (childAddOrg != null && childAddOrg.parentKod != null)}><Translate value="accept" /></Button>
                <Button type="button" variant="secondary" onClick={() => handleCloseDlg(false)}><Translate value="close" /></Button>
            </Modal.Footer>
        </Modal>
        <Modal
            show={showAddNew}
            onHide={(evt) => handleAddOrgToOkpolu(evt,false)}
            backdrop="static"
            keyboard={false}
            bsSize="large">
            <Modal.Header closeButton>
                <Modal.Title><Translate value="childOrgs.addOrgToOkpolu" /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form horizontal>
                    <FormGroup controlId="formHorizontalKod" bsSize='small'>
                        <Col componentClass={ControlLabel} sm={2}>Kod (4 - значный)</Col>
                        <Col sm={10}>
                            <FormControl type="text" value={newOrg.kod} onChange={(evt) => changeNewOrgFld("kod", evt.target.value)} style={{ maxWidth: "60px" }} />
                            {/*<Button type="button" variant="secondary" onClick={verifyKodOrg}>Проверить код</Button>*/}
                        </Col>
                    </FormGroup>
                    <FormGroup controlId="formHorizontalNameOrgRu" bsSize='small'>
                        <Col componentClass={ControlLabel} sm={2}><Translate value="childOrgs.nameOrg" /> RU</Col>
                        <Col sm={10}>
                            <FormControl componentClass="textarea" value={newOrg.rusName} onChange={(evt) => changeNewOrgFld("rusName", evt.target.value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup controlId="formHorizontalNameOrgKz" bsSize='small'>
                        <Col componentClass={ControlLabel} sm={2}><Translate value="childOrgs.nameOrg" /> KZ</Col>
                        <Col sm={10}>
                            <FormControl componentClass="textarea" value={newOrg.kazName} onChange={(evt) => changeNewOrgFld("kazName", evt.target.value)} />
                        </Col>
                    </FormGroup>
                    <Row bsSize='small'>
                        <Col componentClass={ControlLabel} sm={8}><Translate value="editOrgs.obl" /></Col>
                        <Col sm={4} style={{ paddingTop: "8px" }}><strong>{newOrg.obl}</strong></Col>
                    </Row>
                    <Row bsSize='small'>
                        <Col componentClass={ControlLabel} sm={8}><Translate value="editOrgs.raion" /></Col>
                        <Col sm={4} style={{ paddingTop: "8px" }}><strong>{newOrg.raion}</strong></Col>
                    </Row>
                    <Row bsSize='small'>
                        <Col componentClass={ControlLabel} sm={8}><Translate value="editOrgs.nasel" /></Col>
                        <Col sm={4} style={{ paddingTop: "8px" }}><strong>{newOrg.te}</strong></Col>
                    </Row>
                    <FormGroup controlId="formHorizontalAdress" bsSize='small'>
                        <Col componentClass={ControlLabel} sm={2}><Translate value="editOrgs.adr" /></Col>
                        <Col sm={10}>
                            <FormControl componentClass="textarea" value={newOrg.adr} onChange={(evt) => changeNewOrgFld("adr", evt.target.value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup controlId="formNomenkl" bsSize='small'>
                        <Col sm={2} className="text-right"><ControlLabel><Translate value='nomenkl' />{':'}</ControlLabel></Col>
                        <Col sm={10}>
                            <Typeahead
                                id="formNomenkl"
                                labelKey={option => `${option.kod} ${option.nameRu}`}
                                options={props.nsi.nomenkl}
                                placeholder="Выберите номенклатуру..."
                                onChange={onChangeNomen} />
                            <p>{newOrg != null && newOrg.nomenKod != null ? `${newOrg.nomenKod.kod} ${newOrg.nomenKod.nameRu}` : ""}</p>
                        </Col>
                    </FormGroup>
                    <FormGroup controlId="formTorg" bsSize='small'>
                        <Col sm={2} className="text-right"><ControlLabel><Translate value='typeorg' />{':'}</ControlLabel></Col>
                        <Col sm={10}>
                            <Typeahead
                                id="formTorg"
                                labelKey={option => `${option.kod} ${option.nameRu}`}
                                options={props.nsi.torg}
                                placeholder="Выберите тип организации..."
                                onChange={onChangeTorg} />
                            <p>{newOrg != null && newOrg.torgKod != null ? `${newOrg.torgKod.kod} ${newOrg.torgKod.nameRu}` : ""}</p>
                        </Col>
                    </FormGroup>
                </Form>
                {errorsNewOrg.length > 0 ? <><h4 className="text-danger"><strong>Ошибки:</strong></h4> <ListGroup>{errorsNewOrg.map((m, i) =>
                    <ListGroupItem bsStyle="danger" key={i}>{m}</ListGroupItem>)}</ListGroup></> : ""}
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" variant="primary" onClick={(evt) => handleAddOrgToOkpolu(evt,true)}><Translate value="childOrgs.addOrgToOkpolu" /></Button>
                <Button type="button" variant="secondary" onClick={(evt) => handleAddOrgToOkpolu(evt,false)}><Translate value="close" /></Button>
            </Modal.Footer>
        </Modal>
        <Modal
            show={showEditNameOrg}
            onHide={(evt) => handleEditNameOrg(evt, false)}
            backdrop="static"
            keyboard={false}
            bsSize="large">
            <Modal.Header closeButton>
                <Modal.Title><Translate value="childOrgs.editNameOrg" /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form horizontal>
                    <FormGroup controlId="formHorizontalNameOrgRu" bsSize='small'>
                        <Col componentClass={ControlLabel} sm={2}><Translate value="childOrgs.nameOrg" /> RU</Col>
                        <Col sm={10}>
                            <FormControl componentClass="textarea" value={editOrg.nameRu} onChange={(evt) => changeEditOrgFld("nameRu", evt.target.value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup controlId="formHorizontalNameOrgKz" bsSize='small'>
                        <Col componentClass={ControlLabel} sm={2}><Translate value="childOrgs.nameOrg" /> KZ</Col>
                        <Col sm={10}>
                            <FormControl componentClass="textarea" value={editOrg.nameKz} onChange={(evt) => changeEditOrgFld("nameKz", evt.target.value)} />
                        </Col>
                    </FormGroup>
                </Form>
                {errorsEditOrg.length > 0 ? <><h4 className="text-danger"><strong>Ошибки:</strong></h4> <ListGroup>{errorsEditOrg.map((m, i) =>
                    <ListGroupItem bsStyle="danger" key={i}>{m}</ListGroupItem>)}</ListGroup></> : ""}
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" variant="primary" onClick={(evt) => handleEditNameOrg(evt, true)}><Translate value="msgButtons.Change" /></Button>
                <Button type="button" variant="secondary" onClick={(evt) => handleEditNameOrg(evt, false)}><Translate value="close" /></Button>
            </Modal.Footer>
        </Modal>
    </>;
}
export default connect(
    state => state,
    dispatch => bindActionCreators(actionCreatorsNsi, dispatch)
)(ListChildsOrgs);