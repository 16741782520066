import React, { Component, Fragment } from 'react';
import { Panel, Button, Form, Row, Col, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as FileSaver from 'file-saver';

import { actionCreators } from '../../store/Nsi';
import SelectObject from '../common/SelectObject';
import FilterRazOptions from '../common/FilterRazOptions';
import { getNameLocalize, years, getCurrentYear } from '../Constants';

class ToExcel extends Component {
    constructor(props) {
        super(props);
        let nowYear = getCurrentYear();
        this.state = {
            year: nowYear,//props.year !== undefined ? props.year : nowYear,
            kper: 4,//период
            kraz: 1,//this.props.userInfo.user.accessLevel !== 4 ? 1 : 3, //разрез
            tobj: props.userInfo.user.accessLevel, // тип обьекта 1 – РК; 2 – область; 3 - район; 4 - организация (доступ зависит от пользователя)
            obl: undefined,
            raion: undefined,
            org: undefined,
            form: { kf: "01", nameRu: "Численность постоянного населения", nameKz: "Қазақстан Республикасының тұрақты тұрғындары" },
            isLoading: false
        }
        this.onCreateFileExcel = this.onCreateFileExcel.bind(this);
        this.onSelectForm = this.onSelectForm.bind(this);
        if (props.nsi.sprform.length === 0) {
            props.getNs('sprform');
        }
        if (props.nsi.periods.length === 0) {
            props.getNs('periods');
        }
        if (props.nsi.sprraz.length === 0) {
            props.getNs('sprraz');
        }
    }
    async onCreateFileExcel() {
        // alert("generate excel");
        var kobj = "";
        switch (this.state.tobj) {
            case 1:
                kobj = "0";
                break;
            case 2:
                kobj = this.state.obl.kobl;
                break;
            case 3:
                kobj = this.state.raion.kraion;
                break;
            case 4:
                kobj = this.state.org.korg;
                break;
            default:
                kobj = "";
        }
        this.setState({ isLoading: true });
        let absoluteUrl = `${document.location.protocol}//${document.location.host}/generate/Excel/${this.state.year}/${this.state.kper}/${this.state.kraz}/${this.state.tobj}/${kobj}/${this.state.form.kf}/${this.props.i18n.locale.substr(0,2)}`;
        let fileName = `${this.state.year}_${this.state.kper}_${this.state.kraz}_${this.state.tobj}_${kobj}_${this.state.form.kf}.xlsx`;
        var token = this.props.userInfo.user.token;
        const response = await fetch(absoluteUrl, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const blob = await response.blob();
            FileSaver.saveAs(blob, fileName);
            this.setState({ isLoading: false });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    }
    onSelectForm(e) {
        let kf = e.target.value;
        let form = this.props.nsi.sprform.filter(spr => spr.kf === kf)[0];
        this.setState({ form });
    }
    onSelectYear = (e) => {
        let year = parseInt(e.target.value, 10);
        this.setState({ year });
    }
    onSelectKper = (e) => {
        let kper = parseInt(e.target.value, 10);
        this.setState({ kper });
    }
    onSelectKraz = (e) => {
        let kraz = parseInt(e.target.value, 10);
        this.setState({ kraz });
    }
    render() {
        return (<Fragment><Form horizontal>
            <h5 style={{ textAlign: "center" }}><strong><Translate value='toExcel' /></strong></h5>
            <FormGroup controlId="formYear" bsSize='small'>
                <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='year' />{':'}</ControlLabel></Col>
                <Col xs={12} sm={10}>
                    <FormControl className='input-sm' componentClass="select" placeholder="select"
                        defaultValue={this.state.year} onChange={this.onSelectYear} disabled={this.state.isLoading === true}>
                        {years.filter(f => f > 2016).map(it => <option key={it} value={it}>{it}</option>)}
                    </FormControl></Col>
            </FormGroup>
            <FormGroup controlId="formPer" bsSize='small'>
                <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='period' />{':'}</ControlLabel></Col>
                <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={`${this.state.kper}`}
                    onChange={this.onSelectKper} style={{ minWidth: '100%' }} disabled={this.state.isLoading === true}>
                    {this.props.nsi.periods.map(it => <option key={it.kper} value={it.kper}>{getNameLocalize(this.props.i18n, it)}</option>)}
                </FormControl></Col>
            </FormGroup>
            <FormGroup controlId="formRaz" bsSize='small'>
                <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='razres' />{':'}</ControlLabel></Col>
                <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={this.state.kraz}
                    onChange={this.onSelectKraz} style={{ minWidth: '100%' }} disabled={this.state.isLoading === true}>
                    <FilterRazOptions />
                </FormControl></Col>
            </FormGroup>
            <FormGroup controlId="formKf" bsSize='small'>
                <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='forma' />{':'}</ControlLabel></Col>
                <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={this.state.form.kf}
                    onChange={this.onSelectForm} style={{ minWidth: '100%' }} disabled={this.state.isLoading === true}>
                    {this.props.nsi.sprform/*.filter(it => it.kf !== '01')*/.map(it => <option key={it.kf} value={it.kf}>{it.kf} {getNameLocalize(this.props.i18n, it)}</option>)}
                </FormControl></Col>
            </FormGroup>
            <SelectObject
                tobj={this.state.tobj}
                obl={this.state.obl}
                raion={this.state.raion}
                org={this.state.org}
                setTobj={(tobj) => this.setState({ tobj })}
                setObl={(obl) => this.setState({ obl })}
                setRaion={(raion) => this.setState({ raion })}
                setOrg={(org) => this.setState({ org })} disabled={this.state.isLoading === true}
                year={this.state.year}
            />
            <FormGroup bsSize='small'>
                <Col smOffset={2} sm={10}>
                    <Button type="button" bsStyle="primary" bsSize="large" onClick={this.onCreateFileExcel} disabled={this.state.isLoading === true}><Translate value="create" /></Button>
                    {this.state.isLoading ? <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('wait')} width={20} />{' '}<strong><Translate value="generateExcel" /></strong></div> : ''}
                </Col>
            </FormGroup>
        </Form>
        </Fragment>);
    }
}
export default connect(
    state => state,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ToExcel);