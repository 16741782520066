import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { Translate, I18n } from 'react-redux-i18n';
import { Button, Col, FormGroup, FormControl, ControlLabel, Form, Radio, Checkbox, HelpBlock, Row, Alert, Modal } from 'react-bootstrap';

import { actionCreators as actionCreatorsSvod } from '../../store/Svod';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';
import SelectMultiObjects from '../common/SelectMultiObjects';
import SelectMultiForms from '../common/SelectMultiForms';
import { getNameLocalize } from '../Constants';
//import { getToken } from './UserInfo';


import { years, getCurrentYear } from '../Constants';
import FilterRazOptions from '../common/FilterRazOptions';

class EditFormRaz extends Component {

    constructor(props) {
        super(props);
        let nowYear = getCurrentYear();
        this.state = {
            year: props.year !== undefined ? props.year : nowYear,
            kper: 4,//период
            kraz_src: 1,//this.props.userInfo.user.accessLevel !== 4 ? 1 : 3, //разрез источника
            kraz: 1,//this.props.userInfo.user.accessLevel !== 4 ? 1 : 3, //разрез назначения
            tobj: props.userInfo.user.accessLevel, // тип обьекта 1 – РК; 2 – область; 3 - район; 4 - организация (доступ зависит от пользователя)
            obl: undefined,
            raion: undefined,
            org: undefined,
            kobls: [],
            kraions: [],
            korgs: [],
            forOrgs: false,
            kforms: [],
            isAfterSvodDeleteSrc: true,//Удалить данные раздела источника после свода(перноса) в итоговой разрез, для редактрования разрезов источник всегда удаляется! 
            errors: [],
            isSvodComplete: false,
            showConfirm: false,
            isSelectOrgAll: false
        };
        if (props.nsi.periods.length === 0) props.getNs('periods');
        if (props.nsi.sprraz.length === 0) props.getNs('sprraz');
        this.onSvodForm = this.onSvodForm.bind(this);
        this.handleConfirmClose = this.handleConfirmClose.bind(this);
        this.handleConfirmSvod = this.handleConfirmSvod.bind(this);
        this.showSubOrgs = this.showSubOrgs.bind(this);
        this.showListSub = this.showListSub.bind(this);
        this.onCheckChildOrg = this.onCheckChildOrg.bind(this);
        this.getValidationChildOrgs = this.getValidationChildOrgs.bind(this);
        this.getSubOrgs = this.getSubOrgs.bind(this);
        this.isSelectOrgAllChange = this.isSelectOrgAllChange.bind(this);
        if (props.userInfo.user.nomenklKod === '008' || props.userInfo.user.nomenklKod === '049' || props.userInfo.user.nomenklKod === '043') {
            this.getSubOrgs();
        }
    }
    getSubOrgs() {
        var self = this;
        const fetchData = async () => {
            const childOrgs = await self.props.getSubOrgs();
            self.setState({ childOrgs });
        }
        fetchData();
    }
    handleIsAfterSvodDeleteSrc = (e) => {
        e.preventDefault();
        this.setState({ isAfterSvodDeleteSrc: e.target.checked != null ? e.target.checked: false });
    }
    handleConfirmClose = (e) => {
        e.preventDefault();
        this.setState({ showConfirm: false });
    }
    handleConfirmSvod = (e) => {
        e.preventDefault();
        this.setState({ showConfirm: false });
        this.setState({ isSvodComplete: false });
        let param = this.getSvodParams();
        this.props.svodFormRaz(param);
        this.setState({ isSvodComplete: true });
    }
    getSvodParams = () => {
        let kobjs = null;
        switch (this.state.tobj) {
            case 1:
                kobjs = ["00"];
                break;
            case 2:
                if (this.props.userInfo.user.accessLevel === 2) {
                    kobjs = [];
                    kobjs.push(this.state.obl.kobl);
                } else {
                    kobjs = this.state.kobls;
                }
                break;
            case 3:
                if (this.props.userInfo.user.accessLevel === 3) {
                    if (this.state.forOrgs != true) {
                        kobjs = [];
                        kobjs.push(this.state.raion.kraion);
                    } else {
                        kobjs = [...this.state.korgs];
                    }
                } else {
                    kobjs = this.state.kraions;
                }
                break;
            case 4://организации
                if (this.props.userInfo.user.accessLevel === 4) {
                    kobjs = [];
                    kobjs.push(this.state.org.korg);
                } else {
                    kobjs = this.state.korgs;
                }
                break;
            default:
        }
        if (kobjs.length === 0) {
            alert(I18n.t("selectObjs"));
            return null;
        }
        if (this.state.kforms.length === 0) {
            alert(I18n.t("selectForms"));
            return null;
        }
        if (this.state.kraz_src === this.state.kraz) {
            alert(I18n.t("razSrcEqDest"));
            return null;
        }
        let kraz_src = [];
        kraz_src.push(this.state.kraz_src);
        this.setState({ isSvodComplete: false });
        return {
            year: this.state.year,
            kper: this.state.kper,
            krazSrc: kraz_src,
            kraz: this.state.kraz,
            tobj: this.state.forOrgs === true ? 4 : this.state.tobj,
            kobjs,
            kforms: this.state.kforms,
            isAfterSvodDeleteSrc: this.state.isAfterSvodDeleteSrc
        };
    }
    async onSvodForm(e){
        e.preventDefault();
        this.setState({ isSvodComplete: false });
        let param = this.getSvodParams();
        if (param == null) return;
        let retData =  await this.props.isExistSvodRaz(param);
        if (retData.successed === true) {
            if (retData.isExists === true) {
                //данные уже есть в базе данных, спросить создать свод?
                this.setState({ showConfirm: true });
                return;
            } else {
                await this.props.svodFormRaz(param);
                this.setState({ isSvodComplete: true });
            }
        } else {
            this.setState({ isSvodComplete: true });
        }
    }
    onSelectYear = (e) => {
        let year = parseInt(e.target.value, 10);
        this.setState({ year });
    }
    onSelectKper = (e) => {
        let kper = parseInt(e.target.value, 10);
        this.setState({ kper });
    }
    onSelectKraz = (e) => {
        let kraz = parseInt(e.target.value, 10);
        this.setState({ kraz });
    }
    onSelectKrazSrc = (e) => {
        let kraz_src = parseInt(e.target.value, 10);
        this.setState({ kraz_src});
    }
    getValidationStateRaz() {
        let errors = [];
        if (this.state.kraz_src != null) {
            return 'success';
        }else {
            errors.push("Выберите разрезы источника");
            return 'error';
        }
        //return null;
    }
    isSelectOrgAllChange(evt) {
        //evt.preventDefault();
        if (evt.target.checked === true) {
            //this.state.childOrgs.map(m => m.kod);
            this.setState({ isSelectOrgAll: true, korgs: cloneDeep(this.state.childOrgs.map(m => m.kod))})
        } else {
            this.setState({ isSelectOrgAll: false, korgs: [] })
        }
    }
    showSubOrgs() {
        if ((this.props.userInfo.user.nomenklKod === '008' || this.props.userInfo.user.nomenklKod === '049' || this.props.userInfo.user.nomenklKod === '043') && this.state.tobj === 3/*&& this.state.kraz_src.filter(r => r === 23 || r === 24 || r === 25).length > 0*/) {
            return <Fragment><FormGroup bsSize='small'>
                <Col smOffset={2}>
                    <Radio name="radioGroupForOrgs" onClick={() => this.setState({ forOrgs: false, isSelectOrgAll: false, korgs: [] })} checked={this.state.forOrgs === false} inline><Translate value="forRaion" /></Radio>{' '}
                    <Radio name="radioGroupForOrgs" onClick={() => this.setState({ forOrgs: true, isSelectOrgAll: false, korgs: [] })} checked={this.state.forOrgs === true} inline><Translate value="forSub" /></Radio>{' '}&nbsp;&nbsp;&nbsp;
                    {this.state.forOrgs === true ? <Checkbox onChange={this.isSelectOrgAllChange} checked={this.state.isSelectOrgAll===true} inline><Translate value="selectAllOrgs" /></Checkbox> : ''}
                </Col>
            </FormGroup>
                {this.showListSub()}
            </Fragment>
        } else {
            return '';
        }
    }
    showListSub() {
        if (this.state.forOrgs != true) return '';
        return <FormGroup bsSize='small' controlId="subOrgs" validationState={this.getValidationChildOrgs()}>
            <Col smOffset={2}>
                {this.state.childOrgs.map(it => <Checkbox onChange={this.onCheckChildOrg} checked={this.state.korgs.includes(it.kod)===true} key={it.kod} value={it.kod} inline>{it.kod} {getNameLocalize(this.props.i18n, it)}</Checkbox>)}
            </Col>
        </FormGroup>;
    }
    onCheckChildOrg = (e) => {
        //e.preventDefault();
        let checked = e.target.checked;
        let org = e.target.value;
        let korgs = [...this.state.korgs];
        if (checked) {
            korgs.push(org);
        } else {
            let ind = korgs.indexOf(org);
            korgs.splice(ind, 1);
        }
        this.setState({ korgs });
    }
    getValidationChildOrgs() {
        if (this.state.forOrgs != true) return null;
        const length = this.state.korgs.length;
        if (length > 0)
            return 'success';
        if (length === 0) {
            return 'error';
        }
        return null;
    }
    render() {
        let isReadOnLy = this.props.userInfo.user.roles.filter(r => r === 'UserReadData').length > 0;
        if (isReadOnLy === true)
            return <Translate value="noAccess" />
        else
        return <Fragment><Form onSubmit={this.onSvodForm} horizontal>
            <h5 style={{ textAlign: "center" }}><strong><Translate value='editFormRaz' /></strong></h5>
            <FormGroup controlId="formYear" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='year' />{':'}</Col>
                <Col sm={10}>
                    <FormControl className='input-sm' componentClass="select" placeholder="select"
                        defaultValue={this.state.year} onChange={this.onSelectYear} bsSize="small" style={{ width: "auto" }}>
                        {years.map(it => <option key={it} value={it}>{it}</option>)}
                    </FormControl></Col>
            </FormGroup>
            <FormGroup controlId="formPer" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='period' />{':'}</Col>
                <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={`${this.state.kper}`}
                    onChange={this.onSelectKper} bsSize='small' style={{ width: "auto" }}>
                    {this.props.nsi.periods.map(it => <option key={it.kper} value={it.kper}>{it.nameRu}</option>)}
                </FormControl></Col>
            </FormGroup>
            <FormGroup controlId="formRazSel" bsSize='small' validationState={this.getValidationStateRaz()}>
                <Col sm={2} componentClass={ControlLabel}><Translate value='razres' />{':'}</Col>
                <Col sm={10}>
                    <div style={{ float: "left", width: "auto" }}><ControlLabel><Translate value="razSourceOne" /></ControlLabel>
                        <FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={this.state.kraz_src}
                            onChange={this.onSelectKrazSrc} bsSize='small' style={{ width: "auto" }}>
                            <FilterRazOptions />
                        </FormControl>
                    </div>
                    <div><ControlLabel><Translate value="razDest" /></ControlLabel>
                        <FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={this.state.kraz}
                            onChange={this.onSelectKraz} bsSize='small' style={{ width: "auto" }}>
                            <FilterRazOptions />
                        </FormControl>
                    </div>
                </Col>
            </FormGroup>
            <SelectMultiObjects setTobj={(tobj) => this.setState({ tobj })}
                setObl={(obl) => this.setState({ obl })} setObls={(kobls) => this.setState({ kobls })}
                setRaion={(raion) => this.setState({ raion })} setRaions={(kraions) => this.setState({ kraions })}
                setOrg={(org) => this.setState({ org })} setOrgs={(korgs) => this.setState({ korgs })}
                isSelOrg={true} year={this.state.year}
            />
            {this.showSubOrgs()}
            <SelectMultiForms setForms={(kforms) => this.setState({ kforms })} />
            <HelpBlock><ul className="text-danger">{this.state.errors.map(msg => <li>{msg}</li>)}</ul></HelpBlock>
            {this.props.svod.isLoading === true ? <Row>
                <Col smOffset={2} sm={10}>
                    <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('waitsvod')} width={20} />{' '}<strong><Translate value="waitsvod" /></strong></div>
                </Col>
            </Row> : ''}
            {this.state.isSvodComplete === true && this.props.svod.statusSvod != null && this.props.svod.statusSvod.successed === true ? <Row>
                <Col smOffset={2} sm={10}>
                    <Alert bsStyle="success"><Translate value="razChanged" /></Alert>
                </Col>
            </Row> : ''}
            {this.state.isSvodComplete === true && this.props.svod.statusSvod != null && this.props.svod.statusSvod.error != null ? <Row>
                <Col smOffset={2} sm={10}>
                    <Alert bsStyle="danger">{this.props.svod.statusSvod.error}</Alert>
                </Col>
            </Row> : ''}
            <FormGroup>
                <Col smOffset={2} sm={10}>
                    <Button className='btn btn-primary text-center' type="submit" disabled={this.props.svod.isLoading}><Translate value="changeRazrez" /></Button>
                </Col>
            </FormGroup>
        </Form>
            <Modal show={this.state.showConfirm} onHide={this.handleConfirmClose}>
                <Modal.Header closeButton>
                    <Modal.Title><Translate value="warning" /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert bsStyle="warning">
                        <strong><Translate value="warning" /></strong> <Translate value="razEditConfirmMsg" />
                    </Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleConfirmSvod}><Translate value="editRazBtCreate" /></Button>
                    <Button onClick={this.handleConfirmClose}><Translate value="editRazBtCancel" /></Button>
                </Modal.Footer>
            </Modal>
        </Fragment>;
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionCreatorsSvod }, dispatch)
)(EditFormRaz);

/*
  <FormGroup controlId="formIsAfterSvodDeleteSrc" bsSize='small'>
                <Col smOffset={2} sm={10}>
                    <Checkbox checked={this.state.isAfterSvodDeleteSrc}
                        onChange={this.handleIsAfterSvodDeleteSrc}><strong><Translate value='isAfterSvodDeleteSrc' /></strong></Checkbox>
                </Col>
            </FormGroup>
 */