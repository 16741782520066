import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Button, Col, FormGroup, FormControl, ControlLabel, Form, Row, Alert, Modal } from 'react-bootstrap';

import { actionCreators as actionCreatorsWork } from '../../store/Work';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';
import SelectObject from '../common/SelectObject';
import { years, getNameLocalize, getNamePeriod, getCurrentYear } from '../Constants';

class DeleteForm extends Component {
    constructor(props) {
        super(props);
        let nowYear = getCurrentYear();
        this.state = {
            year: props.year !== undefined ? props.year : nowYear,
            kper: 4,//период
            kraz: 1, //разрез
            kform: '01',
            tobj: props.userInfo.user.accessLevel, // тип обьекта 1 – РК; 2 – область; 3 - район; 4 - организация (доступ зависит от пользователя)
            obl: undefined,
            raion: undefined,
            org: undefined,
            showConfirmDelete: false,
            showNodata:false,
        }
        props.getNs('periods');
        props.getNs('sprraz');
        props.getNs('sprform');
        this.onDeleteForm = this.onDeleteForm.bind(this);
        this.handleCloseConfirmDelete = this.handleCloseConfirmDelete.bind(this);
        this.deleteData = this.deleteData.bind(this);
        this.handleCloseNodata = this.handleCloseNodata.bind(this);
        this.getNameKf = this.getNameKf.bind(this);
        this.getNameObj = this.getNameObj.bind(this);
        this.getNameRaz = this.getNameRaz.bind(this);
    };
    handleCloseNodata() {
        this.setState({ showNodata: false });
    }
    handleCloseConfirmDelete() {
        this.setState({ showConfirmDelete: false });
    }
    deleteData() {
        let kobj = null;
        switch (this.state.tobj) {
            case 1:
                kobj = "00";
                break;
            case 2:
                kobj = this.state.obl.kobl;
                break;
            case 3:
                kobj = this.state.raion.kraion;
                break;
            case 4:
                kobj = this.props.userInfo.user.accessLevel === 4 ? this.props.userInfo.user.kodOrg : this.state.org.korg;
                break;
            default:
        }
        this.props.deleteForm({
            year: this.state.year,
            period: this.state.kper,
            raz: this.state.kraz,
            kf: this.state.kform,
            kt: "*",
            tobj: this.state.tobj,
            kobj
        });
        this.setState({ showConfirmDelete: false });
    }
    async onDeleteForm(e) {
        e.preventDefault();
        let kobj = null;
        switch (this.state.tobj) {
            case 1:
                kobj = "00";
                break;
            case 2:
                kobj=this.state.obl.kobl;
                break;
            case 3:
                kobj=this.state.raion.kraion;
                break;
            case 4:
                kobj = this.props.userInfo.user.accessLevel === 4 ? this.props.userInfo.user.kodOrg:this.state.org.korg;
                break;
            default:
        }
        //проверить есть ли данные по данной форме
        let ret=await this.props.isExistsForm({
            year: this.state.year,
            period: this.state.kper,
            raz: this.state.kraz,
            kf: this.state.kform,
            kt:"*",
            tobj: this.state.tobj,
            kobj
        });
        if (ret != null) {
            if (ret.isExists === true) {
                //данные  есть
                this.setState({ showConfirmDelete: true});
            } else {
                //данных  нет
                this.setState({ showNodata: true });
            }
        }
    }
    getNameKf(){
       let kform = this.state.kform;
       let form = this.props.nsi.sprform.filter(f => f.kf === kform);
       if (form.length === 0) return kform;
        return `${kform} ${getNameLocalize(this.props.i18n, form[0])}`;
    }
    getNameObj(){
        switch (this.state.tobj) {
            case 1:
                return "РК";
            case 2:
                return getNameLocalize(this.props.i18n, this.state.obl);
            case 3:
                return `${getNameLocalize(this.props.i18n, this.state.obl)}, ${getNameLocalize(this.props.i18n, this.state.raion)}`;
            case 4:
                return this.props.userInfo.user.accessLevel === 4 ? `${this.props.userInfo.user.kodOrg} ${this.props.userInfo.user.nameOrg}` : `${this.state.org.korg} ${this.state.org.nameRu}`;
            default:
        }
        return "";
    }
    getNameRaz() {
        let kraz = this.state.kraz;
        let raz = this.props.nsi.sprraz.filter(r => r.kod === kraz);
        if (raz.length === 0) return kraz
        return getNameLocalize(this.props.i18n, raz[0]);
    }
    render() {
        return <Fragment><Form onSubmit={this.onDeleteForm} horizontal>
            <h5 style={{ textAlign: "center" }}><strong><Translate value="deleteForm" /></strong></h5>
            <FormGroup controlId="formYear" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='year' />{':'}</Col>
                <Col sm={10}>
                    <FormControl className='input-sm' componentClass="select" placeholder="select"
                        defaultValue={this.state.year} onChange={(e) => this.setState({ year: parseInt(e.target.value, 10)})} bsSize="small" style = {{ width: "auto" }}>
                        {years.filter(y => y > 2020).map(it => <option key={it} value={it}>{it}</option>)}
                    </FormControl></Col>
            </FormGroup>
            <FormGroup controlId="formPer" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='period' />{':'}</Col>
                <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={`${this.state.kper}`}
                    onChange={(e) => this.setState({ kper: parseInt(e.target.value, 10) })} bsSize='small' style={{ width: "auto" }}>
                    {this.props.nsi.periods.map(it => <option key={it.kper} value={it.kper}>{it.nameRu}</option>)}
                </FormControl></Col>
            </FormGroup>
            <FormGroup controlId="formRaz" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='razres' />{':'}</Col>
                <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={this.state.kraz}
                    onChange={(e) => this.setState({ kraz: parseInt(e.target.value, 10) })} bsSize='small' style={{ width: "auto" }}>
                    {this.props.nsi.sprraz.map(it => <option key={it.kod} value={it.kod}>{getNameLocalize(this.props.i18n, it)}</option>)}
                </FormControl>
                </Col>
            </FormGroup>
            <FormGroup controlId="formKf" className='input-group-sm'>
                <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='forma' />{':'}</ControlLabel></Col>
                <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={this.state.kform}
                    onChange={(e) => this.setState({ kform: e.target.value })} style={{ minWidth: '100%' }}>
                    {this.props.nsi.sprform.map(it => <option key={it.kf} value={it.kf}>{it.kf} {getNameLocalize(this.props.i18n, it)}</option>)}
                </FormControl></Col>
            </FormGroup>
            <SelectObject setTobj={(tobj) => this.setState({ tobj })}
                setObl={(obl) => this.setState({ obl })}
                setRaion={(raion) => this.setState({ raion })}
                setOrg={(org) => this.setState({ org })} org={this.state.org}
                year={this.state.year}
            />
            {this.props.work.isLoading === true ? <Row>
                <Col smOffset={2} sm={10}>
                    <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('waitsvod')} width={20} />{' '}<strong><Translate value="waitsvod" /></strong></div>
                </Col>
            </Row> : ''}
            {this.props.work.statusDelete != null && this.props.work.statusDelete.successed === true ? <Row>
                <Col smOffset={2} sm={10}>
                    <Alert bsStyle="success"><Translate value="deleteComplete" /></Alert>
                </Col>
            </Row> : ''}
            {this.props.work.statusDelete != null && this.props.work.statusDelete.error != null ? <Row>
                <Col smOffset={2} sm={10}>
                    <Alert bsStyle="danger">{this.props.work.statusDelete.error}</Alert>
                </Col>
            </Row> : ''}
            <FormGroup>
                <Col smOffset={2} sm={10}>
                    <Button className='btn btn-primary text-center' type="submit" disabled={this.props.isLoading}><Translate value="btDeleteForm"/></Button>
                </Col>
            </FormGroup>
        </Form>
            <Modal show={this.state.showConfirmDelete} onHide={this.handleCloseConfirmDelete}>
                <Modal.Header closeButton>
                    <Modal.Title><Translate value="deleteDataForm2" /></Modal.Title>
                </Modal.Header>
                <Modal.Body bsClass='small'>
                    <p style={{ margin: "10px" }}>
                        <strong><Translate value='deleteYesOrNo' /></strong><br />
                        <Translate value='year' />{':'}<strong>{this.state.year}</strong><br />
                        <Translate value='period' />{':'}<strong>{getNamePeriod(this.state.kper)}</strong><br />
                        <Translate value='razres' />{':'}<strong>{this.getNameRaz()}</strong><br />
                        <Translate value='forma' />{':'}<strong>{this.getNameKf()}</strong><br />
                        <Translate value='object' />{':'}<strong>{this.getNameObj()}</strong>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleCloseConfirmDelete}><Translate value="close" /></Button>
                    <Button bsStyle="danger" onClick={this.deleteData}><Translate value="delete" /></Button>
                </Modal.Footer>
            </Modal>
            <Modal show={this.state.showNodata} onHide={this.handleCloseNodata}>
                <Modal.Header closeButton>
                    <Modal.Title><Translate value="noDataForm" /></Modal.Title>
                </Modal.Header>
                <Modal.Body bsClass='small'>
                    <Row><Col smOffset={4}><strong><Translate value="noDataForm" /></strong></Col></Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleCloseNodata}><Translate value="close" /></Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionCreatorsWork }, dispatch)
)(DeleteForm);

//isNotOrg={true}