import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormGroup, FormControl, ControlLabel, Row, Col } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { getNameLocalize, filterForms, filterRazdels } from '../Constants';

import { actionCreators } from '../../store/Nsi';

class SelectFormTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            krazd: props.krazd != null ? props.krazd: undefined,//:"01",//то до first krazd
            form: props.form != null ? props.form : props.userInfo.user.firstFormTable.formGod /*{ kf: "01", nameRu: "Численность постоянного населения РК", nameKz: "Қазақстан Республикасының тұрақты тұрғындары" }*/,
            table: props.table != null ? props.table : props.userInfo.user.firstFormTable.tableGod/*{ kf: "01", ktab: "0001", nameRu: "Постоянное население Республики Казахстан", nameKz: "Қазақстан Республикасының тұрақты тұрғындары" }*/,
            kper: props.kper != null ? props.kper:4
        }
        this.onSelectRazdel = this.onSelectRazdel.bind(this);
        this.onSelectForm = this.onSelectForm.bind(this);
        this.onSelectTab = this.onSelectTab.bind(this);
        //if (props.nsi.sprform.length === 0) {
        //    props.getNs('sprform');
        //}
        //if (props.nsi.allsprtab.length === 0) {
        //    props.getNs('allsprtab');
        //}
    }
    componentWillReceiveProps(nextProps) {
        if (!(this.state.form.kf === nextProps.form.kf && this.state.table.ktab === nextProps.table.ktab))
            this.setState({ /*...this.state,*/form: nextProps.form, table: nextProps.table });
        if (this.state.kper != nextProps.kper && this.state.table.planYear === 1 && nextProps.kper != 4) {
            let ar = this.props.nsi.allsprtab.filter(spr => spr.kf === this.state.form.kf && spr.planYear === 0);
            if (ar.length > 0) {
                this.props.setTable(ar[0]);
            }
        }
    }
    onSelectRazdel(e) {
        let krazd = e.target.value;
        if (krazd === "") {
            let form = this.props.userInfo.user.firstFormTable.formGod;
            let table = this.props.userInfo.user.firstFormTable.tableGod;
            this.setState({ krazd: undefined, form, table });
            this.props.setForm(form);
            this.props.setTable(table);
            this.props.setKrazd(undefined)
        } else {
            if (this.props.userInfo.user.accessForm.length > 0) {
                let form = this.props.nsi.sprform.filter(spr => filterForms(this.props.userInfo.user.accessForm, spr.kf, this.props.kper)
                    && spr.razdels.length > 0 && spr.razdels.includes(krazd))[0];
                let table = this.props.nsi.allsprtab.filter(spr => spr.kf === form.kf)[0];
                this.setState({ krazd, form, table });
                this.props.setForm(form);
                this.props.setTable(table);
            } else {
                let form = this.props.nsi.sprform.filter(spr => spr.razdels.length > 0 && spr.razdels.includes(krazd))[0];
                let table = this.props.nsi.allsprtab.filter(spr => spr.kf === form.kf)[0];
                this.setState({ krazd, form, table });
                this.props.setForm(form);
                this.props.setTable(table);
            }
            this.props.setKrazd(krazd);
        }
    }

    onSelectForm(e) {
        let kf = e.target.value;
        let form = this.props.nsi.sprform.filter(spr => spr.kf === kf)[0];
        let table = this.props.nsi.allsprtab.filter(spr => spr.kf === kf)[0];
        this.setState({ form, table });
        this.props.setForm(form);
        this.props.setTable(table);
    }
    onSelectTab = (e) => {
        let ktab = e.target.value;
        let table = this.props.nsi.allsprtab.filter(spr => spr.kf === this.state.form.kf && spr.ktab === ktab)[0];
        this.setState({ table });
        this.props.setTable(table);
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col xs={12} sm={12}>
                        <FormGroup controlId="razdelForm" className='input-group-sm'>
                            <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='razdelForm' />{':'}</ControlLabel></Col>
                            <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" value={this.state.krazd}
                                onChange={this.onSelectRazdel} style={{ minWidth: '100%' }}>
                                <option value={""}>{I18n.t("allForms")}</option>
                                {this.props.nsi.sprrazdel.filter(f => filterRazdels(this.props.nsi.sprform, this.props.userInfo.user.accessForm, f.krazd, this.props.kper)).map(it => <option key={it.krazd} value={it.krazd}>{it.krazd} {getNameLocalize(this.props.i18n, it)}</option>)}
                            </FormControl></Col>
                        </FormGroup></Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12}>
                        <FormGroup controlId="formKf" className='input-group-sm'>
                            <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='forma' />{':'}</ControlLabel></Col>
                            <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" value={this.state.form.kf}
                                onChange={this.onSelectForm} style={{ minWidth: '100%' }}>
                                {this.props.noFilter === true ? this.props.nsi.sprform.filter(f => this.state.krazd == null ? true : f.razdels.includes(this.state.krazd)).map(it => <option key={it.kf} value={it.kf}>{it.kf} {getNameLocalize(this.props.i18n, it)}</option>) :
                                    this.props.nsi.sprform.filter(f => filterForms(this.props.userInfo.user.accessForm, f.kf, this.props.kper) && (this.state.krazd == null ? true : f.razdels.includes(this.state.krazd))).map(it => <option key={it.kf} value={it.kf}>{it.kf} {getNameLocalize(this.props.i18n, it)}</option>)}
                            </FormControl></Col>
                        </FormGroup></Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12}>
                        <FormGroup controlId="formKt" className='input-group-sm'>
                            <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='table' />{':'}</ControlLabel></Col>
                            <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" value={this.state.table.ktab}
                                onChange={this.onSelectTab} style={{ minWidth: '100%' }}>
                                {this.props.nsi.allsprtab.filter(spr => spr.kf === this.state.form.kf && !(this.props.kper != 4 && spr.planYear===1)).map(it => <option key={it.ktab} value={it.ktab}>{it.ktab} {getNameLocalize(this.props.i18n, it)}</option>)}
                            </FormControl></Col>
                        </FormGroup></Col>
                </Row>
            </Fragment>);
    }
}
export default connect(
    state => state,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(SelectFormTable);