import React, { Fragment } from 'react';
import { Translate } from 'react-redux-i18n';

export default function CheckBoxIsPrivateOrg(props) {
    const onGov = (evt) => {
        evt.preventDefault();
        props.onChange({ kod: props.kod, isPrivateOrg: 0});
    }
    const onPrivate = (evt) => {
        evt.preventDefault();
        props.onChange({ kod: props.kod, isPrivateOrg:1 });
    }
    return <div className="obj_name" style={{ paddingLeft: "6px", fontWeight: "bold" }}>
        {props.checked === true ? <span className="text-primary"><i className="glyphicon glyphicon-check" style={{ cursor: "pointer" }} onClick={onGov}></i> <Translate value="finPrivateOrg" /></span> :
            <span className="text-default"><i className="glyphicon glyphicon-unchecked" onClick={onPrivate} style={{ cursor: "pointer" }}></i> <Translate value="finGovOrg"/></span>}
    </div>;
}