import { getToken } from './UserInfo';
const requestLoadLog = 'REQUEST_LOAD_LOG';
const receiveLoadLog = 'RECEIVE_LOAD_LOG';

const initialState = { isLoading: false }

export const actionCreators = {
    getLog: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestLoadLog });
        let json = JSON.stringify(params);
        const url = "api/LogApi/LoadLog";
        const response = await fetch(url, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        const listLog = await response.json();
        dispatch({ type: receiveLoadLog });
        return listLog;
    },
}
export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === requestLoadLog) {
        return {
            ...state,
            isLoading: true
        };
    }
    if (action.type === receiveLoadLog) {
       return {
            ...state,
            isLoading: false,
        };
    }
    return state;
}