import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import { Label } from 'react-bootstrap';


export default function CheckBoxStatus(props) {
    const onOk = (evt) => {
        evt.preventDefault();
        props.onChange({ korg:props.korg, kf: props.kf, status: 1 });
    }
    const onMinus = (evt) => {
        evt.preventDefault();
        props.onChange({ korg: props.korg, kf: props.kf, status: 2 });
    }
    return <Fragment>
        {props.checked === 2 ? <Label bsStyle="primary" title={I18n.t("checked")} style={{ cursor: "pointer", fontSize:'11pt' }} onClick={onOk}><i className="glyphicon glyphicon-check"></i>{' '}{props.kf}</Label> :
            <Label bsStyle="default" title={I18n.t("noChecked")} onClick={onMinus} style={{ cursor: "pointer", fontSize: '11pt' }}><i className="glyphicon glyphicon-unchecked"></i>{' '}{props.kf}</Label>}{' '}
    </Fragment>;
}