import React/*, { useEffect}*/ from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { Translate/*, I18n*/ } from 'react-redux-i18n';
//import image from "../images/home-background.jpg";
import HomeMenu from './HomeMenu';
import './Home.css';
const Home = (props) => {
    //useEffect(() => {
    //    document.body.style.backgroundImage = "url('/images/home-background.png')";
    //    return () => {
    //        document.body.style.backgroundImage = "none";
    //    }
    //}, []);
   //style={{ backgroundImage: `url(${process.env.PUBLIC_URL +'/home-background.png'})`}}
    //style={{ backgroundImage: `url(${image})` }}
    if (props.user.warningPaidDays === 0) return <div className="homeBody"><HomeMenu user={props.user}/></div>;

    if (props.user.warningPaidDays === -1)
        return <div className="homeBody">
            {props.user.warningPaidDays != null ? <Alert bsStyle="warning"><Translate value="warningPaidDays0" /></Alert> : ""}
            <HomeMenu user={props.user}/></div>
    else
        return <div className="homeBody">
            {props.user.warningPaidDays != null ? <Alert bsStyle="warning"><Translate value="warningPaidDays" days={props.user.warningPaidDays} /></Alert> : ""}
            <HomeMenu user={props.user}/></div>
};

export default connect(state => state.userInfo)(Home);

/*
         <h1>�������</h1>
        <p>
    ������� ������������ ����������� �������������� �������� ���� �� ������������ ����������� �����������.
    ������������� ������ ������  ������ ����� ��������������  ����� (��������������� ��������).
    ������������  ������ ����� ������� ������� (������������ ��������).
������������  ������ ����� ������� � � ���������� ����������� ����� (���������� ��������). </p>

 */