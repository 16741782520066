import React, { Component, Fragment } from 'react';
//import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Row, Col } from 'react-bootstrap';
//import { Redirect } from 'react-router';
import { Translate, I18n } from 'react-redux-i18n';

import ChangeLanguageLink from './ChangeLanguageLink';

import { actionCreators } from '../store/UserInfo';
import './LoginPage.css';
import { getTextVersion } from './Constants';
import appStoreRu from '../images/Download_on_the_App_Store_Badge_RU.svg';
import appStoreEn from '../images/Download_on_the_App_Store_Badge_US.svg';
import goolePlayKz from '../images/GetItOnGooglePlay_Badge_Web_color_Kazakh.png';
import goolePlayRu from '../images/GetItOnGooglePlay_Badge_Web_color_Russian.png';

import './LoginPage.css';

class LoginPage extends Component {

    constructor(props) {
        super(props);
        // reset login status
        //this.props.logout();
        this.state = {
            username: '',
            password: '',
            submitted: false
        };
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangeUserName = this.handleChangeUserName.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.showHidePass = this.showHidePass.bind(this);
        this.getNomerObiav = this.getNomerObiav.bind(this);
    }
    componentWillMount() {
        document.body.style.backgroundImage = "url('/images/statistics-1020319__340.png')";
    }
    componentWillUnmount() {
        document.body.style.backgroundImage = "none";
    }
    handleChangeUserName(e) {
        let value = e.target.value;
        this.setState({ username: value });
    }
    handleChangePassword(e) {
        let value = e.target.value;
        this.setState({ password: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        let { username, password } = this.state;
        //const { dispatch } = this.props;
        if (username && password) {
            //dispatch(userActions.login(username, password));
            this.props.login({ UserName: username, UserPassword: password });
        }
    }
    showHidePass(e) {
        e.preventDefault();
        var x = document.getElementById("password");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }
    getNomerObiav() {
        if (this.props.i18n.locale[0] === 'r')
            return <><Translate value="packetDoc.t150" /> <a href="mailto:office@medinfo.kz" style={{ fontWeight: "bold", textDecoration: "underline" }}>office@medinfo.kz</a> </>;
        else
            return <><Translate value="packetDoc.t151" /> <a href="mailto:office@medinfo.kz" style={{ fontWeight: "bold", textDecoration: "underline" }}>office@medinfo.kz</a> <Translate value="packetDoc.t152" /></>; 
    }
    render() {
        //const loggingIn  = this.props.user !== undefined ? true : false;
        /* if (this.props.loggingIn===true) {
             return <Redirect to='/' />;
         }
        */
        const { username, password, submitted } = this.state;
        //className="col-md-6 col-md-offset-3 small" className="text-right" style={{ padding:'3px' }}
        //<div className="background-image"></div>
        return (<Fragment>
            <Row>
                <Col sm={12} md={6}>
                    <div className='login'>
                        <div className='header'><Translate value="home" /></div>
                        <div className='lang'>
                            <ChangeLanguageLink lang="ru" />{' '}
                            <ChangeLanguageLink lang="kk" />
                        </div>
                        {/*<p style={{ textIndent: "8px" }}><span style={{ color: "red", fontSize: "10pt", textIndent:0 }}><Translate value="login.comment3" /></span> <a href={I18n.t('login.licenseUrl')} className="linkLicense"><Translate value="login.comment4" /></a></p>*/}
                        <form name="form" onSubmit={this.handleSubmit}>
                            <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                                <label htmlFor="username"><Translate value="login.username" /></label>
                                <input type="text" className="form-control" id="username" name="username" value={username} onChange={this.handleChangeUserName} autoComplete="off"/>
                                {submitted && !username &&
                                    <div className="help-block"><Translate value="login.usrReq" /></div>
                                }
                            </div>
                            <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                <label htmlFor="password"><Translate value="login.password" /></label>
                                <div className="input-group">
                                    <input type="password" id="password" className="form-control" name="password" value={password} onChange={this.handleChangePassword} />
                                    <span className="input-group-btn">
                                        <button className="btn btn-default reveal" type="button" onClick={this.showHidePass}><i className="glyphicon glyphicon-eye-open"></i></button>
                                    </span>
                                </div>
                                {submitted && !password &&
                                    <div className="help-block"><Translate value="login.passReq" /></div>
                                }
                            </div>
                            {this.props.userInfo.isLoading ? <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('wait')} width={20} />{' '}<strong><Translate value="entered" /></strong></div> : ''}
                            {this.props.userInfo.error !== undefined ? <div className='text-danger text-center'><strong>{this.props.userInfo.error}</strong></div> : ''}
                            <div className="buttonLogin">
                                <button className="btn btn-primary"><Translate value="enter" /></button>
                            </div>
                            <div className="policy">
                                <a href="privacy" target="_blank" style={{ fontWeight: "bold", textDecoration: "underline" }}><Translate value="policy" /></a>
                            </div>
                            <div className="version">
                                {getTextVersion()}
                            </div>
                        </form>
                    </div>
                    <div className="appMobile">
                        <div>
                            <h4><strong><Translate value="login.mobileApp" /></strong></h4>
                        </div>
                        <a href="https://apps.apple.com/kz/app/medinfo/id6499259315" target="_blank" alt={I18n.t('login.appStore')}><img src={this.props.i18n.locale[0] === 'r' ? appStoreRu : appStoreEn} alt={I18n.t('login.appStore')} height={50} /></a>
                        <a href="https://play.google.com/store/apps/details?id=kz.minzdrav.medinfo" target="_blank" alt={I18n.t('login.googlePlay')}><img src={this.props.i18n.locale[0] === 'r' ? goolePlayRu : goolePlayKz} alt={I18n.t('login.googlePlay')} height={ this.props.i18n.locale[0] === 'r' ?50:70} /></a>
                    </div>
                </Col>
                <Col sm={12} md={6}>
                    <div className="packetDoc">
                        <div className="packetDoc-heading"><Translate value="packetDoc.t1" /></div>
                        <div className="packetDoc-body">
                            <Translate value="packetDoc.t2" />:
                        <ol>
                                <li><Translate value="packetDoc.t3" /> <a href="downloads/license.pdf" style={{ fontWeight: "bold", textDecoration: "underline" }} download><Translate value="packetDoc.t4" /></a>;</li>
                                <li><Translate value="packetDoc.t5" /> <a href="downloads/author.pdf" style={{ fontWeight: "bold", textDecoration: "underline" }} download><Translate value="packetDoc.t6" /></a>;</li>
                                <li><Translate value="packetDoc.t7" />;</li>
                                <li>
                                    <Translate value="packetDoc.t8" /> <a href="mailto:office@medinfo.kz" style={{ fontWeight: "bold", textDecoration: "underline" }}>office@medinfo.kz</a> <Translate value="packetDoc.t9" />:
                                <ul>
                                        <li><Translate value="packetDoc.t10" />;</li>
                                        <li><Translate value="packetDoc.t11" />;</li>
                                    </ul>
                                </li>
                                <li>
                                    <Translate value="packetDoc.t12" /> :
                                <ul>
                                        <li><a href="downloads/ТС на госзакуп «Медстат».docx" download style={{ fontWeight: "bold", textDecoration: "underline" }}><Translate value="packetDoc.t13" /></a></li>
                                        <li><a href="downloads/КП_медстат_2025 год.pdf" download style={{ fontWeight: "bold", textDecoration: "underline" }}><Translate value="packetDoc.t14" /></a></li>
                                        <li><Translate value="packetDoc.t15" /></li>
                                        <li>{this.getNomerObiav()}</li>
                                    </ul>
                                </li>
                            </ol>
                            <div><Translate value="packetDoc.t16" /></div>
                            <div><Translate value="packetDoc.t17" />: 8-727-241-13-07, 8-727-241-12-16</div>
                            <div style={{ marginTop: "10px" }}><strong><Translate value="packetDoc.t18" /> <a href="/" style={{ fontWeight: "bold", textDecoration: "underline" }}>https://medstat.minzdrav.kz/</a></strong></div>
                            <div style={{ marginTop: "10px" }}><strong><Translate value="packetDoc.t19" /> <a href="downloads/instruction.pdf" style={{ fontWeight: "bold", textDecoration: "underline" }} download>https://medstat.minzdrav.kz/downloads/instruction.pdf</a></strong></div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row><Col>
                <div className="comment">
                    <h4><strong><Translate value="login.text1" /></strong></h4>
                    <ul>
                        <li><Translate value="login.text2" /></li>
                        <li><Translate value="login.text3" /></li>
                        <li><Translate value="login.text4" /></li>
                        <li><Translate value="login.text5" /></li>
                    </ul>
                    <h4><strong><Translate value="login.text6" /></strong></h4>
                    <ul>
                        <li><Translate value="login.text7" /></li>
                        <li><Translate value="login.text8" /></li>
                        <li><Translate value="login.text9" /></li>
                    </ul>
                </div>
            </Col>
            </Row>
        </Fragment>
        );
    }
}
/*
 * <div className="text-center">
                        <Link to={'/register'}><Translate value="register" /></Link>
                    </div>
 */
export default connect(
    state => state,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(LoginPage);
