import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, FormGroup, FormControl, ControlLabel, Modal, Button, ButtonGroup, Row, Col, Radio } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
//import { Typeahead } from 'react-bootstrap-typeahead';
import { getNameLocalize, years, getCurrentYear, getFirstRaz, getFirstFormTable, getRazItems } from '../Constants';
//import FilterRazOptions from '../common/FilterRazOptions';
import SelectFormTable from '../common/SelectFormTable';
import SelectObject from '../common/SelectObject';
import { actionCreators } from '../../store/Nsi';
import './SelectTableForEdit.css';

class SelectTableForEdit extends Component {
    constructor(props) {
        super(props);
        let nowYear = getCurrentYear();
        let kper = 4;
        let raz = getFirstRaz(kper, props);
        this.state = {
            year: nowYear,//props.year !== undefined ? props.year : nowYear,
            kper,//период
            kraz: raz != null ? raz:1,//разрез
            tobj: props.userInfo.user.accessLevel, // тип обьекта 1 – РК; 2 – область; 3 - район; 4 - организация (доступ зависит от пользователя)
            obl: undefined,
            raion: undefined,
            org: undefined,
            form: kper === 4 ? props.userInfo.user.firstFormTable.formGod : props.userInfo.user.firstFormTable.formMes/*{ kf: "01", nameRu: "Численность постоянного населения РК", nameKz: "Қазақстан Республикасының тұрақты тұрғындары" }*/,
            table: kper === 4 ? props.userInfo.user.firstFormTable.tableGod : props.userInfo.user.firstFormTable.tableMess /*{ kf: "01", ktab: "0001", nameRu: "Постоянное население Республики Казахстан", nameKz: "Қазақстан Республикасының тұрақты тұрғындары" }*/,
            isPostr: false, //Построчка
            krazd: undefined
        };
        this.onOpenTable = this.onOpenTable.bind(this);

        if (props.nsi.allsprtab.length === 0) {
            props.getNs('allsprtab');
        }
        if (props.nsi.sprform.length === 0) {
            props.getNs('sprform');
        }
        if (props.nsi.periods.length === 0) {
            props.getNs('periods');
        }
        if (props.nsi.sprraz.length === 0) {
            props.getNs('sprraz');
        }
        if (props.nsi.sprrazdel.length === 0) {
            props.getNs('sprrazdel');
        }
    }
    /*  
   componentWillMount() {
       // This method runs when the component is first added to the page
       //this.props.getNs('sprform');
       if (this.props.userInfo.user.accessRaz.length > 0) {
           this.setState({ kraz: this.props.userInfo.user.accessRaz[0]});
       }
   }
   */

    componentWillReceiveProps(nextProps) {
        // This method runs when incoming props (e.g., route params) change
        if (nextProps.selectParam != null) {
            this.setState({
                year: nextProps.selectParam.year,
                kper: nextProps.selectParam.period.kper,
                kraz: nextProps.selectParam.raz.kod,
                tobj: nextProps.selectParam.tobj.kod,
                obl: nextProps.selectParam.obl,
                raion: nextProps.selectParam.raion,
                org: nextProps.selectParam.org,
                form: nextProps.selectParam.form,
                table: nextProps.selectParam.table,
                isPostr: nextProps.selectParam.isPostr
            });
        } /*else if (this.props.userInfo.user.accessRaz.length > 0 || this.props.userInfo.user.accessForm.length > 0) {
            if (this.props.userInfo.user.accessRaz.length > 0 && this.props.userInfo.user.accessForm.length === 0)
                this.setState({ kraz: this.props.userInfo.user.accessRaz[0] });
            else if (this.props.userInfo.user.accessRaz.length === 0 && this.props.userInfo.user.accessForm.length > 0)
                ;// ttodo set form table 
            else if (this.props.userInfo.user.accessRaz.length > 0 && this.props.userInfo.user.accessForm.length > 0) {
                let kraz = this.props.userInfo.user.accessRaz[0];
                let kform = this.props.userInfo.user.accessForm[0];
                this.setState({ kraz });
            }
        }*/
    }
    onOpenTable = (e) => {
        e.preventDefault();
        let ntobj = {};
        switch (this.state.tobj) {
            case 1:
                ntobj = { nameRu: "РК", nameKz: "РК" };
                break;
            case 2:
                ntobj = { nameRu: "Область", nameKz: "Облысы" };
                break;
            case 3:
                ntobj = { nameRu: "Район", nameKz: "Ауданы" };
                break;
            case 4:
                ntobj = { nameRu: "Организация", nameKz: "Ұйымдастыру" };
                break;
            default:
        }
        this.props.handleCommit({
            year: this.state.year,
            period: this.props.nsi.periods.filter(p => p.kper === this.state.kper)[0],
            raz: this.props.nsi.sprraz.filter(p => p.kod === this.state.kraz)[0],
            tobj: { kod: this.state.tobj, ...ntobj },
            obl: this.state.obl,
            raion: this.state.raion,
            org: this.state.org,
            form: this.state.form,
            table: this.state.table,
            isPostr: this.state.isPostr
        });
        this.props.handleHide();
    }
    onSelectYear = (e) => {
        let year = parseInt(e.target.value, 10);
        this.setState({ year });
    }
    onSelectKper = (e) => {
        let kper = parseInt(e.target.value, 10);
        let kraz = getFirstRaz(kper, this.props);
        let { form, table } = getFirstFormTable(kper, this.props);
        if (kraz == null) {
            if (form == null) {
                this.setState({ kper });
            } else {
                this.setState({ kper, form, table });
            }
        } else {
            if (form == null) {
                this.setState({ kper, kraz });
            } else {
                this.setState({ kper, kraz, form, table });
            }
        }
    }
    onSelectKraz = (e) => {
        let kraz = parseInt(e.target.value, 10);
        this.setState({ kraz });
    }
    onChangeIsPostr = (e) => {
        let isPostr = e.target.value === 'true';
        this.setState({ isPostr });
    }
    /*
    filterRaz = (accessRaz, kod) => {
        if (accessRaz.length > 0) {
            if (this.state.kraz === 4) {
                return accessRaz.filter(f => f.isGod===true && f.raz === kod).length > 0;
            } else {
                return accessRaz.filter(f => f.isMes === true && f.raz === kod).length > 0;
            }
        }
        return true;
    }
    */
    render() {
        return (
            <Modal show={this.props.showModal} onHide={this.props.handleHide} bsSize="large" backdrop="static" dialogClassName="select-table-for-edit-dialog">
                <Modal.Header closeButton>
                    <Modal.Title><Translate value="openformtab" /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.onOpenTable}>
                        <Row>
                            <Col xs={12} sm={12}>
                                <FormGroup controlId="formYear" className='input-group-sm'>
                                    <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='year' />{':'}</ControlLabel></Col>
                                    <Col xs={12} sm={10}>
                                        <FormControl className='input-sm' componentClass="select" placeholder="select"
                                            defaultValue={this.state.year} onChange={this.onSelectYear}>
                                            {years.map(it => <option key={it} value={it}>{it}</option>)}
                                        </FormControl></Col>
                                </FormGroup></Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12}>
                                <FormGroup controlId="formPer" className='input-group-sm'>
                                    <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='period' />{':'}</ControlLabel></Col>
                                    <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={`${this.state.kper}`}
                                        onChange={this.onSelectKper} style={{ minWidth: '100%' }}>
                                        {this.props.nsi.periods.map(it => <option key={it.kper} value={it.kper}>{getNameLocalize(this.props.i18n, it)}</option>)}
                                    </FormControl></Col>
                                </FormGroup></Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12}>
                                <FormGroup controlId="formRaz" className='input-group-sm'>
                                    <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='razres' />{':'}</ControlLabel></Col>
                                    <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" value={this.state.kraz}
                                        onChange={this.onSelectKraz} style={{ minWidth: '100%' }}>
                                        {getRazItems(this.props, this.state.kper)}
                                    </FormControl></Col>
                                </FormGroup></Col>
                        </Row>

                        <SelectFormTable setForm={(form) => this.setState({ form })}
                            setTable={(table) => this.setState({ table })}
                            kper={this.state.kper}
                            form={this.state.form} table={this.state.table}
                            krazd={this.state.krazd}
                            setKrazd={(krazd) => this.setState({ krazd })}
                        />

                        <SelectObject
                            tobj={this.state.tobj}
                            obl={this.state.obl}
                            raion={this.state.raion}
                            org={this.state.org}
                            setTobj={(tobj) => this.setState({ tobj })}
                            setObl={(obl) => this.setState({ obl })}
                            setRaion={(raion) => this.setState({ raion })}
                            setOrg={(org) => this.setState({ org })}
                            year={this.state.year}
                        />
                        <Row>
                            <Col xs={12} sm={12} smOffset={2}>
                                <FormGroup controlId="formIsPostr" className='input-group-sm' style={{ marginLeft: '10px' }}>
                                    <Radio type='boolean' value={false} onChange={this.onChangeIsPostr} checked={this.state.isPostr === false} inline><Translate value='table' /></Radio>{' '}
                                    <Radio type='boolean' value={true} onChange={this.onChangeIsPostr} checked={this.state.isPostr === true} inline><Translate value='pstr' /></Radio>{' '}
                                </FormGroup></Col>
                        </Row>
                        <ButtonGroup>
                            <Button className='btn btn-primary text-center' type="submit"><Translate value="select" /></Button>
                            <Button className='btn btn-default text-center' onClick={this.props.handleHide} type="button"><Translate value="cancel" /></Button>
                        </ButtonGroup>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}
export default connect(
    state => state,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(SelectTableForEdit);
