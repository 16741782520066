import React, { useEffect, Fragment, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { Alert, Row, Table, Button, ButtonGroup, Col, Modal, FormGroup, FormControl, ListGroup, ListGroupItem, ControlLabel, Form, Radio, Checkbox, Panel } from 'react-bootstrap';
//import { useTable, useGlobalFilter } from 'react-table'
//import ReactTable from 'react-table';
import { Translate, I18n } from 'react-redux-i18n';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';
import { actionCreators as actionCreatorsWork } from '../../store/Work';

import { Typeahead } from 'react-bootstrap-typeahead';
import { getNameLocalize } from '../Constants';



const AccessRazdOrg = (props) => {
    useEffect(() => {
        props.getNs('orgs', [{ name: "obl", value: props.userInfo.user.kodObl }]);
    }, [props.userInfo.user.kodObl])
    const refTypeahead = React.createRef();
    const [yearsCopy, setYearsCopy] = useState();
    const [selYear, setSelYear] = useState();
    useState(() => {
        if (props.nsi.sprraz.length === 0) props.getNs('sprraz');
        if (props.nsi.sprform.length === 0) props.getNs('sprform');
        let year = new Date().getFullYear();
        setYearsCopy([year - 1, year]);
        setSelYear(year - 1);
    });
    const onSelectYear = (e) => {
        setSelYear(parseInt(e.target.value, 10));
    }
    const btCopyFromYear = (evt) => {
        evt.preventDefault();
        const getL = async () => {
            setLoadingText("editOrgs.loading");
            setIsLoading(true);
            let resp = await props.getAccesRazdFromYear(orgParam.korg, selYear);
            if (resp.successed == true) {
                if (resp.isAll === true || (resp.listRaz.length === 0 && resp.listForm.length === 0)) { setIsLoading(false); return; }
                let op = cloneDeep(orgParam);
                if (resp.listRaz.length > 0) {
                    //разрезы
                    for (var i in resp.listRaz) {
                        let r = resp.listRaz[i];
                        if (op.listRaz.filter(f => f.raz === r.raz).length > 0) continue;
                            op.listRaz.push(r);
                    }
                }
                if (resp.listForm.length > 0) {
                    //формы
                    for (var j in resp.listForm) {
                        let fo = resp.listForm[j];
                        if (op.listForm.filter(f => f.kf === fo.kf).length > 0) continue;
                        op.listForm.push(fo);
                    }
                }
                setOrgParam(op);
            } else {
                alert(resp.message);
            }
            setIsLoading(false);
        }
        getL();
    }
    const [selectOrg, setSelectOrg] = useState();
    const [orgParam, setOrgParam] = useState();
    const [orgParamOriginal, setOrgParamOriginal] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState("editOrgs.loading");
    const [isDataSaved, setIsDataSaved] = useState(false);
    const [mode, setMode] = useState("show");
    const onChangeOrg = (selected) => {
        setIsDataSaved(false);
        if (selected.length > 0) {
            let korg = selected[0].kobj;
            setSelectOrg({ korg, nameRu: selected[0].nameRu });
            loadOrgParam(korg);
        } else {
            setSelectOrg(null);
            setOrgParam(null);
        }
    }
    const btClearClick = (evt) => {
        evt.preventDefault();
        refTypeahead.current.clear();
        setIsDataSaved(false);
        setSelectOrg(null);
        setOrgParam(null);
    }
    const loadOrgParam = async (korg) => {
        setOrgParam(null);
        setIsLoading(true);
        let resp = await props.loadAccesRazdOrgParam(korg);
        if (resp.successed == true) {
            setOrgParam({ korg, isAll: resp.isAll, listRaz: resp.listRaz, listForm: resp.listForm });
            setIsDataSaved(false);
        } else {
            alert(resp.message);
        }
        setIsLoading(false);
    }
    const btSaveClick = (evt) => {
        evt.preventDefault();
        setMode("show");
        const saveAcRazdOrg = async () => {
            setLoadingText("saving");
            setIsLoading(true);
            let resp = await props.saveAccesRazdOrgParam(orgParam);
            setIsLoading(false);
            if (resp.successed == true) {
                setOrgParamOriginal(null);
                setIsDataSaved(true);
            } else {
                alert(resp.message);
            }
        }
        saveAcRazdOrg();
    }
    const changeFieldParamOrg = (fldName, value) => {
        let updateValues = {};
        updateValues[fldName] = value;
        let p = cloneDeep(orgParam);
        let ar = { ...p, ...updateValues };
        setOrgParam(ar);
    }
    const changeIsAllParamOrg = (nm, target) => {
        if (nm === "all") {
            //let op = cloneDeep(orgParam);
            //op.listRaz = [];
            //op.listForm = [];
            //setOrgParam(op);
            //setSelRazrs([]);
            //setSelForms([]);
            if (target.checked === true) {
                changeFieldParamOrg("isAll", true);
            } else {
                changeFieldParamOrg("isAll", false);
            }
        } else if (nm === "list") {
            if (target.checked === true) {
                changeFieldParamOrg("isAll", false);
            } else {
                changeFieldParamOrg("isAll", true);
            }
        }
    }
    const btEditClick = (evt) => {
        evt.preventDefault();
        setIsDataSaved(false);
        setOrgParamOriginal(cloneDeep(orgParam));
        setMode("edit");
    }
    const btCancelClick = (evt) => {
        evt.preventDefault();
        setOrgParam(cloneDeep(orgParamOriginal));
        setOrgParamOriginal(null);
        setMode("show");
    }

    const [showRazModal, setShowRazModal] = useState(false);
    const [selRazrs, setSelRazrs] = useState([]);
    const btEditRaz = (evt) => {
        evt.preventDefault();
        if (orgParam.listRaz != null && orgParam.listRaz.length > 0) {
            setSelRazrs(cloneDeep(orgParam.listRaz));
        } else
            setSelRazrs([]);
        setShowRazModal(true);
    }
    const btSubmitRazds = (evt) => {
        evt.preventDefault();
        let p = cloneDeep(orgParam);
        p.listRaz = cloneDeep(selRazrs);
        setOrgParam(p);
        setShowRazModal(false);
        setSelRazrs([]);
    }
    const checkRazrez = (evt) => {
        let kod = parseInt(evt.target.value);
        if (evt.target.checked === true) {
            let i1 = selRazrs.map(r => r.raz).indexOf(kod);
            if (i1 < 0) {
                let p0 = cloneDeep(selRazrs);
                p0.push({ raz: kod, isGod:true, isMes:true });
                setSelRazrs(p0);
            }
        } else {
            let i2 = selRazrs.map(r=>r.raz).indexOf(kod);
            if (i2 > -1) {
                let p1 = cloneDeep(selRazrs);
                p1.splice(i2, 1);
                setSelRazrs(p1);
            }
        }
    }
    const getNameRaz = (r) => {
        let razs = props.nsi.sprraz.filter(f => f.kod === r);
        if (razs != null && razs.length > 0)
            return getNameLocalize(props.i18n, razs[0]);
        else
            return "";
    }
    const delRaz = (evt, kod) => {
        evt.preventDefault();
        let op = cloneDeep(orgParam);
        let i2 = op.listRaz.map(r=>r.raz).indexOf(kod);
        if (i2 > -1) {
            op.listRaz.splice(i2, 1);
            setOrgParam(op);
        }
    }

    const [showFormModal, setShowFormModal] = useState(false);
    const [selForms, setSelForms] = useState([]);
    const btEditForm = (evt) => {
        evt.preventDefault();
        if (orgParam.listForm != null && orgParam.listForm.length > 0) {
            setSelForms(cloneDeep(orgParam.listForm));
        } else
            setSelForms([]);
        setShowFormModal(true);
    }
    const btSubmitForms = (evt) => {
        evt.preventDefault();
        let p = cloneDeep(orgParam);
        p.listForm = cloneDeep(selForms);
        setOrgParam(p);
        setShowFormModal(false);
        setSelForms([]);
    }
    const checkForm = (evt) => {
        let kod = evt.target.value;
        if (evt.target.checked === true) {
            let i1 = selForms.map(f => f.kf).indexOf(kod);
            if (i1 < 0) {
                let p0 = cloneDeep(selForms);
                p0.push({ kf: kod, isGod: true, isMes: true });
                setSelForms(p0);
            }
        } else {
            let i2 = selForms.map(f => f.kf).indexOf(kod);
            if (i2 > -1) {
                let p1 = cloneDeep(selForms);
                p1.splice(i2, 1);
                setSelForms(p1);
            }
        }
    }
    const getNameForm = (m) => {
        let fo = props.nsi.sprform.filter(f => f.kf === m);
        if (fo != null && fo.length > 0)
            return props.i18n.locale[0] === 'r' ? fo[0].nameRu : fo[0].nameKz;
        else
            return "";
    }
    const delForm = (evt, kod) => {
        evt.preventDefault();
        let op = cloneDeep(orgParam);
        let i2 = op.listForm.map(m=>m.kf).indexOf(kod);
        if (i2 > -1) {
            op.listForm.splice(i2, 1);
            setOrgParam(op);
        }
    }
    const changeIs = (evt, list, tp, m) => {
        let op = cloneDeep(orgParam);
        if (list === "raz") {
            let i2 = op.listRaz.map(m => m.raz).indexOf(m.raz);
            if (i2 === -1) return;
            if (tp == "god") {
                op.listRaz[i2].isGod = evt.target.checked;
            } else if (tp == "mes") {
                op.listRaz[i2].isMes = evt.target.checked;
            }
            setOrgParam(op);
        } else if (list === "form") {
            let i2 = op.listForm.map(m => m.kf).indexOf(m.kf);
            if (i2 === -1) return;
            if (tp == "god") {
                op.listForm[i2].isGod = evt.target.checked;
            } else if (tp == "mes") {
                op.listForm[i2].isMes = evt.target.checked;
            }
            setOrgParam(op);
        }
    }

    if (props.userInfo.user.accessLevel != 2 || props.userInfo.user.roles.filter(r => r === 'UserReadData').length > 0) {
        return <div className="text-danger"><Translate value="noAccess" /></div>;
    }
    return (<Fragment>
        <h4><Translate value="accessRazd.tit" /></h4>
        <Form horizontal>
            <FormGroup bsSize='small' controlId="formOrgs" >
                <Col componentClass={ControlLabel} sm={2} className="text-right"><Translate value='org' />{':'}</Col>
                <Col sm={8}>
                    <Typeahead id="formOrgs" disabled={props.nsi.isLoading === true || isLoading === true || mode === "edit"}
                        labelKey={props.i18n.locale[0] === 'r' ? "nameRu" : "nameKz"}
                        options={props.nsi.orgs}
                        placeholder={`${I18n.t("selectOrg")}...`}
                        ref={refTypeahead}
                        onChange={onChangeOrg} /><p>{selectOrg == null ? "" : selectOrg.nameRu}</p>
                </Col>
                <Col>
                    <Button type="button" onClick={btClearClick} disabled={props.nsi.isLoading === true || isLoading === true || mode === "edit"}><Translate value="editOrgs.clearFilter" /></Button>
                </Col>
            </FormGroup>
        </Form>
        {isLoading === true ? <Row>
            <Col sm={12}>
                <div className='text-center'><img src="../images/loading.gif" alt={I18n.t(loadingText)} width={20} />{' '}<strong><Translate value={loadingText} /></strong></div>
            </Col>
        </Row> : ''}
        {selectOrg != null && orgParam != null && isLoading != true ?
            <Panel bsStyle="primary" style={{ width: "100%" }}>
                <Panel.Heading>
                    <Panel.Title componentClass="h6">{selectOrg.korg} {selectOrg.nameRu}</Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                    <ButtonGroup bsSize="small" style={{ marginBottom: "2px" }}>
                        <Button type="button" bsStyle="primary" onClick={btEditClick} disabled={mode === "edit"}><Translate value="msgButtons.Change" /></Button>
                        <Button type="button" bsStyle="success" onClick={btSaveClick} disabled={mode === "show"}><Translate value="msgButtons.Save" /></Button>
                        <Button type="button" onClick={btCancelClick} disabled={mode === "show"}><Translate value="msgButtons.Cancel" /></Button>
                        {isDataSaved === true ? <strong className="text-primary" style={{ marginLeft: "5px" }}><Translate value="dataSaved" /></strong> : ""}
                    </ButtonGroup>
                    <Form horizontal>

                        <FormGroup>
                            <Col sm={6} componentClass={ControlLabel} className="text-right"><Translate value='accessRazd.titIsAccess' /></Col>
                            <Col sm={6}>
                                <Radio name="radioGroupAccessOrgRf" defaultChecked={orgParam.isAll === true} inline bsSize='small' disabled={mode === "show"}
                                    onChange={(evt) => changeIsAllParamOrg("all", evt.target)}><Translate value="accessRazd.allRf" /></Radio>
                                <Radio name="radioGroupAccessOrgRf" defaultChecked={orgParam.isAll === false} inline bsSize='small' disabled={mode === "show"}
                                    onChange={(evt) => changeIsAllParamOrg("list", evt.target)}><Translate value="accessRazd.listRf" /></Radio>
                            </Col>
                        </FormGroup>
                        {orgParam.isAll === false ? <>
                            <FormGroup>
                                <Col sm={8} componentClass={ControlLabel} className="text-right"><Translate value='accessRazd.createList' /></Col>
                                <Col sm={2}>
                                    <FormControl componentClass="select" disabled={mode === "show"}
                                        defaultValue={selYear} onChange={onSelectYear}>
                                        {yearsCopy.map(m => <option key={m} value={m}>{m}</option>)}
                                    </FormControl>
                                </Col>
                                <Col sm={2}>
                                    <Button type="button" onClick={btCopyFromYear} disabled={mode === "show"}><Translate value="accessRazd.btCopyFromYear" /></Button>
                                </Col>
                            </FormGroup>
                            <Row>
                                <Col sm={6}>
                                    <Button type="button" bsSize="sm" bsStyle="primary" onClick={btEditRaz} disabled={mode === "show"}><i className="bi bi-pencil"></i></Button>
                                    <Panel style={{ width: "100%" }} bsStyle="success">
                                        <Panel.Heading>
                                            <Panel.Title componentClass="h5" style={{ color: "black" }}><Translate value="accessRazd.razrs" /></Panel.Title>
                                        </Panel.Heading>
                                        <Panel.Body>
                                            {orgParam.listRaz != null && orgParam.listRaz.length > 0 ? <ListGroup>
                                                {orgParam.listRaz.map(m => <ListGroupItem key={m.raz}><Row bsSize="sm"><Col sm={6}><Button type="button" bsSize="sm" bsStyle="danger" onClick={(evt) => delRaz(evt, m.raz)} disabled={mode === "show"}><i className="bi bi-x"></i></Button><small>{'  '}{getNameRaz(m.raz)}</small></Col>
                                                    <Col sm={6} className="text-right"><Checkbox defaultChecked={m.isGod} onChange={(evt) => changeIs(evt, "raz", "god", m)} inline disabled={mode === "show"}><small><Translate value="accessRazd.isGod" /></small></Checkbox> <Checkbox defaultChecked={m.isMes} onChange={(evt) => changeIs(evt, "raz", "mes", m)} inline disabled={mode === "show"}><small><Translate value="accessRazd.isMes" /></small></Checkbox></Col></Row></ListGroupItem>)}
                                            </ListGroup> : ""}
                                        </Panel.Body>
                                    </Panel>
                                </Col>
                                <Col sm={6}>
                                    <Button type="button" bsSize="sm" bsStyle="primary" onClick={btEditForm} disabled={mode === "show"}><i className="bi bi-pencil"></i></Button>
                                    <Panel style={{ width: "100%" }} bsStyle="success">
                                        <Panel.Heading>
                                            <Panel.Title componentClass="h5" style={{ color: "black" }}><Translate value="accessRazd.forms" /></Panel.Title>
                                        </Panel.Heading>
                                        <Panel.Body>
                                            {orgParam.listForm != null && orgParam.listForm.length > 0 ? <ListGroup>
                                                {orgParam.listForm.map(m => <ListGroupItem key={m.kf}><Row bsSize="sm"><Col sm={6}><Button type="button" bsSize="sm" bsStyle="danger" onClick={(evt) => delForm(evt, m.kf)} disabled={mode === "show"}><i className="bi bi-x"></i></Button><small>{'  '}{m.kf} {getNameForm(m.kf)}</small></Col>
                                                    <Col sm={6} className="text-right"><Checkbox inline defaultChecked={m.isGod} onChange={(evt) => changeIs(evt, "form", "god", m)} disabled={mode === "show"}><small><Translate value="accessRazd.isGod" /></small></Checkbox> <Checkbox inline defaultChecked={m.isMes} onChange={(evt) => changeIs(evt, "form", "mes", m)} disabled={mode === "show"}><small><Translate value="accessRazd.isMes" /></small></Checkbox></Col></Row></ListGroupItem>)}
                                            </ListGroup> : ""}
                                        </Panel.Body>
                                    </Panel>
                                </Col>
                            </Row></> : ""}
                    </Form></Panel.Body>
            </Panel> : ""}
        <Modal show={showRazModal}
            onHide={() => setShowRazModal(false)} bsSize="lg" backdrop="static"
            dialogClassName="select-razdel">
            <Modal.Header closeButton>
                <Modal.Title><Translate value="accessRazd.selRazs" /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormGroup bsSize="small">
                    {props.nsi.sprraz.map(m => <Fragment key={m.kod}><Checkbox value={m.kod} defaultChecked={selRazrs.map(m=>m.raz).indexOf(m.kod) > -1} onChange={checkRazrez} style={{ margin: "2px", border: "1px solid lightgray" }} inline>{getNameLocalize(props.i18n, m)}</Checkbox>{'   '}</Fragment>)}
                </FormGroup>
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <Button className='btn btn-primary text-center' type="button" onClick={btSubmitRazds}><Translate value="select" /></Button>
                    <Button className='btn btn-default text-center' onClick={() => setShowRazModal(false)} type="button"><Translate value="cancel" /></Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
        <Modal show={showFormModal}
            onHide={() => setShowFormModal(false)} bsSize="lg" backdrop="static"
            dialogClassName="select-forms">
            <Modal.Header closeButton>
                <Modal.Title><Translate value="accessRazd.selForms" /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormGroup bsSize="small">
                    {props.nsi.sprform.map(m => <Fragment key={m.kf}><Checkbox value={m.kf} defaultChecked={selForms.map(f=>f.kf).indexOf(m.kf) > -1} onChange={checkForm} style={{ margin: "2px", border: "1px solid lightgray" }} inline>{m.kf} {getNameLocalize(props.i18n, m)}</Checkbox>{'   '}</Fragment>)}
                </FormGroup>
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <Button className='btn btn-primary text-center' type="button" onClick={btSubmitForms}><Translate value="select" /></Button>
                    <Button className='btn btn-default text-center' onClick={() => setShowFormModal(false)} type="button"><Translate value="cancel" /></Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    </Fragment>);
}

export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionCreatorsWork }, dispatch)
)(AccessRazdOrg);