import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Table, FormGroup, FormControl, ControlLabel, Row, Col} from 'react-bootstrap';
import { Translate/*, I18n*/ } from 'react-redux-i18n';
import NumberFormat from 'react-number-format';

import { actionCreators } from '../../store/Medinfo/FormulMedinfo';
import './editBokMkb.css';

class EditBokDr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pages: [],
            selectedTable: undefined,
            seletedPageId: undefined,
        };
        this.showListTables = this.showListTables.bind(this);
        this.onSelectTable = this.onSelectTable.bind(this);
        this.showListPages = this.showListPages.bind(this);
        this.onSelectPage = this.onSelectPage.bind(this);
        this.props.getTablesPagesOnlyBokDr().finally(() => {
            let selectedTable = this.props.tablesPages.tables[0];
            let seletedPageId = selectedTable.pages[0].pageId;
            this.setState({ selectedTable, seletedPageId});
            this.props.getBokDr(seletedPageId);
        });
    }
    onSelectTable = (e) => {
        let tableId = parseInt(e.target.value, 10);
        let selectedTable = this.props.tablesPages.tables.filter(f => f.tableId === tableId)[0];
        let seletedPageId = selectedTable.pages[0].pageId;
        this.setState({ selectedTable, seletedPageId });
        this.props.getBokDr(seletedPageId);
    }
    showListTables() {
        if (this.props.tablesPages == null) return '';
        return <FormGroup controlId="formTypePage" className='input-group-sm'>
            <Col xs={12} sm={4} lg={2} className="text-right"><ControlLabel>Таблица{':'}</ControlLabel></Col>
            <Col xs={12} sm={8} lg={10}>
                <FormControl className='input-sm' componentClass="select" placeholder="select" style={{ width: "auto" }}
                    defaultValue={this.state.selectedTable != null ? this.state.selectedTable.tableId : undefined} onChange={this.onSelectTable}>
                    {this.props.tablesPages.tables.map(l => <option key={l.tableId} value={l.tableId}>{l.kod} {l.nameRu}</option>)}
                </FormControl>
            </Col></FormGroup>;
    }
    showListPages() {
        if (this.state.selectedTable == null) return '';
        return <FormGroup controlId="formTypePage" className='input-group-sm'>
            <Col xs={12} sm={4} lg={2} className="text-right"><ControlLabel>Страница{':'}</ControlLabel></Col>
            <Col xs={12} sm={8} lg={10}>
                <FormControl className='input-sm' componentClass="select" placeholder="select" style={{ width: "auto" }}
                    defaultValue={this.state.selectedPageId} onChange={this.onSelectPage}>
                    {this.state.selectedTable.pages.map(l => <option key={l.pageId} value={l.pageId}>{l.kod} {l.nameRu}</option>)}
                </FormControl>
            </Col></FormGroup>;
    }
    onSelectPage = (e) => {
        let seletedPageId = e.target.value;
        this.setState({ seletedPageId });
        //load BokDr
        this.props.getBokDr(seletedPageId);
    }
    nppChange = (values, bokMkbId) => {
        this.props.updateFieldBokDr({ bokMkbId, field: 'Npp', value: values.value });
    }
    /*
    nameRuChange = (nameRu, bokMkbId) => {
        this.props.updateFieldBokDr({ bokMkbId, field: 'NameRu', value: nameRu });
    }
    nameKzChange = (nameKz, bokMkbId) => {
        this.props.updateFieldBokDr({ bokMkbId, field: 'NameKz', value: nameKz });
    }
    */
    nameChange = (name, bokMkbId, fld) => {
        this.props.updateFieldBokDr({ bokMkbId, field: fld, value: name });
    }
    showTable = () => {
        if (this.props.bokDr === undefined) {
            return undefined;
        }
        return <Fragment>
            {this.props.error !== undefined ? <div className='text-danger text-center'><strong>{this.props.error}</strong></div> : ''}
            <Table striped bordered condensed hover className="bok-mkb-table">
                <thead>
                    <tr>
                        <th>№ п/п</th>
                        <th><Translate value="nameRu" /></th>
                        <th><Translate value="shortNameRu" /></th>
                        <th><Translate value="nameKz" /></th>
                        <th><Translate value="shortNameKz" /></th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.bokDr.map(b =>
                        <tr key={b.bokMkbId}>
                            <td> <NumberFormat onValueChange={(values) => this.nppChange(values, b.bokMkbId)}
                                key={b.bokMkbId} ref={b.bokMkbId} id={b.bokMkbId} value={b.npp} />
                            </td>
                            <td><input value={b.nameRu} onChange={(e) => this.nameChange(e.target.value, b.bokMkbId, 'NameRu')} /></td>
                            <td><input value={b.nameShortRu} onChange={(e) => this.nameChange(e.target.value, b.bokMkbId, 'NameShortRu')} /></td>
                            <td><input value={b.nameKz} onChange={(e) => this.nameChange(e.target.value, b.bokMkbId, 'NameKz')} /></td>
                            <td><input value={b.nameShortKz} onChange={(e) => this.nameChange(e.target.value, b.bokMkbId, 'NameShortKz')} /></td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Fragment>;
    }
    render() {
        return <Fragment>
            <h4><Translate value="editBokDrMedinfo" /></h4>
            <Row>
                <Col md={12}>
                    {this.showListTables()}
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    {this.showListPages()}
                </Col>
            </Row>
            {this.showTable()}
        </Fragment>;
    }
}
export default connect(
    state => state.formulmedinfo,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(EditBokDr);