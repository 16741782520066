import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { /*Form,*/ Button, FormGroup, FormControl, ControlLabel, Row, Col, ListGroup, ListGroupItem, Modal } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import * as FileSaver from 'file-saver';
//import { cloneDeep } from 'lodash';

import { actionCreators } from '../../store/Medinfo/FormulMedinfo';
class EditMedinfoFormul extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type_page: 1,//1,2
            pages: [],
            selectedTable: undefined,
            selectedPage: undefined,
            selectedFormul: undefined,
            selectedFormulYear: undefined,
            isCopyYearFormul: true,
            showGetYearOfFormul: false,
            yearFormulInsert: undefined,
            errorInsertYear: undefined
        };
        /*
        this.handleSelectNsiSubmit = this.handleSelectNsiSubmit.bind(this);
        this.setEditMode = this.setEditMode.bind(this);
        this.saveDataTable = this.saveDataTable.bind(this);
        this.cancelData = this.cancelData.bind(this);
        this.changeTable = this.changeTable.bind(this);

        this.props. getTablesPages();
        */
        this.showListTables = this.showListTables.bind(this);
        this.onClickTable = this.onClickTable.bind(this);
        this.showListPages = this.showListPages.bind(this);
        this.onClickPage = this.onClickPage.bind(this);
        this.onClickFormul = this.onClickFormul.bind(this);
        this.handleChangeNpp = this.handleChangeNpp.bind(this);
        this.handleChangeNumberAfterPoint = this.handleChangeNumberAfterPoint.bind(this);
        this.handleChangeFormulNameRu = this.handleChangeFormulNameRu.bind(this);
        this.handleChangeFormulNameKz = this.handleChangeFormulNameKz.bind(this);
        this.onSelectFormulYear = this.onSelectFormulYear.bind(this);
        this.handleChangeFormulText = this.handleChangeFormulText.bind(this);
        this.showEditNameTable = this.showEditNameTable.bind(this);
        this.handleChangeTableNameRu = this.handleChangeTableNameRu.bind(this);
        this.handleChangeTableNameKz = this.handleChangeTableNameKz.bind(this);
        //this.showEditNamePage = this.showEditNamePage.bind(this);
        this.handleChangePageNameRu = this.handleChangePageNameRu.bind(this);
        this.handleChangePageNameKz = this.handleChangePageNameKz.bind(this);
        this.handleChangePageNameRazdRu = this.handleChangePageNameRazdRu.bind(this);
        this.handleChangePageNameRazdKz = this.handleChangePageNameRazdKz.bind(this);
        this.handleChangeTableInPageNameRu = this.handleChangeTableInPageNameRu.bind(this);
        this.handleChangeTableInPageNameKz = this.handleChangeTableInPageNameKz.bind(this);
        this.handleChangeSubHeaderRu = this.handleChangeSubHeaderRu.bind(this);
        this.handleChangeSubHeaderKz = this.handleChangeSubHeaderKz.bind(this);
        this.handleChangeSubHeader2Ru = this.handleChangeSubHeader2Ru.bind(this);
        this.handleChangeSubHeader2Kz = this.handleChangeSubHeader2Kz.bind(this);
        this.handleChangeSubHeader2RangeExcl = this.handleChangeSubHeader2RangeExcl.bind(this);
        this.handleChangeSubHeader2RangeExclOne = this.handleChangeSubHeader2RangeExclOne.bind(this);
        this.handleChangeSubHeader3Ru = this.handleChangeSubHeader3Ru.bind(this);
        this.handleChangeSubHeader3Kz = this.handleChangeSubHeader3Kz.bind(this);
        this.handleChangePageNote1 = this.handleChangePageNote1.bind(this);
        this.handleChangePageNote2 = this.handleChangePageNote2.bind(this);
        this.uploadExcel = this.uploadExcel.bind(this);
        this.downloadExcel = this.downloadExcel.bind(this);
        this.uploadExcelOneYear = this.uploadExcelOneYear.bind(this);
        this.downloadExcelOneYear = this.downloadExcelOneYear.bind(this);
        this.handleChangeStartRangePeriodsExcl = this.handleChangeStartRangePeriodsExcl.bind(this);
        this.handleChangeStartRangeDataExcl = this.handleChangeStartRangeDataExcl.bind(this);
        this.handleChangeRangesPeriodExcl = this.handleChangeRangesPeriodExcl.bind(this);
        this.showTemplatePageEdit = this.showTemplatePageEdit.bind(this);
        this.btCopyFormulYear = this.btCopyFormulYear.bind(this);
        this.btInsertFormulYear = this.btInsertFormulYear.bind(this);
        this.AddOrCopyFormulYear = this.AddOrCopyFormulYear.bind(this);

        this.props.getTablesPages(this.state.type_page);
    }
    btCopyFormulYear = (e) => {
        this.setState({
            isCopyYearFormul: true,
            showGetYearOfFormul: true,
            errorInsertYear: undefined,
            yearFormulInsert: undefined
        });
    }
    btInsertFormulYear = (e) => {
        this.setState({
            isCopyYearFormul: false,
            showGetYearOfFormul: true,
            errorInsertYear: undefined,
            yearFormulInsert: undefined
        });
    }
    hideDialogInsertFormulYear = (e) => {
        this.setState({
            showGetYearOfFormul: false,
            errorInsertYear: undefined,
            yearFormulInsert: undefined
        });
    }
    AddOrCopyFormulYear = async (e) => {
        if (this.state.yearFormulInsert == null || this.state.yearFormulInsert < 2000) {
            this.setState({ errorInsertYear: "Не правильный год" });
            return;
        }
        if (this.props.formulYears.years.filter(f => f.year === this.state.yearFormulInsert).length > 0) {
            this.setState({ errorInsertYear: "Формула за данный год существует" });
            return;
        }
        await this.props.copyOrInsertFormulYear({
            year: this.state.yearFormulInsert,
            formulIdSrc: this.state.selectedFormul.formulId,
            formulIdYearSrc: this.state.isCopyYearFormul === true ? this.state.selectedFormulYear.formulId : null
        })
        let selectedFormulYear = this.props.formulYears.years.filter(f => f.year === this.state.yearFormulInsert)[0];
        this.setState({
            showGetYearOfFormul: false,
            errorInsertYear: undefined,
            yearFormulInsert: undefined,
            selectedFormulYear
        });
    }
    onSelectTypePage = (e) => {
        let type_page = parseInt(e.target.value, 10);
        this.props.getTablesPages(type_page);
        this.setState({
            type_page, selectedTable: undefined, selectedPage: undefined, selectedFormul: undefined,
            selectedFormulYear: undefined
        });
    }
    onClickTable = (e) => {
        let tableId = parseInt(e.target.id, 10);
        let selectedTable = this.props.tablesPages.tables.filter(t => t.tableId === tableId)[0];
        this.setState({
            selectedTable, selectedPage: undefined, selectedFormul: undefined,
            selectedFormulYear: undefined
        });
    }
    showListTables() {
        if (this.props.tablesPages == null) return '';
        return <ListGroup style={{ maxHeight: 600, overflowY: "auto" }}>
            {this.props.tablesPages.tables.map(l => {
                if (this.state.selectedTable != null && this.state.selectedTable.tableId === l.tableId)
                    return <ListGroupItem key={l.tableId} id={l.tableId} active
                        onClick={this.onClickTable}>{l.kod}</ListGroupItem>;
                else
                    return <ListGroupItem key={l.tableId} id={l.tableId}
                        onClick={this.onClickTable}>{l.kod}</ListGroupItem>;
            })}
        </ListGroup>;
    }
    handleChangeTableNameRu(e) {
        let selectedTable = { ...this.state.selectedTable };
        selectedTable.nameRu = e.target.value;
        //todo update table
        this.setState({ selectedTable });
    }
    handleChangeTableNameKz(e) {
        let selectedTable = { ...this.state.selectedTable };
        selectedTable.nameKz = e.target.value;
        //todo update table
        this.setState({ selectedTable });
    }
    showEditNameTable() {
        if (this.state.selectedTable == null) return '';
        return <Fragment>
            <FormGroup controlId="formuln-table-name-ru" className='input-group-sm'>
                <Col sm={12} className="text-right"><ControlLabel>Наименование(рус){':'}</ControlLabel></Col>
                <Col sm={12}><FormControl
                    componentClass="textarea"
                    value={this.state.selectedTable.nameRu}
                    placeholder="Enter text"
                    onChange={this.handleChangeTableNameRu}
                /></Col>
            </FormGroup>
            <FormGroup controlId="formuln-table-name-kz" className='input-group-sm'>
                <Col sm={12} className="text-right"><ControlLabel>Наименование(каз){':'}</ControlLabel></Col>
                <Col sm={12}><FormControl
                    componentClass="textarea"
                    value={this.state.selectedTable.nameKz}
                    placeholder="Enter text"
                    onChange={this.handleChangeTableNameKz}
                /></Col>
            </FormGroup></Fragment>;
    }
    onClickPage = (e) => {
        let pageId = parseInt(e.target.id, 10);
        let selectedPage = this.state.selectedTable.pages.filter(t => t.pageId === pageId)[0];
        this.setState({
            selectedPage, selectedFormul: undefined,
            selectedFormulYear: undefined
        });
        this.props.getFormuls(pageId);
    }
    handleChangePageNameRu = async (e) => {
        let selectedPage = { ...this.state.selectedPage };
        selectedPage.nameRu = e.target.value;
        //update page
        await this.props.updatePageInfo(selectedPage);
        this.refreshSelectedPage(selectedPage.pageId);
    }
    handleChangePageNameKz = async (e) => {
        let selectedPage = { ...this.state.selectedPage };
        selectedPage.nameKz = e.target.value;
        //update page
        await this.props.updatePageInfo(selectedPage);
        this.refreshSelectedPage(selectedPage.pageId);
    }
    handleChangePageNameRazdRu = async (e) => {
        let selectedPage = { ...this.state.selectedPage };
        selectedPage.nameRazdRu = e.target.value;
        //update page
        await this.props.updatePageInfo(selectedPage);
        this.refreshSelectedPage(selectedPage.pageId);
    }
    handleChangePageNameRazdKz = async (e) => {
        let selectedPage = { ...this.state.selectedPage };
        selectedPage.nameRazdKz = e.target.value;
        //update page
        await this.props.updatePageInfo(selectedPage);
        this.refreshSelectedPage(selectedPage.pageId);
    }

    handleChangeTableInPageNameRu = async (e) => {
        let selectedPage = { ...this.state.selectedPage };
        selectedPage.nameTableRu = e.target.value;
        //update page
        await this.props.updatePageInfo(selectedPage);
        this.refreshSelectedPage(selectedPage.pageId);
    }
    handleChangeTableInPageNameKz = async (e) => {
        let selectedPage = { ...this.state.selectedPage };
        selectedPage.nameTableKz = e.target.value;
        //update page
        await this.props.updatePageInfo(selectedPage);
        this.refreshSelectedPage(selectedPage.pageId);
    }
    handleChangeSubHeaderRu = async (e) => {
        let selectedPage = { ...this.state.selectedPage };
        selectedPage.subHeaderRu = e.target.value;
        //update page
        await this.props.updatePageInfo(selectedPage);
        this.refreshSelectedPage(selectedPage.pageId);
    }
    handleChangeSubHeaderKz = async (e) => {
        let selectedPage = { ...this.state.selectedPage };
        selectedPage.subHeaderKz = e.target.value;
        //update page
        await this.props.updatePageInfo(selectedPage);
        this.refreshSelectedPage(selectedPage.pageId);
    }
    handleChangeSubHeader2Ru = async (e) => {
        let selectedPage = { ...this.state.selectedPage };
        selectedPage.subHeader2Ru = e.target.value;
        //update page
        await this.props.updatePageInfo(selectedPage);
        this.refreshSelectedPage(selectedPage.pageId);
    }
    handleChangeSubHeader2Kz = async (e) => {
        let selectedPage = { ...this.state.selectedPage };
        selectedPage.subHeader2Kz = e.target.value;
        //update page
        await this.props.updatePageInfo(selectedPage);
        this.refreshSelectedPage(selectedPage.pageId);
    }
    handleChangeSubHeader2RangeExcl = async (e) => {
        let selectedPage = { ...this.state.selectedPage };
        selectedPage.subHeader2RangeExcl = e.target.value;
        //update page
        await this.props.updatePageInfo(selectedPage);
        this.refreshSelectedPage(selectedPage.pageId);
    }
    handleChangeSubHeader2RangeExclOne = async (e) => {
        let selectedPage = { ...this.state.selectedPage };
        selectedPage.subHeader2RangeExclOne = e.target.value;
        //update page
        await this.props.updatePageInfo(selectedPage);
        this.refreshSelectedPage(selectedPage.pageId);
    } 
    handleChangeSubHeader3Ru = async (e) => {
        let selectedPage = { ...this.state.selectedPage };
        selectedPage.subHeader3Ru = e.target.value;
        //update page
        await this.props.updatePageInfo(selectedPage);
        this.refreshSelectedPage(selectedPage.pageId);
    }
    handleChangeSubHeader3Kz = async (e) => {
        let selectedPage = { ...this.state.selectedPage };
        selectedPage.subHeader3Kz = e.target.value;
        //update page
        await this.props.updatePageInfo(selectedPage);
        this.refreshSelectedPage(selectedPage.pageId);
    }
    handleChangeStartRangePeriodsExcl = async (e) => {
        let selectedPage = { ...this.state.selectedPage };
        selectedPage.startRangePeriodsExcl = e.target.value
        //update page
        await this.props.updatePageInfo(selectedPage);
        this.refreshSelectedPage(selectedPage.pageId);
    }
    handleChangeStartRangeDataExcl = async (e) => {
        let selectedPage = { ...this.state.selectedPage };
        selectedPage.startRangeDataExcl = e.target.value;
        //update page
        await this.props.updatePageInfo(selectedPage);
        this.refreshSelectedPage(selectedPage.pageId);
    }
    handleChangeRangesPeriodExcl = async (e) => {
        let selectedPage = { ...this.state.selectedPage };
        selectedPage.rangesPeriodExcl = e.target.value;
        //update page
        await this.props.updatePageInfo(selectedPage);
        this.refreshSelectedPage(selectedPage.pageId);
    }
    handleChangePageNote1 = async (e) => {
        let selectedPage = { ...this.state.selectedPage };
        selectedPage.note1 = e.target.value;
        //update page
        await this.props.updatePageInfo(selectedPage);
        this.refreshSelectedPage(selectedPage.pageId);
    }
    handleChangePageNote2 = async (e) => {
        let selectedPage = { ...this.state.selectedPage };
        selectedPage.note2 = e.target.value;
        //update page
        await this.props.updatePageInfo(selectedPage);
        this.refreshSelectedPage(selectedPage.pageId);
    }
    showListPages() {
        if (this.state.selectedTable == null) return '';
        return <ListGroup style={{ maxHeight: 600, overflowY: "auto" }}>
            {this.state.selectedTable.pages.map(p => {
                if (this.state.selectedPage != null && this.state.selectedPage.pageId === p.pageId)
                    return <ListGroupItem key={p.pageId} id={p.pageId} active
                        onClick={this.onClickPage}>{p.kod}</ListGroupItem>;
                else
                    return <ListGroupItem key={p.pageId} id={p.pageId}
                        onClick={this.onClickPage}>{p.kod}</ListGroupItem>;
            })}
        </ListGroup>;
    }
    getTablePageKod() {
        if (this.state.selectedTable == null || this.state.selectedPage == null) return '';
        return `${this.state.selectedTable.kod}${this.state.selectedPage.kod}`;
    }
    showListFormuls() {
        if (this.props.formuls == null) return '';
        return <ListGroup style={{ maxHeight: 200, overflowY: "auto" }}>
            {this.props.formuls.formuls.sort((a, b) => a.npp - b.npp).map(f => {
                if (this.state.selectedFormul != null && this.state.selectedFormul.formulId === f.formulId)
                    return <ListGroupItem key={f.formulId} id={f.formulId} onClick={this.onClickFormul} active>${f.npp}</ListGroupItem>;
                else
                    return <ListGroupItem key={f.formulId} id={f.formulId} onClick={this.onClickFormul}>${f.npp}</ListGroupItem>;
            })}
        </ListGroup>;
    }
    onClickFormul = async (e) => {
        let formulId = parseInt(e.target.id, 10);
        let selectedFormul = this.props.formuls.formuls.filter(t => t.formulId === formulId)[0];
        await this.props.getFormulYears(formulId);
        let selectedFormulYear = undefined;
        if (this.props.formulYears != null && this.props.formulYears.years.length > 0) {
            selectedFormulYear = this.props.formulYears.years[0];
        }
        this.setState({ selectedFormul, selectedFormulYear });

    }
    handleChangeNpp(e) {
        let selectedFormul = { ...this.state.selectedFormul };
        selectedFormul.npp = parseInt(e.target.value, 10);
        //todo update formul
        this.setState({ selectedFormul });
    }
    handleChangeNumberAfterPoint(e) {
        let selectedFormul = { ...this.state.selectedFormul };
        selectedFormul.numberAfterPoint = parseInt(e.target.value, 10);
        //update formul
        this.props.updateFormul(selectedFormul);
        this.setState({ selectedFormul });
    }
    handleChangeFormulNameRu(e) {
        let selectedFormul = { ...this.state.selectedFormul };
        selectedFormul.nameRu = e.target.value;
        //todo update formul
        this.props.updateFormul(selectedFormul);
        this.setState({ selectedFormul });
    }
    handleChangeFormulNameKz(e) {
        let selectedFormul = { ...this.state.selectedFormul };
        selectedFormul.nameKz = e.target.value;
        //update formul
        this.props.updateFormul(selectedFormul);
        this.setState({ selectedFormul });
    }
    /*
     * <FormControl
                    type="text"
                    value={this.state.selectedFormul.npp}
                    placeholder="Enter text"
                    onChange={this.handleChangeNpp}
                />
     */
    showFormulDetail() {
        if (this.state.selectedFormul == null) return '';
        return <Fragment>
            <FormGroup controlId="formulnpp" className='input-group-sm'>
                <Col xs={12} sm={10} className="text-right"><ControlLabel>Порядковый номер формулы(${this.state.selectedFormul.npp}){':'}</ControlLabel></Col>
                <Col xs={12} sm={2}>${this.state.selectedFormul.npp}</Col>
            </FormGroup>
            <FormGroup controlId="formuln-point" className='input-group-sm'>
                <Col xs={12} sm={10} className="text-right"><ControlLabel>Количество знаков после запятой{':'}</ControlLabel></Col>
                <Col xs={12} sm={2}><FormControl
                    type="text"
                    value={this.state.selectedFormul.numberAfterPoint}
                    placeholder="Enter text"
                    onChange={this.handleChangeNumberAfterPoint}
                /></Col>
            </FormGroup>
            <FormGroup controlId="formuln-name-ru" className='input-group-sm'>
                <Col sm={12} className="text-right"><ControlLabel>Наименование(рус){':'}</ControlLabel></Col>
                <Col sm={12}><FormControl
                    componentClass="textarea"
                    value={this.state.selectedFormul.nameRu}
                    placeholder="Enter text"
                    onChange={this.handleChangeFormulNameRu}
                /></Col>
            </FormGroup>
            <FormGroup controlId="formuln-name-kz" className='input-group-sm'>
                <Col sm={12} className="text-right"><ControlLabel>Наименование(каз){':'}</ControlLabel></Col>
                <Col sm={12}><FormControl
                    componentClass="textarea"
                    value={this.state.selectedFormul.nameKz}
                    placeholder="Enter text"
                    onChange={this.handleChangeFormulNameKz}
                /></Col>
            </FormGroup>
        </Fragment>;
    }
    onSelectFormulYear(e) {
        let yearFormulId = parseInt(e.target.value, 10);
        let selectedFormulYear = this.props.formulYears.years.filter(y => y.yearFormulId === yearFormulId)[0];
        this.setState({ selectedFormulYear });
    }
    handleChangeFormulText = async (e) => {
        let selectedFormulYear = { ...this.state.selectedFormulYear };
        selectedFormulYear.formulText = e.target.value;
        //update formul year
        await this.props.updateFormulYear(selectedFormulYear);
        this.setState({ selectedFormulYear });
    }
    showFormulYearDetail() {
        if (this.state.selectedFormulYear == null) return '';
        return <Row><Col sm={12}>
            <FormGroup controlId="formulYear" className='input-group-sm'>
                <Col xs={12} sm={2} className="text-right"><ControlLabel>Год{':'}</ControlLabel></Col>
                <Col xs={12} sm={10}><FormControl
                    type="text"
                    value={this.state.selectedFormulYear.year}
                    readOnly={true}
                /></Col>
            </FormGroup>
            <FormGroup controlId="formulText" className='input-group-sm'>
                <Col xs={12} sm={2} className="text-right"><ControlLabel>Формула{':'}</ControlLabel></Col>
                <Col xs={12} sm={10}><FormControl
                    type="text"
                    value={this.state.selectedFormulYear.formulText}
                    placeholder="Enter text"
                    onChange={this.handleChangeFormulText}
                /></Col>
            </FormGroup></Col>
        </Row>;
    }
    uploadExcel = async (e) => {
        e.preventDefault();
        let input = document.getElementById('fileTemplate');
        if (input.files.length === 0) {
            alert("Выберите файл");
            return;
        }
        let formData = new FormData();
        let pageId = this.state.selectedPage.pageId;
        formData.append("pageId", pageId);
        formData.append("fileExcel", input.files[0]);
        await this.props.UploadTemplate(formData, pageId);
        this.refreshSelectedPage(pageId);
    }
    uploadExcelOneYear = async (e) => {
        e.preventDefault();
        let input = document.getElementById('fileTemplateOneYear');
        if (input.files.length === 0) {
            alert("Выберите файл");
            return;
        }
        let formData = new FormData();
        let pageId = this.state.selectedPage.pageId;
        formData.append("pageId", pageId);
        formData.append("fileExcel", input.files[0]);
        await this.props.UploadTemplateOneYear(formData, pageId);
        this.refreshSelectedPage(pageId);
    }
    refreshSelectedPage(pageId) {
        let index = this.state.selectedTable.pages.map(e => e.pageId).indexOf(pageId);
        let table = this.props.tablesPages.tables.filter(t => t.tableId === this.state.selectedPage.tableId)[0];
        let page = table.pages.filter(p => p.pageId === pageId)[0];
        this.state.selectedTable.pages.splice(index, 1, page);
        this.setState({ selectedPage: page });
    }
    async downloadExcel(e) {
        e.preventDefault();
        const blob = await this.props.DownloadTemplate(this.state.selectedPage.pageId);
        FileSaver.saveAs(blob, this.state.selectedPage.fileTemplate);
    }
    async downloadExcelOneYear(e) {
        e.preventDefault();
        const blob = await this.props.DownloadTemplateOneYear(this.state.selectedPage.pageId);
        FileSaver.saveAs(blob, this.state.selectedPage.fileTemplateOneYear);
    }
    showTemplatePageEdit() {
        if (this.state.selectedPage == null) return '';
        return <Fragment>
            <Row><Col sm={6}>
                <FormGroup controlId="fileTemplate" className='input-group-sm'>
                    <Col xs={12} sm={5} className="text-right"><ControlLabel>Шаблон Excel(2-периода){':'}</ControlLabel></Col>
                    <Col xs={12} sm={7}>
                        <FormControl
                            type="file"
                            label="File" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            help="Выберите щаблон Excel" /><Button onClick={this.uploadExcel}>Загрузить шаблон на сервер</Button>
                        {this.state.selectedPage != null && this.state.selectedPage.fileTemplate != null ? <Button onClick={this.downloadExcel}>Скачать шаблон с сервера {this.state.selectedPage.fileTemplate}</Button> : ''}
                    </Col>
                </FormGroup></Col>
                <Col sm={6}>
                    <FormGroup controlId="fileTemplateOneYear" className='input-group-sm'>
                        <Col xs={12} sm={5} className="text-right"><ControlLabel>Шаблон Excel(1-период){':'}</ControlLabel></Col>
                        <Col xs={12} sm={7}>
                            <FormControl
                                type="file"
                                label="File" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                help="Выберите щаблон Excel" /><Button onClick={this.uploadExcelOneYear}>Загрузить шаблон на сервер</Button>
                            {this.state.selectedPage != null && this.state.selectedPage.fileTemplateOneYear != null ? <Button onClick={this.downloadExcelOneYear}>Скачать шаблон с сервера {this.state.selectedPage.fileTemplateOneYear}</Button> : ''}
                        </Col>
                    </FormGroup></Col>
            </Row>
            <Row><Col sm={12}>
                <FormGroup controlId="nameTableRu" className='input-group-sm'>
                    <Col xs={12} sm={2} className="text-right"><ControlLabel>Наименование таблицы(рус){':'}</ControlLabel></Col>
                    <Col xs={12} sm={10}><FormControl
                        type="text"
                        value={this.state.selectedPage.nameTableRu}
                        onChange={this.handleChangeTableInPageNameRu}
                    /></Col>
                </FormGroup></Col></Row>
            <Row><Col sm={12}>
                <FormGroup controlId="namePageKz" className='input-group-sm'>
                    <Col xs={12} sm={2} className="text-right"><ControlLabel>Наименование таблицы(каз){':'}</ControlLabel></Col>
                    <Col xs={12} sm={10}><FormControl
                        type="text"
                        value={this.state.selectedPage.nameTableKz}
                        onChange={this.handleChangeTableInPageNameKz}
                    /></Col>
                </FormGroup></Col>
            </Row>
            <Row><Col sm={12}>
                <FormGroup controlId="namePageRu" className='input-group-sm'>
                    <Col xs={12} sm={2} className="text-right"><ControlLabel>Наименование страницы(рус){':'}</ControlLabel></Col>
                    <Col xs={12} sm={10}><FormControl
                        type="text"
                        value={this.state.selectedPage.nameRu}
                        onChange={this.handleChangePageNameRu}
                    /></Col>
                </FormGroup></Col></Row>
            <Row><Col sm={12}>
                <FormGroup controlId="namePageKz" className='input-group-sm'>
                    <Col xs={12} sm={2} className="text-right"><ControlLabel>Наименование страницы(каз){':'}</ControlLabel></Col>
                    <Col xs={12} sm={10}><FormControl
                        type="text"
                        value={this.state.selectedPage.nameKz}
                        onChange={this.handleChangePageNameKz}
                    /></Col>
                </FormGroup></Col>
            </Row>
            <Row><Col sm={12}>
                <FormGroup controlId="namePageRazdRu" className='input-group-sm'>
                    <Col xs={12} sm={2} className="text-right"><ControlLabel>Наименование страницы в разделе "Мединфо"(рус){':'}</ControlLabel></Col>
                    <Col xs={12} sm={10}><FormControl
                        type="text"
                        value={this.state.selectedPage.nameRazdRu}
                        onChange={this.handleChangePageNameRazdRu}
                    /></Col>
                </FormGroup></Col></Row>
            <Row><Col sm={12}>
                <FormGroup controlId="namePageRazdKz" className='input-group-sm'>
                    <Col xs={12} sm={2} className="text-right"><ControlLabel>Наименование страницы в разделе "Мединфо"(каз){':'}</ControlLabel></Col>
                    <Col xs={12} sm={10}><FormControl
                        type="text"
                        value={this.state.selectedPage.nameRazdKz}
                        onChange={this.handleChangePageNameRazdKz}
                    /></Col>
                </FormGroup></Col>
            </Row>
            <Row><Col sm={12}>
                <FormGroup controlId="namePageRu" className='input-group-sm'>
                    <Col xs={12} sm={2} className="text-right"><ControlLabel>Подзаголовок 1(рус){':'}</ControlLabel></Col>
                    <Col xs={12} sm={10}><FormControl
                        type="text"
                        value={this.state.selectedPage.subHeaderRu}
                        onChange={this.handleChangeSubHeaderRu}
                    /></Col>
                </FormGroup></Col></Row>
            <Row><Col sm={12}>
                <FormGroup controlId="namePageKz" className='input-group-sm'>
                    <Col xs={12} sm={2} className="text-right"><ControlLabel>Подзаголовок 1(каз){':'}</ControlLabel></Col>
                    <Col xs={12} sm={10}><FormControl
                        type="text"
                        value={this.state.selectedPage.subHeaderKz}
                        onChange={this.handleChangeSubHeaderKz}
                    /></Col>
                </FormGroup></Col>
            </Row>
            <Row><Col sm={12}>
                <FormGroup controlId="namePageRu" className='input-group-sm'>
                    <Col xs={12} sm={2} className="text-right"><ControlLabel>Подзаголовок 2(рус){':'}</ControlLabel></Col>
                    <Col xs={12} sm={10}><FormControl
                        type="text"
                        value={this.state.selectedPage.subHeader2Ru}
                        onChange={this.handleChangeSubHeader2Ru}
                    /></Col>
                </FormGroup></Col></Row>
            <Row><Col sm={12}>
                <FormGroup controlId="namePageKz" className='input-group-sm'>
                    <Col xs={12} sm={2} className="text-right"><ControlLabel>Подзаголовок 2(каз){':'}</ControlLabel></Col>
                    <Col xs={12} sm={10}><FormControl
                        type="text"
                        value={this.state.selectedPage.subHeader2Kz}
                        onChange={this.handleChangeSubHeader2Kz}
                    /></Col>
                </FormGroup></Col>
            </Row>
            {this.state.selectedPage.typePage === 2 ? <Row><Col sm={6}>
                <FormGroup controlId="subHeader2RangeExcl" className='input-group-sm'>
                    <Col xs={12} sm={8} className="text-right"><ControlLabel>Ячейка подзаголовока 2(2-периода){':'}</ControlLabel></Col>
                    <Col xs={12} sm={4}><FormControl
                        type="text"
                        value={this.state.selectedPage.subHeader2RangeExcl}
                        onChange={this.handleChangeSubHeader2RangeExcl}
                    /></Col>
                </FormGroup></Col>
                <Col sm={6}>
                    <FormGroup controlId="subHeader2RangeExclOne" className='input-group-sm'>
                        <Col xs={12} sm={8} className="text-right"><ControlLabel>Ячейка подзаголовока 2(1-период){':'}</ControlLabel></Col>
                        <Col xs={12} sm={4}><FormControl
                            type="text"
                            value={this.state.selectedPage.subHeader2RangeExclOne}
                            onChange={this.handleChangeSubHeader2RangeExclOne}
                        /></Col>
                    </FormGroup></Col>
                </Row> : ''}
            <Row><Col sm={12}>
                <FormGroup controlId="namePageRu" className='input-group-sm'>
                    <Col xs={12} sm={2} className="text-right"><ControlLabel>Подзаголовок 3(рус){':'}</ControlLabel></Col>
                    <Col xs={12} sm={10}><FormControl
                        type="text"
                        value={this.state.selectedPage.subHeader3Ru}
                        onChange={this.handleChangeSubHeader3Ru}
                    /></Col>
                </FormGroup></Col></Row>
            <Row><Col sm={12}>
                <FormGroup controlId="namePageKz" className='input-group-sm'>
                    <Col xs={12} sm={2} className="text-right"><ControlLabel>Подзаголовок 3(каз){':'}</ControlLabel></Col>
                    <Col xs={12} sm={10}><FormControl
                        type="text"
                        value={this.state.selectedPage.subHeader3Kz}
                        onChange={this.handleChangeSubHeader3Kz}
                    /></Col>
                </FormGroup></Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <FormGroup controlId="StartRangePeriodsExcl" className='input-group-sm'>
                        <Col xs={12} sm={8} className="text-right"><ControlLabel>Стартовая ячейка периодов{':'}</ControlLabel></Col>
                        <Col xs={12} sm={4}><FormControl
                            type="text"
                            value={this.state.selectedPage.startRangePeriodsExcl}
                            onChange={this.handleChangeStartRangePeriodsExcl}
                        /></Col>
                    </FormGroup></Col>
                <Col sm={4}>
                    <FormGroup controlId="StartRangeDataExcl" className='input-group-sm'>
                        <Col xs={12} sm={8} className="text-right"><ControlLabel>Стартовая ячейка данных{':'}</ControlLabel></Col>
                        <Col xs={12} sm={4}><FormControl
                            type="text"
                            value={this.state.selectedPage.startRangeDataExcl}
                            onChange={this.handleChangeStartRangeDataExcl}
                        /></Col>
                    </FormGroup>
                </Col>
                <Col sm={4}>
                    <FormGroup controlId="StartRangeDataExcl" className='input-group-sm'>
                        <Col xs={12} sm={8} className="text-right"><ControlLabel>Ячейки для замены &#123;период&#125;, когда 1-период{':'}</ControlLabel></Col>
                        <Col xs={12} sm={4}><FormControl
                            type="text"
                            value={this.state.selectedPage.rangesPeriodExcl}
                            onChange={this.handleChangeRangesPeriodExcl}
                        /></Col>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                    <Col>
                        <FormGroup controlId="namePageKz" className='input-group-sm'>
                            <Col sm={12} className="text-left"><ControlLabel>Примечание1{':'}</ControlLabel></Col>
                            <Col sm={12}><FormControl
                                type="text"
                                value={this.state.selectedPage.note1}
                                onChange={this.handleChangePageNote1}
                            /></Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup controlId="namePageKz" className='input-group-sm'>
                            <Col sm={12} className="text-left"><ControlLabel>Примечание2{':'}</ControlLabel></Col>
                            <Col sm={12}><FormControl
                                type="text"
                                value={this.state.selectedPage.note2}
                                onChange={this.handleChangePageNote2}
                            /></Col>
                        </FormGroup>
                    </Col>
            </Row>
        </Fragment>;
    }
    render() {
        return <Fragment>
            <h4><Translate value="editFormulMedinfo" /></h4>
            {this.props.isLoading === true ? <Row>
                <Col smOffset={2} sm={10}>
                    <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('wait')} width={20} />{' '}<strong><Translate value="wait" /></strong></div>
                </Col>
            </Row> : ''}
            <Row>
                <Col xs={12} sm={12}>
                    <FormGroup controlId="formTypePage" className='input-group-sm'>
                        <Col xs={12} sm={2} className="text-right"><ControlLabel>Тип таблицы{':'}</ControlLabel></Col>
                        <Col xs={12} sm={10}>
                            <FormControl className='input-sm' componentClass="select" placeholder="select" style={{ width: "auto" }}
                                defaultValue={this.state.type_page} onChange={this.onSelectTypePage}>
                                <option value={1}>{1}</option>
                                <option value={2}>{2}</option>
                            </FormControl></Col>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <Row>
                        <Col md={6}><strong>Таблица</strong></Col>
                        <Col md={6}><strong>Страница</strong></Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            {this.showListTables()}
                        </Col>
                        <Col md={6}>
                            {this.showListPages()}
                        </Col>
                    </Row>
                </Col>
                {this.state.selectedPage != null ? <Col md={8}>
                    <Row><Col>{this.showTemplatePageEdit()}</Col></Row>
                    <Row><Col><strong>Формулы {this.getTablePageKod()}</strong></Col></Row>
                    <Row><Col md={6}>{this.showListFormuls()}</Col><Col md={6}>{this.showFormulDetail()}</Col></Row>
                    {this.props.formulYears != null ? <Fragment>
                        <Row>
                            <Col xs={12} sm={3} style={{ textAlign: "right" }}>
                                <FormGroup controlId="formYear" className='input-group-sm'>
                                    <Col xs={12} sm={2} className="text-right"><ControlLabel>Год{':'}</ControlLabel></Col>
                                    <Col xs={12} sm={10}>
                                        <FormControl className='input-sm' componentClass="select" placeholder="select" style={{ width: "auto" }}
                                            defaultValue={this.state.selectedFormulYear != null ? this.state.selectedFormulYear.yearFormulId : 0} onChange={this.onSelectFormulYear}>
                                            {this.props.formulYears.years.map(y => <option value={y.yearFormulId} key={y.yearFormulId}>{y.year}</option>)}
                                        </FormControl></Col>
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={9} style={{ textAlign: "left" }}>
                                <Button type="button" onClick={this.btCopyFormulYear}>Копировать</Button><Button type="button" onClick={this.btInsertFormulYear}>Вставить</Button>
                            </Col>
                        </Row>
                        {this.showFormulYearDetail()}
                    </Fragment> : ''}
                </Col>
                    : ''}
            </Row>
            <Modal show={this.state.showGetYearOfFormul === true} onHide={() => this.setState({ showGetYearOfFormul: false })}>
                <Modal.Header>
                    <Modal.Title>Modal title</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <FormGroup controlId="StartRangePeriodsExcl" className='input-group-sm'>
                        <Col xs={12} sm={6} className="text-right"><ControlLabel>Год формулы{':'}</ControlLabel></Col>
                        <Col xs={12} sm={6}><FormControl style={{ width: "auto" }}
                            type="text"
                            value={this.state.yearFormulInsert}
                            onChange={(e) => this.setState({ yearFormulInsert: parseInt(e.target.value, 10) })}
                        /></Col>
                    </FormGroup>
                    <p style={{ color: "red" }}>{this.state.errorInsertYear}</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => this.setState({ showGetYearOfFormul: false })}>Закрыть</Button>
                    <Button onClick={this.AddOrCopyFormulYear} bsStyle="primary">{this.state.isCopyYearFormul ? "Копировать формулу" : "Добавить формулу"}</Button>
                </Modal.Footer>
            </Modal>

        </Fragment>;
    }
}
export default connect(
    state => state.formulmedinfo,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(EditMedinfoFormul);
// {this.showEditNameTable()}
//{this.showEditNamePage()}