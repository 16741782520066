import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Button, Col, FormGroup, FormControl, ControlLabel, Form, Row, Alert } from 'react-bootstrap';

import { actionCreators as actionCreatorsSvod } from '../../store/Svod';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';
import SelectMultiObjects from '../common/SelectMultiObjects';
import SelectMultiForms from '../common/SelectMultiForms';

import { years, getCurrentYear } from '../Constants';
import FilterRazOptions from '../common/FilterRazOptions';

class SvodForm extends Component {
    constructor(props) {
        super(props);
        let nowYear = getCurrentYear();
        this.state = {
            year: props.year !== undefined ? props.year : nowYear,
            kper: 4,//период
            kraz: 1,//this.props.userInfo.user.accessLevel !== 4 ? 1 : 3, //разрез
            tobj: props.userInfo.user.accessLevel, // тип обьекта 1 – РК; 2 – область; 3 - район; 4 - организация (доступ зависит от пользователя)
            obl: undefined,
            raion: undefined,
            kobls: [],
            kraions: [],
            kforms: [],
            isSvodComplete:false
        };
        if (props.nsi.sprraz.length === 0) props.getNs('sprraz');
        if (props.nsi.periods.length === 0) props.getNs('periods');
        this.onSvodForm = this.onSvodForm.bind(this)
    }
    onSvodForm = (e) => {
        e.preventDefault();
        this.setState({ isSvodComplete: false });
        let kobjs = null;
        switch (this.state.tobj) {
            case 1:
                kobjs = ["00"];
                break;
            case 2:
                if (this.props.userInfo.user.accessLevel === 2) {
                    kobjs = [];
                    kobjs.push(this.state.obl.kobl);
                } else {
                    kobjs = this.state.kobls;
                }
                break;
            case 3:
                if (this.props.userInfo.user.accessLevel === 3) {
                    kobjs = [];
                    kobjs.push(this.state.raion.kraion);
                } else {
                    kobjs = this.state.kraions;
                }
                break;
            default:
        }
        if (kobjs.length === 0) {
            alert(I18n.t("selectObjs"));
            return;
        }
        if (this.state.kforms.length === 0) {
            alert(I18n.t("selectForms"));
            return;
        }
        this.props.svodForm({
            year: this.state.year,
            kper: this.state.kper,
            kraz: this.state.kraz,
            tobj: this.state.tobj,
            kobjs,
            kforms: this.state.kforms,
        });
        this.setState({ isSvodComplete: true });
    }
    onSelectYear = (e) => {
        let year = parseInt(e.target.value, 10);
        this.setState({ year });
    }
    onSelectKper = (e) => {
        let kper = parseInt(e.target.value, 10);
        this.setState({ kper });
    }
    onSelectKraz = (e) => {
        let kraz = parseInt(e.target.value, 10);
        this.setState({ kraz });
    }
    
    render() {
        let isReadOnLy = this.props.userInfo.user.roles.filter(r => r === 'UserReadData').length > 0;
        if (isReadOnLy === true)
            return <Translate value="noAccess" />
        else
        return <Form onSubmit={this.onSvodForm} horizontal>
            <h5 style={{ textAlign: "center" }}><strong><Translate value="svod"/></strong></h5>
            <FormGroup controlId="formYear" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='year' />{':'}</Col>
                <Col sm={10}>
                    <FormControl className='input-sm' componentClass="select" placeholder="select"
                        defaultValue={this.state.year} onChange={this.onSelectYear} bsSize="small" style={{ width: "auto"}}>
                        {years.map(it => <option key={it} value={it}>{it}</option>)}
                    </FormControl></Col>
            </FormGroup>
            <FormGroup controlId="formPer" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='period' />{':'}</Col>
                <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={this.state.kper}
                    onChange={this.onSelectKper} bsSize='small' style={{ width: "auto" }}>
                    {this.props.nsi.periods.map(it => <option key={it.kper} value={it.kper}>{it.nameRu}</option>)}
                </FormControl></Col>
            </FormGroup>
            <FormGroup controlId="formRaz" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='razres' />{':'}</Col>
                <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={this.state.kraz}
                    onChange={this.onSelectKraz} bsSize='small' style={{ width: "auto" }}>
                    <FilterRazOptions />
                </FormControl></Col>
            </FormGroup>
            <SelectMultiObjects setTobj={(tobj) => this.setState({ tobj })}
                setObl={(obl) => this.setState({ obl })} setObls={(kobls) => this.setState({ kobls})}
                setRaion={(raion) => this.setState({ raion })} setRaions={(kraions) => this.setState({ kraions })} year={this.state.year}/>
            <SelectMultiForms setForms={(kforms) => this.setState({ kforms })} no47={false/*this.state.tobj===3*/} />
            <FormGroup>
                <Col smOffset={2} sm={10}>
                    <Button className='btn btn-primary text-center' type="submit" disabled={this.props.isLoading}><Translate value="execSvod" /></Button>
                </Col>
            </FormGroup>
            {this.props.svod.isLoading === true ? <Row>
                <Col smOffset={2} sm={10}>
                    <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('waitsvod')} width={20} />{' '}<strong><Translate value="waitsvod" /></strong></div>
                </Col>
            </Row> : ''}
            {this.state.isSvodComplete === true && this.props.svod.statusSvod != null && this.props.svod.statusSvod.successed===true ? <Row>
                <Col smOffset={2} sm={10}>
                    <Alert bsStyle="success"><Translate value="svodComplete"/></Alert>
                </Col>
            </Row> : ''}
            {this.state.isSvodComplete === true && this.props.svod.statusSvod != null && this.props.svod.statusSvod.error != null?<Row>
                <Col smOffset={2} sm={10}>
                    <Alert bsStyle="danger">{this.props.svod.statusSvod.error}</Alert>
                </Col>
            </Row>:''}
        </Form>;
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionCreatorsSvod }, dispatch)
)(SvodForm);