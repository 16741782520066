import React from 'react';
import { Button, Modal, Alert  } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { btnsMsgBox } from '../Constants';


export default function MessageBox(props) {
    const handleClose = (tpBtn) => {
        props.close(tpBtn);
    }
    return <Modal
        size={props.size != null ? props.size : "lg"}
        show={props.show}
        onHide={() => handleClose(btnsMsgBox.Cancel)}
        backdrop="static"
        keyboard={false}
        aria-labelledby={`add-message-box-sizes-title-${props.size != null ? props.size : "lg"}`}
    >
        <Modal.Header closeButton>
            <Modal.Title id={`add-message-box-sizes-title-${props.size != null ? props.size : "lg"}`}>{props.titleMsg}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {props.warningHtml!=null?<Alert bsStyle="warning"><p dangerouslySetInnerHTML={{__html:props.warningHtml}}></p></Alert>:""}
            <p>{props.message}</p>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "flex-center" }}>
            {props.buttons & btnsMsgBox.OK ? <Button variant="outline-primary" onClick={() => props.close(btnsMsgBox.OK)} ><Translate value='msgButtons.OK' /></Button> : ''}
            {props.buttons & btnsMsgBox.Yes ? <Button variant="outline-primary" onClick={() => props.close(btnsMsgBox.Yes)} ><Translate value='msgButtons.Yes' /></Button> : ''}
            {props.buttons & btnsMsgBox.No ? <Button variant="outline-secondary" onClick={() => props.close(btnsMsgBox.No)} ><Translate value='msgButtons.No' /></Button> : ''}
            {props.buttons & btnsMsgBox.Retry ? <Button variant="outline-primary" onClick={() => props.close(btnsMsgBox.Retry)} ><Translate value='msgButtons.Retry' /></Button> : ''}
            {props.buttons & btnsMsgBox.Cancel ? <Button variant="outline-secondary" onClick={() => props.close(btnsMsgBox.Cancel)} ><Translate value='msgButtons.Cancel' /></Button> : ''}
            {props.buttons & btnsMsgBox.Request ? <Button variant="outline-primary" onClick={() => props.close(btnsMsgBox.Request)} ><Translate value='msgButtons.Request' /></Button> : ''}
            {props.buttons & btnsMsgBox.Submit ? <Button variant="outline-primary" onClick={() => props.close(btnsMsgBox.Submit)} ><Translate value='msgButtons.Submit' /></Button> : ''}
        </Modal.Footer>
    </Modal>
}