import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import * as FileSaver from 'file-saver';
import { Translate, I18n } from 'react-redux-i18n';
import { Button, Col, FormGroup, FormControl, ControlLabel, Form, Row, Alert, ListGroup, ListGroupItem, Checkbox } from 'react-bootstrap';

import { actionCreators as actionCreatorsSvod } from '../../store/Svod';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';

import FilterRazOptions from '../common/FilterRazOptions';
import { years, getNameLocalize, getCurrentYear } from '../Constants';

import './SvodSpecificOrg.css';
/*
 Сделаем отдельный режим "Свод по конкретным организациям района":
1. Пользователь выбирает:  год, период, разрез, область, район, форму
2. Показывается список организаций района у которых введены данные по введенным параметрам
3. Пользователь выбирает из списка организаций нужные.
4. Пользователь Нажимает кнопку "Сформировать Excel файл свода выбранных организаций"
5. Формируется файл Excel
При этом данные свода не сохраняются в базе данных, просто формируется файл Excel свода формы
*/
const SvodSpecificOrg = (props) => {
    useState(() => {
        if (props.nsi.allobls.length === 0) props.getNs('allobls');
        if (props.nsi.sprraz.length === 0) props.getNs('sprraz');
        if (props.nsi.sprform.length === 0) props.getNs('sprform');
        if (props.nsi.periods.length === 0) props.getNs('periods');
        if (props.nsi.allraions.length === 0) props.getNs('allraions');
        //let year = new Date().getFullYear();
        //setYearsCopy([year - 1, year]);
        //setSelYear(year - 1);
    });
    const [year, setYear] = useState(() => getCurrentYear());
    const [kper, setKper] = useState(4);
    const [kraz, setKraz] = useState(1);
    const [kobl, setKobl] = useState(props.userInfo.user.kodObl);
    const [kraion, setKraion] = useState(props.userInfo.user.kodRaion.substring(0, 4));
    const [isAllRaions, setIsAllRaions] = useState(false);
    const [kf, setKf] = useState("01");
    const [orgs, setOrgs] = useState([]);
    const [selOrgs, setSelOrgs] = useState([]);
    const [isNotdata, setIsNotdata] = useState(false);
    const selKobl = (obl) => {
        setKobl(obl)
        let ra = props.nsi.allraions.filter(r => r.obl === obl);
        if (ra.length > 0) {
            setKraion(ra[0].idRaion);
        }
    }
    const filterObl = (f) => {
        if (year == null) return true;
        let ye = typeof year === 'string' ? parseInt(year, 10) : year;
        let dtBeg = f.dtBeg == null ? null : new Date(f.dtBeg);
        if (dtBeg != null && dtBeg.getFullYear() > ye) return false;
        let dtEnd = f.dtEnd == null ? null : new Date(f.dtEnd);
        if (dtEnd != null && ye >= dtEnd.getFullYear()) return false;
        return true;
    }
    const onGetListOrgs = (e) => {
        e.preventDefault();
        setOrgs([]);
        setSelOrgs([]);
        setIsNotdata(false);
        const loadList = async () => {
            let ret = await props.loadListOrgData({
                year, kper, kraz, kobl, kraion, kf, isAllRaions
            });
            if (ret.successed === true) {
                setOrgs(ret.orgs);
                if (ret.orgs.length === 0) setIsNotdata(true);
            } else
                alert(ret.error);
        }
        loadList();
    }
    const onClickOrg = (korg, check) => {
        let sel = cloneDeep(selOrgs);
        if (check === true) {
            if (sel.filter(f => f == korg).length === 0) {
                sel.push(korg);
                setSelOrgs(sel);
            }
        } else {
            let ind = sel.map(m => m).indexOf(korg);
            if (ind > -1) {
                sel.splice(ind, 1);
                setSelOrgs(sel);
            }
        }
    }
    const getListItem = (m) => {
        if (selOrgs.filter(f => f === m.kod).length > 0)
            return <ListGroupItem key={m.kod} bsStyle="success"><Checkbox value={m.kod} disabled={props.svod.isLoading} onClick={(evt) => onClickOrg(evt.target.value, evt.target.checked)}>{m.kod} {getNameLocalize(props.i18n, m)}</Checkbox></ListGroupItem>
        else
            return <ListGroupItem key={m.kod}><Checkbox value={m.kod} disabled={props.svod.isLoading} onClick={(evt) => onClickOrg(evt.target.value, evt.target.checked)}>{m.kod} {getNameLocalize(props.i18n, m)}</Checkbox></ListGroupItem>
    }
    const onGenSvodExcel = (e) => {
        e.preventDefault();
        const toExcel = async () => {
            props.setIsLoading(true);
            let absoluteUrl = `${document.location.protocol}//${document.location.host}/generate/Excel/SpecificOrgs`;
            let fileName = `s${isAllRaions?'ar':''}_${year}_${kper}_${kraz}_${kobl}_${kraion}_${kf}.xlsx`;
            let json = JSON.stringify({ year, kper, kraz, kobl, kraion, kf, orgs: selOrgs });
            const response = await fetch(absoluteUrl, {
                credentials: 'include',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${props.userInfo.user.token}`
                },
                body: json
            });
            if (response.ok) { // если HTTP-статус в диапазоне 200-299
                // получаем тело ответа (см. про этот метод ниже)
                const blob = await response.blob();
                FileSaver.saveAs(blob, fileName);
                props.setIsLoading(false);
            } else {
                alert("Ошибка HTTP: " + response.status);
                props.setIsLoading(false);
            }
        }
        toExcel();
    }
    const filterRaion = (rai, year) => {
        if (year == null) return true;
        let ye = typeof year === 'string' ? parseInt(year, 10) : year;
        let dtBeg = rai.dtBeg == null ? null : new Date(rai.dtBeg);
        if (dtBeg != null && dtBeg.getFullYear() > ye) return false;
        let dtEnd = rai.dtEnd == null ? null : new Date(rai.dtEnd);
        if (dtEnd != null && ye >= dtEnd.getFullYear()) return false;
        return true;
    }
    let isReadOnLy = props.userInfo.user.roles.filter(r => r === 'UserReadData').length > 0;
    if (isReadOnLy === true)
        return <Translate value="noAccess" />
    else
    return <><Form onSubmit={onGetListOrgs} horizontal>
        <h5 style={{ textAlign: "center" }}><strong><Translate value="svodSpecificOrg.tit" /></strong></h5>
        <FormGroup controlId="formYear" bsSize='small'>
            <Col sm={2} componentClass={ControlLabel}><Translate value='year' />{':'}</Col>
            <Col sm={10}>
                <FormControl className='input-sm' componentClass="select" placeholder="select" disabled={props.svod.isLoading}
                    defaultValue={year} onChange={(e) => setYear(parseInt(e.target.value, 10))} bsSize="small" style={{ width: "auto" }}>
                    {years.map(it => <option key={it} value={it}>{it}</option>)}
                </FormControl></Col>
        </FormGroup>
        <FormGroup controlId="formPer" bsSize='small'>
            <Col sm={2} componentClass={ControlLabel}><Translate value='period' />{':'}</Col>
            <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={`${kper}`} disabled={props.svod.isLoading}
                onChange={(e) => setKper(parseInt(e.target.value, 10))} bsSize='small' style={{ width: "auto" }}>
                {props.nsi.periods.map(it => <option key={it.kper} value={it.kper}>{it.nameRu}</option>)}
            </FormControl></Col>
        </FormGroup>
        <FormGroup controlId="formRaz" bsSize='small'>
            <Col sm={2} componentClass={ControlLabel}><Translate value='razres' />{':'}</Col>
            <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={kraz} disabled={props.svod.isLoading}
                onChange={(e) => setKraz(parseInt(e.target.value, 10))} bsSize='small' style={{ width: "auto" }}>
                <FilterRazOptions />
            </FormControl></Col>
        </FormGroup>
        <FormGroup controlId="formObl" bsSize='small'>
            <Col sm={2} componentClass={ControlLabel}><Translate value='obl' />{':'}</Col>
            <Col xs={12} sm={10}>
                {props.userInfo.user.accessLevel > 1 ? <div>{kobl} {props.i18n.locale[0] === 'r' ? props.userInfo.user.nameObl : props.userInfo.user.nameOblKz}</div> :
                    <FormControl className='input-sm' componentClass="select" placeholder="select" value={kobl} disabled={props.svod.isLoading}
                        onChange={(e) => selKobl(e.target.value)} style={{ width: "auto" }}>
                        {props.nsi.allobls.filter(f => filterObl(f)).map(it => <option key={it.kod} value={it.kod}>{getNameLocalize(props.i18n, it)}</option>)}
                    </FormControl>}
            </Col>
        </FormGroup>
        <FormGroup controlId="formRaion" bsSize='small'>
            <Col sm={2} className="text-right"><ControlLabel><Translate value='raion' />{':'}</ControlLabel></Col>
            <Col sm={8} bsSize='small'>
                {props.userInfo.user.accessLevel > 2 ? <span>{kraion} {props.i18n.locale[0] === 'r' ? props.userInfo.user.nameRaion : props.userInfo.user.nameRaionKz}</span> :<>
                    <FormControl className='input-sm' componentClass="select" placeholder="select" value={kraion} disabled={props.svod.isLoading || isAllRaions===true}
                        onChange={(e) => setKraion(e.target.value)} style={{ width: "auto" }} style={{ display: "inline-block", width: "auto", marginRight: "5px" }}>
                        {props.nsi.allraions.filter(r => r.obl === kobl && filterRaion(r, year)).map(it => <option key={it.kod} value={it.idRaion}>{getNameLocalize(props.i18n, it)}</option>)}
                    </FormControl>
                    <Checkbox onChange={(e) => setIsAllRaions(e.target.checked)} checked={isAllRaions} inline><Translate value="allRaions" /></Checkbox>
                </>}
            </Col>
        </FormGroup>
        <FormGroup controlId="formKf" bsSize='small'>
            <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='forma' />{':'}</ControlLabel></Col>
            <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={kf} disabled={props.svod.isLoading}
                onChange={(e) => setKf(e.target.value)} style={{ minWidth: '100%' }}>
                {props.nsi.sprform.map(it => <option key={it.kf} value={it.kf}>{it.kf} {getNameLocalize(props.i18n, it)}</option>)}
            </FormControl></Col>
        </FormGroup>
        <FormGroup>
            <Col smOffset={2} sm={10}>
                <Button className='btn btn-primary text-center' type="submit" disabled={props.svod.isLoading===true}><Translate value="svodSpecificOrg.btGetListOrgs" /></Button>
            </Col>
        </FormGroup>
    </Form>
        {props.svod.isLoading === true ? <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('wait')} width={20} />{' '}<strong><Translate value="generateExcel" /></strong></div> : ''}
        {isNotdata === true ? <Alert bsStyle="warning"><Translate value="noDataForm" /></Alert> : ""}
        {orgs.length > 0 ? <Form onSubmit={onGenSvodExcel} horizontal><ListGroup bsSize="sm" className="ulListSpecOrg">
            {orgs.map(m => getListItem(m))}
        </ListGroup>
            <FormGroup>
                <Col smOffset={2} sm={10}>
                    <Button className='btn btn-primary text-center' type="submit" disabled={props.svod.isLoading === true || selOrgs.length===0}><Translate value="svodSpecificOrg.btGenSvodExcel" /></Button>
                </Col>
            </FormGroup>
        </Form> : ""}
    </>
}
export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionCreatorsSvod }, dispatch)
)(SvodSpecificOrg);

//{this.state.tobj === 4 && this.state.accessLevel < 3 ? <Checkbox onChange={this.onCheckIsAllRaions} checked={this.state.isAllRaions} inline><Translate value="allRaions" /></Checkbox>
//disabled={this.state.isAllRaions === true}
/*
 {this.props.noFilter === true ? this.props.nsi.sprform.map(it => <option key={it.kf} value={it.kf}>{it.kf} {getNameLocalize(props.i18n, it)}</option>) :
                    props.nsi.sprform.filter(f => filterForms(props.userInfo.user.accessForm, f.kf)).map(it => <option key={it.kf} value={it.kf}>{it.kf} {getNameLocalize(props.i18n, it)}</option>)}
 */