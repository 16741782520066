import React, { useState, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import parse from 'html-react-parser';
import { actionCreators } from '../store/Nsi';
const VersionsHistory = (props) => {
    const [] = useState(() => {
        if (props.nsi.versions_history.length === 0) {
            props.getNs('versions_history');
        }
        return true;
    }, props.nsi.versions_history.length > 0);
    return <><h5 className="text-primary"><strong><Translate value="versions.tit" /></strong></h5>
        <Table striped bordered hover>
            <thead>
                <tr><th><Translate value="versions.versionNo" /></th><th><Translate value="versions.dtVersion" /></th><th><Translate value="versions.comment" /></th></tr>
            </thead>
            <tbody>
                {props.nsi.versions_history.map((d, i) => <tr key={i}>
                    <td>{d.versionNo}</td>
                    <td>{d.versionDt}</td>
                    <td>{parse(d.comment)}</td>
                </tr>)}
            </tbody>
        </Table>
    </>
}
export default connect(
    state => state,
    dispatch => bindActionCreators(actionCreators, dispatch)//,
)(VersionsHistory);