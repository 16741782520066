import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Button, Col, FormGroup, FormControl, ControlLabel, Form, Row, Alert, Checkbox } from 'react-bootstrap';

import { actionCreators as actionCreatorsSvod } from '../../store/Svod';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';
import SelectMultiForms from '../common/SelectMultiForms';
import { years, getNameLocalize, getCurrentYear } from '../Constants';

class SvodSubOrg extends Component {
    constructor(props) {
        super(props);
        let nowYear = getCurrentYear();
        this.state = {
            year: props.year !== undefined ? props.year : nowYear,
            kper: 4,//период
            kraz: 23, //разрез
            kforms: [],
            isNotParent: false,//исключить ЦРБ
            excludeNomenkl:[],
            isSvodComplete: false
        }
        if (props.nsi.periods.length === 0) props.getNs('periods');
        if (props.nsi.sprraz.length === 0) props.getNs('sprraz');
        if (props.nsi.nomenkl.length === 0) props.getNs('nomenkl');
        this.onSvodForm = this.onSvodForm.bind(this);
        this.textTargetRaz = this.textTargetRaz.bind(this);
        this.textIsNotParent = this.textIsNotParent.bind(this);
        this.onCheckIsNotParent = this.onCheckIsNotParent.bind(this);
        this.onCheckNomen = this.onCheckNomen.bind(this);
    };
    onSelectYear = (e) => {
        let year = parseInt(e.target.value, 10);
        this.setState({ year });
    }
    onSelectKper = (e) => {
        let kper = parseInt(e.target.value, 10);
        this.setState({ kper });
    }
    onSelectKraz = (e) => {
        let kraz = parseInt(e.target.value, 10);
        this.setState({ kraz });
    }
    textTargetRaz(){
        // getNameLocalize(this.props.i18n, it)
        let org = getNameLocalize(this.props.i18n, { nameRu: this.props.userInfo.user.nameOrg, nameKz: this.props.userInfo.user.nameOrgKz });
        let srcRaz = getNameLocalize(this.props.i18n, this.props.nsi.sprraz.filter(f => f.kod === this.state.kraz)[0]);
        let ti = this.state.kraz - 20;
        let targetRaz = getNameLocalize(this.props.i18n, this.props.nsi.sprraz.filter(f => f.kod === ti)[0]);
        return I18n.t("svodSubText", { srcRaz, targetRaz, org });//<Translate value="svodSubText" srcRaz={srcRaz} targetRaz={targetRaz} org={org} />
    }
    textIsNotParent() {
        // getNameLocalize(this.props.i18n, it)
        let org = getNameLocalize(this.props.i18n, { nameRu: this.props.userInfo.user.nameOrg, nameKz: this.props.userInfo.user.nameOrgKz });
        //let srcRaz = getNameLocalize(this.props.i18n, this.props.nsi.sprraz.filter(f => f.kod === this.state.kraz)[0]);
        //let ti = this.state.kraz - 20;
        //let targetRaz = getNameLocalize(this.props.i18n, this.props.nsi.sprraz.filter(f => f.kod === ti)[0]);
        return org;//<Translate value="svodSubText" srcRaz={srcRaz} targetRaz={targetRaz} org={org} />
    }
    onCheckIsNotParent= (e) => {
        let isNotParent = e.target.checked;
        this.setState({ isNotParent });
    }
    onCheckNomen = (e) => {
        let checked = e.target.checked;
        let nomen = e.target.value;
        let excludeNomenkl = [...this.state.excludeNomenkl];
        if (checked) {
            excludeNomenkl.push(nomen);
        } else {
            let ind = excludeNomenkl.indexOf(nomen);
            excludeNomenkl.splice(ind, 1);
        }
        this.setState({ excludeNomenkl });
    }
    onSvodForm(e) {
        e.preventDefault();
        this.setState({ isSvodComplete: false });
        if (this.state.kforms.length === 0) {
            alert("Выберте формы");
            return;
        }
        this.props.svodSubForm({
            year: this.state.year,
            kper: this.state.kper,
            kraz: this.state.kraz,
            kforms: this.state.kforms,
            isNotParent: this.state.isNotParent,
            excludeNomenkl: this.state.excludeNomenkl
        });
        this.setState({ isSvodComplete: true });
    }
    render() {
        let isReadOnLy = this.props.userInfo.user.roles.filter(r => r === 'UserReadData').length > 0;
        if (isReadOnLy === true)
            return <Translate value="noAccess" />
        else
        return <Form onSubmit={this.onSvodForm} horizontal>
            <h5 style={{ textAlign: "center" }}><strong><Translate value="svodSub" /></strong></h5>
            <FormGroup controlId="formYear" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='year' />{':'}</Col>
                <Col sm={10}>
                    <FormControl className='input-sm' componentClass="select" placeholder="select"
                        defaultValue={this.state.year} onChange={this.onSelectYear} bsSize="small" style={{ width: "auto" }}>
                        {years.filter(y=> y > 2020).map(it => <option key={it} value={it}>{it}</option>)}
                    </FormControl></Col>
            </FormGroup>
            <FormGroup controlId="formPer" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='period' />{':'}</Col>
                <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={this.state.kper}
                    onChange={this.onSelectKper} bsSize='small' style={{ width: "auto" }}>
                    {this.props.nsi.periods.map(it => <option key={it.kper} value={it.kper}>{it.nameRu}</option>)}
                </FormControl></Col>
            </FormGroup>
            <FormGroup controlId="formRaz" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='razres' />{':'}</Col>
                <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={this.state.kraz}
                    onChange={this.onSelectKraz} bsSize='small' style={{ width: "auto" }}>
                    {this.props.nsi.sprraz.filter(f => f.kod === 23 || f.kod === 24 || f.kod === 25).map(it => <option key={it.kod} value={it.kod}>{getNameLocalize(this.props.i18n, it)}</option>)}
                </FormControl>
                    <div className="text-primary text-left"><strong>{this.textTargetRaz()}</strong></div>
                </Col>
            </FormGroup>
            <FormGroup controlId="isNotParent" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='notinclude' />{':'}</Col>
                <Col sm={10}><Checkbox onChange={this.onCheckIsNotParent} checked={this.state.isNotParent}>{this.textIsNotParent()}</Checkbox>
                    <div><ControlLabel><Translate value="orgIsNomenkl" />:</ControlLabel></div>
                    {this.props.nsi.nomenkl.filter(f=>f.kod==='052' || f.kod==='053').map(it => <Checkbox onChange={this.onCheckNomen} key={it.kod} value={it.kod}>{it.kod} {getNameLocalize(this.props.i18n, it)}</Checkbox>)}
                </Col>
            </FormGroup>
            <SelectMultiForms setForms={(kforms) => this.setState({ kforms })} no47={false/*true*/} />
            {this.props.svod.isLoading === true ? <Row>
                <Col smOffset={2} sm={10}>
                    <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('waitsvod')} width={20} />{' '}<strong><Translate value="waitsvod" /></strong></div>
                </Col>
            </Row> : ''}
            {this.state.isSvodComplete === true && this.props.svod.statusSvod != null && this.props.svod.statusSvod.successed === true ? <Row>
                <Col smOffset={2} sm={10}>
                    <Alert bsStyle="success"><Translate value="svodComplete" /></Alert>
                </Col>
            </Row> : ''}
            {this.state.isSvodComplete === true && this.props.svod.statusSvod != null && this.props.svod.statusSvod.error != null ? <Row>
                <Col smOffset={2} sm={10}>
                    <Alert bsStyle="danger">{this.props.svod.statusSvod.error}</Alert>
                </Col>
            </Row> : ''}
            <FormGroup>
                <Col smOffset={2} sm={10}>
                    <Button className='btn btn-primary text-center' type="submit" disabled={this.props.isLoading}><Translate value="execSvod" /></Button>
                </Col>
            </FormGroup>
        </Form>
    }
}
export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionCreatorsSvod }, dispatch)
)(SvodSubOrg);