import { getToken } from './UserInfo';
const requestSvodFormAction = 'REQUEST_SVOD_FORM';
const receiveSvodFormAction = 'RECEIVE_SVOD_FORM';
const requestSvodFormRazAction = 'REQUEST_SVOD_FORM_RAZ';
const receiveSvodFormRazAction = 'RECEIVE_SVOD_FORM_RAZ';
const requestIsExistSvodFormRazAction = 'REQUEST_IS_EXIST_SVOD_FORM_RAZ';
const receiveIsExistSvodFormRazAction = 'RECEIVE_IS_EXIST_SVOD_FORM_RAZ';
const requestSvodSubFormAction = 'REQUEST_SVOD_SUB_FORM';
const receiveSvodSubFormAction = 'RECEIVE_SVOD_SUB_FORM';
const requestCopyData12MonthToYear = 'REQUEST_CopyData12MonthToYear';
const receiveCopyData12MonthToYear = 'RECEIVE_CopyData12MonthToYear';
const requestLoadListOrgData = 'REQUEST_LoadListOrgData';
const receiveLoadListOrgData = 'RECEIVE_LoadListOrgData';

const requestSetIsLoading = 'REQUEST_SetIsLoading';

const initialState = {
    isLoading: false,
    statusSvod: undefined,
}

export const actionCreators = {
    copyData12MonthToYear: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestCopyData12MonthToYear });
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/CopyData12MonthToYear', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveCopyData12MonthToYear, status: data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    svodForm: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestSvodFormAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/SvodForm', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveSvodFormAction, status: data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    svodSubForm: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestSvodSubFormAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/SvodSubForm', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveSvodSubFormAction, status: data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    svodSubOblForm: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/svodSubOblForm', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            return await response.json();
        } else {
            return { successed: false, error: `Ошибка HTTP: ${response.status}` };
        }
    },
    isExistSvodRaz: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestIsExistSvodFormRazAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/IsExistSvodFormRaz', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveIsExistSvodFormRazAction, status: data });
            return data;
        } else {
            alert("Ошибка HTTP: " + response.status);
            return false;
        }
    },
    isExistDataPeriod: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/IsExistDataPeriod', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            return data;
        } else {
            alert("Ошибка HTTP: " + response.status);
            return false;
        }
    },
    svodFormRaz: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestSvodFormRazAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/SvodFormRaz', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveSvodFormRazAction, status: data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    editFormPeriod: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/EditFormPeriod', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            return await response.json();
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    isExistSvodOrgRaz: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestIsExistSvodFormRazAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/IsExistSvodFormOrgRaz', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveIsExistSvodFormRazAction, status: data });
            return data;
        } else {
            alert("Ошибка HTTP: " + response.status);
            return false;
        }
    },
    svodFormOrgRaz: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestSvodFormRazAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/SvodFormOrgRaz', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveSvodFormRazAction, status: data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    svodFormOrgRaz2: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestSvodFormRazAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/SvodFormOrgRaz2', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveSvodFormRazAction, status: data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    svodFormOrgRaz3: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestSvodFormRazAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/SvodFormOrgRaz3', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveSvodFormRazAction, status: data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    loadListOrgData: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestLoadListOrgData });
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/ListOrgData', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveLoadListOrgData });
            return data;
        } else {
            dispatch({ type: receiveLoadListOrgData });
            //alert("Ошибка HTTP: " + response.status);
            return { successed: false, error: `Ошибка HTTP: ${response.status}`};
        }
    },
    loadHeadOrgs: () => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        const response = await fetch('api/DataApi/LoadHeadOrgs', {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            return {successed: true, data }
        } else {
            return { successed: false, error: `Ошибка HTTP: ${response.status}` };
        }
    },
    setIsLoading: (flag) => (dispatch) => {
        dispatch({ type: requestSetIsLoading, flag });
    },
    svodFormQuarts: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/SvodFormQuarts', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            return await response.json();
        } else {
            return { successed: false, error:`Ошибка HTTP: ${response.status}`}
        }
    },
    existsSvodFormMonths: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/ExistsSvodFormMonths', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            return await response.json();
        } else {
            return { successed: false, error: `Ошибка HTTP: ${response.status}` }
        }
    },
    existsSvodFormMonths2: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/ExistsSvodFormMonths2', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            return await response.json();
        } else {
            return { successed: false, error: `Ошибка HTTP: ${response.status}` }
        }
    }
}
export const reducer = (state, action) => {
    state = state || initialState;
    //copy data
    if (action.type === requestCopyData12MonthToYear) {
        return {
            ...state,
            isLoading: true,
            statusSvod: undefined,
        };
    }
    if (action.type === receiveCopyData12MonthToYear) {
        return {
            ...state,
            isLoading: false,
            statusSvod: action.status,
        };
    }
    //svod form
    if (action.type === requestSvodFormAction) {
        return {
            ...state,
            isLoading: true,
            statusSvod: undefined,
        };
    }
    if (action.type === receiveSvodFormAction) {
        return {
            ...state,
            isLoading: false,
            statusSvod: action.status,
        };
    }
    if (action.type === requestSvodSubFormAction) {
        return {
            ...state,
            isLoading: true,
            statusSvod: undefined,
        };
    }
    if (action.type === receiveSvodSubFormAction) {
        return {
            ...state,
            isLoading: false,
            statusSvod: action.status,
        };
    }
    //svod form raz
    if (action.type === requestSvodFormRazAction) {
        return {
            ...state,
            isLoading: true,
            statusSvod: undefined,
        };
    }
    if (action.type === receiveSvodFormRazAction) {
        return {
            ...state,
            isLoading: false,
            statusSvod: action.status,
        };
    }
    if (action.type === requestIsExistSvodFormRazAction) {
        return {
            ...state,
            isLoading: true,
            statusSvod: undefined,
        };
    }
    if (action.type === receiveIsExistSvodFormRazAction) {
        return {
            ...state,
            isLoading: false,
            statusSvod: action.status,
        };
    }
    if (action.type === requestLoadListOrgData) {
        return {
            ...state,
            isLoading: true
        };
    }
    if (action.type === receiveLoadListOrgData) {
        return {
            ...state,
            isLoading: false
        };
    }
    if (action.type === requestSetIsLoading) {
        return {
            ...state,
            isLoading: action.flag
        };
    }
    return state;
}

