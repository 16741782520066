import React, { Component,Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal, Button, Panel, Col, FormGroup, FormControl, ControlLabel, Form } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import DatePicker from 'react-date-picker';
import { getDateString, formatDateISO } from '../common/DateFunctions';

import { actionCreators } from '../../store/FinPaidData';
import './DogovorModal.css';
class SelectOrgModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModalDeleteDogovor: false,
            showModalDeleteBill: false,
            showModalEditBill: false,
            showModalEditDogovor: false,
    
            selectedDogovor: undefined,
            editDogovor: undefined,
            deleteDogovor: undefined,

            selectedBill: undefined,
            editBill: undefined,
            deleteBill: undefined,

        }
        this.addDogovor = this.addDogovor.bind(this);

        this.editDogovor = this.editDogovor.bind(this);
        this.deleteDogovor = this.deleteDogovor.bind(this);
        this.handleDeleteDogovor = this.handleDeleteDogovor.bind(this);
        this.handleHideDeleteDogovor = this.handleHideDeleteDogovor.bind(this);
        this.handleEditDogovor = this.handleEditDogovor.bind(this);
        this.handleHideEditDogovor = this.handleHideEditDogovor.bind(this);
        this.onSubmitDogovorForm = this.onSubmitDogovorForm.bind(this);

        this.addBill = this.addBill.bind(this);
        this.selectBill = this.selectBill.bind(this);
        this.editBill = this.editBill.bind(this);
        this.deleteBill = this.deleteBill.bind(this);
        this.handleDeleteBill = this.handleDeleteBill.bind(this);
        this.handleHideDeleteBill = this.handleHideDeleteBill.bind(this);
        this.handleEditBill = this.handleEditBill.bind(this);
        this.handleHideEditBill = this.handleHideEditBill.bind(this);
        this.onSubmitBillForm = this.onSubmitBillForm.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.obj != null && (this.props.obj==null || (this.props.obj.kod !== nextProps.obj.kod))) {
           this.props.getDogovors({ typeObj: nextProps.obj.typeObj, kodObj: nextProps.obj.kod });
           this.setState({ selectedDogovor: undefined, selectedBill: undefined });
        }
    }
    addDogovor = () => {
        this.setState({
            editDogovor: { id: undefined, obj: this.props.obj, nom: "", dt: new Date(), srokOt: new Date(), srokDo: new Date(), summa: 0.0, isNew: true },
            showModalEditDogovor: true
        });
    }

    editDogovor = (editDogovor) => {
        this.setState({ showModalEditDogovor: true, editDogovor: { ...editDogovor }});
    }
    deleteDogovor = (deleteDogovor) => {
        this.setState({ deleteDogovor, showModalDeleteDogovor: true });
    }
    handleHideDeleteDogovor = () => {
        this.setState({ showModalDeleteDogovor: false, deleteDogovor: undefined });
    }
    handleDeleteDogovor = () => {
        this.props.DeleteDogovor(this.state.deleteDogovor.id);
        this.setState({ deleteDogovor: undefined, showModalDeleteDogovor: false });
    }
    handleEditDogovor = () => {
        if (this.state.editDogovor.id === undefined) {
            //новый договор
            this.props.AddDogovor({
                paidLevel:this.props.obj.typeObj,
                kodObj:this.props.obj.kod,
                dt:formatDateISO(this.state.editDogovor.dt),
                nom:this.state.editDogovor.nom,
                srokOt:formatDateISO(this.state.editDogovor.srokOt),
                srokDo:formatDateISO(this.state.editDogovor.srokDo),
                summa:this.state.editDogovor.summa
            });
            this.setState({ showModalEditDogovor: false, editDogovor: undefined });
        } else {
            //редактирование договора
            this.props.UpdateDogovor({
                id: this.state.editDogovor.id,
                paidLevel: this.state.editDogovor.paidLevel,
                kodObj: this.state.editDogovor.kodObj,
                dt: formatDateISO(this.state.editDogovor.dt),
                nom: this.state.editDogovor.nom,
                srokOt: formatDateISO(this.state.editDogovor.srokOt),
                srokDo: formatDateISO(this.state.editDogovor.srokDo),
                summa: this.state.editDogovor.summa
            })
            this.setState({ selectedDogovor: this.state.editDogovor , showModalEditDogovor: false, editDogovor: undefined });
        }
    }
    handleHideEditDogovor = () => {
        this.setState({ showModalEditDogovor: false, editDogovor: undefined });
    }
    onSubmitDogovorForm = () => {

    }

    addBill = () => {
        this.setState({
            editBill: { id: undefined, dogovorId: this.state.selectedDogovor.id, nom: "", dtFrom: new Date(), dtTo: new Date(), summa: 0.00 },
            showModalEditBill: true
        });
    }
    editBill = (editBill) => {
        this.setState({ showModalEditBill: true, editBill: { ...editBill } });
    }
    deleteBill = (deleteBill) => {
        this.setState({ deleteBill, showModalDeleteBill: true });
    }
    handleHideDeleteBill = () => {
        this.setState({ showModalDeleteBill: false, deleteBill: undefined });
    }
    handleDeleteBill = () => {
        this.props.DeleteBill(this.state.deleteBill.id,
            (dg) => {
                this.setState({ selectedDogovor: dg });
        });
        this.setState({ deleteBill: undefined, showModalDeleteBill: false });
    }
    selectBill = (selectedBill) => {
        this.setState({ selectedBill });
    }
    handleEditBill = () => {
        if (this.state.editBill.id === undefined) {
            //новая платежка
            this.props.AddBill({
                dogovorId: this.state.editBill.dogovorId,
                nom: this.state.editBill.nom,
                dtFrom: formatDateISO(this.state.editBill.dtFrom),
                dtTo: formatDateISO(this.state.editBill.dtTo),
                summa: this.state.editBill.summa
            }, (dg) => {
                this.setState({ selectedDogovor: dg });
            });
            this.setState({ showModalEditBill: false, editBill: undefined });

        } else {
            //редактирование платежки
            this.props.UpdateBill({
                id: this.state.editBill.id,
                dogovorId: this.state.editBill.dogovorId,
                nom: this.state.editBill.nom,
                dtFrom: formatDateISO(this.state.editBill.dtFrom),
                dtTo: formatDateISO(this.state.editBill.dtTo),
                summa: this.state.editBill.summa
            }, (dg) => {
                this.setState({ selectedDogovor: dg });
            });
            this.setState({ showModalEditBill: false, editBill: undefined });
        }
    }
    handleHideEditBill= () => {
        this.setState({ showModalEditBill: false, editBill: undefined });
    }
    onSubmitBillForm = () => {

    }

  
    render() {
        return (<Fragment>
            <Modal show={this.props.showModal} onHide={this.props.handleHide} backdrop="static" bsSize="large">
                <Modal.Header closeButton>
                    <Modal.Title>Договора</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>{this.props.obj != null ? this.props.obj.nameRu : ""}</div>
                    <Button className='btn btn-primary text-center' onClick={this.addDogovor} type="button">Добавить договор</Button>
                    <Panel>
                        <Panel.Heading>Договора</Panel.Heading>
                        <Panel.Body>
                            <ul className="list-group">
                                {this.props.dogovors.map(dogovor =>
                                    <li key={dogovor.id} onClick={() => this.setState({ selectedDogovor: dogovor })}
                                        className={'list-group-item' + (this.state.selectedDogovor != null && this.state.selectedDogovor.id === dogovor.id ? ' list-group-item-success' : '')}>
                                        <div className='dogovor_info'>Договор № {dogovor.nom} дата: {getDateString(dogovor.dt)} сумма: {dogovor.summa}</div>
                                        <div className="dogovor_bt_edit"><button type='button' className='btn btn-success btn-sm' onClick={() => this.editDogovor(dogovor)}>
                                            <span className='glyphicon glyphicon-pencil'></span></button></div>
                                        <div className="dogovor_bt_del"><button type='button' className='btn btn-danger btn-sm' onClick={() => this.deleteDogovor(dogovor)}>
                                            <span className='glyphicon glyphicon-remove'></span></button></div></li>)}
                            </ul>
                        </Panel.Body>
                    </Panel>
                    <Button className='btn btn-primary text-center' onClick={this.addBill} type="button" disabled={this.state.selectedDogovor == null}>Добавить платежку</Button>
                    <Panel>
                        <Panel.Heading>Платежки:
                        {this.state.selectedDogovor != null ? <Fragment>&nbsp;Договор № <strong>{this.state.selectedDogovor.nom}</strong> дата: <strong>{getDateString(this.state.selectedDogovor.dt)}</strong> сумма: <strong>{this.state.selectedDogovor.summa}</strong></Fragment> : ''}
                        </Panel.Heading>
                        <Panel.Body>
                            <ul className="list-group">
                                {this.state.selectedDogovor != null && this.state.selectedDogovor.bills != null ?
                                    this.state.selectedDogovor.bills.map(bill =>
                                        <li key={bill.id} onClick={() => this.setState({ selectedBill: bill })}
                                            className={'list-group-item' + (this.state.selectedBill != null &&  this.state.selectedBill.id === bill.id ? ' list-group-item-success' : '')}>
                                            <div className='dogovor_info'>Платежка № {bill.nom} дата: {getDateString(bill.dtFrom)} сумма: {bill.summa}</div>
                                            <div className="dogovor_bt_edit"><button type='button' className='btn btn-success btn-sm' onClick={() => this.editBill(bill)}>
                                                <span className='glyphicon glyphicon-pencil'></span></button></div>
                                            <div className="dogovor_bt_del"><button type='button' className='btn btn-danger btn-sm' onClick={() => this.deleteBill(bill)}>
                                                <span className='glyphicon glyphicon-remove'></span></button></div></li>):' '}
                            </ul>
                        </Panel.Body>
                    </Panel>
                </Modal.Body>
                <Modal.Footer>
                        <Button className='btn btn-default text-center' onClick={this.props.handleHide} type="button">Закрыть</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={this.state.showModalDeleteDogovor} onHide={this.handleHideDeleteDogovor}>
                <Modal.Header closeButton>
                    <Modal.Title>Удаление договора</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Вы дествительно хотите удалить договор {this.state.deleteDogovor !== undefined ? `договор № ${this.state.deleteDogovor.nom} от ${getDateString(this.state.deleteDogovor.dt)}` : ''}?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleHideDeleteDogovor}>Закрыть</Button>
                    <Button onClick={this.handleDeleteDogovor}>Удалить</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={this.state.showModalDeleteBill} onHide={this.handleHideDeleteBill}>
                <Modal.Header closeButton>
                    <Modal.Title>Удаление платежки</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Вы дествительно хотите удалить платежку {this.state.deleteBill !== undefined ? `договор № ${this.state.deleteBill.nom} от ${getDateString(this.state.deleteBill.dtFrom)}` : ''}?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleHideDeleteBill}>Закрыть</Button>
                    <Button onClick={this.handleDeleteBill}>Удалить</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={this.state.showModalEditDogovor} onHide={this.handleHideEditDogovor}>
                <Modal.Header closeButton>
                    <Modal.Title>Добавление/редактирование договора</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.editDogovor != null ?
                        <Form onSubmit={this.onSubmitDogovorForm} horizontal>
                            <FormGroup controlId="formNom" bsSize='small'>
                                <Col sm={2} componentClass={ControlLabel}>№</Col>
                                <Col sm={10}> <FormControl
                                    type="text"
                                    value={this.state.editDogovor.nom}
                                    onChange={(e) => {
                                        var editDogovor = { ...this.state.editDogovor };
                                        editDogovor.nom = e.target.value;
                                        this.setState({ editDogovor });
                                    }}
                                /></Col>
                            </FormGroup>
                            <FormGroup controlId="dt" bsSize='small'>
                                <Col sm={2} componentClass={ControlLabel}>Дата договора</Col>
                                <Col sm={10}>
                                    <DatePicker value={this.state.editDogovor.dt} onChange={date => {
                                        var editDogovor = { ...this.state.editDogovor };
                                        editDogovor.dt = date;
                                        this.setState({ editDogovor });
                                    }}/>
                                </Col>
                            </FormGroup>
                            <FormGroup controlId="srokOt" bsSize='small'>
                                <Col sm={2} componentClass={ControlLabel}>с</Col>
                                <Col sm={10}>
                                    <DatePicker value={this.state.editDogovor.srokOt} onChange={date => {
                                        var editDogovor = { ...this.state.editDogovor };
                                        editDogovor.srokOt = date;
                                        this.setState({ editDogovor });
                                    }} />
                                </Col>
                            </FormGroup>
                            <FormGroup controlId="srokDo" bsSize='small'>
                                <Col sm={2} componentClass={ControlLabel}>по</Col>
                                <Col sm={10}>
                                    <DatePicker value={this.state.editDogovor.srokDo} onChange={date => {
                                        var editDogovor = { ...this.state.editDogovor };
                                        editDogovor.srokDo = date;
                                        this.setState({ editDogovor });
                                    }} />
                                </Col>
                            </FormGroup>
                            <FormGroup controlId="summa" bsSize='small'>
                                <Col sm={2} componentClass={ControlLabel}>Сумма</Col>
                                <Col sm={10}>
                                    <NumberFormat
                                        value={this.state.editDogovor.summa}
                                        onChange={(e) => {
                                            var editDogovor = { ...this.state.editDogovor };
                                            editDogovor.summa = e.target.value;
                                            this.setState({ editDogovor });
                                        }}/>
                                </Col>
                            </FormGroup>
                        </Form> : ''}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleHideEditDogovor}>Закрыть</Button>
                    <Button onClick={this.handleEditDogovor}>Сохранить</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={this.state.showModalEditBill} onHide={this.handleHideEditBill}>
                <Modal.Header closeButton>
                    <Modal.Title>Добавление/редактирование платежки</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.editBill!=null?
                    <Form onSubmit={this.onSubmitBillForm} horizontal>
                        <FormGroup controlId="formNom" bsSize='small'>
                            <Col sm={2} componentClass={ControlLabel}>№</Col>
                            <Col sm={10}> <FormControl
                                type="text"
                                value={this.state.editBill.nom}
                                onChange={(e) => {
                                        var editBill = { ...this.state.editBill };
                                        editBill.nom = e.target.value;
                                        this.setState({ editBill });
                                    }}
                            /></Col>
                            </FormGroup>
                            <FormGroup controlId="dtFrom" bsSize='small'>
                                <Col sm={2} componentClass={ControlLabel}>с</Col>
                                <Col sm={10}>
                                    <DatePicker value={this.state.editBill.dtFrom} onChange={date => {
                                        var editBill = { ...this.state.editBill };
                                        editBill.dtFrom = date;
                                        this.setState({ editBill });
                                    }} />
                                </Col>
                            </FormGroup>
                            <FormGroup controlId="dtTo" bsSize='small'>
                                <Col sm={2} componentClass={ControlLabel}>по</Col>
                                <Col sm={10}>
                                    <DatePicker value={this.state.editBill.dtTo} onChange={date => {
                                        var editBill = { ...this.state.editBill };
                                        editBill.dtTo = date;
                                        this.setState({ editBill });
                                    }} />
                                </Col>
                            </FormGroup>
                            <FormGroup controlId="summa" bsSize='small'>
                                <Col sm={2} componentClass={ControlLabel}>Сумма</Col>
                                <Col sm={10}>
                                    <NumberFormat
                                        value={this.state.editBill.summa}
                                        onChange={(e) => {
                                            var editBill = { ...this.state.editBill };
                                            editBill.summa = e.target.value;
                                            this.setState({ editBill });
                                        }} />
                                </Col>
                            </FormGroup>
                        </Form> : ''}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleHideEditBill}>Закрыть</Button>
                    <Button onClick={this.handleEditBill}>Сохранить</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
        );
    }
}
export default connect(
    state => state.finPaidData,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(SelectOrgModal);
