import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button, FormGroup, FormControl, ControlLabel, Row, Col } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';

import { actionCreators } from '../../store/Nsi';
import DataTable from './DataTable';
import { years } from '../Constants';

class Nsi extends Component {
    constructor(props) {
        super(props);
        this.state = { mode: 'load', selTable: 'kopf', selTableExt:null, selYear: (new Date()).getFullYear() };
        this.handleSelectNsiSubmit = this.handleSelectNsiSubmit.bind(this);
        this.setEditMode = this.setEditMode.bind(this);
        this.saveDataTable = this.saveDataTable.bind(this);
        this.cancelData = this.cancelData.bind(this);
        this.changeTable = this.changeTable.bind(this);
        this.onSelectYear = this.onSelectYear.bind(this);
        this.getTableExt = this.getTableExt.bind(this);

        this.props.requestNsiMetadata();
    }
    handleSelectNsiSubmit(e) {
        e.preventDefault();
        //тодо загрузить справочник
        if (this.state.selTable === "sprstr" || this.state.selTable === "sprgraf" || this.state.selTable === "tmp_med" || this.state.selTable === "tmp_med_titul")
           this.props.getDataTable(this.state.selTableExt);
        else
           this.props.getDataTable(this.state.selTable);
        this.setState({ mode: 'view' });
    }
    setEditMode(e) {
        //e.preventDefault();
        this.setState({ mode: 'edit' });
    }
    saveDataTable(dataTable) {
        if (this.state.selTable === "sprstr" || this.state.selTable === "sprgraf" || this.state.selTable === "tmp_med" || this.state.selTable === "tmp_med_titul")
            this.props.saveDataTable(this.state.selTableExt, dataTable.filter((row) => row.status === 1 || row.status === 2 || row.status === 3));
        else
            this.props.saveDataTable(this.state.selTable, dataTable.filter((row) => row.status === 1 || row.status === 2 || row.status === 3));
        this.setState({ mode: 'load' });
    }
    cancelData(e) {
        //e.preventDefault();
        this.setState({ mode: 'load' });
    }
    changeTable(e) {
        e.preventDefault();
        let table = e.target.value;
        if (table === "sprstr" || table === "sprgraf" || table === "tmp_med" || table === "tmp_med_titul") {
            let selTableExt = this.getTableExt(table, this.state.selYear);
            this.setState({ selTable: table, selTableExt, mode: 'load' });
        }
        else
            this.setState({ selTable: table, selTableExt:null, mode: 'load' });
    }
    renderUpdateStatus = () => {
        if (this.props.updateStatus === undefined)
            return undefined;
        if (this.props.updateStatus.successed === true)
            return (<p className='text-info'><strong>{this.props.updateStatus.message}</strong></p>);
        else
            return (<p className='text-danger'><strong>{this.props.updateStatus.error}</strong></p>);
    }
    onSelectYear = (e) => {
        let year = parseInt(e.target.value, 10);
        let selTableExt = this.getTableExt(this.state.selTable,year);
        this.setState({ selYear: year, selTableExt, mode: 'load'});
    }
    getTableExt=(table, year)=>{
        let sye = `${year}`;
        return table + sye.substr(2);
    }
    render() {
        let selMetaData;
        if (!Array.prototype.find)
            selMetaData = this.props.nsimeta.filter(el => el.nameTable === this.state.selTable)[0];
         else
            selMetaData= this.props.nsimeta.find(el => el.nameTable === this.state.selTable);
        return (<div>
            <Row><Col md={12} sm={12} lg={12}><Form inline onSubmit={this.handleSelectNsiSubmit}>
            <FormGroup controlId="formInlineName">
                <ControlLabel>Справочник:</ControlLabel>{' '}
                <FormControl componentClass="select" placeholder="select" defaultValue={'kopf'} onChange={this.changeTable}>
                    {this.props.nsimeta.map((it) => <option key={it.nameTable} value={it.nameTable}>{it.nameRu}</option>)}
                </FormControl>
            </FormGroup>{' '}
                {this.state.selTable === "sprstr" || this.state.selTable === "sprgraf" || this.state.selTable === "tmp_med" || this.state.selTable === "tmp_med_titul"? 
                    <FormGroup controlId="formYear" className='input-group-sm'>
                        <ControlLabel><Translate value='year' />{':'}</ControlLabel>
                        <FormControl className='input-sm' componentClass="select" placeholder="select"
                                defaultValue={this.state.selYear} onChange={this.onSelectYear}>
                                {years.map(it => <option key={it} value={it}>{it}</option>)}
                        </FormControl>
                    </FormGroup>:""}
            {this.state.mode === 'load' ?
                <Button type="submit" bsStyle="info" disabled={this.state.mode !== 'load'}>Загрузить</Button> : ''}
        </Form></Col></Row>
            {this.props.isLoading === true ? <Row>
                <Col smOffset={2} sm={10}>
                    <div className='text-left'><img src="../images/loading.gif" alt={I18n.t('loading')} width={20} />{' '}<strong><Translate value="loading" /></strong></div>
                </Col>
            </Row> : ''}
            {this.renderUpdateStatus()}
            {this.state.mode !== 'load' && this.props.dataTable !== undefined && this.props.isLoading === false ?
                <DataTable mode={this.state.mode} meta={selMetaData} data={this.props.dataTable} saveDataTable={this.saveDataTable} editTable={this.setEditMode} cancelData={this.cancelData} /> :
                ''}
            </div>)
    }
}
export default connect(
    state => state.nsi,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Nsi);
//nameRu nameKz nameTable