import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import * as FileSaver from 'file-saver';
import { Translate, I18n } from 'react-redux-i18n';
import { Button, Col, FormGroup, FormControl, ControlLabel, Form, Row, Alert, Checkbox } from 'react-bootstrap';

import { actionCreators as actionCreatorsSvod } from '../../store/Svod';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';

import { years, getNameLocalize, getCurrentYear } from '../Constants';

import SelectMultiForms from '../common/SelectMultiForms';


const SvodSubOrgObl = (props) => {
    useState(() => {
        const loadHeadOrgs = async () => {
            let ret = await props.loadHeadOrgs();
            if (ret.successed) {
                setHeadOrgs(ret.data);
            } else {
                alert(ret.error);
            }
        };
        loadHeadOrgs();
        if (props.nsi.allobls.length === 0) props.getNs('allobls');
        if (props.nsi.sprraz.length === 0) props.getNs('sprraz');
        if (props.nsi.sprform.length === 0) props.getNs('sprform');
        if (props.nsi.periods.length === 0) props.getNs('periods');
        if (props.nsi.allraions.length === 0) props.getNs('allraions');
        if (props.nsi.nomenkl.length === 0) props.getNs('nomenkl');
    });
    const [headOrgs, setHeadOrgs] = useState([]);
    const [isSvodComplete, setIsSvodComplete] = useState(false);
    const [error, setError] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [year, setYear] = useState(() => getCurrentYear());
    const [kper, setKper] = useState(4);
    const [kraz, setKraz] = useState(23);
    const [orgs, setOrgs] = useState([]);
    const [kforms, setKforms] = useState([]);
    const [isNotParent, setIsNotParent] = useState(false)
    const [excludeNomenkl, setExcludeNomenkl] = useState([]);
    const onCheckOrg = (e) => {
        let checked = e.target.checked;
        let ko = e.target.value;
        let korgs = cloneDeep(orgs);
        if (checked) {
            korgs.push(ko);
        } else {
            let ind = korgs.indexOf(ko);
            korgs.splice(ind, 1);
        }
        setOrgs(korgs);
    }
    const onCheckNomen = (e) => {
        let checked = e.target.checked;
        let nomen = e.target.value;
        let excNomenkl = cloneDeep(excludeNomenkl);
        if (checked) {
            excNomenkl.push(nomen);
        } else {
            let ind = excNomenkl.indexOf(nomen);
            excNomenkl.splice(ind, 1);
        }
        setExcludeNomenkl(exclNomenkl);
    }
    const onSvodForm = (e) => {
        e.preventDefault();
        setIsSvodComplete(false);
        setError([]);
        if (orgs.length === 0) {
            setError([I18n.t("selHeadOrg")]);
            return;
        }
        if (kforms.length === 0) {
            setError([I18n.t("selectForms")]);
            return;
        }
        const svodFn = async () => {
            setIsLoading(true);
            let ret = await props.svodSubOblForm({
                year: year,
                kper: kper,
                kraz: kraz,
                orgs,
                kforms: kforms,
                isNotParent,
                excludeNomenkl
            });
            setIsLoading(false);
            if (ret.successed == true) {
                setIsSvodComplete(true);
            } else {
                setError([ret.error]);
            }
        }
        svodFn();
    }
    const textTargetRaz = () => {
        //let org = getNameLocalize(props.i18n, { nameRu: props.userInfo.user.nameOrg, nameKz: props.userInfo.user.nameOrgKz });
        let srcRaz = getNameLocalize(props.i18n, props.nsi.sprraz.filter(f => f.kod === kraz)[0]);
        let ti = kraz - 20;
        let targetRaz = getNameLocalize(props.i18n, props.nsi.sprraz.filter(f => f.kod === ti)[0]);
        return I18n.t("svodSubText", { srcRaz, targetRaz, org: "" });//<Translate value="svodSubText" srcRaz={srcRaz} targetRaz={targetRaz} org={org} />
    }
    let isReadOnLy = props.userInfo.user.roles.filter(r => r === 'UserReadData').length > 0;
    if (isReadOnLy === true)
        return <Translate value="noAccess" />
    else
    return <Form onSubmit={onSvodForm} horizontal>
        <h5 style={{ textAlign: "center" }}><strong><Translate value="svodSub" /></strong></h5>
        <FormGroup controlId="formYear" bsSize='small'>
            <Col sm={2} componentClass={ControlLabel}><Translate value='year' />{':'}</Col>
            <Col sm={10}>
                <FormControl className='input-sm' componentClass="select" placeholder="select" disabled={isLoading}
                    defaultValue={year} onChange={(e) => setYear(parseInt(e.target.value, 10))} bsSize="small" style={{ width: "auto" }}>
                    {years.filter(y => y > 2020).map(it => <option key={it} value={it}>{it}</option>)}
                </FormControl></Col>
        </FormGroup>
        <FormGroup controlId="formPer" bsSize='small'>
            <Col sm={2} componentClass={ControlLabel}><Translate value='period' />{':'}</Col>
            <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={kper} disabled={isLoading}
                onChange={(e) => setKper(parseInt(e.target.value, 10))} bsSize='small' style={{ width: "auto" }}>
                {props.nsi.periods.map(it => <option key={it.kper} value={it.kper}>{it.nameRu}</option>)}
            </FormControl></Col>
        </FormGroup>
        <FormGroup controlId="formRaz" bsSize='small'>
            <Col sm={2} componentClass={ControlLabel}><Translate value='razres' />{':'}</Col>
            <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={kraz} disabled={isLoading}
                onChange={(e) => setKraz(parseInt(e.target.value, 10))} bsSize='small' style={{ width: "auto" }}>
                {props.nsi.sprraz.filter(f => f.kod === 23 || f.kod === 24 || f.kod === 25).map(it => <option key={it.kod} value={it.kod}>{getNameLocalize(props.i18n, it)}</option>)}
            </FormControl>
                <div className="text-primary text-left"><strong>{textTargetRaz()}</strong></div>
            </Col>
        </FormGroup>
        <FormGroup controlId="isNotParent" bsSize='small'>
            <Col sm={2} componentClass={ControlLabel}><Translate value='headOrg2' />{':'}</Col>
            <Col sm={8}>
                <Checkbox onChange={(e) => setIsNotParent(e.target.checked)} checked={isNotParent}><Translate value='notinclude' /></Checkbox>
            </Col>
        </FormGroup>
        <FormGroup controlId="nomenIncl" bsSize='small'>
            <Col sm={2} componentClass={ControlLabel}><Translate value='onlyNomen' />{':'}</Col>
            <Col sm={8}>
                {props.nsi.nomenkl.filter(f => f.kod === '052' || f.kod === '053').map(it => <Checkbox onChange={onCheckNomen} key={it.kod} value={it.kod}>{it.kod} {getNameLocalize(props.i18n, it)}</Checkbox>)}
            </Col>
        </FormGroup>
        <FormGroup controlId="formOrgs" bsSize='small'>
            <Col sm={2} componentClass={ControlLabel}><Translate value='headOrg' />{':'}</Col>
            <Col sm={10}>
                {headOrgs.map(it => <Checkbox onChange={onCheckOrg} key={it.kod} value={it.kod} disabled={isLoading} inline>{it.kod} {getNameLocalize(props.i18n, it)}</Checkbox>)}
            </Col>
        </FormGroup>
        <SelectMultiForms setForms={setKforms} no47={false/*true*/} />
        {isSvodComplete === true ? <Row>
            <Col smOffset={2} sm={10}>
                <Alert bsStyle="success"><Translate value="svodComplete" /></Alert>
            </Col>
        </Row> : ''}
        {error.length > 0 ? <Row bsSize='small'>
            <Col smOffset={2} sm={10}>
                <Alert bsStyle="danger"><ul>{error.map(m => <li>{m}</li>)}</ul></Alert>
            </Col>
        </Row> : ''}
        <FormGroup bsSize='small'>
            <Col smOffset={2} sm={10}>
                <Button className='btn btn-primary text-center' type="submit" disabled={isLoading}><Translate value="execSvod" /></Button>
            </Col>
        </FormGroup>
    </Form>
}
export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionCreatorsSvod }, dispatch)
)(SvodSubOrgObl);