import { cloneDeep } from 'lodash';
import { getToken } from './UserInfo';
const requestGetDataTableAction = 'REQUEST_GET_DATA_TABLE';
const receiveGetDataTableAction = 'RECEIVE_GET_DATA_TABLE';
const requestUpdateDataAction = 'REQUEST_UPDATE_DATA_TABLE';
const receiveUpdateDataAction = 'RECEIVE_UPDATE_DATA_TABLE';
const requestDeleteAction = 'REQUEST_DELETE_DATA_TABLE';
const receiveDeleteAction = 'RECEIVE_DELETE_DATA_TABLE';

const requestGetInfoDataAction = 'REQUEST_GET_INFO_DATA';
const receiveGetInfoDataAction = 'RECEIVE_GET_INFO_DATA';
const requestGetInfoDataFormAction = 'REQUEST_GET_INFO_DATA_FORM';
const receiveGetInfoDataFormAction = 'RECEIVE_GET_INFO_DATA_FORM';
const requestGetInfoDataObjAction = 'REQUEST_GET_INFO_DATA_OBJ';
const receiveGetInfoDataObjAction = 'RECEIVE_GET_INFO_DATA_OBJ';

const requestGetStatusInfoDataAction = 'REQUEST_GET_STATUS_INFO_DATA';
const receiveGetStatusInfoDataAction = 'RECEIVE_GET_STATUS_INFO_DATA';
const requestUpdateStatusInfoDataAction = 'REQUEST_UPDATE_STATUS_INFO_DATA';
const receiveUpdateStatusInfoDataAction = 'RECEIVE_UPDATE_STATUS_INFO_DATA';

const requestGetStatusInfoDataRkAction = 'REQUEST_GET_STATUS_INFO_DATA_RK';
const receiveGetStatusInfoDataRkAction = 'RECEIVE_GET_STATUS_INFO_DATA_RK';
const requestUpdateStatusInfoDataRkAction = 'REQUEST_UPDATE_STATUS_INFO_DATA_RK';
const receiveUpdateStatusInfoDataRkAction = 'RECEIVE_UPDATE_STATUS_INFO_DATA_RK';

const requestGetStatusInfoDataTablesRkAction = 'REQUEST_GET_STATUS_INFO_DATA_TABLES_RK';
const receiveGetStatusInfoDataTablesRkAction = 'RECEIVE_GET_STATUS_INFO_DATA_TABLES_RK';
const requestUpdateStatusInfoDataTablesRkAction = 'REQUEST_UPDATE_STATUS_INFO_TABLES_DATA_RK';
const receiveUpdateStatusInfoDataTablesRkAction = 'RECEIVE_UPDATE_STATUS_INFO_TABLES_DATA_RK';

const requestGetStatusInfoDataTablesOblAction = 'REQUEST_GET_STATUS_INFO_DATA_TABLES_OBL';
const receiveGetStatusInfoDataTablesOblAction = 'RECEIVE_GET_STATUS_INFO_DATA_TABLES_OBL';
const requestUpdateStatusInfoDataTablesOblAction = 'REQUEST_UPDATE_STATUS_INFO_TABLES_DATA_OBL';
const receiveUpdateStatusInfoDataTablesOblAction = 'RECEIVE_UPDATE_STATUS_INFO_TABLES_DATA_OBL';

const requestDeleteFormAllTablesAction = 'REQUEST_DELETE_FORM_ALL_TABLES';
const receiveDeleteFormAllTablesAction = 'RECEIVE_DELETE_FORM_ALL_TABLES';

const requestGetReport1RCRZType = 'REQUEST_GET_REPORT1_RCRZ';
const receiveGetReport1RCRZType = 'RECEIVE_GET_REPORT1_RCRZ';

const initialState = {
    isLoading: false,
    data: undefined,
    dataInfo: undefined,
    dataInfoForm: undefined,
    dataInfoObj: undefined,
    statusInfo: undefined,
    statusInfoRk: undefined,
    statusInfoTablesRk: undefined,
    statusInfoTablesObl: undefined,
    statusUpdate: undefined,
    statusDelete: undefined,
    rep1data: undefined,
    rep1AllOrgs:0
}


export const actionCreators = {
    isExistsForm: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        let json = JSON.stringify(params);
        const response = await fetch("api/DataApi/IsExistsForm", {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            let ret = await response.json();
            return ret;
        } else {
            alert("Ошибка HTTP: " + response.status);
            return null;
        }
    },
    getReport1: year => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestGetReport1RCRZType });
        const url = `api/DataApi/report1?year=${year}`;
        const response = await fetch(url, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const rep1data = await response.json();
        dispatch({ type: receiveGetReport1RCRZType, rep1data });
    },
    updateStatusInfoData: params => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestUpdateStatusInfoDataAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/UpdateStatusInfoData', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveUpdateStatusInfoDataAction, data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    getStatusInfoData: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestGetStatusInfoDataAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/GetStatusInfoData', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveGetStatusInfoDataAction, data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    updateStatusInfoDataRk: params => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestUpdateStatusInfoDataRkAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/UpdateStatusInfoDataRk', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveUpdateStatusInfoDataRkAction, data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    getStatusInfoDataRk: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestGetStatusInfoDataRkAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/GetStatusInfoDataRk', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveGetStatusInfoDataRkAction, data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },

    updateStatusInfoDataTablesRk: params => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestUpdateStatusInfoDataTablesRkAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/UpdateStatusInfoDataTablesRk', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveUpdateStatusInfoDataTablesRkAction, data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    getStatusInfoDataTablesRk: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestGetStatusInfoDataTablesRkAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/GetStatusInfoDataTablesRk', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveGetStatusInfoDataTablesRkAction, data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },

    updateStatusInfoDataTablesObl: params => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestUpdateStatusInfoDataTablesOblAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/UpdateStatusInfoDataTables', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveUpdateStatusInfoDataTablesOblAction, data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    getStatusInfoDataTablesObl: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestGetStatusInfoDataTablesOblAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/GetStatusInfoDataTables', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveGetStatusInfoDataTablesOblAction, data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },


    getInfoData: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestGetInfoDataAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/GetInfoData', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveGetInfoDataAction, data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    getInfoDataForm: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestGetInfoDataFormAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/GetInfoDataForm', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveGetInfoDataFormAction, data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    getInfoDataObj: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestGetInfoDataObjAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/GetInfoDataObj', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveGetInfoDataObjAction, data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },

    getData: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestGetDataTableAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/GetForm', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveGetDataTableAction, data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    getIsAvariableDelete: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/GetIsAvariableDelete', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            //dispatch({ type: receiveGetDataTableAction, data });
            return data.isAvariableDelete;
        } else {
            alert("Ошибка HTTP: " + response.status);
            return false;
        }
    },
    updateData: (dataTable) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestUpdateDataAction });
        let rows = dataTable.rows.map(r => {
            let values = r.values.map(v => { return { Vl: v.vl, OriginalVl: v.originalVl, Kgr: v.kgr, Str: v.str }; });
            return { Kstr: r.kstr, Values: values };
        });
        let data = {
            Rows: rows,
            Year: dataTable.year,
            Raz: dataTable.raz,
            Period: dataTable.period,
            Tobj: dataTable.tobj,
            Kobj: dataTable.kobj,
            Kf: dataTable.kf,
            Kt: dataTable.kt       };
        let json = JSON.stringify(data);
        const response = await fetch('api/DataApi/UpdateForm', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const status = await response.json();
            dispatch({ type: receiveUpdateDataAction, status, dataTable });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    deleteData: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestDeleteAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/DeleteForm', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const status = await response.json();
            dispatch({ type: receiveDeleteAction, status });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    deleteForm: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestDeleteFormAllTablesAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApi/DeleteFormAllTables', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const status = await response.json();
            dispatch({ type: receiveDeleteFormAllTablesAction, status });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    loadAccesRazdOrgParam: korg => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        const url = `api/DataApi/loadAccesRazdOrgParam?korg=${korg}`;
        const response = await fetch(url, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) {
            return await response.json();
        } else {
            return { successed: false, message: response.statusText};
        }
    },
    saveAccesRazdOrgParam: params => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        let json = JSON.stringify(params);
        //const url = "api/DataApi/saveAccesRazdOrgParam`;
        const response = await fetch("api/DataApi/SaveAccesRazdOrgParam", {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) {
            return await response.json();
        } else {
            return { successed: false, message: response.statusText };
        }
    },
    getAccesRazdFromYear: (korg, year)=> async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        const url = `api/DataApi/getAccesRazdFromYear?korg=${korg}&year=${year}`;
        const response = await fetch(url, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) {
            return await response.json();
        } else {
            return { successed: false, message: response.statusText };
        }
    },
}
export const reducer = (state, action) => {
    state = state || initialState;
    //get data
    if (action.type === requestGetDataTableAction) {
        return {
            ...state,
            isLoading: true,
            data: undefined,
            statusUpdate: undefined,
            statusDelete: undefined
        };
    }
    if (action.type === receiveGetDataTableAction) {
        return {
            ...state,
            isLoading: false,
            data: action.data,
            controlResult: undefined
        };
    }
    //get info data
    if (action.type === requestGetInfoDataAction) {
        return {
            ...state,
            isLoading: true,
            dataInfo: undefined,
        };
    }
    if (action.type === receiveGetInfoDataAction) {
        return {
            ...state,
            isLoading: false,
            dataInfo: action.data,
        };
    }
    //get info data form
    if (action.type === requestGetInfoDataFormAction) {
        return {
            ...state,
            isLoading: true,
            dataInfoForm: undefined,
        };
    }
    if (action.type === receiveGetInfoDataFormAction) {
        return {
            ...state,
            isLoading: false,
            dataInfoForm: action.data,
        };
    }
    //get info data obj
    if (action.type === requestGetInfoDataObjAction) {
        return {
            ...state,
            isLoading: true,
            dataInfoObj: undefined,
        };
    }
    if (action.type === receiveGetInfoDataObjAction) {
        return {
            ...state,
            isLoading: false,
            dataInfoObj: action.data,
        };
    }
    //get status info data
    if (action.type === requestGetStatusInfoDataAction) {
        return {
            ...state,
            isLoading: true,
            statusInfo: undefined,
        };
    }
    if (action.type === receiveGetStatusInfoDataAction) {
        return {
            ...state,
            isLoading: false,
            statusInfo: action.data,
        };
    }
    //get status info data Rk
    if (action.type === requestGetStatusInfoDataRkAction) {
        return {
            ...state,
            isLoading: true,
            statusInfoRk: undefined,
        };
    }
    if (action.type === receiveGetStatusInfoDataRkAction) {
        return {
            ...state,
            isLoading: false,
            statusInfoRk: action.data,
        };
    }
    //update data
    if (action.type === requestUpdateDataAction) {
        return {
            ...state,
            isLoading: true,
            statusUpdate: undefined,
            statusDelete: undefined
        };
    }
    if (action.type === receiveUpdateDataAction) {
        return {
            ...state,
            isLoading: false,
            statusUpdate: action.status,
            data: action.status.successed === true ? Object.assign(action.dataTable) : state.data,
            controlResult: undefined,
        };
    }
    //delete data
    if (action.type === requestDeleteAction) {
        return {
            ...state,
            isLoading: true,
            statusUpdate: undefined,
            statusDelete: undefined
        };
    }
    if (action.type === receiveDeleteAction) {
        if (action.status.successed === true)
            return {
                ...state,
                isLoading: false,
                data: undefined,
                statusDelete: action.status,
            };
        else
            return {
                ...state,
                isLoading: false,
                statusDelete: action.status,
            };
    }
    //delete all tables data
    if (action.type === requestDeleteFormAllTablesAction) {
        return {
            ...state,
            isLoading: true,
            statusUpdate: undefined,
            statusDelete: undefined
        };
    }
    if (action.type === receiveDeleteFormAllTablesAction) {
        if (action.status.successed === true)
            return {
                ...state,
                isLoading: false,
                data: undefined,
                statusDelete: action.status,
            };
        else
            return {
                ...state,
                isLoading: false,
                statusDelete: action.status,
            };
    }
    if (action.type === requestGetReport1RCRZType) {
        return {
            ...state,
            rep1data: undefined,
            rep1AllOrgs: 0,
            isLoading: true
        };
    }
    if (action.type === receiveGetReport1RCRZType) {
        return {
            ...state,
            isLoading: false,
            rep1data: action.rep1data,
            rep1AllOrgs: action.rep1data.allOrgs,
        };
    }
    //update status info data
    if (action.type === requestUpdateStatusInfoDataAction) {
        return {
            ...state,
            isLoading: true,
        };
    }
    if (action.type === receiveUpdateStatusInfoDataAction) {
        let statusInfo = cloneDeep(state.statusInfo);
        statusInfo.rows.filter(f => f.kod === action.data.kod)[0].status.filter(s => s.kf === action.data.kf)[0].status=action.data.status;
        return {
            ...state,
            isLoading: false,
            statusInfo
        };
    }
    //update status info data Rk
    if (action.type === requestUpdateStatusInfoDataRkAction) {
        return {
            ...state,
            isLoading: true
        };
    }
    if (action.type === receiveUpdateStatusInfoDataRkAction) {
        let statusInfoRk = cloneDeep(state.statusInfoRk);
        statusInfoRk.rows.filter(f => f.kod === action.data.kod)[0].status.filter(s => s.kf === action.data.kf)[0].status = action.data.status;
        return {
            ...state,
            isLoading: false,
            statusInfoRk
        };
    }
    //get status info data tables Rk
    if (action.type === requestGetStatusInfoDataTablesRkAction) {
        return {
            ...state,
            isLoading: true,
            statusInfoTablesRk: undefined,
        };
    }
    if (action.type === receiveGetStatusInfoDataTablesRkAction) {
        return {
            ...state,
            isLoading: false,
            statusInfoTablesRk: action.data,
        };
    }
    //update status info data tables Rk
    if (action.type === requestUpdateStatusInfoDataTablesRkAction) {
        return {
            ...state,
            isLoading: true,
        };
    }
    if (action.type === receiveUpdateStatusInfoDataTablesRkAction) {
        let statusInfoTablesRk = cloneDeep(state.statusInfoTablesRk);
        statusInfoTablesRk.rows.filter(f => f.kod === action.data.kod)[0].status.filter(s => s.kf === action.data.kt)[0].status = action.data.status;
        return {
            ...state,
            isLoading: false,
            statusInfoTablesRk
        };
    }
    //get status info data tables Obl
    if (action.type === requestGetStatusInfoDataTablesOblAction) {
        return {
            ...state,
            isLoading: true,
            statusInfoTablesObl: undefined,
        };
    }
    if (action.type === receiveGetStatusInfoDataTablesOblAction) {
        return {
            ...state,
            isLoading: false,
            statusInfoTablesObl: action.data,
        };
    }
    //update status info data tables Obl
    if (action.type === requestUpdateStatusInfoDataTablesOblAction) {
        return {
            ...state,
            isLoading: true,
        };
    }
    if (action.type === receiveUpdateStatusInfoDataTablesOblAction) {
        let statusInfoTablesObl = cloneDeep(state.statusInfoTablesObl);
        statusInfoTablesObl.rows.filter(f => f.kod === action.data.kod)[0].status.filter(s => s.kf === action.data.kt)[0].status = action.data.status;
        return {
            ...state,
            isLoading: false,
            statusInfoTablesObl
        };
    }
    return state;
}
