import { getToken } from './UserInfo';
const requestGetUsersAction = 'REQUEST_USERS';
const receiveGetUsersAction = 'RECEIVE_USERS';
const requestSearchUsersAction = 'REQUEST_SEARCH_USERS';
const receiveSearchUsersAction = 'RECEIVE__SEARCH_USERS';
const requestInsertOrUpdateUser = 'REQUEST_INSERT_OR_UPDATE_USER';
const receiveInsertOrUpdateUser = 'RECEIVE_INSERT_OR_UPDATE_USER';
const requestChangePassUser = 'REQUEST_CHANGE_PASS_USER';
const receiveChangePassUser = 'RECEIVE_CHANGE_PASS_USER';
const requestChangeUserName = 'REQUEST_CHANGE_USER_NAME';
const receiveChangeUserName = 'RECEIVE_CHANGE_USER_NAME';
const ClearUserUpdateResult = 'CLEAR_USER_UPDATE_RESULT';
const requestDeleteUserAction = 'REQUEST_DELETE_USER';
const receiveDeleteUserAction = 'RECEIVE_DELETE_USER';

const initialState = {
        usersResult: undefined,
        userUpdateResult: undefined,
        startPage: 1,
        sizePage: 20,
        searchUserString: undefined,
        errorsChangePass: undefined,
        passUpdateResult: undefined,
        errorsChangeUserName: undefined,
        userNameUpdateResult: undefined,
        isLoading: false,
        userNameNew: undefined,
        deleteUserResult: undefined
};

export const actionCreators = {
    requestSearchUsers: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestSearchUsersAction });
        let json = JSON.stringify(params);
        const url = 'api/ManageUsers/SearchUsers';
        const response = await fetch(url, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        const data = await response.json();
        dispatch({ type: receiveSearchUsersAction, usersResult: data });
    },
    requestSearchUsersChildOrgs: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestSearchUsersAction });
        let json = JSON.stringify(params);
        const url = 'api/ManageUsersChildOrgs/SearchUsers';
        const response = await fetch(url, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        const data = await response.json();
        dispatch({ type: receiveSearchUsersAction, usersResult: data });
    },
    requestGetUsers: (searchUserString, startPage, sizePage) => async (dispatch, getState) => {
        var token =  await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestGetUsersAction });
        const url = `api/ManageUsers/Users?searchUserString=${searchUserString}&startPage=${startPage}&sizePage=${sizePage}`;
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        dispatch({ type: receiveGetUsersAction, usersResult: data });
    },
    requestInsertOrUpdateUser: (user) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestInsertOrUpdateUser });
        let json = JSON.stringify(
            {
                Id:user.id,
                Username: user.username === undefined || user.username === ''? user.email : user.username, 
                Email: user.email,
                Fio: user.fio,
                AccessLevel: user.accessLevel,// 1 – РК; 2 – область; 3-район; 4 - организация
                TypeUser: user.typeUser,
                KodOrg: user.org.kod,
                KodObl: user.obl.kod,
                KodRaion: user.raion.kod,
                Roles: user.roles,
                IsNew: user.isNew,
                Password: user.password
            }
        );
        const url = 'api/ManageUsers/userUpdate';
        const response = await fetch(url, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        const data = await response.json();
        dispatch({ type: receiveInsertOrUpdateUser, updateResult: data, userUpdate: user });
    },
    requestInsertOrUpdateUserChildOrgs: (user) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestInsertOrUpdateUser });
        let json = JSON.stringify(
            {
                Id: user.id,
                Username: user.username === undefined || user.username === '' ? user.email : user.username,
                Email: user.email,
                Fio: user.fio,
                AccessLevel: user.accessLevel,// 1 – РК; 2 – область; 3-район; 4 - организация
                TypeUser: user.typeUser,
                KodOrg: user.org.kod,
                KodObl: user.obl.kod,
                KodRaion: user.raion.kod,
                Roles: user.roles,
                IsNew: user.isNew,
                Password: user.password
            }
        );
        const url = 'api/ManageUsersChildOrgs/userUpdate';
        const response = await fetch(url, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        const data = await response.json();
        dispatch({ type: receiveInsertOrUpdateUser, updateResult: data, userUpdate: user });
    },
    ClearUserUpdateResult: () => ({ type: ClearUserUpdateResult }),
    changePassUser: (inpData) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestChangePassUser });
        const url = `api/ManageUsers/ChangeSecretSlovo?userName=${encodeURIComponent(inpData.username)}&secsl=${encodeURIComponent(inpData.newPassword)}&cursecsl=${encodeURIComponent(inpData.oldPassword)}`;
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }});
        const data = await response.json();
        dispatch({ type: receiveChangePassUser, changePassResult: data });
    },
    changePassUserChildOrgs: (inpData) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestChangePassUser });
        const url = `api/ManageUsersChildOrgs/ChangeSecretSlovo?userName=${encodeURIComponent(inpData.username)}&secsl=${encodeURIComponent(inpData.newPassword)}&cursecsl=${encodeURIComponent(inpData.oldPassword)}`;
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        dispatch({ type: receiveChangePassUser, changePassResult: data });
    },
    changeUserName: (inpData) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestChangeUserName });
        const url = `api/ManageUsers/ChangeUserName?userNameOld=${encodeURIComponent(inpData.userNameOld)}&userNameNew=${encodeURIComponent(inpData.userNameNew)}`;
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }});
        const data = await response.json();
        dispatch({ type: receiveChangeUserName, changeUserNameResult: data, userNameOld: inpData.userNameOld, userNameNew: inpData.userNameNew });
    },
    changeUserNameChildOrgs: (inpData) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestChangeUserName });
        const url = `api/ManageUsersChildOrgs/ChangeUserName?userNameOld=${encodeURIComponent(inpData.userNameOld)}&userNameNew=${encodeURIComponent(inpData.userNameNew)}`;
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        dispatch({ type: receiveChangeUserName, changeUserNameResult: data, userNameOld: inpData.userNameOld, userNameNew: inpData.userNameNew });
    },
    deleteUser: (userId) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestDeleteUserAction });
        const url = `api/ManageUsers/DeleteUser?userId=${userId}`;
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveDeleteUserAction, resultDeleteUser: data});
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    deleteUserChildOrgs: (userId) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestDeleteUserAction });
        const url = `api/ManageUsersChildOrgs/DeleteUser?userId=${userId}`;
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveDeleteUserAction, resultDeleteUser: data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    if (action.type === requestSearchUsersAction) {
        return {
            ...state,
            isLoading: true,
            startPage: action.startPage,
            sizePage: action.sizePage,
            searchUserString: action.searchUserString,
            usersResult: undefined,
            userUpdateResult: state.userUpdateResult
        };
    }
    if (action.type === receiveSearchUsersAction) {
        return {
            ...state,
            isLoading: false,
            usersResult: action.usersResult,
            searchUserString: state.searchUserString,
            sizePage: state.sizePage,
            startPage: state.startPage,
            userUpdateResult: state.userUpdateResult
        };
    }

    if (action.type === requestGetUsersAction) {
        return {
            ...state,
            isLoading:true,
            startPage: action.startPage,
            sizePage: action.sizePage,
            searchUserString: action.searchUserString,
            usersResult: undefined,
            userUpdateResult: state.userUpdateResult
        };
    }
    if (action.type === receiveGetUsersAction) {
            return {
                ...state,
                isLoading: false,
                usersResult: action.usersResult,
                searchUserString: state.searchUserString,
                sizePage: state.sizePage,
                startPage: state.startPage,
                userUpdateResult: state.userUpdateResult
            };
    }
    if (action.type === requestInsertOrUpdateUser) {
        return {
                ...state,
                usersResult: state.usersResult,
                searchUserString: state.searchUserString,
                sizePage: state.sizePage,
                startPage: state.startPage,
                userUpdateResult: undefined
        };
    }
    if (action.type === receiveInsertOrUpdateUser) {
        let usersResult = undefined;
        if (state.usersResult !== undefined && action.updateResult.result && action.userUpdate) {
            var userUpd = { ...action.userUpdate };
            usersResult = {
                allCounts: action.userUpdate.isNew ? state.usersResult.allCounts + 1 : state.usersResult.allCounts,
                users: state.usersResult.users.map(m => m)
            };
            if (action.userUpdate.isNew) {
                userUpd.isNew = false;
                usersResult.users.push(userUpd);
            } else {
                let oldUs;
                if (!Array.prototype.find)
                    oldUs = usersResult.users.filter(u => u.username === userUpd.username)[0];
                else 
                    oldUs = usersResult.users.find(u => u.username === userUpd.username);
                if (oldUs) {
                    let startIndex = usersResult.users.indexOf(oldUs);
                    usersResult.users.splice(startIndex, 1, userUpd);
                }
            }
        }
        return {
            ...state,
            usersResult: usersResult || state.usersResult,
            searchUserString: state.searchUserString,
            sizePage: state.sizePage,
            startPage: state.startPage,
            userUpdateResult: action.updateResult
        };
    }
    if (action.type === ClearUserUpdateResult) {
        return {
            ...state,
            usersResult: state.usersResult,
            searchUserString: state.searchUserString,
            sizePage: state.sizePage,
            startPage: state.startPage,
            userUpdateResult: undefined,
            changePassResult: undefined,
            errorsChangePass: undefined,
            passUpdateResult: undefined,
            errorsChangeUserName: undefined,
            userNameUpdateResult: undefined,
            userNameNew: undefined
        };
    }
    if (action.type === requestChangePassUser) {
        return {
            ...state,
            errorsChangePass: undefined,
            passUpdateResult: undefined
        };
    }
    if (action.type === receiveChangePassUser) {
        return {
            ...state,
            errorsChangePass: action.changePassResult.result === false ? action.changePassResult.error : undefined,
            passUpdateResult: action.changePassResult.result
        };
    }
    if (action.type === requestChangeUserName) {
        return {
            ...state,
            errorsChangeUserName: undefined,
            userNameUpdateResult: undefined,
            userNameNew: undefined
        };
    }
    if (action.type === receiveChangeUserName) {
        //Result
        //console.dir(action.changeUserNameResult);
        //var errorsChangeUserName = action.changeUserNameResult.result === false ? action.changeUserNameResult.error : undefined;
        //console.info(errorsChangeUserName);
        let usersResult = { ...state.usersResult };
        if (action.changeUserNameResult.result === true) {
            let oldUs;
            if (!Array.prototype.find)
                oldUs = usersResult.users.filter(u => u.username === action.userNameOld)[0];
            else
                oldUs = usersResult.users.find(u => u.username === action.userNameOld);
            if (oldUs) {
                let startIndex = usersResult.users.indexOf(oldUs);
                let newUser = { ...oldUs };
                newUser.userName = action.userNameNew;
                usersResult.users.splice(startIndex, 1, newUser);
            }
        }
        return {
            ...state,
            usersResult: usersResult,
            errorsChangeUserName: action.changeUserNameResult.result === false ? action.changeUserNameResult.error : undefined,
            userNameUpdateResult: action.changeUserNameResult.result,
            userNameNew: action.userNameNew
        };
    }
    if (action.type === requestDeleteUserAction) {
        return {
            ...state,
            deleteUserResult: undefined
        };
    }
    if (action.type === receiveDeleteUserAction) {
        //удалить из списка удаленного пользователя
        let usersResult = { ...state.usersResult };
        if (action.resultDeleteUser.result === true) {
            let oldUs;
            if (!Array.prototype.find)
                oldUs = usersResult.users.filter(u => u.id === action.resultDeleteUser.userId)[0];
            else
                oldUs = usersResult.users.find(u => u.id === action.resultDeleteUser.userId);
            if (oldUs) {
                let startIndex = usersResult.users.indexOf(oldUs);
                usersResult.users.splice(startIndex, 1);
            }
        } 
        return {
            ...state,
            usersResult,
            deleteUserResult: action.resultDeleteUser
        };
    }
    return state;
};