import React, { Component, Fragment, useState } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import * as FileSaver from 'file-saver';
import { Row, Col, FormGroup, FormControl, ControlLabel, Form, Button, Table } from 'react-bootstrap';

import { actionCreators as actionCreatorsWork } from '../../store/Work';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';

import { years, getNameLocalize, getCurrentYear } from '../Constants';
import FilterRazOptions from '../common/FilterRazOptions';
import CheckBoxStatus from '../common/CheckBoxStatus';

const StatusFormRk = (props) => {
    useState(() => {
        if (props.nsi.periods.length === 0) props.getNs('periods');
        if (props.nsi.sprraz.length === 0) props.getNs('sprraz');
        if (props.nsi.sprform.length === 0) props.getNs('sprform');
    });
    const [year, setYear] = useState(props.year !== undefined ? props.year : getCurrentYear());
    const [kper, setKper] = useState(4);
    const [kraz, setKraz] = useState(1);
    const showStatusData=() => {
        if (props.work.statusInfoRk === undefined) return '';
        let statusInfoRk = props.work.statusInfoRk;
        return <Fragment>
            <Row>
                <Col xs={12} sm={6}><h5 style={{ textAlign: "center" }}><strong><Translate value='statusForm.title' /></strong></h5></Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}><strong>Период: {statusInfoRk.namePeriod}</strong></Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}><strong>Разрез: {statusInfoRk.nameRaz}</strong></Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}><strong>Дата и время создания: {statusInfoRk.dtRun}</strong></Col>
            </Row>
            {statusInfoRk.rows.length > 0 ? <Fragment><Row>
                <Col xs={12} sm={6}><Button bsSize='small' onClick={printExcel}><Translate value="infoData.printExcel" /></Button></Col>
            </Row>
            </Fragment> : ""}
            <Table bordered>
                <thead>
                    <tr>
                        <th><Translate value='statusForm.tit2' />
                            <span style={{ marginLeft: "30px" }}><span className="label label-default" style={{ paddingTop: "7px" }}><i style={{ fontSize: '11pt' }} className="glyphicon glyphicon-unchecked"></i></span>{' '}<Translate className="text-deafult" value='statusForm.titFormUncheckRk' /></span>
                            <span style={{ marginLeft: "25px" }}><span className="label label-primary" style={{ paddingTop: "7px" }}><i style={{ fontSize: '11pt' }} className="glyphicon glyphicon-check"></i></span>{' '}<Translate className="text-primary" value='statusForm.titFormCheckRk' /></span></th>
                    </tr>
                </thead>
                <tbody>
                    {statusInfoRk.rows.map(r => renderRowStatus(r))}
                </tbody>
            </Table>
        </Fragment>;
    }
    const renderRowStatus= (r) => {
        return <tr key={r.kod}><td><strong>{r.kod}</strong> {getNameLocalize(props.i18n, r)}<br />
            {r.status.map(s => <CheckBoxStatus key={s.kf} korg={r.kod} kf={s.kf} checked={s.status} onChange={onChangeStatusKf} />)}
        </td></tr>
    }
    const onChangeStatusKf=(d) => {
        //console.dir(d);
        props.updateStatusInfoDataRk({ year, kraz, kper, kod: d.korg, kf: d.kf, status: d.status });
    }
    const printExcel = async () => {
        let absoluteUrl = `${document.location.protocol}//${document.location.host}/generate/Excel/InputInfoRk/${year}/${kper}/${kraz}`;
        let fileName = `infRk_${year}_${kper}_${kraz}.xlsx`;
        var token = props.userInfo.user.token;
        const response = await fetch(absoluteUrl, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const blob = await response.blob();
            FileSaver.saveAs(blob, fileName);
            //this.setState({ isLoading: false });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    }
    const onOpenStatus = (e) => {
        e.preventDefault();
        props.getStatusInfoDataRk({
            year: year,
            period: kper,
            raz: kraz,
        });
    }
    if (props.userInfo.user.accessLevel != 1 || props.userInfo.user.roles.filter(r => r === 'UserReadData').length > 0) return <div className="text-danger"><Translate value="noAccess" /></div>;
    return <Fragment>
        <Form onSubmit={onOpenStatus}>
            <Row>
                <Col xs={12} sm={12}><h5 style={{ textAlign: "center" }}><strong><Translate value='statusForm.title' /></strong></h5></Col>
            </Row>
            <Row>
                <Col xs={12} sm={12}>
                    <FormGroup controlId="formYear" className='input-group-sm'>
                        <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='year' />{':'}</ControlLabel></Col>
                        <Col xs={12} sm={10}>
                            <FormControl className='input-sm' componentClass="select" placeholder="select"
                                defaultValue={year} onChange={(e) => setYear(parseInt(e.target.value, 10))}>
                                {years.map(it => <option key={it} value={it}>{it}</option>)}
                            </FormControl></Col>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={12}>
                    <FormGroup controlId="formPer" className='input-group-sm'>
                        <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='period' />{':'}</ControlLabel></Col>
                        <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={`${kper}`}
                            onChange={(e) => setKper(parseInt(e.target.value, 10))} style={{ minWidth: '100%' }}>
                            {props.nsi.periods.map(it => <option key={it.kper} value={it.kper}>{it.nameRu}</option>)}
                        </FormControl></Col>
                    </FormGroup></Col>
            </Row>
            <Row>
                <Col xs={12} sm={12}>
                    <FormGroup controlId="formRaz" className='input-group-sm'>
                        <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='razres' />{':'}</ControlLabel></Col>
                        <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={kraz}
                            onChange={(e) => setKraz(parseInt(e.target.value, 10))} style={{ minWidth: '100%' }}>
                            <FilterRazOptions />
                        </FormControl></Col>
                    </FormGroup></Col>
            </Row>
            {props.work.isLoading === true ? <Row>
                <Col smOffset={2} sm={10}>
                    <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('waitsvod')} width={20} />{' '}<strong><Translate value="waitsvod" /></strong></div>
                </Col>
            </Row> : ''}
            <Button className='btn btn-primary text-center' type="submit" disabled={props.work.isLoading === true}><Translate value="msgButtons.Load"/></Button>
        </Form>
        {showStatusData()}
    </Fragment>;
}
export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionCreatorsWork }, dispatch)
)(StatusFormRk);