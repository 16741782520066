import React, { useEffect, useState, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { Alert, Row, Table, Button, Col, Modal, FormGroup, FormControl, Checkbox, ControlLabel, Form } from 'react-bootstrap';
//import { useTable, useGlobalFilter } from 'react-table'
//import ReactTable from 'react-table';
import { Translate, I18n } from 'react-redux-i18n';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';
import { getNameLocalize } from '../Constants';

const SelectTeModal = (props) => {
    const [nasels, setNasels] = useState([]);
    const [te, setTe] = useState();
    useEffect(() => {
        if (props.te != null) {
            let o = cloneDeep(props.te);
            const lNas = async (raion) => {
                setNasels(await getNasels(raion));
            }
            lNas(o.raion);
            setTe(o);
        } else {
            setTe(null);
        }
    }, [props.te]);

    const handleCloseDlg = (isNotCancel) => {
        if (isNotCancel === false)
            props.handleCloseDlg({ isNotCancel, te: null });
        else /*if (org != undefined)*/ {
            props.handleCloseDlg({ isNotCancel, te });
        }
    };
    const changeFld = (fld, value) => {
        let o = cloneDeep(te);
        o[fld] = value;
        if (fld == "obl") {
            //попробуем заменить область в районе
            let ra = `${value}${o.raion.substring(2)}`;
            if (props.nsi.allraions.filter(r => r.idRaion === ra).length > 0) {
                o.raion = ra;
                //попробуем заменить область в te
                let t = `${value}${o.te.substring(2)}`;
                let getN = async (ra) => {
                    let nas = await getNasels(ra);
                    if (nas.length > 0) {
                        let nn = nas.filter(n => n.te == t)
                        if (nn.length > 0) {
                            o.te = nn[0].te;
                        } else {
                            o.te = nas[0].te;
                        }
                    } 
                    setNasels(nas);
                    setTe(o);
                }
                getN(ra);
                return;
            } else {
                let ra = props.nsi.allraions.filter(r => r.obl === o.obl)[0].idRaion;
                o.raion = ra;
                getNas(ra,o);
                return;
            }
        }else if (fld == "raion") {
            getNas(value,o);
            return;
        } else {//nas te
            setTe(o);
        }
    }
    const getNas = async (kodRaion,o) => {
        let nas = await getNasels(kodRaion);
        o.te = nas[0].te;
        setNasels(nas);
        setTe(o);
    }
    const getNasels = async (kodRaion) => {
        let nls = await props.getNaselRaion(kodRaion);
        if (nls != null && nls.length > 0) {
            return nls;
        } else {
            return [];
        }
    }
    return (<Modal
        show={props.show}
        onHide={() => handleCloseDlg(false)}
        backdrop="static"
        keyboard={false}
        bsSize="large">
        <Modal.Header closeButton>
            <Modal.Title><Translate value="msgButtons.Change" /> <Translate value="obl" /></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {te != null ?
                <Form horizontal>
                    <FormGroup controlId="formOblNew" bsSize="sm">
                        <Col componentClass={ControlLabel} sm={2}><Translate value="obl" /></Col>
                        <Col sm={10}>
                            <FormControl className='input-sm' componentClass="select" placeholder="select" value={te.obl} 
                                onChange={(evt) => changeFld("obl",evt.target.value)} style={{ width: "auto" }} style={{ display: "inline-block", width: "auto", marginRight: "5px" }}>
                                {props.nsi.allobls.map(it => <option key={it.kod} value={it.kod}>{getNameLocalize(props.i18n, it)}</option>)}
                            </FormControl>
                        </Col>
                    </FormGroup>
                    <FormGroup controlId="formRaion" bsSize="sm">
                        <Col componentClass={ControlLabel} sm={2}><Translate value="editOrgs.raion" /></Col>
                        <Col sm={10}>
                            <FormControl className='input-sm' componentClass="select" placeholder="select" value={te.raion}
                                onChange={(evt) => changeFld("raion", evt.target.value)} style={{ width: "auto" }} style={{ display: "inline-block", width: "auto", marginRight: "5px" }}>
                                {props.nsi.allraions.filter(r => r.obl === te.obl).map(it => <option key={it.kod} value={it.idRaion}>{it.idRaion} {getNameLocalize(props.i18n, it)}</option>)}
                            </FormControl>
                        </Col>
                    </FormGroup>
                    <FormGroup controlId="formNasel" bsSize="sm">
                        <Col componentClass={ControlLabel} sm={2}><Translate value="editOrgs.nasel" /></Col>
                        <Col sm={10}>
                            <FormControl className='input-sm' componentClass="select" placeholder="select" value={te.te}
                                onChange={(evt) => changeFld("te", evt.target.value)} style={{ width: "auto" }} style={{ display: "inline-block", width: "auto", marginRight: "5px" }}>
                                {nasels != null ? nasels.map((nas) => <option key={nas.te} value={nas.te}>{nas.te} {getNameLocalize(props.i18n, nas)}</option>) : ''}
                            </FormControl>
                        </Col>
                    </FormGroup>
                </Form>
                :""}
        </Modal.Body>
        <Modal.Footer>
            <Button type="button" variant="secondary" onClick={() => handleCloseDlg(false)}><Translate value="close" /></Button>
            <Button type="button" variant="primary" onClick={() => handleCloseDlg(true)} ><Translate value="accept" /></Button>
        </Modal.Footer>
    </Modal>);
}

export default connect(
    state => state,
    dispatch => bindActionCreators(actionCreatorsNsi, dispatch)
)(SelectTeModal);