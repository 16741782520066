import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as FileSaver from 'file-saver';
import { Translate, I18n } from 'react-redux-i18n';
import { Button, Col, FormGroup, FormControl, ControlLabel, Form, Row, Table } from 'react-bootstrap';

import './ReportFin.css';
//import { ExportCSV } from '../Work/ExportCSV';

import { actionCreators } from '../../store/FinPaidData';
import { years, getCurrentYear } from '../Constants';

class FinPaidData extends Component {
    constructor(props) {
        super(props);
        let nowYear = getCurrentYear() + 1;
        this.state = {
            year: props.year !== undefined ? props.year : nowYear, isLoading: false,
        }
        this.onCreateReport = this.onCreateReport.bind(this);
        this.toExcel = this.toExcel.bind(this);
    }
    onSelectYear = (e) => {
        let year = parseInt(e.target.value, 10);
        this.setState({ year });
    }
    onCreateReport = (e) => {
        e.preventDefault();
        this.props.getReport1(this.state.year);
    }
    renderPrivateOrg = (isPrivateOrg) => {
        if (isPrivateOrg === 1)
            return <strong className="text-primary">(<Translate value="finPrivateOrg" />)</strong>
        else
            return "";
    }
    renderRowsTable = (it, i) => {
        return <tr key={i} >
            <td>{it.oblName}</td>
            <td>{it.teName}</td>
            <td>{it.orgKod} {this.renderPrivateOrg(it.isPrivateOrg)} {it.orgName}</td>
            <td>
                <div><strong>{it.strDog}</strong></div>
                <div><strong>{it.strPlat}</strong></div>
                {it.is50pers === true ? <div><strong className="text-primary"><Translate value="fin50persOrg" /></strong></div> : ""}
                <Table bordered>
                    <thead>
                        <tr><th>1 квартал</th><th>2 квартал</th><th>3 квартал</th><th>4 квартал</th></tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{it.paidQ1 === true ? <strong className="text-success">Да</strong> : <strong className="text-danger">Нет</strong>}</td><td>{it.paidQ2 === true ? <strong className="text-success">Да</strong> : <strong className="text-danger">Нет</strong>}</td>
                            <td>{it.paidQ3 === true ? <strong className="text-success">Да</strong> : <strong className="text-danger">Нет</strong>}</td><td>{it.paidQ4 === true ? <strong className="text-success">Да</strong> : <strong className="text-danger">Нет</strong>}</td>
                        </tr>
                    </tbody>
                </Table>
            </td>
        </tr>
    }
    renderReduceRowsTable = () => {
        let ret = [];
        let last = this.props.finPaidData.rep1data.items[0];//this.props.finPaidData.rep1data.items[this.props.finPaidData.rep1data.items.length - 1];
        this.props.finPaidData.rep1data.items.reduce((last, it, i) => {
            if (last.oblKod !== it.oblKod) {
                try {
                    //найти итог по области
                    let ar_itog = this.props.finPaidData.rep1data.oblsCnts.filter(f => f.kod === last.oblKod);
                    let cnt_pl = this.props.finPaidData.rep1data.items.filter(f => f.oblKod === last.oblKod).length;
                    let cnt_private = this.props.finPaidData.rep1data.items.filter(f => f.oblKod === last.oblKod && f.isPrivateOrg===1).length;
                    let cnt_50pers = this.props.finPaidData.rep1data.items.filter(f => f.oblKod === last.oblKod && f.is50pers === true).length;
                    if (ar_itog.length > 0) {
                        ret.push(<tr key={`itog${last.oblKod}`} >
                            <td><strong>{last.oblName}</strong></td>
                            <td colSpan={3}>Организаций всего: <strong>{ar_itog[0].cnt}</strong> , из них оплатили: <strong>{cnt_pl}</strong>, из них частные: <strong>{cnt_private}</strong>, из них оплатили 50%: <strong>{cnt_50pers}</strong></td>
                        </tr>);
                    }
                } catch (er) {
                    console.error(er);
                }
            }
            ret.push(this.renderRowsTable(it, i));
            if (i === this.props.finPaidData.rep1data.items.length - 1) {
                //поместить итог по последней области
                let ar_itog = this.props.finPaidData.rep1data.oblsCnts.filter(f => f.kod === it.oblKod);
                let cnt_pl = this.props.finPaidData.rep1data.items.filter(f => f.oblKod === it.oblKod).length;
                let cnt_private = this.props.finPaidData.rep1data.items.filter(f => f.oblKod === it.oblKod && f.isPrivateOrg === 1).length;
                let cnt_50pers = this.props.finPaidData.rep1data.items.filter(f => f.oblKod === last.oblKod && f.is50pers === true).length;
                if (ar_itog.length > 0) {
                    ret.push(<tr key={`itog${it.oblKod}`} >
                        <td><strong>{it.oblName}</strong></td>
                        <td colSpan={3}>Организаций всего: <strong>{ar_itog[0].cnt}</strong> , из них оплатили: <strong>{cnt_pl}</strong>, из них частные: <strong>{cnt_private}</strong>, из них оплатили 50%: <strong>{cnt_50pers}</strong></td>
                    </tr>);
                }
            }
            return it;
        }, last);
        return ret;
    }
    renderItog = () => {
        let cnt_private = this.props.finPaidData.rep1data.items.filter(f => f.isPrivateOrg === 1).length;
        let cnt_50pers = this.props.finPaidData.rep1data.items.filter(f => f.is50pers === true).length;
        return <tr key={'itog'}>
            <td colSpan={4}>
                Организаций всего: <strong>{this.props.finPaidData.rep1data.allOrgs}</strong>, из них оплатили: <strong>{this.props.finPaidData.rep1data.items.length}</strong>, из них частные: <strong>{cnt_private}</strong>, из них оплатили 50%: <strong>{cnt_50pers}</strong>
            </td>
        </tr>;
    }
    /*
    excelDataCSV = () => {
        let dataCSV = [];
        let last = this.props.finPaidData.rep1data.items[0];//this.props.finPaidData.rep1data.items[this.props.finPaidData.rep1data.items.length - 1];
        this.props.finPaidData.rep1data.items.reduce((last, s, i) => {
            let row = {};
            if (last.oblKod !== s.oblKod) {
                try {
                    //найти итог по области
                    let ar_itog = this.props.finPaidData.rep1data.oblsCnts.filter(f => f.kod === last.oblKod);
                    let cnt_pl = this.props.finPaidData.rep1data.items.filter(f => f.oblKod === last.oblKod).length;
                    let cnt_private = this.props.finPaidData.rep1data.items.filter(f => f.oblKod === last.oblKod && f.isPrivateOrg === 1).length;
                    if (ar_itog.length > 0) {
                        row["Область"] = last.oblName;
                        row["Район"] = `Организаций всего: ${ar_itog[0].cnt}, из них оплатили: ${cnt_pl}, из них частные: ${cnt_private}`;
                        dataCSV.push(row);
                    }
                } catch (er) {
                    console.error(er);
                }
            }
            row = {};
            row["Область"] = s.oblName;
            row["Район"] = s.teName;
            row["Организация"] = s.orgKod + ' ' + s.orgName;
            row["Договор"] = s.strDog;
            row["Платежка"] = s.strPlat;
            row["1 квартал"] = s.paidQ1 === true ? 'Да' : 'Нет';
            row["2 квартал"] = s.paidQ2 === true ? 'Да' : 'Нет';
            row["3 квартал"] = s.paidQ3 === true ? 'Да' : 'Нет';
            row["4 квартал"] = s.paidQ4 === true ? 'Да' : 'Нет';
            dataCSV.push(row);

            if (i === this.props.finPaidData.rep1data.items.length - 1) {
                //поместить итог по последней области
                let ar_itog = this.props.finPaidData.rep1data.oblsCnts.filter(f => f.kod === s.oblKod);
                let cnt_pl = this.props.finPaidData.rep1data.items.filter(f => f.oblKod === s.oblKod).length;
                let cnt_private = this.props.finPaidData.rep1data.items.filter(f => f.oblKod === s.oblKod && f.isPrivateOrg === 1).length;
                if (ar_itog.length > 0) {
                    row = {};
                    row["Область"] = s.oblName;
                    row["Район"] = `Организаций всего: ${ar_itog[0].cnt}, из них оплатили: ${cnt_pl}, из них частные: ${cnt_private}`;
                    dataCSV.push(row);
                }
            }
            return s;
        }, last);
        let cnt_private = this.props.finPaidData.rep1data.items.filter(f => f.isPrivateOrg === 1).length;
        dataCSV.push({ "Область": `Организаций всего: ${this.props.finPaidData.rep1data.allOrgs}, из них оплатили: ${this.props.finPaidData.rep1data.items.length}, из них частные: ${cnt_private}` });
        return dataCSV;
    }
    */
    printData = (e) => {
        e.preventDefault();
        window.print();
    }
    toExcel = (e) => {
        e.preventDefault();
        const execRep = async () => {
            this.setState({ isLoading: true });
            let absoluteUrl = `${document.location.protocol}//${document.location.host}/FinRepExcel?year=${this.state.year}`;
            let fileName = `medstat-finreport-${this.state.year}.xlsx`;
            var token = this.props.userInfo.user.token;
            const response = await fetch(absoluteUrl, {
                credentials: 'include',
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.ok) { // если HTTP-статус в диапазоне 200-299
                // получаем тело ответа (см. про этот метод ниже)
                const blob = await response.blob();
                FileSaver.saveAs(blob, fileName);
                this.setState({ isLoading: false });
            } else {
                alert("Ошибка HTTP: " + response.status);
            }
            this.setState({ isLoading: false });
        };
        execRep();
    }
    render() {
        var nextYear = getCurrentYear() + 1;
        return (
            <Fragment>
                <h4><Translate value='reportfin' /></h4>
                <Form onSubmit={this.onCreateReport} horizontal>
                    <Row>
                        <Col xs={12} sm={12}>
                            <FormGroup controlId="formYear" className='input-group-sm'>
                                <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='year' />{':'}</ControlLabel></Col>
                                <Col xs={12} sm={10}>
                                    <FormControl className='input-sm' componentClass="select" placeholder="select" style={{ width: 'auto' }}
                                        defaultValue={this.state.year} onChange={this.onSelectYear} disabled={this.props.finPaidData.isLoading === true || this.state.isLoading===true}>
                                        {years.filter(f=>f>2018).map(it => <option key={it} value={it}>{it}</option>)}
                                        {/*<option key={nextYear} value={nextYear}>{nextYear}</option>*/}
                                    </FormControl></Col>
                            </FormGroup></Col>
                    </Row>
                    <Button type="submit" bsStyle="primary" bsSize="large" disabled={this.props.finPaidData.isLoading === true || this.state.isLoading === true}><Translate value="create" /></Button>
                </Form>
                {this.props.finPaidData.isLoading === true || this.state.isLoading === true ? <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('wait')} width={20} />{' '}<strong><Translate value="wait" /></strong></div> : ''}
                {this.props.finPaidData.rep1data != null ? <Fragment>
                    <Button id='bt' type="button" bsStyle="default" onClick={this.printData} disabled={this.state.isLoading === true}>Печать</Button>
                    <Button id='bt' type="button" bsStyle="default" onClick={this.toExcel} disabled={this.state.isLoading === true}><Translate value="exportToExcel" /></Button>
                    {/*<ExportCSV csvData={this.excelDataCSV()} fileName={`medstat-finreport-${this.state.year}`} />*/}
                    <Table striped bordered condensed hover>
                        <thead>
                            <tr>
                                <th colSpan={4}><Translate value='reportfin' /> {this.state.year} год</th>
                            </tr>
                            <tr>
                                <th>Область</th>
                                <th>Район</th>
                                <th>Организация</th>
                                <th>Оплата</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderReduceRowsTable()}
                            {this.renderItog()}
                        </tbody>
                    </Table></Fragment> : ''}
            </Fragment>
        );
    }
}
// {this.props.finPaidData.rep1data.items.map((it, i) => this.renderRowsTable(it, i))}
export default connect(
    state => state,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(FinPaidData);
