import React, { Component } from 'react';
import { ListGroupItem } from 'react-bootstrap';
//import './UserItemList.css';

export class ContrItemlist extends Component {
    constructor(props) {
        super(props);
        this.selectClick = this.selectClick.bind(this);
        //this.deleteOnClick = this.deleteOnClick.bind(this);
    }
    selectClick() {
        this.props.selectControl(this.props.control);
    }
    /*deleteOnClick(event) {
        this.props.deleteUser({ id: this.props.user.id, fio: this.props.user.fio });
    }*/
    render() {
        /*
        return (<li key={this.props.user.id} onClick={this.selectClick}
            className={'list-group-item' + (this.props.selectedUserId === this.props.user.id ? ' list-group-item-success' : '')}>
            <div className='user_fio'>{this.props.user.fio}</div><div className="user_bt_del"><button type='button' className='btn btn-danger btn-sm' onClick={this.deleteOnClick}>
                <span className='glyphicon glyphicon-remove'></span></button></div></li>);
*/
        if (this.props.selectedControl === this.props.control) {
            return (<ListGroupItem key={this.props.control.k1} onClick={this.selectClick} active>
                <strong>{this.props.control.k1}</strong> {this.props.control.k2} </ListGroupItem>);
        } else {
            return (<ListGroupItem key={this.props.control.k1} onClick={this.selectClick}>
                <strong>{this.props.control.k1}</strong> {this.props.control.k2}</ListGroupItem>);
        }
    }
}