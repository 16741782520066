import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Button, Col, FormGroup, FormControl, ControlLabel, Row, Alert, Modal/*, Checkbox, Radio*/ } from 'react-bootstrap';

import { Typeahead } from 'react-bootstrap-typeahead';

import { actionCreators as actionCreatorsGen47 } from '../../store/Gen47';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';

import './gen47OrgParam.css';
class Gen47OrgParam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            kobl: props.userInfo.user.kodObl,
            korg: undefined,
            editOrg: undefined,
            showEditDialog: false,
            searchText:""
        };
        this.typeaheadNomen = null;
        this.typeaheadTorg = null;
        if (props.nsi.allobls.length === 0) props.getNs('allobls');
        if (props.nsi.torg.length === 0) props.getNs('torg');
        if (props.nsi.nomenkl.length === 0) props.getNs('nomenkl');
        this.onSelectKobl = this.onSelectKobl.bind(this);
        this.onChangeNomen = this.onChangeNomen.bind(this);
        this.clearNomen = this.clearNomen.bind(this);
        this.onChangeTorg = this.onChangeTorg.bind(this);
        this.clearTorg = this.clearTorg.bind(this);
        this.btEditClick = this.btEditClick.bind(this);
        this.setKodName = this.setKodName.bind(this);
        this.filtrOrg = this.filtrOrg.bind(this);
    }
    setKodName(searchText) {
        this.setState({ searchText });
    }
    filtrOrg(org) {
        if (this.state.searchText == null || this.state.searchText === "") return true;
        if (this.state.searchText != '' && (org.kod != this.state.searchText && !org.nameRu.toLowerCase().includes(this.state.searchText.toLowerCase()) && !org.nameKz.toLowerCase().includes(this.state.searchText.toLowerCase()))) return false;
        return true;
    }
    componentWillMount() {
        this.props.loadParamOrgsByObl(this.state.kobl);
    }
    onSelectKobl(e) {
        let kobl = e.target.value;
        this.setState({ kobl });
        this.props.loadParamOrgsByObl(kobl);
    }
    onChangeNomen(selected) {
        let editOrg = { ...this.state.editOrg };
        if (selected.length > 0) {
            editOrg.nomenKod = selected[0].kod;
            editOrg.nomenNameRu = selected[0].nameRu;
            editOrg.nomenNameKz = selected[0].nameRu;
        } else {
            editOrg.nomenKod = undefined;
            editOrg.nomenNameRu = undefined;
            editOrg.nomenNameKz = undefined;
            if (this.typeaheadNomen != null)
                this.typeaheadNomen.getInstance().clear();
        }
        this.setState({ editOrg });
    }
    clearNomen() {
        let editOrg = { ...this.state.editOrg };
        editOrg.nomenKod = undefined;
        editOrg.nomenNameRu = undefined;
        editOrg.nomenNameKz = undefined;
        if (this.typeaheadNomen != null)
            this.typeaheadNomen.getInstance().clear();
        this.setState({ editOrg });
    }
    onChangeTorg(selected) {
        let editOrg = { ...this.state.editOrg };
        if (selected.length > 0) {
            editOrg.torgKod = selected[0].kod;
            editOrg.torgNameRu = selected[0].nameRu;
            editOrg.torgNameKz = selected[0].nameRu;
        } else {
            editOrg.torgKod = undefined;
            editOrg.torgNameRu = undefined;
            editOrg.torgNameKz = undefined;
            if (this.typeaheadTorg != null)
                this.typeaheadTorg.getInstance().clear();
        }
        this.setState({ editOrg });
    }
    clearTorg() {
        let editOrg = { ...this.state.editOrg };
        editOrg.torgKod = undefined;
        editOrg.torgNameRu = undefined;
        editOrg.torgNameKz = undefined;
        if (this.typeaheadTorg != null)
            this.typeaheadTorg.getInstance().clear();
        this.setState({ editOrg });
    }

    renderRow(row) {
        return (<div className="rowDivTable" key={`${row.kod}`}>
            <div className="cellDivRowTable" style={{ width: "55%" }}>{row.kod} {row.nameRu}</div>
            <div className="cellDivRowTable" style={{ width: "20%" }}>{row.nomenKod} {row.nomenNameRu}</div>
            <div className="cellDivRowTable" style={{ width: "20%" }}>{row.torgKod} {row.torgNameRu}</div>
            <div className="cellDivRowTable" style={{ width: "5%" }}><Button bsStyle="primary" bsSize="sm" style={{ margin: 5 }} onClick={() => this.btEditClick(row.kod)}>Редактировать</Button></div>
        </div>);
    }
    renderTableOrgs() {
        if (this.props.gen47.orgsParam == null) return '';
        return (<div className="tableDiv">
            <div className="rowHeader">
                <div className="cellHeader" style={{ width: "55%" }}><Translate value='org' /></div>
                <div className="cellHeader" style={{ width: "20%" }}><Translate value='nomenkl' /></div>
                <div className="cellHeader" style={{ width: "20%" }}><Translate value='typeorg' /></div>
                <div className="cellHeader" style={{ width: "5%" }}></div>
            </div>
            {this.props.gen47.orgsParam.filter(this.filtrOrg).map(r => this.renderRow(r))}
        </div>);
    }
    handleCloseEditDialog = (e) => {
        e.preventDefault();
        this.setState({ showEditDialog: false });
    }
    saveData = (e) => {
        e.preventDefault();
        this.setState({ showEditDialog: false });
        this.props.updateOrg(this.state.editOrg);
    }
    btEditClick = (kodorg) => {
        let org = this.props.gen47.orgsParam.filter(o => o.kod === kodorg)[0];
        if (this.typeaheadNomen != null) this.typeaheadNomen.getInstance().clear();
        if (this.typeaheadTorg != null) this.typeaheadTorg.getInstance().clear();
        this.setState({ editOrg: org, showEditDialog: true });
    }
    render() {
        let isReadOnLy = this.props.userInfo.user.roles.filter(r => r === 'UserReadData').length > 0;
        if (isReadOnLy === true)
            return <Translate value="noAccess" />
        else
        return <Fragment>
            <h5 style={{ textAlign: "center" }}><strong><Translate value='gen47Param' /></strong></h5>
            <Row>
                <FormGroup controlId="formObl" bsSize='small'>
                    <Col sm={2} className="text-right"><ControlLabel><Translate value='obl' />{':'}</ControlLabel></Col>
                    <Col sm={4}>
                        {this.props.userInfo.user.accessLevel > 1 ? <strong>{this.state.kobl} {this.props.userInfo.user.nameObl}</strong> :
                            <FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={this.state.kobl}
                                onChange={this.onSelectKobl} style={{ width: "auto" }}>
                                {this.props.nsi.allobls.map(it => <option key={it.kod} value={it.kod}>{it.nameRu}</option>)}
                            </FormControl>}
                    </Col>
                </FormGroup>
            </Row>
            <Row>
                <FormGroup controlId="formObl" bsSize='small'>
                    <Col sm={2} className="text-right"><ControlLabel><Translate value="editOrgs.kodName" /></ControlLabel></Col>
                    <Col sm={4}>
                        <FormControl type="text" value={this.state.kodName} onChange={(evt) => this.setKodName(evt.target.value != null ? evt.target.value.trim() : '')}  />
                    </Col>
                </FormGroup>
            </Row>
            {this.props.isLoading === true ? <Row>
                <Col smOffset={2} sm={10}>
                    <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('wait')} width={20} />{' '}<strong><Translate value="wait" /></strong></div>
                </Col>
            </Row> : ''}
            {this.state.isGen47Complete === true && this.props.gen47.statusGen47 != null && this.props.gen47.statusGen47.error != null ? <Row>
                <Col smOffset={2} sm={10}>
                    <Alert bsStyle="danger">{this.props.gen47.statusGen47.error}</Alert>
                </Col>
            </Row> : ''}
            {this.renderTableOrgs()}
            <Modal show={this.state.showEditDialog} onHide={this.handleCloseEditDialog}>
                <Modal.Header closeButton>
                    <Modal.Title>Изменение параметров организации</Modal.Title>
                </Modal.Header>
                <Modal.Body bsClass='small' style={{ margin: 5 }}>
                    {this.state.editOrg != null ? <Fragment>
                        <Row><Col sm={12}>Организация: <strong>{this.state.editOrg.kod} {this.state.editOrg.nameRu}</strong></Col></Row>
                        <Row>
                            <FormGroup controlId="formNomenkl" bsSize='small'>
                                <Col sm={4} className="text-right"><ControlLabel><Translate value='nomenkl' />{':'}</ControlLabel></Col>
                                <Col sm={8}>
                                    <Typeahead id="formNomenkl"
                                        ref={(ref) => this.typeaheadNomen = ref}
                                        labelKey="nameRu"
                                        options={this.props.nsi.nomenkl}
                                        placeholder="Выберите номенклатуру..."
                                        onChange={this.onChangeNomen} />
                                    <p>{this.state.editOrg.nomenKod} {this.state.editOrg.nomenNameRu}
                                        {this.state.editOrg.nomenKod != null ? <Button bsSize="xs" onClick={this.clearNomen}>Очистить</Button> : ""}
                                    </p>
                                </Col>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup controlId="formTorg" bsSize='small'>
                                <Col sm={4} className="text-right"><ControlLabel><Translate value='typeorg' />{':'}</ControlLabel></Col>
                                <Col sm={8}>
                                    <Typeahead id="formTorg"
                                        ref={(ref) => this.typeaheadTorg = ref}
                                        labelKey="nameRu"
                                        options={this.props.nsi.torg}
                                        placeholder="Выберите тип организации..."
                                        onChange={this.onChangeTorg} />
                                    <p>{this.state.editOrg.torgKod} {this.state.editOrg.torgNameRu}
                                        {this.state.editOrg.torgKod != null ? <Button bsSize="xs" onClick={this.clearTorg}>Очистить</Button> : ""}
                                    </p>
                                </Col>
                            </FormGroup>
                        </Row></Fragment> : ""}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleCloseEditDialog}>Закрыть</Button>
                    <Button bsStyle="primary" onClick={this.saveData}>Сохранить</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>;
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionCreatorsGen47 }, dispatch)
)(Gen47OrgParam);