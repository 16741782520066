import React, { /*useEffect,*/ useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, FormControl, ControlLabel, Row, Col, Button, Modal, ListGroup, FormGroup, ListGroupItem } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { actionCreators } from '../../store/Nsi';
import { getNameLocalize } from '../Constants';

function SelectOrgModal(props) {
    const [] = useState(() => {
        if (props.nsi.allobls.length === 0) {
            props.getNs('allobls');
            return false;
        } else
            return true;
    }, props.nsi.allobls.length > 0);
    const [kodOrg, setKodOrg] = useState("");
    const [nameOrg, setNameOrg] = useState("");
    const [kodObl, setKodObl] = useState("");
    const [org, setOrg] = useState();

    const handleCloseDlg = (isNotCancel) => {
        if (isNotCancel === false)
            props.handleCloseDlg({ isNotCancel, org: null });
        else if (org != undefined) {
            let selorg = props.nsi.orgs.find((it) => it.kobj == org.kod);
            props.handleCloseDlg({ isNotCancel, org: selorg });
        } //else {

        //}
    };
    const handleSelectKodObl = (kod) => {
        setOrg(null);
        setKodObl(kod);
        if (kodOrg !== '' || nameOrg !== '') {
            setOrg(null);
            props.getOrgsByOblKodName({ KodObl: kod, KodOrg: kodOrg, NameOrg: nameOrg });
        }
    }
    const handleSearchOrgsClick = () => {
        if (kodObl !== '' || kodOrg !== '' || nameOrg !== '') {
            setOrg(null);
            props.getOrgsByOblKodName({ KodObl: kodObl, KodOrg: kodOrg, NameOrg: nameOrg });
        }
    }
    const handleSelectOrg = async (org) => {
        //setOrg(org);
        let kod = org.kobj;
        let name = getNameLocalize(props.i18n, org);
        setOrg({ kod, name });
    }

    return <Modal
        show={props.show}
        onHide={() => handleCloseDlg(false)}
        backdrop="static"
        keyboard={false}
        bsSize="large">
        <Modal.Header closeButton>
            <Modal.Title><Translate value="selectOrgDlg.title" /></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <strong className="text-info"><Translate value="selectOrgDlg.comment" /></strong>
            <Form inline>
                <FormGroup controlId="formKod">
                    <ControlLabel><Translate value="kod" /></ControlLabel>{' '}
                    <FormControl type="text" size="4" value={kodOrg} onChange={(evt) => setKodOrg(evt.target.value.trim())} />
                </FormGroup>{' '}
                <FormGroup controlId="formNameOrg">
                    <ControlLabel><Translate value="nameOrgBr" /></ControlLabel>{' '}
                    <FormControl type="text" size="60" value={nameOrg} onChange={(evt) => setNameOrg(evt.target.value.trim())} />
                </FormGroup>{' '}
                <Button variant="outline-primary" size="sm" style={{ marginBottom: '4px' }} onClick={handleSearchOrgsClick}><i className="bi-search"></i>&nbsp;<Translate value="search" /></Button>
            </Form>
            <Row>
                <Col md={4}>
                    <FormControl componentClass="select" size="sm" size={15} id="listObls" onChange={(evt) => handleSelectKodObl(evt.target.value)}>
                        {props.nsi.allobls.map((obl) => <option key={obl.idObl} value={obl.idObl}>{getNameLocalize(props.i18n, obl)}</option>)}
                    </FormControl>
                </Col>
                <Col md={8}>
                    <ListGroup as="ul" id="listOrgs" className="border overflow-auto" style={{ maxHeight: '260px', fontSize: '9pt', overflow: "scroll", WebkitOverflowScrolling: "touch",overflowX:"hidden"}}>
                        {props.nsi.orgs.map((o) => {
                            if (org != null && o.kobj === org.kod)
                                return <ListGroupItem key={o.kobj} value={o.kobj} active onClick={() => handleSelectOrg(o)}>{getNameLocalize(props.i18n, o)}</ListGroupItem>
                            else
                                return <ListGroupItem key={o.kobj} value={o.kobj} onClick={() => handleSelectOrg(o)}>{getNameLocalize(props.i18n, o)}</ListGroupItem>
                        })
                        }
                    </ListGroup>
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => handleCloseDlg(false)}><Translate value="close" /></Button>
            <Button variant="primary" onClick={() => handleCloseDlg(true)} ><Translate value="accept" /></Button>
        </Modal.Footer>
    </Modal>
}

export default connect(
    state => state,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(SelectOrgModal);