import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { getNameLocalize } from '../Constants';

//const filterRaz = (user, kod) => {
//    return user.accessLevel !== 4 || kod === 3 || kod === 4 || kod === 5 || kod === 6 || kod === 12 || kod === 23 || kod === 24 || kod === 25;
//};
const FilterRazOptions = props => (
    <Fragment>
        {props.nsi.sprraz/*.filter(f => filterRaz(props.userInfo.user, f.kod))*/.map(it => <option key={it.kod} value={it.kod}>{getNameLocalize(props.i18n, it)}</option>)}
    </Fragment>
);

export default connect(
    state => state,
)(FilterRazOptions);
