import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Button, ButtonGroup, Table, Modal, Row, Col, Alert } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

import { actionCreators } from '../../store/UserInfo';
import { getNameLocalize } from '../Constants';

import './TableViewPstr.css';
//import ResultControl from './ResultControl';
//import { ExportCSV } from './ExportCSV';
//import { ExportReactCSV } from './ExportReactCSV';

class TableViewPstr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: 'view', dataStore: this.props.storeData, statusUpdate: this.props.statusUpdate, activeTabKey: 'data', showConfirmDelete: false
        };
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ dataStore: nextProps.storeData, statusUpdate: nextProps.statusUpdate });
    }
    saveData = (e) => {
        e.preventDefault();
        this.props.saveDataTable(this.state.dataStore);
        this.setState({ mode: 'view' });
    }
    deleteData = (e) => {
        e.preventDefault();
        this.props.deleteDataTable();
        this.setState({ showConfirmDelete: false });
    }
    setEditMode = (e) => {
        e.preventDefault();
        this.setState({ mode: 'edit', activeTabKey: 'data' });
    }
    cancelData = (e) => {
        e.preventDefault();
        this.setState({ mode: 'view', dataStore: this.cloneRows(this.props.storeData) });
    }
    controlData = (e) => {
        e.preventDefault();
        this.setState({ activeTabKey: 'control' });
        this.props.controlData(/*this.state.dataStore*/);
    }
    printData = (e) => {
        e.preventDefault();
        if (this.state.activeTabKey === 'data') {
            //печать данных формы
            this.props.printData();
        } else {
            //печать результатов контроля
            this.props.printControl();
        }
    }
    cloneRows = (dataStore) => {
        let rows = dataStore.rows.map(r => {
            let values = r.values.map(v => { return { vl: v.vl, originalVl: v.originalVl, kgr: v.kgr, str: v.str }; });
            return { kstr: r.kstr, values: values };
        });
        let dataStoreM = { ...dataStore, rows };
        return dataStoreM;
    }
    changeValue = (e) => {
        if (e.target.value === '-') {
            return;
        }
        let id = e.target.id;
        let vl = e.target.value !== '' ? parseFloat(e.target.value) : NaN;
        let ar = id.split('_');
        let kstr = ar[0];
        let kgr = ar[1];
        let dataStore = this.cloneRows(this.state.dataStore);
        let row;
        let col;
        if (!Array.prototype.find) {
            row = dataStore.rows.filter((r) => r.kstr === kstr)[0];
            col = row.values.filter((c) => c.kgr === kgr && c.str === kstr)[0];
        } else {
            row = dataStore.rows.find((r) => r.kstr === kstr);
            col = row.values.find((c) => c.kgr === kgr && c.str === kstr);
        }
        col.vl = vl;
        this.setState({ dataStore });
    }
    valueChange = (values, id) => {
        let vl = values.floatValue === undefined ? 0.0 : values.floatValue;
        let ar = id.split('_');
        let kstr = ar[0];
        let kgr = ar[1];
        let dataStore = this.cloneRows(this.state.dataStore);
        let row;
        let col;
        if (!Array.prototype.find) {
            row = dataStore.rows.filter((r) => r.kstr === kstr)[0];
            col = row.values.filter((c) => c.kgr === kgr && c.str === kstr)[0];
        } else {
            row = dataStore.rows.find((r) => r.kstr === kstr);
            col = row.values.find((c) => c.kgr === kgr && c.str === kstr);
        }
        col.vl = vl;
        this.setState({ dataStore });
    }
    statusUpdate = () => {
        if (this.props.storeData.statusUpdate !== undefined) {
            if (this.props.storeData.statusUpdate.successed)
                return (<div className="text-info small"><strong><Translate value="dataSaved"/></strong></div>);
            else
                return (<div className="text-danger"><strong><Translate value="error" />{':'}</strong>{this.props.storeData.statusUpdate.error}</div>);
        }
        return ('');
    }
    renderRowTable = (r) => {
        let strs = this.state.dataStore.strs;
        let str = strs.filter((s) => r.kstr === s.kstr)[0];
        let isNotHeader = str.kstr !== '   ' && str.kstr !== '  ' && str.kstr !== ' ';
        return (<div className="rowDiv" key={`${str.kstr}`}>
            {isNotHeader === true ? <div className="cellDivLong clip" data-toggle="tooltip" title={getNameLocalize(this.props.i18n, str)}>{getNameLocalize(this.props.i18n, str)}</div> :
                <div className="cellDivLongH clip" data-toggle="tooltip" title={str.nstr}><strong>{str.nstr}</strong></div>}
            {isNotHeader === true ? <div className="cellDiv">{str.kstr}</div> : <div className="cellDivH">{str.kstr}</div>}
            {this.state.dataStore.grs.map((gr) => {
                let agraf = r.values.filter(f => f.str === str.kstr && f.kgr === gr.kgr);
                if (agraf != null && agraf.length > 0) {
                    let v = agraf[0];
                    let keyinput = `${str.kstr}_${v.kgr}`;
                    return (<div className="cellDiv" key={`${str.kstr}_${v.kgr}`}>{this.state.mode === 'edit' ?
                        <NumberFormat className="inputData" thousandSeparator={' '} onValueChange={(values) => this.valueChange(values, keyinput)}
                            key={keyinput} id={keyinput} value={v.vl} style={{ color: v.vl === v.originalVl ? 'black' : 'blue' }} /> :
                        <NumberFormat className="inputData" thousandSeparator={' '} value={v.vl} displayType={'text'} />}</div>);
                } else
                    return (<div className="cellDivH" key={`${str.kstr}_${gr.kgr}`}></div>);
            })}
        </div>);
    }
    renderObjStr = (param) => {
        var ob = this.state.dataStore.objs.filter(o => o.kod === param.r.kObj)[0];
        if (ob == null) return '';
        return <div className="rowDiv" key={`${param.kstr}_${param.r.kObj}`}>
            <div className="cellDivH">{getNameLocalize(this.props.i18n, ob)}</div>
            {this.state.dataStore.grs.map((gr) => {
                let agraf = param.r.values.filter(f => f.str === param.kstr && f.kgr === gr.kgr);
                if (agraf != null && agraf.length > 0) {
                    let v = agraf[0];
                    let keyinput = `${param.kstr}_${param.r.kObj}_${v.kgr}`;
                    return (<div className="cellDiv" key={`${param.kstr}_${param.r.kObj}_${gr.kgr}`}>
                        {this.state.mode === 'edit' ?
                            <NumberFormat className="inputData" thousandSeparator={' '} onValueChange={(values) => this.valueChange(values, keyinput)}
                                key={keyinput} id={keyinput} value={v.vl} style={{ color: v.vl === v.originalVl ? 'black' : 'blue' }} /> :
                            <NumberFormat className="inputData" thousandSeparator={' '} value={v.vl} displayType={'text'} />}
                    </div>);
                } else {
                    return <div className="cellDiv" key={`${param.kstr}_${param.r.kObj}_${gr.kgr}`}> </div>
                }
            })}
        </div>; 
    }//   <div className="cellDivH" title={param.nstr}>{param.kstr}</div>
    renderItog = (params) => {
        return (<div className="rowDiv" key={`${params.kstr}_itog`}><div className="cellDivH" style={{ textAlign: "left" }}><strong><Translate value='strItog' />: {`${params.no_str == null ? " " : params.no_str + " "}(${params.kstr})`} {params.nstr} - <Translate value="pstrCntObj" cnt={params.cnt_obj} style={{ fontSize: "14px", color:"red" }} /></strong></div>
            {this.state.dataStore.grs.map((gr) => {
                return <div className="cellDiv" key={`${params.kstr}_itog_${gr.kgr}`} style={{ textAlign: "center" }}><strong>{this.renderGrItog({ kstr: params.kstr, kgr: gr.kgr })}</strong></div>;
            })}
        </div>);
    }
    renderGrItog = (params) => {
       /* let objValues = this.state.dataStore.rows.filter(r => r.kstr === params.kstr)[0].objValues;
        const reduce2 = (acc0, colValue) => acc0 + (colValue.kgr === params.kgr ? parseFloat(colValue.vl) : 0.0);
        const reduce1 = (acc, objValue) => acc + objValue.values.reduce(reduce2, 0.0);
        let ret = objValues.reduce(reduce1, 0.0).toFixed(3);
       */
        let row = this.state.dataStore.rows.filter(r => r.kstr === params.kstr)[0]
        let ret = row.itogValues.filter(c => c.kgr === params.kgr);
        if (ret.length === 0) return "";
        return parseFloat(ret[0].vl).toFixed(3);
    }
    renderStrs = (params) => {
        let str = this.state.dataStore.strs.filter(r => r.kstr === params.str)[0];
        var rowsStr = this.state.dataStore.rows.filter(r => r.kstr === params.str);
        return rowsStr.map(r => {
            return <Fragment key={`${r.kObj}`}><div className="rowDiv"><div className="cellDivH" style={{ textAlign: "left" }}><strong><Translate value='str' />: {`${str.nstr == null ? " " : str.nstr + " "}(${str.kstr})`} {params.nstr}</strong></div></div>
                {r.objValues.map((sr) => { return this.renderObjStr({ r: sr, kstr: params.str, nstr: params.nstr }); })}
                {this.renderItog({ kstr: params.str, nstr: params.nstr, no_str: str.nstr, cnt_obj: r.objValues.length })}
            </Fragment>;
        });
    }
    /*
     {this.renderItog({ kstr: params.str, nstr: params.nstr })}
     */
    renderTable = () => {
        return (<div className="containerDiv">
            <div className="rowDivHeader">
                <div className="cellDivHeaderLong"><Translate value="object"/></div>
                {this.state.dataStore.grs.map((g) => {
                    return <div key={g.kgr} className="cellDivHeader" data-toggle="tooltip" title={getNameLocalize(this.props.i18n, g)}>{g.kgr} {getNameLocalize(this.props.i18n, g)}</div>
                })}
            </div>
            {this.state.dataStore.strs.map(s => this.renderStrs({ str: s.kstr, nstr: getNameLocalize(this.props.i18n, s) }))}
        </div>);
    }// <div className="cellDivHeader">Код стр.</div>

    excelDataCSV = () => {
        let dataCSV = [];
        /*for (var i = 0; i < this.state.dataStore.rows.length; i++) {
            let row = {};
            let r = this.state.dataStore.rows[i];
            let s = this.state.dataStore.strs[i];
            let isNotHeader = s.kstr !== '   ' && s.kstr !== '  ' && s.kstr !== ' ';
            row["Показатели"] = s.nameRu;
            row["Код стр."] = s.kstr;
            for (var j in this.state.dataStore.grs) {
                let g = this.state.dataStore.grs[j];
                let gr = g.kgr + ' ' + g.nameRu;
                let agraf = r.values.filter(f => f.str === s.kstr && f.kgr === g.kgr);
                if (agraf != null && agraf.length > 0 && isNotHeader === true && agraf[0].vl != null) {
                    row[gr] = agraf[0].vl;
                } else {
                    row[gr] = '';
                }

            }
            dataCSV.push(row);
        }
        */
        return dataCSV;
    }
    fileName = () => {
        let d = this.state.dataStore;
        return `b_ ${d.raz}_${d.year}_${d.period}_${d.tobj}_${d.kobj}_${d.kf}_${d.kt}`;
    }
    fileNamePstr = () => {
        let d = this.state.dataStore;
        return `s_ ${d.raz}_${d.year}_${d.period}_${d.tobj}_${d.kobj}_${d.kf}_${d.kt}`;
    }
    renderPostr = () => {
        return (<Table striped bordered condensed hover>
            <thead>
                <tr>
                    <th>Строка</th>
                    <th>Колонка</th>
                    <th>Значение</th>
                </tr>
            </thead>
            <tbody>
                {this.state.dataStore.rows.map(r => this.renderRowPostr(r))}
            </tbody>
        </Table>);
    }
    excelDataCSVPstr = () => {
        let dataCSV = [];
        for (var i = 0; i < this.state.dataStore.rows.length; i++) {
            let r = this.state.dataStore.rows[i];
            let s = this.state.dataStore.strs[i];
            let isNotHeader = s.kstr !== '   ' && s.kstr !== '  ' && s.kstr !== ' ';
            for (var j = 0; j < this.state.dataStore.grs.length; j++) {
                let row = {};
                row["Строка"] = j === 0 ? s.nameRu : '';
                let g = this.state.dataStore.grs[j];
                let gr = g.kgr + ' ' + g.nameRu;
                row["Колонка"] = gr;
                let agraf = r.values.filter(f => f.str === s.kstr && f.kgr === g.kgr);
                if (agraf != null && agraf.length > 0 && isNotHeader === true && agraf[0].vl != null) {
                    row["Значение"] = agraf[0].vl;
                } else {
                    row["Значение"] = '';
                }
                dataCSV.push(row);
            }
        }
        return dataCSV;
    }
    renderRowPostr = (r) => {
        let strs = this.state.dataStore.strs;
        let str = strs.filter((s) => r.kstr === s.kstr)[0];
        let isNotHeader = str.kstr !== '   ' && str.kstr !== '  ' && str.kstr !== ' ';
        if (isNotHeader === true) {
            let countCol = r.values.length;
            return r.values.map((v, i) => this.renderColPostr(v, i, str, countCol));
        } else
            return (<tr key={`${str.kstr}`}><th colSpan={3}>{str.kstr} {getNameLocalize(this.props.i18n, str)}</th></tr>)
    }
    renderColPostr = (v, i, str, countCol) => {
        let gr = this.state.dataStore.grs.filter((g) => g.kgr === v.kgr)[0];
        let keyinput = `${str.kstr}_${v.kgr}`;
        return (<tr key={`${str.kstr}_${v.kgr}`}>{i === 0 ? <td rowSpan={countCol}>{str.kstr} {getNameLocalize(this.props.i18n, str)}</td> : undefined}<td>{gr.kgr} {getNameLocalize(this.props.i18n, gr)}</td><td>{this.state.mode === 'edit' ?
            <NumberFormat thousandSeparator={' '} onValueChange={(values) => this.changeValue(values, keyinput)} key={`${str.kstr}_${v.kgr}`} id={`${str.kstr}_${v.kgr}`}
                className="inputData" value={v.vl} style={{ color: v.vl === v.originalVl ? 'black' : 'blue' }} /> :
            <NumberFormat className="inputData" thousandSeparator={' '} value={v.vl} displayType={'text'} />}</td></tr>);
    }

    handleCloseConfirmDelete = (e) => {
        e.preventDefault();
        this.setState({ showConfirmDelete: false });
    }
    ConfirmDelete = (e) => {
        e.preventDefault();
        this.setState({ showConfirmDelete: true });
    }
    render() {
        if (this.state.dataStore == null /*|| this.state.dataStore.rows === undefined*/) {
            return ('');
        }
        //let isAdminData = this.props.user.roles.filter(r => r === 'AdminEditData').length > 0 ? true : false;
        //let isReadData = this.props.user.roles.filter(r => r === 'UserReadData').length > 0 ? true : this.state.dataStore.readOnly;
        return (
            <div>
                {this.statusUpdate()}
                <ButtonGroup bsSize="xsmall">
                    <Button id='bt' type="button" bsStyle="default" disabled={this.state.mode !== 'view'} onClick={this.printData}><Translate value="print" /></Button>
                    <Button bsSize='small' onClick={this.props.printExcel} style={{ marginLeft: "5px", marginRight: "5px" }} disabled={this.props.isLoadingExcel === true}><Translate value="infoData.printExcel" /></Button>
                    {this.props.isLoadingExcel === true ? <strong className="text-success"><img src="../images/loading.gif" alt={I18n.t('waitexcel')} width={20} />&nbsp;<Translate value="waitexcel" /></strong> : ''}
                </ButtonGroup>
                {this.state.statusUpdate != null && this.state.statusUpdate.error != null ? <Row>
                    <Col smOffset={2} sm={10}>
                        <Alert bsStyle="danger">{this.state.statusUpdate.error}</Alert>
                    </Col>
                </Row> : ''}
                {this.props.isPostr === true ? this.renderPostr() : this.renderTable()}
                <Modal show={this.state.showConfirmDelete} onHide={this.handleCloseConfirmDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title><Translate value="deleteDataForm" /></Modal.Title>
                    </Modal.Header>
                    <Modal.Body bsClass='small'>
                        <Row><Col smOffset={4}><strong><Translate value="deleteQuestion" /></strong></Col></Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleCloseConfirmDelete}><Translate value="close" /></Button>
                        <Button bsStyle="danger" onClick={this.deleteData}><Translate value="delete" /></Button>
                    </Modal.Footer>
                </Modal>
            </div>);
    }
}
export default connect(
    state => state,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(TableViewPstr);
//    <ExportCSV csvData={this.excelDataCSV()} fileName={this.fileName()} /> : 
/*
   <ButtonGroup bsSize="xsmall">
                    {this.state.mode === 'view' ? <Fragment>
                        <Button type="button" bsStyle="info" disabled={this.state.mode !== 'view'} onClick={() => this.props.selectParam()}>Выбрать</Button>{' '}
                        {isReadData === false ? <Button type="button" bsStyle="primary" disabled={this.state.mode !== 'view'} onClick={this.setEditMode}>Редактировать</Button> : ''}
                        {' '}</Fragment> : ''}
                    {this.state.mode === 'edit' ? <Fragment>
                        <Button type="button" bsStyle="success" disabled={this.state.mode !== 'edit'} onClick={this.saveData}>Сохранить</Button>{' '}
                        <Button type="button" disabled={this.state.mode !== 'edit'} onClick={this.cancelData}>Отменить</Button></Fragment> : ''}
                </ButtonGroup>
                <ButtonGroup bsSize="xsmall">
                    {isAdminData === true && this.state.mode === 'view' ? <Button id='bt' type="button" bsStyle="danger" disabled={this.state.mode !== 'view'} onClick={this.ConfirmDelete}>Удалить</Button> : ''}
                    {this.state.mode === 'view' ? <Fragment>
                        <Button id='bt' type="button" bsStyle="default" disabled={this.state.mode !== 'view'} onClick={this.printData}>Печать</Button>
                        {this.props.isPostr === true ? <ExportCSV csvData={this.excelDataCSVPstr()} fileName={this.fileNamePstr()} disabled={this.state.mode !== 'view'} /> : <ExportCSV csvData={this.excelDataCSV()} fileName={this.fileName()} disabled={this.state.mode !== 'view'} />}
                    </Fragment> : ''}
                </ButtonGroup>
 */ 