import React, { Fragment, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import * as FileSaver from 'file-saver';
import { Translate, I18n } from 'react-redux-i18n';
import { Table, Button, ButtonGroup, Col, FormGroup, FormControl, ControlLabel, Form, Checkbox, Radio, HelpBlock, Row, Alert, Modal } from 'react-bootstrap';

import TableScrollbar from 'react-table-scrollbar';

import { actionCreators as actionMedstatTemplates } from '../../store/Medstat/Templates';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';

import { years, getCurrentYear, getNameLocalize, getNameSprLocalize, btnsMsgBox } from '../Constants';
import MessageBox from '../common/MessageBox';
import EditFormulExcelModal from '../Modals/EditFormulExcelModal';

const EditTempTablesMonths = (props) => {
    useState(() => {
        if (props.nsi.sprform.length === 0) props.getNs('sprform');
        if (props.nsi.allsprtab.length === 0) props.getNs('allsprtab');
    });
    const [kf, setKf] = useState("56");
    const [ktab, setKtab] = useState("0001");
    const [year, setYear] = useState(() => getCurrentYear());
    const [selectedTemplate, setSelectedTemplate] = useState()
    const onLoadTemplates = (e) => {
        e.preventDefault();
        e.target.value = null;
        let inputRu = document.getElementById('fileTemplateMedstat');
        let inputKz = document.getElementById('fileTemplateMedstatKz');
        if(inputRu!=null)inputRu.value = null;
        if(inputKz!= null)inputKz.value = null;
        const fetchData = async () => {
            let data = await props.LoadTablesMonthsTemplates(year, kf, ktab);
            //let form = props.nsi.sprform.filter(f => f.kf === kf)[0];
            setSelectedTemplate(data/*{ form, ...data }*/);
        }
        fetchData();
    }
    const uploadExcel = async (lang) => {
        let input = document.getElementById(lang == "ru" ? 'fileTemplateMedstat' : 'fileTemplateMedstatKz');
        if (input.files.length === 0) {
            alert("Выберите файл");
            return;
        }
        let file = input.files[0];
        let formData = new FormData();
        formData.append("year", year);
        formData.append("kf", kf);
        formData.append("ktab", ktab);
        formData.append("lang", lang);
        formData.append("fileExcel", file);
        var ret = await props.UploadTemplateTable(formData, year, kf, ktab, lang);
        if (ret!=null && ret.successed === true) {
            let selTmp = cloneDeep(selectedTemplate);
            if (lang === "ru")
                selTmp.fileName = file.name;
            else
                selTmp.fileNameKz = file.name;
            setSelectedTemplate(selTmp);
        }
    }
    const uploadExcelRu = (e) => {
        e.preventDefault();
        uploadExcel("ru");
    }
    const uploadExcelKz = (e) => {
        e.preventDefault();
        uploadExcel("kk");
    }
    const downloadExcel = async (lang) => {
        const blob = await props.DownloadTemplateTable(year, kf, ktab, lang);
        FileSaver.saveAs(blob, lang = "ru" ? selectedTemplate.fileName : selectedTemplate.fileNameKz);
    }
    const downloadExcelRu = (e) => {
        e.preventDefault();
        downloadExcel("ru");
    }
    const downloadExcelKz = (e) => {
        e.preventDefault();
        downloadExcel("kk");
    }
    const ChangeFld = (fld,vl) => {
        const change = async () => {
            var ret = await props.ChangeFld(year, kf, ktab, fld, vl);
            if (ret != null && ret.successed === true) {
                let ob = cloneDeep(selectedTemplate);
                ob[fld] = vl;
                setSelectedTemplate(ob);
            }
        }
        change();
    }
    const btAddFormul = (e) => {
        e.preventDefault();
        setCell("");
        setFormul("");
        setIsNew(true);
        setShowModalEditFormul(true);
    }
    const btEditFormul = (e, i) => {
        e.preventDefault();
        setCell(selectedTemplate.sprTableImplFormuls[i].exclCol);
        setFormul(selectedTemplate.sprTableImplFormuls[i].exclFormul);
        setIsNew(false);
        setShowModalEditFormul(true);
    }
    const [showMsg, setShowMsg] = useState(false);
    const [delIndex, setDelIndex] = useState();
    const btRemoveFormul = (e, i) => {
        e.preventDefault();
        setDelIndex(i);
        setShowMsg(true);
    }
    const messageAction = (act) => {
        switch (act) {
            case btnsMsgBox.Yes:
                //delete
                const deleteFormul = async () => {
                    var ret = await props.deleteFormulExcel(year, kf, ktab, selectedTemplate.sprTableImplFormuls[delIndex].exclCol);
                    if (ret != null && ret.successed === true) {
                        let formls = cloneDeep(selectedTemplate);
                        formls.sprTableImplFormuls.splice(delIndex, 1);
                        setSelectedTemplate(formls);
                    }
                }
                deleteFormul();
                break;
            default:
                break;
        }
        setShowMsg(false);
    }

    const [showModalEditFormul, setShowModalEditFormul] = useState(false);
    const [cell, setCell] = useState("");
    const [formul, setFormul] = useState("");
    const [isNew, setIsNew] = useState(true);

    const handleHideEditFormul = () => setShowModalEditFormul(false);

    const isExistsCell = (c) => {
        if (selectedTemplate.sprTableImplFormuls.filter(f => f.exclCol === c).length > 0)
            return true;
        else
            return false;
    }
    const setFormulExcel = (c, f, n) => {
        setShowModalEditFormul(false)
        const changeFormul = async () => {
            //save and change
            var ret = await props.changeFormulExcel(year, kf, ktab, c, f, n);
            if (ret != null && ret.successed === true) {
                let ob = cloneDeep(selectedTemplate);
                if (n == true) {
                    ob.sprTableImplFormuls.push({ exclCol: c, exclFormul: f });
                } else {
                    ob.sprTableImplFormuls.filter(f => f.exclCol === c)[0].exclFormul = f;
                }
                setSelectedTemplate(ob);
                setCell("");
                setFormul("");
                setIsNew(true);
            }
        }
        changeFormul();
    }

    let ac = props.userInfo.user.accessLevel;
    if (ac != 1 && props.userInfo.user.roles.filter(r => r === 'AdminEditData').length === 0) return <div className="text-danger"><Translate value="noAccess" /></div>;
    return <Fragment>
        <Form onSubmit={onLoadTemplates} horizontal>
        <h5 style={{ textAlign: "center" }}><strong><Translate value='templateTableExcel.title' /></strong></h5>
        <FormGroup controlId="formYear" bsSize='small'>
            <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='year' />{':'}</ControlLabel></Col>
            <Col xs={12} sm={10}>
                <FormControl className='input-sm' componentClass="select" placeholder="select"
                    defaultValue={year} onChange={(e) => setYear(parseInt(e.target.value, 10))} disabled={props.medstatTemplates.isLoading === true}>
                    {years.filter(f => f > 2016).map(it => <option key={it} value={it}>{it}</option>)}
                </FormControl></Col>
        </FormGroup>
        <FormGroup controlId="formKf" bsSize='small'>
            <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='forma' />{':'}</ControlLabel></Col>
            <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={kf}
                onChange={(e) => setKf(e.target.value)} style={{ minWidth: '100%' }} disabled={props.medstatTemplates.isLoading === true}>
                {props.nsi.sprform.filter(f => f.monthWithInc === 1 && f.kf != "70" && f.kf != "71" && f.kf != "72" && f.kf != "73" && f.kf != "74").map(it => <option key={it.kf} value={it.kf}>{it.kf} {it.nameRu}</option>)}
            </FormControl></Col>
        </FormGroup>
        <FormGroup controlId="formKtab" bsSize='small'>
            <Col sm={2} componentClass={ControlLabel}><Translate value='table' />{':'}</Col>
            <Col sm={10} bsSize='small'>
                <FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={ktab} disabled={props.medstatTemplates.isLoading}
                    onChange={(e) => setKtab(e.target.value)} bsSize='small' style={{ width: "auto" }}>
                    {props.nsi.allsprtab.filter(f => f.kf === kf).map(it => <option key={it.ktab} value={it.ktab}>{it.ktab} {getNameLocalize(props.i18n, it)}</option>)}
                </FormControl>
            </Col>
        </FormGroup>
        <FormGroup bsSize='small'>
            <Col smOffset={2} sm={10}>
                <Button type="submit" bsStyle="primary" bsSize="large" disabled={props.medstatTemplates.isLoading === true}><Translate value="templateExcel.load" /></Button>
            </Col>
        </FormGroup>
    </Form>
        {selectedTemplate != null ? <div style={{ border: "1px solid lightgray", padding: "5px", margin: "5px" }}>
            <Row><Col sm={6} smOffset={4}><strong><Translate value='forma' />{':'}</strong>{selectedTemplate.kf} {getNameSprLocalize(props.i18n, props.nsi.sprform, selectedTemplate.kf, "kf")}</Col></Row>
            <Row><Col sm={6} smOffset={4}><strong><Translate value='table' />{':'}</strong>{selectedTemplate.ktab} {getNameSprLocalize(props.i18n, props.nsi.allsprtab.filter(f => f.kf === selectedTemplate.kf), selectedTemplate.ktab, "ktab")}</Col></Row>
            <Row><Col sm={6}>
                <FormGroup controlId="fileTemplateMedstat" className='input-group-sm'>
                    <Col xs={12} sm={5} className="text-right"><ControlLabel>Шаблон Excel(рус){':'}</ControlLabel></Col>
                    <Col xs={12} sm={7}>
                        <FormControl disabled={props.medstatTemplates.isLoading === true}
                            type="file"
                            label="File" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            help="Выберите щаблон Excel" /><Button onClick={uploadExcelRu}>Загрузить шаблон на сервер</Button>
                        {selectedTemplate != null && selectedTemplate.fileName != null ? <Button onClick={downloadExcelRu}>Скачать шаблон с сервера {selectedTemplate.fileName}</Button> : ''}
                    </Col>
                </FormGroup></Col>
                <Col sm={6}>
                    <FormGroup controlId="fileTemplateMedstatKz" className='input-group-sm'>
                        <Col xs={12} sm={5} className="text-right"><ControlLabel>Шаблон Excel(каз){':'}</ControlLabel></Col>
                        <Col xs={12} sm={7}>
                            <FormControl disabled={props.medstatTemplates.isLoading === true}
                                type="file"
                                label="File" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                help="Выберите щаблон Excel" /><Button onClick={uploadExcelKz}>Загрузить шаблон на сервер</Button>
                            {selectedTemplate != null && selectedTemplate.fileNameKz != null ? <Button onClick={downloadExcelKz}>Скачать шаблон с сервера {selectedTemplate.fileNameKz}</Button> : ''}
                        </Col>
                    </FormGroup></Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <FormGroup controlId="excel-range-sheet" className='input-group-sm'>
                        <Col sm={9} className="text-right"><ControlLabel>Наименование Листа Excel{':'}</ControlLabel></Col>
                        <Col sm={3}><FormControl
                            value={selectedTemplate.excelSheetName}
                            onChange={(e) => ChangeFld("excelSheetName", e.target.value)}
                        /></Col>
                    </FormGroup>
                </Col>
                <Col sm={4}>
                    <FormGroup controlId="excel-range-start" className='input-group-sm'>
                        <Col sm={9} className="text-right"><ControlLabel>Ячейка Excel старта данных{':'}</ControlLabel></Col>
                        <Col sm={3}><FormControl
                            value={selectedTemplate.excelRangeStartData}
                            onChange={(e) => ChangeFld("excelRangeStartData", e.target.value)}
                        /></Col>
                    </FormGroup>
                </Col>
                <Col sm={4}>
                    <FormGroup controlId="excel-range-raz" className='input-group-sm'>
                        <Col sm={9} className="text-right"><ControlLabel>Ячейка Excel разреза{':'}</ControlLabel></Col>
                        <Col sm={3}><FormControl
                            value={selectedTemplate.excelRangeRaz}
                            onChange={(e) => ChangeFld("excelRangeRaz", e.target.value)}
                        /></Col>
                    </FormGroup>
                </Col>
               
            </Row>
            <Row>
                <Col sm={4}>
                    <FormGroup controlId="excel-range-per" className='input-group-sm'>
                        <Col sm={9} className="text-right"><ControlLabel>Ячейка Excel периода{':'}</ControlLabel></Col>
                        <Col sm={3}><FormControl
                            value={selectedTemplate.excelRangePeriod}
                            onChange={(e) => ChangeFld("excelRangePeriod", e.target.value)}
                        /></Col>
                    </FormGroup>
                </Col>
                <Col sm={4}>
                    <FormGroup controlId="excel-range-form" className='input-group-sm'>
                        <Col sm={9} className="text-right"><ControlLabel>Ячейка Excel наименования формы{':'}</ControlLabel></Col>
                        <Col sm={3}><FormControl
                            value={selectedTemplate.excelRangeForm}
                            onChange={(e) => ChangeFld("excelRangeForm", e.target.value)}
                        /></Col>
                    </FormGroup>
                </Col>
                <Col sm={4}>
                    <FormGroup controlId="excel-range-table" className='input-group-sm'>
                        <Col sm={9} className="text-right"><ControlLabel>Ячейка Excel наименования таблицы{':'}</ControlLabel></Col>
                        <Col sm={3}><FormControl
                            value={selectedTemplate.excelRangeTable}
                            onChange={(e) => ChangeFld("excelRangeTable", e.target.value)}
                        /></Col>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <FormGroup controlId="excel-range-obj" className='input-group-sm'>
                        <Col sm={9} className="text-right"><ControlLabel>Ячейка Excel наименования обьекта{':'}</ControlLabel></Col>
                        <Col sm={3}><FormControl
                            value={selectedTemplate.excelRangeObj}
                            onChange={(e) => ChangeFld("excelRangeObj", e.target.value)}
                        /></Col>
                    </FormGroup>
                </Col>
            </Row>
            <Row><Col sm={6} smOffset={4}><strong>Формулы Excel</strong></Col></Row>
            <Button bsSize="small" className='btn btn-primary text-center' type="button" onClick={btAddFormul}>Добавить формулу</Button>
            <TableScrollbar height="250px">
                <Table striped bordered condensed hover>
                    <tbody>
                        {selectedTemplate.sprTableImplFormuls.map((r,i) => <tr key={r.exclCol} >
                            <td>{r.exclCol}</td>
                            <td>{r.exclFormul}</td>
                            <td><ButtonGroup>
                                <Button bsSize="small" className='btn btn-success text-center' type="button" onClick={(e)=> btEditFormul(e, i)}>Редактировать</Button>
                                <Button bsSize="small" className='btn btn-danger text-center' type="button" onClick={(e) => btRemoveFormul(e, i)}>Удалить</Button>
                            </ButtonGroup></td>
                        </tr>)}
                    </tbody>
                </Table>
            </TableScrollbar>
            <EditFormulExcelModal showModal={showModalEditFormul} handleHide={handleHideEditFormul}
                cell={cell} formul={formul} isNew={isNew} isExistsCell={isExistsCell} setFormulExcel={setFormulExcel} />
        </div> : ""}
        <MessageBox show={showMsg} titleMsg={I18n.t("templateTableExcel.del")}
            message={I18n.t("templateTableExcel.delFormlExcel")} buttons={btnsMsgBox.Yes | btnsMsgBox.Cancel}
            close={(action) => messageAction(action)} />
    </Fragment>
}
export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionMedstatTemplates }, dispatch)
)(EditTempTablesMonths);
