import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormGroup, FormControl, ControlLabel, Row, Col, Checkbox } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';

//import SelectOrgModal from '../Modals/SelectOrgModal';
import { Typeahead } from 'react-bootstrap-typeahead';

import { actionCreators } from '../../store/Nsi';

import { getNameLocalize } from '../Constants';

class SelectObject extends Component {
    constructor(props) {
        super(props);
        let kobl, kraion, korg;
        let accessLevel = props.userInfo.user.accessLevel;
        //var self = this;
        if (props.obl == null) {
            kobl = props.userInfo.user.kodObl;
            props.setObl({ kobl, nameRu: props.userInfo.user.nameObl, nameKz: props.userInfo.user.nameOblKz });
            if (props.userInfo.user.nomenklKod === "008" || props.userInfo.user.nomenklKod === "049" || props.userInfo.user.nomenklKod === "043") {
                this.props.getNs('orgs', [{ name: "obl", value: kobl }, { name: "parent_kod", value: props.userInfo.user.kodOrg }]);
            } else {
                this.props.getNs('orgs', [{ name: "obl", value: kobl }]);
            }
        } else {
            kobl = props.obl.kobl;
        }
        if (props.raion == null) {
            kraion = props.userInfo.user.kodRaion.substring(0, 4);
            props.setRaion({ kraion, nameRu: props.userInfo.user.nameRaion, nameKz: props.userInfo.user.nameRaionKz });
        } else {
            kraion = props.raion.kraion;
        }
        let org = null;
        if (props.isNotOrg !== true) {
            if (props.org == null) {
                korg = props.userInfo.user.kodOrg;
                org = { korg, nameRu: props.userInfo.user.nameOrg, nameKz: props.userInfo.user.nameOrgKz };
                props.setOrg(org);
            } else {
                korg = props.org.korg;
            }
        }
        /*
         tobj={this.state.tobj}
         obl={this.state.obl}
         raion={this.state.raion}
         org={this.state.org}
         */
        let tobj = props.tobj == null ? props.userInfo.user.accessLevel : props.tobj;
        this.state = {
            kobl,
            kraion,
            korg,
            accessLevel,
            selectOrg: org,
            tobj: tobj, // тип обьекта 1 – РК; 2 – область; 3 - район; 4 - организация (доступ зависит от пользователя)
            isAllRaions: false
        }

        this.typeahead = null;
        this.onSelectTobj = this.onSelectTobj.bind(this);
        this.onSelectKobl = this.onSelectKobl.bind(this);
        this.onSelectKraion = this.onSelectKraion.bind(this);
        this.filterObl = this.filterObl.bind(this);
        this.onChangeOrg = this.onChangeOrg.bind(this);
        this.onCheckIsAllRaions = this.onCheckIsAllRaions.bind(this);
        this.filterRaion = this.filterRaion.bind(this);
        if (props.nsi.allobls.length === 0) {
            props.getNs('allobls');
        }

        if (props.nsi.allraions.length === 0) {
            props.getNs('allraions');
            /*.finally(() => {
                    self.setState({
                        kobl: props.userInfo.user.kodObl,
                        kraion: props.userInfo.user.kodRaion.substring(0, 4),
                        korg: props.userInfo.user.kodOrg
                    });
                });*/
        }
    }
    /*
       componentWillMount() {
           // This method runs when the component is first added to the page
          
   
       }
   */
    componentWillReceiveProps(nextProps) {
        // This method runs when incoming props (e.g., route params) change
        if (this.typeahead != null)
            this.typeahead.getInstance().clear();
    }
    filterRaion(rai, year) {
        if (year == null) return true;
        let ye = typeof year === 'string' ? parseInt(year, 10) : year;
        let dtBeg = rai.dtBeg == null ? null : new Date(rai.dtBeg);
        if (dtBeg != null && dtBeg.getFullYear() > ye) return false;
        let dtEnd = rai.dtEnd == null ? null : new Date(rai.dtEnd);
        if (dtEnd != null && ye >= dtEnd.getFullYear()) return false;
        return true;
    }
    onSelectTobj(e) {
        let tobj = parseInt(e.target.value, 10);
        if (tobj != 4)
            this.setState({ tobj, isAllRaions: false });
        else
            this.setState({ tobj });
        this.props.setTobj(tobj);
    }
    onSelectKobl(e) {
        let kobl = e.target.value;
        let first_raion = this.props.nsi.allraions.filter(r => r.obl === kobl);
        this.setState({
            kobl,
            kraion: first_raion === undefined || first_raion.length === 0 ? undefined : first_raion[0].idRaion,
            korg: undefined
        });
        if (first_raion != null || first_raion.length > 0) {
            this.props.setRaion({ kraion: first_raion[0].idRaion, nameRu: first_raion[0].nameRu });
        }
        let nameRu = this.props.nsi.allobls.filter(r => r.kod === kobl)[0].nameRu;
        this.props.setObl({ kobl, nameRu });
        if (this.state.tobj > 3) {
            this.typeahead.getInstance().clear();
            //загрузить организации
            if (this.props.userInfo.user.nomenklKod === "008" || this.props.userInfo.user.nomenklKod === "049" || this.props.userInfo.user.nomenklKod === "043") {
                this.props.getNs('orgs', [{ name: "obl", value: kobl }, { name: "parent_kod", value: this.props.userInfo.user.kodOrg }]);
            } else {
                this.props.getNs('orgs', [{ name: "obl", value: kobl }]);
            }
        }
    }
    onSelectKraion(e) {
        let kraion = e.target.value;
        this.setState({
            kraion,
            korg: undefined
        });
        if (this.state.tobj > 3) {
            this.typeahead.getInstance().clear();
        }
        let nameRu = this.props.nsi.allraions.filter(r => r.idRaion === kraion)[0].nameRu;
        this.props.setRaion({ kraion, nameRu });
    }
    onChangeOrg(selected) {
        if (selected.length > 0) {
            let korg = selected[0].kobj;
            let selectOrg = { korg, nameRu: selected[0].nameRu }
            let nameRu = this.props.nsi.allraions.filter(r => r.idRaion === selected[0].raion)[0].nameRu;
            this.setState({ korg, selectOrg, kraion: selected[0].raion });
            this.props.setRaion({ kraion: selected[0].raion, nameRu });
            this.props.setOrg(selectOrg);
            return;
        }
        this.setState({ korg: undefined, selectOrg: undefined });
        this.props.setOrg(undefined);
    }
    filterObl(f) {
        if (this.props.year == null) return true;
        let ye = typeof this.props.year === 'string' ? parseInt(this.props.year, 10) : this.props.year;
        let dtBeg = f.dtBeg == null ? null : new Date(f.dtBeg);
        if (dtBeg != null && dtBeg.getFullYear() > ye) return false;
        let dtEnd = f.dtEnd == null ? null : new Date(f.dtEnd);
        if (dtEnd != null && ye >= dtEnd.getFullYear()) return false;
        return true;
    }
    showSelectObl() {
        let cssDispl = this.state.tobj > 1 ? "block" : "none";
        return <Row style={{ display: cssDispl }}>
            <Col xs={12} sm={12}>
                <FormGroup bsSize='small'>
                    <Col sm={2} className="text-right"><ControlLabel><Translate value='obl' />{':'}</ControlLabel></Col>
                    <Col xs={12} sm={10}>
                        {this.state.accessLevel > 1 ? <div>{this.state.kobl} {this.props.i18n.locale[0] === 'r' ? this.props.userInfo.user.nameObl : this.props.userInfo.user.nameOblKz}</div> :
                            <FormControl className='input-sm' componentClass="select" placeholder="select" value={this.state.kobl}
                                onChange={this.onSelectKobl} style={{ width: "auto" }}>
                                {this.props.nsi.allobls.filter(f => this.filterObl(f)).map(it => <option key={it.kod} value={it.kod}>{getNameLocalize(this.props.i18n, it)}</option>)}
                            </FormControl>}
                    </Col>
                </FormGroup></Col>
        </Row>;
    }
    onCheckIsAllRaions(e) {
        this.setState({ isAllRaions: e.target.checked });
    }
    //showSelectRaions() {
    //    let cssDispl = this.state.tobj > 2 ? "block" : "none";
    //    return <Row style={{ display: cssDispl }}>
    //        <Col xs={12} sm={12}>
    //            <Col sm={2} className="text-right"><ControlLabel><Translate value='raion' />{':'}</ControlLabel></Col>
    //            <Col sm={8}>
    //                <FormGroup controlId="formRaion" bsSize='small'>
    //                    {this.state.accessLevel > 2 ? <div>{this.state.kraion} {this.props.i18n.locale[0] === 'r' ? this.props.userInfo.user.nameRaion : this.props.userInfo.user.nameRaionKz}</div> :
    //                        <FormControl className='input-sm' componentClass="select" placeholder="select" value={this.state.kraion} disabled={this.state.isAllRaions === true}
    //                            onChange={this.onSelectKraion} style={{ width: "auto" }}>
    //                            {this.props.nsi.allraions.filter(r => r.obl === this.state.kobl).map(it => <option key={it.kod} value={it.idRaion}>{getNameLocalize(this.props.i18n, it)}</option>)}
    //                        </FormControl>}
    //                </FormGroup>
    //                {this.state.tobj === 4 ? <FormGroup controlId="formIsAllRaion" bsSize='small'>
    //                    <Checkbox onChange={this.onCheckIsAllRaions} checked={this.state.isAllRaions}><Translate value="allRaions" /></Checkbox>
    //                </FormGroup>
    //                    : ""}
    //            </Col>
    //        </Col>
    //    </Row>;
    //}
    showSelectRaions() {
        let cssDispl = this.state.tobj > 2 ? "block" : "none";
        return <Row style={{ display: cssDispl }}>
            <Col xs={12} sm={12}>
                <FormGroup bsSize='small'>
                <Col sm={2} componentClass={ControlLabel} className="text-right"><Translate value='raion' />{':'}</Col>
                <Col sm={8} bsSize='small'>
                    {this.state.accessLevel > 2 ? <span>{this.state.kraion} {this.props.i18n.locale[0] === 'r' ? this.props.userInfo.user.nameRaion : this.props.userInfo.user.nameRaionKz}</span> :
                        <FormControl className='input-sm' componentClass="select" placeholder="select" value={this.state.kraion} disabled={this.state.isAllRaions === true}
                                onChange={this.onSelectKraion} style={{ width: "auto" }} style={{ display: "inline-block", width: "auto", marginRight: "5px" }}>
                                {this.props.nsi.allraions.filter(r => r.obl === this.state.kobl && this.filterRaion(r, this.props.year)).map(it => <option key={it.kod} value={it.idRaion}>{getNameLocalize(this.props.i18n, it)}</option>)}
                        </FormControl>}
                    {this.state.tobj === 4 && this.state.accessLevel < 3 ? <Checkbox onChange={this.onCheckIsAllRaions} checked={this.state.isAllRaions} inline><Translate value="allRaions" /></Checkbox>
                            : ""}
                    </Col>
                </FormGroup></Col>
        </Row>;
    }
    showSelectOrgs() {
        let cssDispl = (this.state.tobj > 3 || this.state.accessLevel > 3) ? "block" : "none";

        return <Row style={{ display: cssDispl }}>
            <Col xs={12} sm={12}>
                <FormGroup bsSize='small'>
                    <Col sm={2} componentClass={ControlLabel} className="text-right"><Translate value='org' />{':'}</Col>
                    <Col sm={10}>
                        {this.state.accessLevel > 3 ? <div>{this.state.korg} {this.props.i18n.locale[0] === 'r' ? this.props.userInfo.user.nameOrg : this.props.userInfo.user.nameOrgKz}</div> :
                            <Fragment><Typeahead id="formOrgs"
                                ref={(ref) => this.typeahead = ref}
                                labelKey={this.props.i18n.locale[0] === 'r' ? "nameRu" : "nameKz"}
                                options={this.props.userInfo.user.nomenklKod === "043" || this.state.isAllRaions === true ? this.props.nsi.orgs : this.props.nsi.orgs.filter(o => o.raion === this.state.kraion)}
                                placeholder={`${I18n.t("selectOrg")}...`}
                                onChange={this.onChangeOrg} /><p>{this.props.org == null ? "" : this.props.org.nameRu}</p></Fragment>
                        }
                    </Col>
                </FormGroup></Col>
        </Row>;
    }
    render() {
        let isOrg = this.props.isNotOrg === true ? false : true;
        return (
            <Fragment>
                <Row>
                    <Col xs={12} sm={12}>
                        <FormGroup bsSize='small'>
                            <Col sm={2} className="text-right"><ControlLabel><Translate value='obj' />{':'}</ControlLabel></Col>
                            <Col sm={10}>
                                {this.props.userInfo.user.accessLevel != 4 && this.props.hideTorg != true ?
                                    <FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={this.state.tobj}
                                        onChange={this.onSelectTobj} style={{ width: "auto" }}>
                                        {this.props.userInfo.user.accessLevel === 1 ? <option value="1">РК</option> : ''}
                                        {this.props.userInfo.user.accessLevel < 3 ? <option value="2">{I18n.t('obl')}</option> : ''}
                                        {this.props.userInfo.user.accessLevel < 4 ? <option value="3">{I18n.t('raion')}</option> : ''}
                                        {isOrg ? <option value="4">{I18n.t('org')}</option> : ''}
                                    </FormControl> : <Translate value='org' />}</Col>
                        </FormGroup></Col>
                </Row>
                {this.showSelectObl()}
                {this.showSelectRaions()}
                {isOrg || this.props.userInfo.user.accessLevel === 4 ? this.showSelectOrgs() : ''}
            </Fragment>
        );
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(SelectObject);