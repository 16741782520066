import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, FormGroup, FormControl, ControlLabel, Modal, Button, ButtonGroup } from 'react-bootstrap';

import { actionCreators } from '../../store/Nsi';

class SelectOrgModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orgNameSearch: undefined,
            selectOrg: undefined,
            foundOrgs:[],
        };
        this.onChangeOrgNameSearch = this.onChangeOrgNameSearch.bind(this);
        this.onSearchOrg = this.onSearchOrg.bind(this);
        this.handleSelectOrg = this.handleSelectOrg.bind(this);
        this.onSelectOrg = this.onSelectOrg.bind(this);
    }
    /*
    componentWillMount() {
        // This method runs when the component is first added to the page
    }
     */
    componentWillReceiveProps(nextProps) {
        // This method runs when incoming props (e.g., route params) change
        this.setState({ foundOrgs: []});
    }
   
    onChangeOrgNameSearch(e) {
        var val = e.target.value;
        this.setState({ orgNameSearch: val });
    }
    async onSearchOrg(e) {
        e.preventDefault();
        if (this.state.orgNameSearch === undefined && this.state.orgNameSearch==='') {
            alert('Введите подстроку для поиска организации');
            return;
        }
        if (this.props.raion == null) return;
        let data = await this.props.searchOrgRaion(this.props.raion.kod.substring(0, 4), this.state.orgNameSearch);
        this.setState({
                selectOrg: undefined,
                foundOrgs: data
        });
    }
    orgItem(org) {
        return <li key={org.kod} onClick={this.onSelectOrg} id={org.kod}
            className={'list-group-item' + (this.state.selectOrg ? (this.state.selectOrg.kod === org.kod ? ' list-group-item-success' : '') : '')}>
            {org.nameRu}</li>;
    }
    onSelectOrg(e) {
        let val = e.target.id;
        let selectOrg;
        if (!Array.prototype.find)
            selectOrg = this.state.foundOrgs.filter((itm) => itm.kod === val)[0];
        else
            selectOrg = this.state.foundOrgs.find((itm) => itm.kod === val);
        this.setState({ selectOrg });
    }
    handleSelectOrg() {
        if (this.state.selectOrg !== undefined) {
            this.props.setOrg(this.state.selectOrg);
        } else {
            alert("Выбирете организацию из списка");
        }
    }
    render() {
        return (
            <Modal show={this.props.showSelectOrgModal} onHide={this.props.handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Выбор организации</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form inline onSubmit={this.onSearchOrg}>
                        <FormGroup controlId="formInlineName">
                            <ControlLabel>Организация:</ControlLabel>{' '}
                            <FormControl type="text" placeholder="введите подстроку для поиска" onChange={this.onChangeOrgNameSearch} />
                        </FormGroup>{' '}
                        <Button type="submit">Найти</Button>
                        {this.props.raion != null && (this.props.raion.kod.startsWith("71") || this.props.raion.kod.startsWith("75") || this.props.raion.kod.startsWith("79")) ? <strong className="text-primary">Поиск по всем районам</strong> : ""}
                    </Form>
                    <ul className="list-group" style={{ minHeight: 200, maxHeight: 200, overflow: 'auto' }}>
                        {this.state.foundOrgs.map(org => this.orgItem(org))}
                    </ul>
                    <ButtonGroup>
                        <Button className='btn btn-primary text-center' onClick={this.handleSelectOrg} type="button">Выбрать</Button>
                        <Button className='btn btn-default text-center' onClick={this.props.handleHide} type="button">Отмена</Button>
                    </ButtonGroup>
                </Modal.Body>
            </Modal>
        );
    }
}
export default connect(
    state => state.nsi,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(SelectOrgModal);