import React, { useState, useEffect } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { Button, Modal, Row, Col, FormControl } from 'react-bootstrap';

export default function EditFormulExcelModal(props) {
    const [cell, setCell] = useState();
    const [formul, setFormul] = useState();
    const [isNew, setIsNew] = useState(true);
    useEffect(() => {
        setEr(null);
        if (props.isNew === true) {
            setCell("");
            setFormul("");
            setIsNew(true)
        } else {
            setCell(props.cell);
            setFormul(props.formul);
            setIsNew(false)
        }
    }, [props.cell, props.formul, props.isNew]);

    const [er, setEr] = useState();

    const commitBt = (e) => {
        e.preventDefault();
        setEr(null)
        if (cell == null || cell.trim() === "") {
            setEr(I18n.t("templateTableExcel.errors.celReq"));
            return;     
        }
        if (formul == null || formul.trim() === "") {
            setEr(I18n.t("templateTableExcel.errors.celFormulaReq"));
            return;
        }
        if (isNew === true && props.isExistsCell(cell)) {
            setEr(I18n.t("templateTableExcel.errors.celExists"));
            return;
        }
        props.setFormulExcel(cell, formul, isNew);
    }
    return props.showModal ? <Modal show={props.showModal} onHide={props.handleHide}
        size="sm"
        show={true}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-sm-dialog-scrollable"
        centered
    >
        <Modal.Body>
            <Row>
                <Col sm={6}><strong><Translate value="templateTableExcel.cell" /></strong></Col>
                <Col sm={6}><FormControl
                    type="text"
                    value={cell}
                    placeholder="Enter text"
                    disabled={isNew != true}
                    onChange={(e => setCell(e.target.value))}
                /></Col>
           </Row>
           <Row>
                <Col sm={6}><strong><Translate value="templateTableExcel.formul" /></strong></Col>
                <Col sm={6}><FormControl
                    type="text"
                    value={formul}
                    placeholder="Enter text"
                    onChange={(e => setFormul(e.target.value))}
                /></Col>
            </Row>
            {er != null ? <Row>
                <Col sm={6} className="text-danger">{er}</Col></Row> : ""}
        </Modal.Body>
        <Modal.Footer>
            <Button type="button" bsStyle="success" onClick={commitBt}><Translate value="save" /></Button>
            <Button type="button" onClick={props.handleHide}><Translate value="cancel" /></Button>
        </Modal.Footer>
    </Modal> : ""
}