import React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
//import { PrivateRoute } from './components/PrivateRoute';

import Home from './components/Home';
import AdminUsers from './components/admin/AdminUsers';
import Nsi from './components/admin/Nsi';
import ClosingPeriod from './components/admin/ClosingPeriod';
import ClosingOrgPeriod from './components/admin/ClosingOrgPeriod';
import FinPaid from './components/FinPaid/FinPaid';
import ReportFin from './components/FinPaid/ReportFin';
import ReportFinRCRZ from './components/FinPaid/ReportFinRCRZ';
import OpenForm from './components/Work/OpenForm';
import OpenFormPstr from './components/Work/OpenFormPstr';
import SvodForm from './components/Svod/SvodForm';
import SvodFormQuarts from './components/Svod/SvodFormQuarts';
import SvodFormMonths from './components/Svod/SvodFormMonths';
import SvodFormRaz from './components/Svod/SvodFormRaz';
import SvodSubOrg from './components/Svod/SvodSubOrg';
import EditFormRaz from './components/Svod/EditFormRaz';
import EditFormOrgRaz from './components/Svod/EditFormOrgRaz';
import Copy12MonthToYear from './components/Svod/Copy12MonthToYear';
import EditControl from './components/admin/EditControl';
import ControlForm from './components/Control/ControlForm';
import ToExcel from './components/ToExcel/ToExcel';
import FromExcel from './components/FromExcel/FromExcel';
import InputInfor from './components/Work/InputInfor';
import InputInforForm from './components/Work/InputInforForm';
import InputInforObj from './components/Work/InputInforObj';
import License from './components/License';
import EditMedinfoFormul from './components/Medinfo/EditMedinfoFormul';
import EditMedinfoBokMkb from './components/Medinfo/editBokMkb';
import EditMedinfoBokDr from './components/Medinfo/editBokDr';
import EditMedinfoPart from './components/Medinfo/editParts';
import GenMedinfoTable from './components/Medinfo/GenMedinfoTable';
import GenMedinfoTable2 from './components/Medinfo/GenMedinfoTable2';
import Gen47Form from './components/gen47/gen47form';
import Gen47OrgParam from './components/gen47/gen47OrgParam';
import Register from './components/Register';
import AdminChildsOrg from './components/admin/AdminChildsOrg';
import StatusForm from './components/Work/StatusForm';
import StatusFormRk from './components/Work/StatusFormRk';
import StatusTableRk from './components/Work/StatusTableRk';
import StatusTableObl from './components/Work/StatusTableObl';
import DeleteForm from './components/Work/DeleteForm';
import RepSubordinates from './components/FinPaid/RepSubordinates';
import AdminEditChildOrg from './components/admin/AdminEditChildOrg';
import EditOrgsOnObl from './components/Work/EditOrgsOnObl';
import AdminChildUsers from './components/admin/AdminChildUsers';
import AccessRazdOrg from './components/Work/AccessRazdOrg';
import SvodSpecificOrg from './components/Svod/SvodSpecificOrg';
import SvodFormOrgRaz2 from './components/Svod/SvodFormOrgRaz2';
import SvodSubOrgObl from './components/Svod/SvodSubOrgObl';
import LogAudit from './components/admin/logaudit';
import MapSvg from './components/MapSvg';
import EditTemplateFiles from './components/admin/EditTemplateFiles';
import VersionsHistory from './components/VersionsHistory';
import EditFormPeriod from './components/Svod/EditFormPeriod';
import EditTempTablesMonths from './components/admin/EditTempTablesMonths';
//import Counter from './components/Counter';
//import FetchData from './components/FetchData';


export default () => (
    <Layout>
        <Route exact path='/' component={Home} />
        <Route path='/adminUsers' component={AdminUsers} />
        <Route path='/nsi' component={Nsi} />
        <Route path='/editTemplateFiles' component={EditTemplateFiles} />
        <Route path='/editTemplateTableFiles' component={EditTempTablesMonths} />
        <Route path='/fin' component={FinPaid} />
        <Route path='/reportfin' component={ReportFin} />
        <Route path='/reportfincrrz' component={ReportFinRCRZ} />
        <Route path='/closingPeriod' component={ClosingPeriod} />
        <Route path='/closingOrgPeriod' component={ClosingOrgPeriod} />
        <Route path='/openForm' component={OpenForm} />
        <Route path='/openFormPstr' component={OpenFormPstr} />
        <Route path='/svodForm' component={SvodForm} />
        <Route path='/svodFormQuarts' component={SvodFormQuarts} />
        <Route path='/svodFormMonths' component={SvodFormMonths} />
        <Route path='/svodFormRaz' component={SvodFormRaz} />
        <Route path='/svodSubOrg' component={SvodSubOrg} />
        <Route path='/editFormRaz' component={EditFormRaz} />
        <Route path='/editFormOrgRaz' component={EditFormOrgRaz} />
        <Route path='/editFormPeriod' component={EditFormPeriod} />
        <Route path='/copy12MonthYoYear' component={Copy12MonthToYear} />
        <Route path='/controlInForm' component={ControlForm} />
        <Route path='/controlAllForm' component={ControlForm} />
        <Route path='/controlInYear' component={ControlForm} />
        <Route path='/editcontrol' component={EditControl} />
        <Route path='/toexcel' component={ToExcel} />
        <Route path='/fromexcel' component={FromExcel} />
        {/*<Route path='/counter' component={Counter} />
        <Route path='/fetchdata/:startDateIndex?' component={FetchData} />*/}
        <Route path='/inputInfor' component={InputInfor} />
        <Route path='/inputInforForm' component={InputInforForm} />
        <Route path='/inputInforObj' component={InputInforObj} />
        <Route path='/license' component={License} />
        <Route path='/register' component={Register} />
        <Route path='/editMF' component={EditMedinfoFormul} />
        <Route path='/editBokMkb' component={EditMedinfoBokMkb} />
        <Route path='/editBokDr' component={EditMedinfoBokDr} />
        <Route path='/editParts' component={EditMedinfoPart} />
        <Route path='/genMedinfoTable' component={GenMedinfoTable} />
        <Route path='/genMedinfoTable2' component={GenMedinfoTable2} />
        <Route path='/gen47' component={Gen47Form} />
        <Route path='/gen47OrgParam' component={Gen47OrgParam} />
        {/*<Route path='/adminChilsOrgs' component={AdminChildsOrg} />*/}
        <Route path='/statusForm' component={StatusForm} />
        <Route path='/statusFormRk' component={StatusFormRk}/>
        <Route path='/statusTableRk' component={StatusTableRk} />
        <Route path='/statusTableObl' component={StatusTableObl} />
        <Route path='/deleteForm' component={DeleteForm} />
        <Route path='/repSubordinates' component={RepSubordinates} />
        <Route path='/adminEditChildOrg' component={AdminEditChildOrg} />
        <Route path='/editOrgsOnObl' component={EditOrgsOnObl} />
        <Route path='/adminChildUsers' component={AdminChildUsers} />
        <Route path='/accessRazdOrg' component={AccessRazdOrg} />
        <Route path='/svodSpecificOrg' component={SvodSpecificOrg} />
        <Route path='/svodFormOrgRaz' component={SvodFormOrgRaz2} />
        <Route path='/svodSubOrgObl' component={SvodSubOrgObl} />
        <Route path='/logAudit' component={LogAudit} />
        <Route path='/MapSvg' component={MapSvg} />
        <Route path='/VersionsHistory' component={VersionsHistory} />
    </Layout>);
//      <Route path="/login" component={LoginPage} />
