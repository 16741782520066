import React, { useEffect, useState, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { Alert, Row, Table, Button, Col, Modal, FormGroup, FormControl, Checkbox, ControlLabel, Form } from 'react-bootstrap';
//import { useTable, useGlobalFilter } from 'react-table'
//import ReactTable from 'react-table';
import { Translate, I18n } from 'react-redux-i18n';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';
import { getNameLocalize } from '../Constants';

import SelectTeModal from './SelectTeModal';

const EditOrgModal = (props) => {
    const [nasels, setNasels] = useState([]);
    const [org, setOrg] = useState();
    useEffect(() => {
        if (props.org != null) {
            let o = cloneDeep(props.org);
            let fRa = props.nsi.allraions.filter(r => r.obl === o.obl)[0];
            if (o.raion.substr(2, 2) === "00") {
                o.raion = fRa.idRaion;
                o.te = fRa.te;
            } else {
                let fRa2 = props.nsi.allraions.filter(r => r.kod.substr(0, 4) === o.te.substr(0, 4));
                if (fRa2.length === 0) {
                    o.raion = fRa.idRaion;
                    o.te = fRa.te;
                }
            }
            setOrg(o);
            let getNas = async (kodRaion) => {
                let nas = await getNasels(kodRaion);
                setNasels(nas);
            }
            getNas(o.raion);
        } else {
            setOrg(null);
            setNasels([]);
        }
    }, [props.org]);
    const getNasels = async (kodRaion) => {
        let nls = await props.getNaselRaion(kodRaion);
        if (nls != null && nls.length > 0) {
            return nls;
        } else {
            return [];
        }
    }
    const handleCloseDlg = (isNotCancel) => {
        if (isNotCancel === false)
            props.handleCloseDlg({ isNotCancel, org: null });
        else if (org != undefined) {
            props.handleCloseDlg({ isNotCancel, org});
        } 
    };
    const changeFld = (fld, value) => {
        let o = cloneDeep(org);
        o[fld] = value;
        if (fld =="raion") {
            let getNas = async (kodRaion) => {
                let nas = await getNasels(kodRaion);
                if (nas.length > 0 && nas.filter(n => n.te == org.te).length === 0) {
                    o.te = nas[0].te;
                }
                setNasels(nas);
                setOrg(o);
            }
            getNas(value);
            return;
        }
        setOrg(o);
    }
    const [showChangeObl, setShowChangeObl] = useState(false);
    const [newTe, setNewTe] = useState({});
    const btChangeObl = (evt) => {
        evt.preventDefault();
        setNewTe({ obl: org.obl, raion: org.raion, te: org.te });
        setShowChangeObl(true);
    }
    const closeChangeObl = (param) => {
        setShowChangeObl(false);
        if (param.isNotCancel === true) {
            let o = cloneDeep(org);
            o.obl = param.te.obl;
            o.raion = param.te.raion;
            o.te = param.te.te;
            let getNas = async (kodRaion) => {
                let nas = await getNasels(kodRaion);
                if (nas.length > 0 && nas.filter(n => n.te == o.te).length === 0) {
                    o.te = nas[0].te;
                }
                setNasels(nas);
                setOrg(o);
            }
            getNas(o.raion);
        }
    }
    return (<><Modal
        show={props.show}
        onHide={() => handleCloseDlg(false)}
        backdrop="static"
        keyboard={false}
        bsSize="large">
        <Modal.Header closeButton>
            <Modal.Title><Translate value="editOrgs.edit" /></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {org != null ?
                <Form horizontal>
                    <FormGroup controlId="formNameRu" bsSize="sm">
                        <Col componentClass={ControlLabel} sm={2}><Translate value="editOrgs.nameRu" /></Col>
                        <Col sm={10}>
                            <FormControl componentClass="textarea" value={org.nameRu} disabled={props.userInfo.user.accessLevel != 1}
                                onChange={(evt) => changeFld("nameRu", evt.target.value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup controlId="formNameKz" bsSize="sm">
                        <Col componentClass={ControlLabel} sm={2}><Translate value="editOrgs.nameKz" /></Col>
                        <Col sm={10}>
                            <FormControl componentClass="textarea" value={org.nameKz} disabled={props.userInfo.user.accessLevel != 1}
                                onChange={(evt) => changeFld("nameKz", evt.target.value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup controlId="formObl" bsSize="sm">
                        <Col componentClass={ControlLabel} sm={2}><Translate value="obl" /></Col>
                        <Col sm={10}>
                            <FormControl className='input-sm' componentClass="select" placeholder="select" value={org.obl} disabled={true}
                                style={{ width: "auto" }} style={{ display: "inline-block", width: "auto", marginRight: "5px" }}>
                                {props.nsi.allobls.map(it => <option key={it.kod} value={it.kod}>{getNameLocalize(props.i18n, it)}</option>)}
                            </FormControl>
                            {props.userInfo.user.accessLevel === 1 ? <Button type="button" variant="secondary" onClick={btChangeObl}><Translate value="msgButtons.Change" /></Button>:""}
                        </Col>
                    </FormGroup>
                    <FormGroup controlId="formRaion" bsSize="sm">
                        <Col componentClass={ControlLabel} sm={2}><Translate value="editOrgs.raion" /></Col>
                        <Col sm={10}>
                            <FormControl className='input-sm' componentClass="select" placeholder="select" value={org.raion} 
                                onChange={(evt) => changeFld("raion", evt.target.value)} style={{ width: "auto" }} style={{ display: "inline-block", width: "auto", marginRight: "5px" }}>
                                {props.nsi.allraions.filter(r => r.obl === org.obl).map(it => <option key={it.kod} value={it.idRaion}>{it.idRaion} {getNameLocalize(props.i18n, it)}</option>)}
                            </FormControl>
                        </Col>
                    </FormGroup>
                    <FormGroup controlId="formNasel" bsSize="sm">
                        <Col componentClass={ControlLabel} sm={2}><Translate value="editOrgs.nasel" /></Col>
                        <Col sm={10}>
                            <FormControl className='input-sm' componentClass="select" placeholder="select" value={org.te}
                                onChange={(evt) => changeFld("te", evt.target.value)} style={{ width: "auto" }} style={{ display: "inline-block", width: "auto", marginRight: "5px" }}>
                                {nasels != null ? nasels.map((nas) => <option key={nas.te} value={nas.te}>{nas.te} {getNameLocalize(props.i18n, nas)}</option>) : ''}
                            </FormControl>
                        </Col>
                    </FormGroup>
                    <FormGroup bsSize="sm">
                        <Col smOffset={2} sm={10}>
                            <Checkbox checked={org.isMedstat} onChange={(evt) => changeFld("isMedstat", evt.target.checked)}><Translate value="editOrgs.isMedstat" /></Checkbox>
                        </Col>
                    </FormGroup>
                </Form> : ""}
        </Modal.Body>
        <Modal.Footer>
            <Button type="button" variant="secondary" onClick={() => handleCloseDlg(false)}><Translate value="close" /></Button>
            <Button type="button" variant="primary" onClick={() => handleCloseDlg(true)} ><Translate value="msgButtons.Save" /></Button>
        </Modal.Footer>
    </Modal>
        <SelectTeModal show={showChangeObl} te={newTe} handleCloseDlg={closeChangeObl}/>
    </>);
}
export default connect(
    state => state,
    dispatch => bindActionCreators(actionCreatorsNsi, dispatch)
)(EditOrgModal);
//disabled={props.userInfo.user.accessLevel != 1}