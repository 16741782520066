import React, { Fragment } from 'react';

export default function CheckBoxQuarter(props) {
    const onOk = (evt) => {
        evt.preventDefault();
        props.onChange({ kod: props.kod, typeObj: props.typeObj, quarter: props.quarter, value: false});
    }
    const onMinus = (evt) => {
        evt.preventDefault();
        props.onChange({ kod: props.kod, typeObj: props.typeObj, quarter: props.quarter, value: true });
    }
    return <Fragment>
        {props.checked === true ? <span className="label label-primary"><i className="glyphicon glyphicon-check" style={{ cursor: "pointer" }} onClick={onOk}></i></span> : <i className="glyphicon glyphicon-unchecked" onClick={onMinus} style={{ cursor: "pointer" }}></i>}
        
    </Fragment>;
}