import React, { Component, Fragment, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import * as FileSaver from 'file-saver';
import { Row, Col, FormGroup, FormControl, ControlLabel, Form, Button, ButtonGroup, Panel } from 'react-bootstrap';

import { actionCreators as actionCreatorsWork } from '../../store/Work';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';
import SelectObject from '../common/SelectObject';
import './InputInfor.css';

import { years, getNameLocalize, getNameSprLocalize, getCurrentYear } from '../Constants';




const InputInforObj = (props) => {
    useState(() => {
        if (props.nsi.allobls.length === 0) props.getNs('allobls');
        if (props.nsi.sprraz.length === 0) props.getNs('sprraz');
        if (props.nsi.sprform.length === 0) props.getNs('sprform');
        if (props.nsi.periods.length === 0) props.getNs('periods');
        if (props.nsi.allraions.length === 0) props.getNs('allraions');
        //let year = new Date().getFullYear();
        //setYearsCopy([year - 1, year]);
        //setSelYear(year - 1);
    });
    const [year, setYear] = useState(() => getCurrentYear());
    const [kper, setKper] = useState(4);
    //const [kf, setKf] = useState("01");
    const [tobj, setTobj] = useState(props.userInfo.user.accessLevel);
    const [obl, setObl] = useState();
    const [raion, setRaion] = useState();
    const [org, setOrg] = useState();
    const [strObj, setStrObj] = useState(0);
    //const [razFiltr, setRazFiltr] = useState(0);
    const onOpenTable = (e) => {
        e.preventDefault();
        let kobj = "";
        switch (tobj) {
            case 1://РК
                kobj = "00";
                break;
            case 2://Область
                kobj = obl.kobl;
                break;
            case 3://Район
                kobj = raion.kraion;
                break;
            case 4://Организация
                kobj = org.korg;
                break;
            default:
                break;

        }
        props.getInfoDataObj({
            year: year,
            period: kper,
            //kf: kf,
            tobj: tobj,
            kobj,
            strObj: strObj
        });
    }
    //const filtrRaz = (r) => {
    //    if (razFiltr == null || razFiltr === 0) return true;
    //    //if (parseInt(r.kod, 10) != razFiltr) return false;
    //    if (r.razrs.filter(f => f.kraz === razFiltr).length === 0) return false;
    //    return true;
    //}
    const getObjectTypeName= (tobj) => {
        switch (tobj) {
            //case 1://РК
            //    return "Республика:";
            case 2://Область
                return `${I18n.t("obl")}:`;
            case 3://Район
                return `${I18n.t("raion")}:`;
            case 4://Организация
                return `${I18n.t("org")}:`;
            default:
                break;
        }
        return "";
    }
    const showInfoData= ()=> {
        if (props.work.dataInfoObj === undefined) return '';
        let dataInfo = props.work.dataInfoObj;
        return <Fragment>
            <ButtonGroup style={{ marginTop: '5px' }}>
                <Button bsSize='small' onClick={printData} style={{ marginRight: '10px' }}><Translate value="infoData.print" /></Button>
                <Button bsSize='small' onClick={printExcel}><Translate value="infoData.printExcel" /></Button>
            </ButtonGroup>
            <h4 id="print-h4"><strong><Translate value="infoData.title" /></strong></h4>
            <Row>
                <Col md={12}><strong><Translate value="infoData.period" /> {dataInfo.namePeriod}</strong></Col>
            </Row>
            <Row>
                <Col md={12}><strong><Translate value="infoData.obj" /> <strong>{getObjectTypeName(dataInfo.tobj)} {dataInfo.obj.kod}</strong> {getNameLocalize(props.i18n, dataInfo.obj)}</strong></Col>
            </Row>
            <Row>
                <Col md={12}><strong><Translate value="infoData.dt" /> {dataInfo.strCreateDateTime}</strong></Col>
            </Row>
            {strObj === 1 || tobj === 4 ? <Fragment><Row>
                <Col md={6}>
                    <div style={{ display: "inline-block", width: "20px", height: "20px", backgroundColor: "#777777" }}></div><div style={{ display: "inline-block", height: "20px", paddingLeft: "5px", borderRadius: "0.25em" }}><Translate className="text-deafult" value='statusForm.titFormUncheck' /></div>
                </Col>
                <Col md={6}>
                    <div style={{ display: "inline-block", width: "20px", height: "20px", backgroundColor: "#337ab7" }}></div><div style={{ display: "inline-block", height: "20px", paddingLeft: "5px", borderRadius: "0.25em" }}><Translate className="text-primary" value='statusForm.titFormCheck' /></div>
                </Col>
            </Row></Fragment> : ""}
            {/*<Form horizontal>
                <FormGroup bsSize="small">
                    <Col componentClass={ControlLabel} sm={2}><Translate value="filtrRaz" /></Col>
                    <Col sm={6}>
                        <FormControl className='input-sm' componentClass="select" placeholder="select" value={razFiltr}
                            onChange={(evt) => setRazFiltr(parseInt(evt.target.value,10))} style={{ minWidth: '100%' }}>
                            <option value={0} key={"all"}></option>
                            {props.nsi.sprraz.map(it => <option key={it.kod} value={it.kod}>{getNameLocalize(props.i18n, it)}</option>)}
                        </FormControl>
                    </Col>
                    <Col sm={2}>
                        <Button type="button" onClick={() => setRazFiltr(0)} disabled={props.work.isLoading === true}
                            bsSize="sm"><Translate value="editOrgs.clearFilter" /></Button>
                    </Col>
                </FormGroup>
            </Form>*/}
            <hr style={{ marginTop: "4px", marginBottom: "4px" }} />
            {dataInfo.rows.map(r => <Fragment key={r.form.kf}>
                <Row>
                    <Col md={12}><strong className="text-info">{r.form.kf} {getNameLocalize(props.i18n, r.form)}</strong></Col>
                </Row>
                <Row>
                    <Col md={12}>{showRaz(r, dataInfo.tobj)}</Col>
                </Row>
            </Fragment>)}
        </Fragment>;
    }
    const showRaz = (r, typeObj) => {
        if (typeObj === 4/* && this.props.userInfo.user.accessLevel===2 */)
            return r.razrs.map(f => f.status === 2 ? <Fragment key={r.form.kf + f.kraz}><span className="label label-primary" style={{ fontSize: '11pt' }} title={I18n.t("checked")}>{getNameSprLocalize(props.i18n, props.nsi.sprraz,f.kraz)}</span>{' '}</Fragment> :
                <Fragment key={r.form.kf + f.kraz}><span className="label label-default" title={I18n.t("noChecked")} style={{ fontSize: '11pt' }}>{getNameSprLocalize(props.i18n, props.nsi.sprraz, f.kraz)}</span>{' '}</Fragment>)
        else
            return r.razrs.map(f => <Fragment key={r.form.kf + f.kraz}><span style={{ fontSize: '11pt', padding: '2px', borderStyle: 'solid', borderWidth: 'thin' }} key={r.form.kf + f.kraz}>{getNameSprLocalize(props.i18n, props.nsi.sprraz, f.kraz)}</span>{' '}</Fragment>)
    }
    const printData = () => {
        window.print();
    }
    const printExcel = async () => {
        let kobj = "";
        switch (tobj) {
            case 1://РК
                kobj = "00";
                break;
            case 2://Область
                kobj = obl.kobl;
                break;
            case 3://Район
                kobj = raion.kraion;
                break;
            case 4://Организация
                kobj = org.korg;
                break;
            default:
                break;

        }
        let absoluteUrl = `${document.location.protocol}//${document.location.host}/generate/Excel/InputInfoObj/${year}/${kper}/${tobj}/${kobj}/${strObj}`;
        let fileName = `inf3_${year}_${kper}_${tobj}_${kobj}_${strObj}.xlsx`;
        var token = props.userInfo.user.token;
        const response = await fetch(absoluteUrl, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const blob = await response.blob();
            FileSaver.saveAs(blob, fileName);
            //this.setState({ isLoading: false });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    }
    return <Fragment><Form onSubmit={onOpenTable} horizontal>
        <h5 style={{ textAlign: "center" }}><strong><Translate value="InputInforObj.tit" /></strong></h5>
        <FormGroup controlId="formYear" bsSize='small'>
            <Col sm={2} componentClass={ControlLabel}><Translate value='year' />{':'}</Col>
            <Col sm={10}>
                <FormControl className='input-sm' componentClass="select" placeholder="select" disabled={props.svod.isLoading}
                    defaultValue={year} onChange={(e) => setYear(parseInt(e.target.value, 10))} bsSize="small" style={{ width: "auto" }}>
                    {years.map(it => <option key={it} value={it}>{it}</option>)}
                </FormControl></Col>
        </FormGroup>
        <FormGroup controlId="formPer" bsSize='small'>
            <Col sm={2} componentClass={ControlLabel}><Translate value='period' />{':'}</Col>
            <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={`${kper}`} disabled={props.svod.isLoading}
                onChange={(e) => setKper(parseInt(e.target.value, 10))} bsSize='small' style={{ width: "auto" }}>
                {props.nsi.periods.map(it => <option key={it.kper} value={it.kper}>{it.nameRu}</option>)}
            </FormControl></Col>
        </FormGroup>
        {/*<FormGroup controlId="formKf" bsSize='small'>
            <Col xs={12} sm={2} componentClass={ControlLabel}><Translate value='forma' />{':'}</Col>
            <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={kf} disabled={props.svod.isLoading}
                onChange={(e) => setKf(e.target.value)} style={{ minWidth: '100%' }}>
                {props.nsi.sprform.map(it => <option key={it.kf} value={it.kf}>{it.kf} {getNameLocalize(props.i18n, it)}</option>)}
            </FormControl></Col>
        </FormGroup>*/}
        <SelectObject
            tobj={tobj}
            obl={obl}
            raion={raion}
            org={org}
            setTobj={(tobj) => { setTobj(tobj); setStrObj(0); }}
            setObl={setObl}
            setRaion={setRaion}
            setOrg={setOrg}
            year={year}
        />
        {/*{tobj === 2 ? <FormGroup controlId="formStrObj" bsSize='small'
            onChange={(e) => setStrObj(parseInt(e.target.value, 10))}>
            <Col xs={12} sm={2} componentClass={ControlLabel}><Translate value='strocs' />{':'}</Col>
            <Col xs={12} sm={10}>
                <input type="radio" value={0} name="StrObj" checked={strObj === 0} disabled={props.work.isLoading === true}/>&nbsp;<Translate value='raions' />&nbsp;&nbsp;&nbsp;&nbsp;
                <input type="radio" value={1} name="StrObj" checked={strObj === 1} disabled={props.work.isLoading === true}/>&nbsp;<Translate value='orgs' />&nbsp;&nbsp;&nbsp;&nbsp;
            </Col>
            </FormGroup> : ''}
        {(tobj === 3 && props.userInfo.user.accessLevel === 3) ? <FormGroup controlId="formStrObj" bsSize='small'
            onChange={(e) => (e.target.checked === true ? setStrObj(2) : setStrObj(0))}>
            <Col xs={12} sm={2} className="text-right" componentClass={ControlLabel}><Translate value='strocs' />{':'}</Col>
            <Col xs={12} sm={10}>
                <input type="checkbox" value={2} name="StrObj" checked={strObj === 2} disabled={props.work.isLoading === true}/>&nbsp;<Translate value='childOrgs.title' />
             </Col>
           </FormGroup>: ''}
           */}
        <FormGroup>
            <Col smOffset={2} sm={10}>
                <Button className='btn btn-primary text-center' type="submit" disabled={props.work.isLoading === true}><Translate value='msgButtons.Load' /></Button>
            </Col>
        </FormGroup>
    </Form>
        {showInfoData()}
    </Fragment>
}
export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionCreatorsWork }, dispatch)
)(InputInforObj);