//import { cloneDeep } from 'lodash';
import { getToken } from '../UserInfo';
const requestUploadTemplateAction = 'REQUEST_MEDSTAT_UPLOAD_TEMPLATE';
const receiveUploadTemplateAction = 'RECEIVE_MEDSTAT_UPLOAD_TEMPLATE';
const requestDownloadTemplateAction = 'REQUEST_MEDSTAT_DOWNLOAD_TEMPLATE';
const receiveDownloadTemplateAction = 'RECEIVE_MEDSTAT_DOWNLOAD_TEMPLATE';
const requestLoadTemplatesAction = 'REQUEST_MEDSTAT_LOAD_TEMPLATES';
const receiveLoadTemplatesAction = 'RECEIVE_MEDSTAT_LOAD_TEMPLATES';


const initialState = { isLoading: false }

export const actionCreators = {
    UploadTemplate: (formData, year, kf, lang) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestUploadTemplateAction });
        const response = await fetch(`api/MedstatTemplates/UploadTemplate?year=${year}&kf=${kf}&lang=${lang}`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                // "Content-Type": "multipart/form-data"
            },
            body: formData
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            dispatch({ type: receiveUploadTemplateAction });
            return await response.json();
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
        return null;
    },
    UploadTemplateTable: (formData, year, kf, ktab, lang) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestUploadTemplateAction });
        const response = await fetch(`api/MedstatTemplates/UploadTemplateTable?year=${year}&kf=${kf}&ktab=${ktab}&lang=${lang}`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                // "Content-Type": "multipart/form-data"
            },
            body: formData
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            dispatch({ type: receiveUploadTemplateAction });
            return await response.json();
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
        return null;
    },
    DownloadTemplate: (year, kf, lang) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestDownloadTemplateAction });
        const response = await fetch(`api/MedstatTemplates/DownloadTemplate/${year}/${kf}/${lang}`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            dispatch({ type: receiveDownloadTemplateAction });
            return await response.blob();
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
        return null;
    },
    DownloadTemplateTable: (year, kf, ktab, lang) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestDownloadTemplateAction });
        const response = await fetch(`api/MedstatTemplates/DownloadTemplateTable/${year}/${kf}/${ktab}/${lang}`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            dispatch({ type: receiveDownloadTemplateAction });
            return await response.blob();
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
        return null;
    },
    LoadTemplates: (year, kf) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestLoadTemplatesAction });
        const response = await fetch(`api/MedstatTemplates/LoadTemplates/${year}/${kf}`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            dispatch({ type: receiveLoadTemplatesAction });
            return await response.json();
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
        return null;
    },
    LoadTablesMonthsTemplates: (year, kf, ktab) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestLoadTemplatesAction });
        const response = await fetch(`api/MedstatTemplates/LoadTablesTemplates/${year}/${kf}/${ktab}`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            dispatch({ type: receiveLoadTemplatesAction });
            return await response.json();
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
        return null;
    },
    ChangeFld: (year, kf, ktab, fld, vl) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestLoadTemplatesAction });
        let body = JSON.stringify({ year, kf, ktab, fld, vl});
        const response = await fetch("api/MedstatTemplates/ChangeFldTables", {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            dispatch({ type: receiveLoadTemplatesAction });
            return await response.json();
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
        return null;
    },
    changeFormulExcel: (year, kf, ktab, cell, formul, isNew) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestLoadTemplatesAction });
        let body = JSON.stringify({ year, kf, ktab, cell, formul, isNew });
        const response = await fetch("api/MedstatTemplates/ChangeFormulExcel", {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            dispatch({ type: receiveLoadTemplatesAction });
            return await response.json();
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
        return null;
    },
    deleteFormulExcel: (year, kf, ktab, cell) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestLoadTemplatesAction });
        let body = JSON.stringify({ year, kf, ktab, cell});
        const response = await fetch("api/MedstatTemplates/DeleteFormulExcel", {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            dispatch({ type: receiveLoadTemplatesAction });
            return await response.json();
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
        return null;
    }
}
export const reducer = (state, action) => {
    state = state || initialState;
    if (action.type === requestLoadTemplatesAction || action.type === requestDownloadTemplateAction || action.type === requestUploadTemplateAction) {
        return {
            ...state,
            isLoading: true,
        };
    }
    if (action.type === receiveLoadTemplatesAction || action.type === receiveDownloadTemplateAction || action.type === receiveUploadTemplateAction) {
        return {
            isLoading: false,
        };
    }
    return state;
}