import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal, Button, ButtonGroup,FormControl } from 'react-bootstrap';
//import { Typeahead } from 'react-bootstrap-typeahead';

//import 'react-bootstrap-typeahead/css/Typeahead.css';


import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';
//import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';


import SelectOrgModal from '../Modals/SelectOrgModal';
import GenPssw from './genPssw';
import { SpObl, SpRoles, getTikcs } from '../Constants';
const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
class UserEdit extends Component {
    constructor(props) {
        super(props);
        this.setOrg = this.setOrg.bind(this);
        this.roleItem = this.roleItem.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangePassSubmit = this.handleChangePassSubmit.bind(this);
        this.onShowModalChangeUserNameClick = this.onShowModalChangeUserNameClick.bind(this);
        this.onChangeUserNameClick = this.onChangeUserNameClick.bind(this);
        this.handleCloseChangeUserName = this.handleCloseChangeUserName.bind(this);
        this.onChangeFio = this.onChangeFio.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.accessLevelHandleChange = this.accessLevelHandleChange.bind(this);
        //this.accessSectorHandleChange = this.accessSectorHandleChange.bind(this);
        this.accessOblHandleChange = this.accessOblHandleChange.bind(this);
        this.accessRaionHandleChange = this.accessRaionHandleChange.bind(this);
        this.checkboxsRolesInputChange = this.checkboxsRolesInputChange.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangePasswordConfirm = this.onChangePasswordConfirm.bind(this);
        this.onChangeOldPassword = this.onChangeOldPassword.bind(this);
        this.onChangeNewPassword = this.onChangeNewPassword.bind(this);
        this.onChangeNewUserName = this.onChangeNewUserName.bind(this);
        this.onChangeOrg2 = this.onChangeOrg2.bind(this);
        this.typeUserHandleChange = this.typeUserHandleChange.bind(this);
        this.showHideInputPassword = this.showHideInputPassword.bind(this);
        this.showHideInputPasswordConfirm = this.showHideInputPasswordConfirm.bind(this);
        this.showHideInputOldPassword = this.showHideInputOldPassword.bind(this);
        //this.eInput = React.createRef();
        //this.pInput = React.createRef();
    }
    //componentDidMount() {
    //    if (this.eInput.current != null) this.eInput.current.value = "";
    //    if (this.pInput.current != null) this.pInput.current.value = "";
    //}
    componentWillMount() {
        let obl, raion, props = this.props;
        if (!Array.prototype.find)
            obl = SpObl.filter(o => o !== undefined && o.kod === props.user.obl.kod)[0];
        else
            obl = SpObl.find(o => o !== undefined && o.kod === props.user.obl.kod);
        this.setState({
            id: props.user.id,
            accessLevel: props.user.accessLevel,
            username: props.user.username,
            email: props.user.email,
            fio: props.user.fio,
            obl: obl ? obl : { kod: props.user.obl.kod, nameKz: props.user.obl.nameKz, nameRu: props.user.obl.nameRu },
            raion: raion ? raion : { kod: props.user.raion.kod, nameKz: props.user.raion.nameKz, nameRu: props.user.raion.nameRu },
            typeUser: props.user.typeUser,
            org: { kod: props.user.org.kod, nameKz: props.user.org.nameKz, nameRu: props.user.org.nameRu },
            roles: props.user.roles.map(val => val),
            isNew: props.user.isNew,
            password: '',//undefined,
            passwordConfirm: '',//undefined,
            oldPassword: '',//undefined,
            newPassword: '',//undefined,
            showChangeUserName: false,
            showSelectOrgModal: false,
            newUserName: '',//undefined
            tiks: getTikcs()
        });
        //if (this.eInput.current != null) this.eInput.current.value = "";
        //if (this.pInput.current != null) this.pInput.current.value = "";
       
    }
    componentWillReceiveProps(nextProps) {
        let obl, raion;
        //if (!Array.prototype.find) {
            obl = SpObl.filter(o => o !== undefined && o.kod === nextProps.user.obl.kod)[0];
            raion = this.props.nsi.allraions.filter(o => o !== undefined && o.kod === nextProps.user.raion.kod)[0];
        //} else {
        //    obl = SpObl.find(o => o !== undefined && o.kod === nextProps.user.obl.kod);
        //    raion = this.props.nsi.allraions.find(o => o !== undefined && o.kod === nextProps.user.raion.kod);
        //}
        let org = {
            kod: nextProps.user.org.kod, nameKz: nextProps.user.org.nameKz, nameRu: nextProps.user.org.nameRu
        };
        //this.props.getNs('orgs', [{ name: "raion", value: raion.kod}]);
        this.setState({
            id: nextProps.user.id, 
            accessLevel: nextProps.user.accessLevel,
            username: nextProps.user.username,
            email: nextProps.user.email,
            fio: nextProps.user.fio,
            obl: obl ? obl : { kod: nextProps.user.obl.kod, nameKz: nextProps.user.obl.nameKz, nameRu: nextProps.user.obl.nameRu },
            raion: raion ? raion : { kod: nextProps.user.raion.kod, nameKz: nextProps.user.raion.nameKz, nameRu: nextProps.user.raion.nameRu },
            typeUser: nextProps.user.typeUser,
            org: org,
            roles: nextProps.user.roles.map(val => val),
            isNew: nextProps.user.isNew,
            password: '',//undefined,
            passwordConfirm: '',//undefined,
            oldPassword: '',//undefined,
            newPassword: '',//undefined,
            tiks: getTikcs()
        });
        //if (this.eInput.current !=null && (nextProps.user.email == null || nextProps.user.email === "")) {
        //    this.eInput.current.value = "";
        //}
        //if (this.pInput.current != null && (nextProps.user.password == null || nextProps.user.password === "")) {
        //    this.pInput.current.value = "";
        //}
        //console.dir(this.refs.myEml);
    }
    //orgs = [];
    setOrg(org) {
        var praion = this.props.nsi.allraions.filter(o => o.idRaion === org.raion)[0];
        this.setState({ org: org, raion: praion, showSelectOrgModal: false });
        //this.forceUpdate();
    }
    onChangePassword(e){
        var val = e.target.value;
        this.setState({ password: val });
    }
    onChangePasswordConfirm(e){
        var val = e.target.value;
        this.setState({ passwordConfirm: val });
    }
    onChangeOldPassword(e) {
        var val = e.target.value;
        this.setState({ oldPassword: val });
    }
    onChangeNewPassword(e) {
        var val = e.target.value;
        this.setState({ newPassword: val });
    }
    onChangeFio(e){
        var val = e.target.value;
        this.setState({ fio: val });
    }
    onChangeEmail(e){
        var val = e.target.value;
        //TODO check email
        this.setState({ email: val });
    }
    accessLevelHandleChange(e){
        var val = e.target.value;
        this.setState({ accessLevel: val });
    }
    typeUserHandleChange(e) {
        let val = e.target.value;
        let roles = [];
        switch (val) {
            case "1"://EditAdmins Администратор администраторов
                //roles.push(SpRoles.filter(it => it.kod === "EditAdmins")[0]);
                roles.push("EditAdmins");
                break;
            case "2"://EditUsers Администратор пользователей
                //roles.push(SpRoles.filter(it => it.kod === "EditUsers")[0]);
                roles.push("EditUsers");
                break;
            case "3": //AdminEditData Администратор данных
                //roles.push(SpRoles.filter(it => it.kod === "AdminEditData")[0]);
                roles.push("AdminEditData");
                break;
            case "4"://EditFin Финансовый администратор
                //roles.push(SpRoles.filter(it => it.kod === "EditFin")[0]);
                roles.push("EditFin");
                break;
            case "5"://UserEditData пользователь (чтение/запись)
                //roles.push(SpRoles.filter(it => it.kod === "UserEditData")[0]);
                roles.push("UserEditData");
                break;
            case "6"://UserReadData пользователь (только чтение)
                //roles.push(SpRoles.filter(it => it.kod === "UserReadData")[0]);
                roles.push("UserReadData");
                break;
            default:
                break;
        }
        this.setState({ typeUser: val, roles });
    }
    //accessSectorHandleChange(e){
    //    var val = e.target.value;
    //    this.setState({ accessSector: val });
    //}
    accessOblHandleChange(e){
        var val = e.target.value;
        var pobl;
        if (!Array.prototype.find)
            pobl = SpObl.filter(o => o !== undefined && o.kod === val)[0];
        else 
            pobl = SpObl.find(o => o !== undefined && o.kod === val);
        if (pobl !== undefined) {
            var praion = this.props.nsi.allraions.filter(o => o !== undefined && o.obl === val)[0];
            this.setState({
                obl: { kod: pobl.kod, nameKz: pobl.nameKz, nameRu: pobl.nameRu },
                raion: { kod: praion.kod, nameKz: praion.nameKz, nameRu: praion.nameRu, idRaion: praion.idRaion },
                org: undefined
            });
            //this.props.getNs('orgs', [{ name: "obl", value: val }]);
        }
    }
    accessRaionHandleChange(e) {
        var val = e.target.value;
        var praion;
        if (!Array.prototype.find)
            praion = this.props.nsi.allraions.filter(o => o !== undefined && o.kod === val)[0];
        else
            praion = this.props.nsi.allraions.find(o => o !== undefined && o.kod === val);
        if (praion !== undefined) {
            this.setState({ raion: { kod: praion.kod, nameKz: praion.nameKz, nameRu: praion.nameRu, idRaion: praion.idRaion }, org: undefined });
            //this.props.getNs('orgs', [{ name: "raion", value: val }]);
        }
    }
    checkboxsRolesInputChange(e) {
        var val = e.target.checked;
        var id = e.target.id;
        var rls = this.state.roles.map(r => r);
        if (val) {
            //если роль нач. Admin удалить все роли начинающиеся с Admin 
            if (id.substring(0, 5) === 'Admin') {
                let adms = rls.filter((v) => v.substring(0, 5) === 'Admin');
                for (var i in adms) {
                    let r = adms[i];
                    let index = rls.indexOf(r, 0);
                    if (index > -1) {
                        rls.splice(index, 1);
                    }
                }
                //добавить
                rls.push(id);
                this.setState({ roles: rls });
                return;
            }
            //если нет добавить
            if (rls.indexOf(id, 0) < 0) {
                rls.push(id);
                this.setState({ roles: rls });
            }
        } else {
            //если есть удалить
            let index = rls.indexOf(id, 0);
            if (index > -1) {
                rls.splice(index, 1);
                this.setState({ roles: rls });
            }
        }
    }
    handleSubmit(e) {
        e.preventDefault();
        
        if (this.state.org === undefined) {
            alert('Выберите организацию');
            return;
        }

        if (this.state.isNew) {
            if (this.state.email == null || this.state.email === '') {
                alert('Введите логин(Email)');
                return;
            }
            if (this.state.password == null || this.state.password==='') {
                alert('Введите пароль');
                return;
            }
            if (!strongPassword.test(this.state.password)) {
                alert('Пароль должен содержать 8 символов и содержать в себе цифры, маленькие и заглавные буквы и хотя бы один специальный символ из !@#$%^&*');
                return;
            }
            if (this.state.password !== this.state.passwordConfirm) {
                alert('Пароли не совпадают');
                return;
            }
        }
       // console.trace();
        this.props.updateUser({ ...this.state });
    }
    handleChangePassSubmit(e) {
        e.preventDefault();
        if (this.state.oldPassword === undefined || this.state.newPassword === undefined) {
            alert('Введите пароли');
            return;
        }
        if (!strongPassword.test(this.state.newPassword)) {
            alert('Пароль должен содержать 8 символов и содержать в себе цифры, маленькие и заглавные буквы и хотя бы один специальный символ из !@#$%^&*');
            return;
        }
        this.props.changePass(this.state.username, this.state.oldPassword, this.state.newPassword);
    }
    /*
    handleHideSelectOrgModal(e) {
        e.preventDefault();
        this.setState({ showSelectOrgModal: false });
    }
    */
    handleCloseChangeUserName(e) {
        e.preventDefault();
        this.setState({ showChangeUserName: false });
    }
    onShowModalChangeUserNameClick(e) {
        e.preventDefault();
        this.setState({ showChangeUserName: true, newUserName: this.state.username });
    }
    onChangeUserNameClick(e) {
        e.preventDefault();
        if (this.state.username === undefined || this.state.newUserName === undefined || this.state.username === this.state.newUserName) {
            return;
        }
        this.setState({ showChangeUserName: false });
        //изменить имя пользователя
        this.props.changeUserName({ userNameOld: this.state.username, userNameNew: this.state.newUserName });
    }
    onChangeNewUserName(e) {
        var val = e.target.value;
        this.setState({ newUserName: val });
    }
    onChangeOrg = (e) => {
        let orgs = this.props.nsi.orgs.filter((f) => f.kobj === e.target.value);
        if (orgs.length === 0) return;
        let o = orgs[0];
        this.setState({ org: { kod: o.kobj, nameRu: o.nobj, nameKz: o.nobj_kaz, kobl: o.kobl  } });
    }
    onChangeOrg2 = (sel) => {
        if (sel.length > 0) {
            this.setState({ org: { kod: sel[0].id, nameRu: sel[0].label, nameKz: sel[0].label/*, kobl: o.kobl*/ } });
        }
    }
    //public btSelectOrgClick(e: any) {
    //    e.preventDefault();
    //    //TODO: show dialog select org
    //}
    roleItem(o) {
        /*
        if ((!this.props.isAdminAdmin && o.kod.substring(0, 5) !== 'Admin') ||
            (this.props.isAdminAdmin && o.kod.substring(0, 5) === 'Admin')) {
            let IsCheck = false;
            if (!Array.prototype.find)
                IsCheck = this.state.roles.filter((r) => r === o.kod)[0] !== undefined;
            else
                IsCheck = this.state.roles.find((r) => r === o.kod) !== undefined;
            return <li className="list-group-item" key={o.kod}><label>
                <input
                    id={o.kod}
                    name="role"
                    type="checkbox"
                    checked={IsCheck}
                    onChange={this.checkboxsRolesInputChange} />
                {o.nameRu}
            </label></li>;
        };
        return '';
        */
        let IsCheck = false;
        if (!Array.prototype.find)
            IsCheck = this.state.roles.filter((r) => r === o.kod)[0] !== undefined;
        else
            IsCheck = this.state.roles.find((r) => r === o.kod) !== undefined;
        if (IsCheck) {
            return <li className="list-group-item" key={o.kod}>{o.commentRu}</li>
        } else {
            return '';
        }
        
    }
    showHideInputPassword(e) {
        e.preventDefault();
        var x = document.getElementById("inputPassword");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }
    showHideInputPasswordConfirm(e) {
        e.preventDefault();
        var x = document.getElementById("inputPasswordConfirm");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }
    showHideInputOldPassword(e) {
        e.preventDefault();
        var x = document.getElementById("inputOldPassword");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }
    showHideInputNewPassword(e) {
        e.preventDefault();
        var x = document.getElementById("inputNewPassword");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }
     render() {//marginTop: "15px!important", verticalAlign:"middle!important"
        // <button type="button" className="btn btn-default btn-xs" onClick={this.onShowModalChangeUserNameClick} style={{ marginTop: "20px!Important" }}>Изменить</button>
        /*
          <Modal show={this.state.showChangeUserName} onHide={this.handleCloseChangeUserName}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Изменить имя пользователя(логин)</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <label className="col-md-6 control-label">Имя пользователя(логин):</label>
                                    <div className="col-md-6">
                                        <input className="form-control" id="inputUserName"
                                            value={this.state.newUserName} onChange={this.onChangeNewUserName} />
                                    </div>
                                    <ButtonGroup>
                                        <Button className='btn btn-primary text-center' onClick={this.onChangeUserNameClick} type="button">Изменить</Button>
                                        <Button className='btn btn-default text-center' onClick={this.this.handleCloseChangeUserName} type="button">Отмена</Button>
                                    </ButtonGroup>
                                </Modal.Body>
                            </Modal>
                            <Typeahead
                                onChange={(selected) => {
                                    this.onChangeOrg2(selected);
                                }}
                                options={orgs}
                            />
         */
        let raions = this.props.nsi.allraions.filter(f => f.obl === this.state.obl.kod).map(o => { return { kod: o.kod, nameRu: o.nameRu, nameKz: o.nameKz}; });
        //let orgs = this.props.orgs.filter(f => f.raion === this.state.raion.kod.substring(0,4)).map(o => { return { id: o.kod, label: o.nameRu } });
         return <div className="panel panel-default" style={{ width: 'auto' }}>
            <div className="panel-heading">{this.state.fio} ({this.state.email})</div>
            <div className="panel-body">
                 <form className='form-horizontal' name='changeUser' id='changeUser' onSubmit={this.handleSubmit} autoComplete="off">
                    {!this.state.isNew ?
                        <div className="form-group">
                            <label className="col-sm-4 control-label">Логин:</label>
                            <div className="col-sm-8">
                                <div className="form-control-static text-info" style={{ "float": "left" }}><strong>{this.props.nsi.userNameUpdateResult === true ? this.props.nsi.userNameNew:this.state.username}</strong></div>&nbsp;&nbsp;&nbsp;
                                <button type="button" className="btn btn-default btn-xs" onClick={this.onShowModalChangeUserNameClick} style={{ marginTop: "20px!Important" }}>Изменить</button>
                            </div>
                            <Modal show={this.state.showChangeUserName} onHide={this.handleCloseChangeUserName}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Изменить Логин</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <label className="col-md-6 control-label">Логин:</label>
                                    <div className="col-md-6">
                                         <input className="form-control" id="inputUserName" autoComplete="off"
                                            value={this.state.newUserName} onChange={this.onChangeNewUserName} />
                                    </div>
                                    <ButtonGroup>
                                        <Button className='btn btn-primary text-center' onClick={this.onChangeUserNameClick} type="button">Изменить</Button>
                                        <Button className='btn btn-default text-center' onClick={this.handleCloseChangeUserName} type="button">Отмена</Button>
                                    </ButtonGroup>
                                </Modal.Body>
                            </Modal>
                        </div>
                        : ''}
                    {this.props.nsi.errorsChangeUserName !== undefined ?
                        <div className="alert alert-warning alert-dismissible" role="alert">
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            <strong>Ошибка Логина!</strong> {this.props.nsi.errorsChangeUserName}.
                                </div> : ''}
                    {this.props.nsi.userNameUpdateResult === true ? <div className='text-success'>
                        <div className="alert alert-success alert-dismissible" role="alert">
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            <strong>Логин изменен!</strong>
                        </div>
                    </div> : ''}
                    <div className="form-group">
                         <label className="col-sm-4 control-label">{this.state.isNew ? "Логин(Email)" : "Email"}</label>
                         <div className="col-sm-8">
                             <input className="form-control" id="inputE" autoComplete="off" 
                                    value={this.state.email} onChange={this.onChangeEmail} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="col-sm-4 control-label">Ф.И.О.</label>
                        <div className="col-sm-8">
                             <input className="form-control" id="inputFio" type='text' autoComplete="family-name"
                                value={this.state.fio} onChange={this.onChangeFio} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="col-sm-4 control-label">Уровень доступа</label>
                        <div className="col-sm-8">
                             <select className="form-control" value={this.state.accessLevel}
                                disabled={this.props.noEdit === true} onChange={this.accessLevelHandleChange} required>
                                {this.props.userInfo.user.accessLevel === 1 ? <option value={1}>РК</option> : ''}
                                {this.props.userInfo.user.accessLevel < 3 ? <option value={2}>Область</option> : ''}
                                {this.props.userInfo.user.accessLevel < 4 ? <option value={3}>Район</option> : ''}
                                <option value={4}>Организация</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="col-sm-4 control-label">Область</label>
                        <div className="col-sm-8">
                             <select className="form-control" value={this.state.obl.kod} onChange={this.accessOblHandleChange} required
                                 disabled={this.props.userInfo.user.accessLevel>1}
                             >
                                 {SpObl/*.filter(f => this.props.userInfo.user.accessLevel === 1 || this.props.userInfo.user.kodObl === f.kod)*/.map(o => {
                                    return o !== undefined ?
                                        <option key={o.kod} value={o.kod}>{o.nameRu}</option> : '';
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="col-sm-4 control-label">Район</label>
                        <div className="col-sm-8">
                             <select className="form-control" value={this.state.raion ? this.state.raion.kod : undefined}
                                 disabled={this.props.noEdit===true}
                                 onChange={this.accessRaionHandleChange} required>
                                {raions.map(o => {
                                    return o !== undefined ?
                                        <option key={o.kod} value={o.kod}>{o.nameRu}</option> : '';
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="col-sm-4 control-label">Организация</label>
                        <div className="col-sm-8">
                            <div className="form-control-static">{this.state.org ? this.state.org.nameRu : <span className="text-danger">Выберите организацию</span>}</div>
                             <button type="button" className="btn btn-default btn-xs" onClick={() => this.setState({ showSelectOrgModal: true })}
                                 disabled={this.props.noEdit === true} style={{ marginTop: "20px!Important" }}>Выбрать</button>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="col-sm-4 control-label">Тип пользователя:</label>
                        <div className="col-sm-8">
                             <select className="form-control" value={this.state.typeUser} onChange={this.typeUserHandleChange} required>
                                 {this.props.userInfo.user.accessLevel === 1 ? <Fragment><option value={1}>1 – Администратор администраторов</option>
                                     <option value={2}>2 – Администратор пользователей</option>
                                     <option value={3}>3 - Администратор данных</option>
                                     <option value={4}>4 - Финансовый администратор</option></Fragment> : ""}
                                <option value={5}>5 - пользователь (чтение/запись)</option>
                                <option value={6}>6 - пользователь (только чтение)</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="col-sm-4 control-label">Роли</label>
                        <div className="col-sm-8">
                            <ul className="list-group">
                                {SpRoles.map(o => this.roleItem(o))}
                            </ul>
                        </div>
                    </div>
                    {this.state.isNew ?
                         <div>
                             <GenPssw tiks={this.state.tiks}/>
                            <div className="form-group">
                                <label className="col-sm-4 control-label">Пароль</label>
                                 <div className="col-sm-8">
                                     <div className="input-group">
                                         <input className="form-control" name="inputPassword" id="inputPassword" type='password' autoComplete="new-password" 
                                             value={this.state.password} onChange={this.onChangePassword} />
                                         <span className="input-group-btn">
                                             <button className="btn btn-default reveal" type="button" onClick={this.showHideInputPassword}><i className="glyphicon glyphicon-eye-open"></i></button>
                                         </span>
                                     </div>   
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-4 control-label">Подтверждение пароля</label>
                                 <div className="col-sm-8">
                                     <div className="input-group">
                                         <input className="form-control" name="inputPasswordConfirm" id="inputPasswordConfirm" type='password' autoComplete="new-password"
                                        value={this.state.passwordConfirm} onChange={this.onChangePasswordConfirm} />
                                    {this.state.password !== this.state.passwordConfirm ? <div className='text-danger'>Пароли не совпадают!</div> : ''}
                                         <span className="input-group-btn">
                                             <button className="btn btn-default reveal" type="button" onClick={this.showHideInputPasswordConfirm}><i className="glyphicon glyphicon-eye-open"></i></button>
                                         </span>
                                     </div>
                                 </div>
                            </div>
                        </div>
                        : ''}
                    <button className='btn btn-primary text-center' type="submit">{this.state.isNew ? 'Добавить' : 'Сохранить'}</button>
                </form>
                {!this.state.isNew ?
                     <form className='form-horizontal' name='changePass' id='changePass' autoComplete="off">
                        <h4 className='text-center text-info'><strong>Смена пароля</strong></h4>
                            <div className="form-group">
                                <label className="col-sm-4 control-label">Введите старый пароль</label>
                             <div className="col-sm-8">
                                 <div className="input-group">
                                     <input className="form-control" name="inputOldPassword" id="inputOldPassword" type='password' autoComplete="current-password"
                                         value={this.state.oldPassword} onChange={this.onChangeOldPassword} />
                                     <span className="input-group-btn">
                                         <button className="btn btn-default reveal" type="button" onClick={this.showHideInputOldPassword}><i className="glyphicon glyphicon-eye-open"></i></button>
                                     </span>
                                 </div>
                                </div>
                         </div>
                         <GenPssw tiks={this.state.tiks}/>
                            <div className="form-group">
                                <label className="col-sm-4 control-label">Введите новый пароль</label>
                             <div className="col-sm-8">
                                 <div className="input-group">
                                     <input className="form-control" name="inputNewPassword" id="inputNewPassword" type='password' autoComplete="new-password"
                                         value={this.state.newPassword} onChange={this.onChangeNewPassword} /><span className="input-group-btn">
                                         <button className="btn btn-default reveal" type="button" onClick={this.showHideInputNewPassword}><i className="glyphicon glyphicon-eye-open"></i></button>
                                     </span>
                                 </div>
                                </div>
                        </div>
                        <button className='btn btn-primary text-center' type="button" onClick={this.handleChangePassSubmit}>Изменить пароль</button>
                        {this.props.errorsChangePass !== undefined ?
                            <div className="alert alert-warning alert-dismissible" role="alert">
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                <strong>Ошибка смены пароля!</strong> {this.props.errorsChangePass}.
                                </div> : ''}
                        {this.props.nsi.passUpdateResult === true ? <div className='text-success'>
                            <div className="alert alert-success alert-dismissible" role="alert">
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                <strong>Пароль изменен!</strong>
                            </div>
                        </div>:''}
                   </form>
             : ''}
            </div>
            <SelectOrgModal raion={this.state.raion} setOrg={this.setOrg} showSelectOrgModal={this.state.showSelectOrgModal}
                handleHide={() => this.setState({ showSelectOrgModal: false })} />
        </div>;
    }
    
}
export default connect(
    state => state,//,.nsi,
    dispatch => bindActionCreators(actionCreatorsNsi, dispatch)
)(UserEdit);

//<SelectOrgModal obl={this.state.obl} setOrg={this.setOrg} />
/*

  <FormControl className='input-sm' componentClass="select" placeholder="Выберите организацию" defaultValue={this.state.org.kod} onChange={this.onChangeOrg} style={{ minWidth: '100%' }}>
                                {orgs.map(it => <option key={it.kod} value={it.kod}>{it.nameRu}</option>)}
                            </FormControl>



                            <Typeahead
                                labelKey="nameRu"
                                options={this.props.sprobj.filter(f => f.kobl === this.state.obl.kod).map(o => { return { kod: o.kobj, nameRu: o.nobj, nameKz: o.nobj_kaz, kobl: o.kobl }; })}
                                placeholder="Выберите организацию..."
                                onChange={this.onChangeOrg} />
 */