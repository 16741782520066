import { getToken } from './UserInfo';
const requestGetDataTablePstrAction = 'REQUEST_GET_DATA_TABLE_PSTR';
const receiveGetDataTablePstrAction = 'RECEIVE_GET_DATA_TABLE_PSTR';
const requestUpdateDataPstrAction = 'REQUEST_UPDATE_DATA_TABLE_PSTR';
const receiveUpdateDataPstrAction = 'RECEIVE_UPDATE_DATA_TABLE_PSTR';

const initialState = {
    isLoading: false,
    data: undefined,
    statusUpdate: undefined,
}
export const actionCreators = {
    getData: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestGetDataTablePstrAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApiPstr/GetForm', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveGetDataTablePstrAction, data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    getDataF12: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestGetDataTablePstrAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/DataApiPstr/GetFormF12', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveGetDataTablePstrAction, data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    updateData: (dataTable) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestUpdateDataPstrAction });
        let rows = dataTable.rows.map(r => {
            let values = r.values.map(v => { return { Vl: v.vl, OriginalVl: v.originalVl, Kgr: v.kgr, Str: v.str }; });
            return { Kstr: r.kstr, Values: values };
        });
        let data = {
            Rows: rows,
            Year: dataTable.year,
            Raz: dataTable.raz,
            Period: dataTable.period,
            Tobj: dataTable.tobj,
            Kobj: dataTable.kobj,
            Kf: dataTable.kf,
            Kt: dataTable.kt
        };
        let json = JSON.stringify(data);
        const response = await fetch('api/DataApiPstr/UpdateForm', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const status = await response.json();
            dispatch({ type: receiveUpdateDataPstrAction, status, dataTable });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    }
}
export const reducer = (state, action) => {
    state = state || initialState;
    //get data
    if (action.type === requestGetDataTablePstrAction) {
        return {
            ...state,
            isLoading: true,
            data: undefined,
            statusUpdate: undefined
        };
    }
    if (action.type === receiveGetDataTablePstrAction) {
        return {
            ...state,
            isLoading: false,
            data: action.data
        };
    }
    //update data
    if (action.type === requestUpdateDataPstrAction) {
        return {
            ...state,
            isLoading: true,
            statusUpdate: undefined,
        };
    }
    if (action.type === receiveUpdateDataPstrAction) {
        return {
            ...state,
            isLoading: false,
            statusUpdate: action.status,
            data: action.status.successed === true ? Object.assign(action.dataTable) : state.data,
        };
    }
    return state;
}
