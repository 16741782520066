import React, { useState,useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';

const charset = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*ABCDEFGHIJKLMNOPQRSTUVWXYZ";
//const charset0 = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
//const charset1= "abcdefghijklmnopqrstuvwxyz";
//const charset2= "0123456789";
//const charset3= "!@#$%^&*";
function generatePassword() {
    let password = "";
    //let ac = [];
    for (let i = 0; i < 9; i++) {
        /*
        let c = Math.floor(Math.random() * 4) % 4;
        if (ac.length < 5) {
            if (ac.filter(f => f === c).length === 0) {
                ac.push(c);
            } else {
                if (c === 3) {
                    c = 0;
                } else {
                    c++;
                }
            }
        } else if (ac.filter(f => f === c).length > 0) {
            if (c === 3) {
                c = 0;
            }else {
                c++;
            }
        }
        switch (c) {
            case 0:
                password += charset0.charAt(Math.floor(Math.random() * charset0.length));
                break;
            case 1:
                password += charset1.charAt(Math.floor(Math.random() * charset1.length));
                break;
            case 2:
                password += charset2.charAt(Math.floor(Math.random() * charset2.length));
                break;
            case 3:
                password += charset3.charAt(Math.floor(Math.random() * charset3.length));
                break;
            default:
                password += charset3.charAt(Math.floor(Math.random() * charset3.length));
        }
        */
        password += charset.charAt(Math.floor(Math.random() * charset.length));
        //var randomNumber = Math.floor(Math.random() * charset.length);
        //password += charset.substring(randomNumber, randomNumber + 1);
    }
    return password;
}
function checkPassword(password) {
    var strength = 0;
    if (password.match(/[a-z]+/)) {
        strength += 1;
    }
    if (password.match(/[A-Z]+/)) {
        strength += 1;
    }
    if (password.match(/[0-9]+/)) {
        strength += 1;
    }
    if (password.match(/[!@#$%^&*]+/)) {
        strength += 1;

    }

    //if (password.length < 6) {
    //    display.innerHTML = "minimum number of characters is 6";
    //}

    //if (password.length > 12) {
    //    display.innerHTML = "maximum number of characters is 12";
    //}

    switch (strength) {
        case 0:
            return 0;
        case 1:
            return 25;
        case 2:
            return 50;
        case 3:
            return 75;
        case 4:
            return 100;
    }
    return 0;  
}

export default function GenPssw(props) {
    useEffect(() => {
        setPass(null);
    }, [props.tiks])
    const [pass, setPass] = useState();
    const [checkPass, setCheckPass] = useState(0)
    const genPass = (evt) => {
        evt.preventDefault();
        let pss = generatePassword(); 
        setCheckPass(checkPassword(pss));
        setPass(pss);
    }
    const getSt = (strength) => {
        let cs = {};
        if (strength < 100)
            cs["color"] = "red";
        else
            cs["color"] = "green";
            /*
        switch (strength) {
            case 0:
                cs["color"] = "red";
            case 25:
                cs["color"] = "red";
            case 50:
                cs["color"] = "red";
            case 75:
                cs["color"] = "red";
            case 100:
                cs["color"] = "green";
         }
         */
        return cs;
    }
    return <div style={{ marginLeft:"5px" }}>
        <span className="text-center text-info">Пароль должен содержать 8 символов и содержать в себе цифры, маленькие и заглавные буквы и хотя бы один специальный символ из !@#$%^&*</span>
        <Row><Col sm={2}><Button type="button" onClick={genPass}>Сгенерировать пароль</Button></Col>
            {pass != null ? <Col sm={10}><strong style={getSt(checkPass)}>{pass}</strong> {checkPass < 100 ? <span style={{ color: "red" }}>слабый не подходящий пароль! Сгенерируйте еще раз</span> : <span style={{ color: "green" }}>Строгий пароль! Можно использовать</span>}
                <Button size="sm" style={{ marginLeft: "3px" }} type="button" disabled={checkPass<100}
                    onClick={(evt) => { evt.preventDefault(); navigator.clipboard.writeText(pass) }}>Скопировать в буфер обмена</Button></Col> : ""}
        </Row>
    </div>
}