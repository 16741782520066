import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Table, Button, ButtonGroup } from 'react-bootstrap';

import  NsiModalFilter  from './NsiModalFilter';
import { actionCreators } from '../../store/UserInfo';

class DataTable extends Component {
    constructor(props) {
        super(props);
        this.state = { rows: this.props.data, pk: this.getPKey(this.props.meta.columns), newRow: this.getNewRow(this.props.meta.columns), errorNew: undefined, filter: undefined, isShowModalFilter: false };
        this.renderHeaderTable = this.renderHeaderTable.bind(this);
        this.renderBodyTable = this.renderBodyTable.bind(this);
        this.renderRow = this.renderRow.bind(this);
        this.renderField = this.renderField.bind(this);
        //this.getPKkeyValue = this.getPKkeyValue.bind(this);
        this.getNewRow = this.getNewRow.bind(this);
        this.onChangeNewRow = this.onChangeNewRow.bind(this);
        this.getPKey = this.getPKey.bind(this);
        this.onChangeValueRow = this.onChangeValueRow.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.restoreRow = this.restoreRow.bind(this);
        this.AddRow = this.AddRow.bind(this);
        this.saveData = this.saveData.bind(this);
        this.setEditMode = this.setEditMode.bind(this);
        this.cancelData = this.cancelData.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.filter === undefined)
            this.setState({ rows: nextProps.data, pk: this.getPKey(nextProps.meta.columns), newRow: this.state.newRow || this.getNewRow(nextProps.meta.columns) });
        else
            this.setState({pk: this.getPKey(nextProps.meta.columns), newRow: this.state.newRow || this.getNewRow(nextProps.meta.columns) });
    }
    saveData(e) {
        e.preventDefault();
        this.props.saveDataTable(this.state.rows);
    }
    setEditMode(e) {
        e.preventDefault();
        this.props.editTable();
    }
    cancelData(e) {
        e.preventDefault();
        this.props.cancelData();
    }
    onChangeValueRow(e) {
        let rowColKey = e.target.id;
        const rows = Object.assign(this.state.rows);//[...this.state.rows];
        //console.dir(rows);
        let keys = rowColKey.split('_');//numRow_numCol
        let  ind_row = parseInt(keys[0],10);
        let  ind_col = parseInt(keys[1],10);
        let val = e.target.value;
        let col = this.props.meta.columns[ind_col];
        if (val.length <= col.length) {
            if (val !== /*this.props.data*/rows[ind_row].values[ind_col]/*val.localeCompare(this.props.data[ind_row].values[ind_col]) !== 0*/) {
                rows[ind_row].values.splice(ind_col, 1, val);
                if (rows[ind_row].status !==1 )
                    rows[ind_row].status = 2;
            } else {
                if (this.state.rows[ind_row].status !== 1) {
                    rows[ind_row].status = 1;
                }
            }
            rows[ind_row].values.splice(ind_col, 1, val);
            this.setState({ rows });
        }
    }
    deleteRow(e) {
        e.preventDefault();
        let numRow = e.target.id;
        const rows = Object.assign(this.state.rows);
        let row = rows[numRow];
        let delrow = { ...row };
        if (delrow.status === 1) {
            rows.splice(numRow, 1);
        } else {
            delrow.status = 3;
            rows.splice(numRow, 1, delrow);
        }
        this.setState({ rows });
    }
    restoreRow(e) {
        e.preventDefault();
        let numRow = e.target.id;
        const rows = Object.assign(this.state.rows);
        let row = rows[numRow];
        let delrow = { ...row };
        delrow.status = 2;
        rows.splice(numRow, 1, delrow);
        this.setState({ rows });
    }
    AddRow(e) {
        e.preventDefault();
        this.setState({ errorNew: undefined })
        //проверить pk
        let valsPkNew = []; //зн. рк новой записи
        for (let i in this.state.pk) {
            let col = this.state.pk[i];
            valsPkNew.push(this.state.newRow[col.name]);
        }
        for (let n in this.state.rows) {
            let row = this.state.rows[n];
            let valsPk = [];
            for (let m in this.state.pk) {
                let colIndex = this.state.pk[m].index;
                valsPk.push(row.values[colIndex]);
            }
            let isDbl = true;
            for (var k in valsPk) {
                isDbl = isDbl && (valsPkNew[k] === valsPk[k]);
            }
            if (isDbl === true) {
                this.setState({ errorNew: "Данная запись существует" })
                alert("Данная запись существует");
                return;
            }
        }
        //добавить запись
        const rows = Object.assign(this.state.rows);//[...this.state.rows];
        const nr = { status: 1, values: [] };
        for (let prop in this.state.newRow) {
            nr.values.push(this.state.newRow[prop]);
        }
        rows.push(nr);
        this.setState({ rows, newRow: this.getNewRow(this.props.meta.columns) });
    }
    onChangeNewRow(e) {
        let colName = e.target.id.substr(5);
        let val = e.target.value;
        let newRow = Object.assign(this.state.newRow);
        newRow[colName] = val;
        this.setState({ newRow });
    }
    getNewRow(columns) {
       let newRow = {};
       for (var i in columns) {
           let col = columns[i];
           newRow[col.name] = '';
       }
       return newRow; 
    }
    renderHeaderTable(meta, isEdit) {
        return (<thead>
            <tr><th colSpan={isEdit === true?meta.columns.length+1:meta.columns.length}>{meta.nameRu}</th></tr>
            <tr>{meta.columns.map((col) => <th key={col.name}>{col.name}</th>)}{isEdit === true ? <th></th> : undefined}</tr>
        </thead>);
    }
    renderBodyTable(rows, isEdit) {
        return (
            <tbody>
                {rows.map((row, numRow) => this.renderRow(numRow, row, isEdit))}
                {isEdit === true ? <tr>{this.props.meta.columns.map((col) => <td key={`'new_'+${col.name}`}>
                    <input id={`'new_${col.name}`} style={{ minWidth: '100%', color: 'darkblue' }} type='text' value={this.state.newRow[col.name]} onChange={this.onChangeNewRow} /></td>)}
                    <td><Button bsStyle='primary' bsSize='xsmall' onClick={this.AddRow}>Добавить</Button></td></tr> : undefined} 
            </tbody>
        );
    }
    renderRow(numRow, row, isEdit) {
        let rowKey = `${numRow}`;//this.getPKkeyValue(pk, numRow);
        return (<tr key={rowKey}>
            {row.values.map((val, i) => {
                let rowColKey = `${rowKey}_${i}`;
                return (<td key={rowColKey}>{this.renderField(rowColKey, numRow, i, val || '', isEdit)}</td>);
            })}{isEdit === true ? <td>{this.renderButtomDeleteRestore(numRow, row)}</td> : undefined}
        </tr>);
    }
    renderButtomDeleteRestore(rowKey, row) {
        if (row.status === 3)
            return (<Button id={rowKey} bsStyle='primary' bsSize='xsmall' onClick={this.restoreRow}>Востановить</Button>);
        else
            return (<Button id={rowKey} bsStyle='danger' bsSize='xsmall' onClick={this.deleteRow}>Удалить</Button>);
    }
    renderField(rowColKey, numRow, indCol, val, isEdit) {
        var col = this.props.meta.columns[indCol];
        if (isEdit === true && col.isKey !== true) {
            if (this.state.rows[numRow].status === 2) {//запись изменена
                return (<input id={rowColKey} style={{ minWidth: '100%', color: 'darkblue' }} type='text' value={val} onChange={this.onChangeValueRow} />);
            } else if (this.state.rows[numRow].status === 3) {// запись будет удалена
                return (<del style={{ color: 'red' }}><span style={{ color: 'black' }}>{val}</span></del>);
            } else { //запись не изменна
                return (<input id={rowColKey} style={{ minWidth: '100%' }} type='text' value={val} onChange={this.onChangeValueRow} />);
            }
        } else {
            if (this.state.rows[numRow].status === 3) // запись будет удалена
                return (<del style={{ color: 'red' }}><span style={{ color: 'black' }}>{val}</span></del>);
            else
                return (<span>{val}</span>);
        }
    }
    getPKey(columns) {
        let cols = columns.map((col, index) => { let p = { ...col, index }; return p; });
        return cols.filter(col => col.isKey === true);
    }
    showModalFilter=(e)=>{
        e.preventDefault();
        this.setState({ isShowModalFilter: true});
    }
    hideModalFilter = () => {
        this.setState({ isShowModalFilter: false });
    }
    setFilter = (filter) => {
        if (filter === undefined || filter.length === 0) {
            this.setState({ isShowModalFilter: false, filter: undefined });
            return;
        }
        const rows = Object.assign(this.props.data);
        //let regex1 = /^\w+/;
        let filteredRows = rows.filter((r) => {
            for (var i in this.props.meta.filter) {
                if (filter[i] === undefined) continue;
                let itf = this.props.meta.filter[i];
                let indexCol = this.getIndexCol(itf.col);
                let val = r.values[indexCol];
                switch (itf.func) {
                    case 1:
                        if (val !== filter[i])
                            return false;
                        break;
                    case 2:
                        let valUp = val.toUpperCase()
                        let flUp = filter[i].toUpperCase();
                        if (valUp.indexOf(flUp) !== 0)
                            return false;
                        break;
                    case 3:
                        let valUp2 = val.toUpperCase()
                        let flUp2 = filter[i].toUpperCase();
                        if (valUp2.indexOf(flUp2) < 0)
                            return false;
                        break;
                    default:
                        break;
                }
            }
            return true;
        });
        this.setState({ isShowModalFilter: false, filter ,rows: filteredRows });
    }
    getIndexCol=(name) => {
        for (var i = 0; i < this.props.meta.columns.length; i++) {
            if (this.props.meta.columns[i].name === name)
                return i;
        }
        return NaN;
    }
    clearFilter = () => {
        const rows = Object.assign(this.props.data);
        this.setState({ isShowModalFilter: false, filter: undefined, rows });
    }

    render() {
        //let pk = this.getPKey(this.props.meta.columns);
        return (
            <div>
                {this.props.mode !== 'load' ? <ButtonGroup>
                    {this.props.meta.filter.length > 0 ? <Button type="button" disabled={this.props.mode !== 'view'} onClick={this.showModalFilter}>Фильтр</Button>: undefined}
                    <Button type="button" bsStyle="primary" disabled={this.props.mode !== 'view'} onClick={this.setEditMode}>Редактировать</Button>{' '}
                    <Button type="button" bsStyle="success" disabled={this.props.mode !== 'edit'} onClick={this.saveData}>Сохранить</Button>{' '}
                    <Button type="button" disabled={this.props.mode !== 'edit'} onClick={this.cancelData}>Отменить</Button></ButtonGroup> : undefined}
            <Table striped bordered condensed hover>
                {this.renderHeaderTable(this.props.meta, this.props.mode === 'edit')}
                {this.renderBodyTable(this.state.rows, this.props.mode === 'edit')}
                </Table>
                <NsiModalFilter isShowModalFilter={this.state.isShowModalFilter} hideModalFilter={this.hideModalFilter} setFilter={this.setFilter} clearFilter={this.clearFilter} filter={this.state.filter} meta={this.props.meta} />
            </div>
        );
    }
}
export default connect(
    state => state.userInfo,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(DataTable);
/*
 {props.forecasts.map(forecast =>
                    <tr key={forecast.dateFormatted}>
                        <td>{forecast.dateFormatted}</td>
                        <td>{forecast.temperatureC}</td>
                        <td>{forecast.temperatureF}</td>
                        <td>{forecast.summary}</td>
                    </tr>
                )}
 */