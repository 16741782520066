import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';

import { Translate, I18n } from 'react-redux-i18n';
import { Row, Col, FormGroup/*, FormControl*/, ControlLabel, Form, Button, Panel, Table } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { actionCreators as actionClosingPeriod} from '../../store/ClosingPeriod';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';
import { formatDateISO } from '../common/DateFunctions';


import { years, periods, getCurrentYear } from '../Constants';
class ClosingPeriod extends Component {
    constructor(props) {
        super(props);
        var self = this;
        let nowYear = getCurrentYear();
        this.state = {
            year: props.year != null ? props.year : nowYear,
            kper: props.period !=null ? props.period.kper:4,//4,
            oblsClosings: [],
            isDataSaved:false
        };
        //props.getNs('periods');
        self.props.getClosingData();
        //    .finally(() => {
        //        self.setState({
        //            year: self.props.closingPeriod.dataClosing.year,
        //            kper: self.props.closingPeriod.dataClosing.period.kper
        //        });
        //});
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.closingPeriod.dataClosing !== undefined) {
            let dataClosing = cloneDeep(nextProps.closingPeriod.dataClosing);
            //let year = years.filter(y => y === dataClosing.year)[0];
            this.setState({
                year: dataClosing.year,//parseInt(dataClosing.year, 10),// 
                kper: dataClosing.period.kper,//parseInt(dataClosing.period.kper,10),//
                oblsClosings: dataClosing.oblsClosings.map(it => {
                    return {
                        ...it,
                        dtEndLevelRk: new Date(it.dtEndLevelRk),
                        dtEndLevelObl: new Date(it.dtEndLevelObl),
                        dtEndLevelRaion: new Date(it.dtEndLevelRaion),
                        dtEndLevelOrg: new Date(it.dtEndLevelOrg)
                    }
                })
            });
        }
    }
    onSelectYear = (e) => {
        let year = parseInt(e.target.value, 10);
        this.setState({ year });
    }
    onSelectKper = (e) => {
        let kper = parseInt(e.target.value, 10);
        this.setState({ kper });
    }
    onSubmit = (e) => {
        e.preventDefault();
        var self = this;
        self.setState({ isDataSaved: false });
        let oblsClosings = self.state.oblsClosings.map((it) => {
            return {
                ...it,
                dtEndLevelRk: formatDateISO(it.dtEndLevelRk),
                dtEndLevelObl: formatDateISO(it.dtEndLevelObl),
                dtEndLevelRaion: formatDateISO(it.dtEndLevelRaion),
                dtEndLevelOrg: formatDateISO(it.dtEndLevelOrg)
            };
        });
        self.props.setClosingData({
            year: self.state.year,
            period: { kper: self.state.kper },
            oblsClosings
        }).finally(() => {
            self.setState({ isDataSaved: true });
            self.props.getClosingData();
        });
    }
    render() {
        return <Fragment>
            <h4><Translate value='closingPeriod' /></h4>
            <Row><Col sm={12}><h5>Период редактирования (редактирование форм возможно только по этому периоду):</h5></Col></Row>
            <Form onSubmit={this.onSubmit} horizontal>
                <FormGroup controlId="formYear" className='input-group-sm'>
                    <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='year' />{':'}</ControlLabel></Col>
                    <Col xs={12} sm={10}>
                        <select className="form-control" value={this.state.year} onChange={this.onSelectYear} required>
                            {years.map(it => <option key={it} value={it}>{it}</option>)}
                        </select>
                    </Col>
                </FormGroup>
                <FormGroup controlId="formPer" className='input-group-sm'>
                    <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='period' />{':'}</ControlLabel></Col>
                    <Col xs={12} sm={10}>
                        <select className="form-control" value={this.state.kper} onChange={this.onSelectKper} required>
                            {periods.map(it => <option key={it.kper} value={it.kper}>{I18n.t(it.nameT)}</option>)}
                        </select>
                    </Col>
                </FormGroup>
                <Panel style={{ minWidth: '100%' }} bsStyle="primary">
                    <Panel.Heading><Panel.Title componentClass="h3">Даты закрытия периодов редактирования по областям и уровням</Panel.Title></Panel.Heading>
                    <Panel.Body>
                        <Table striped bordered condensed hover>
                            <thead>
                                <tr><td><Translate value='period' /></td>
                                    <td>дата закрытия уровень Организации</td>
                                    <td>дата закрытия уровень Района</td>
                                    <td>дата закрытия уровень Области</td>
                                    <td>дата закрытия уровень РК</td>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.oblsClosings.map((o,i) => {
                                    return <tr key={o.obl.kod}><td>{o.obl.nameRu}</td>
                                        <td><DatePicker value={o.dtEndLevelOrg} onChange={date => {
                                            let oblsClosings = cloneDeep(this.state.oblsClosings);
                                            let o1 = oblsClosings[i];
                                            o1.dtEndLevelOrg = date;
                                            this.state.oblsClosings.splice(i, 1, o1);
                                            this.setState({ oblsClosings });
                                        }} /></td>
                                        <td><DatePicker value={o.dtEndLevelRaion} onChange={date => {
                                            let oblsClosings = cloneDeep(this.state.oblsClosings);
                                            let o1 = oblsClosings[i];
                                            o1.dtEndLevelRaion = date;
                                            this.state.oblsClosings.splice(i, 1, o1);
                                            this.setState({ oblsClosings });
                                        }} /></td>
                                        <td><DatePicker value={o.dtEndLevelObl} onChange={date => {
                                            let oblsClosings = cloneDeep(this.state.oblsClosings);
                                            let o1 = oblsClosings[i];
                                            o1.dtEndLevelObl = date;
                                            this.state.oblsClosings.splice(i, 1, o1);
                                            this.setState({ oblsClosings });
                                        }} />
                                        </td>
                                        <td><DatePicker value={o.dtEndLevelRk} onChange={date => {
                                                let oblsClosings = cloneDeep(this.state.oblsClosings);
                                                let o1 = oblsClosings[i];
                                                o1.dtEndLevelRk = date;
                                                this.state.oblsClosings.splice(i, 1, o1);
                                                this.setState({ oblsClosings });
                                            }} />
                                        </td>
                                     </tr>;
                                })}
                            </tbody>
                        </Table>
                    </Panel.Body>
                </Panel>
                <Button type="submit"><Translate value='save' /></Button>
                {this.state.isDataSaved === true ? <strong className="text-success">&nbsp;&nbsp;<Translate value='dataSaved' /></strong> : ''}
                {this.props.closingPeriod.isLoading === true ? <Row>
                    <Col smOffset={2} sm={10}>
                        <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('wait')} width={20} />{' '}<strong><Translate value="wait" /></strong></div>
                    </Col>
                </Row> : ''}
            </Form>
        </Fragment>;
    }
}
export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionClosingPeriod }, dispatch)
)(ClosingPeriod);