import React, { Component,Fragment } from 'react';
import { Button,/* Col, FormGroup, FormControl, ControlLabel, Form,*/ Table, ButtonGroup } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { ExportCSV } from '../Work/ExportCSV';
import './ControlResultTable.css';

export default class ControlResultTable extends Component {
    excelDataCSV = () => {
        let dataCSV = [];
        for (var ri in this.props.control.controlResult.protocol) {
            let r = this.props.control.controlResult.protocol[ri];
            dataCSV.push({
                "№ условия": r.nusl,
                "Код формулы": r.p,
                "Ошибка": `${r.ider != null ? r.ider:""} ${r.zamech}`,
                "Формула контроля": r.formul,
                "№ строки": r.nstr,
                "№ графы": r.ngr,
                "Левая часть выражения": r.leftch,
                "Правая часть выражения": r.rightch,
                "Результат сравнения": r.result
            });
        }
        return dataCSV;
    }
    fileName = () => {
        let d = this.props.control.controlResult;
        return `c_${d.typeContr}_${d.raz}_${d.year}_${d.period}_${d.tobj}_${d.kobj}_${d.kf}`;
    }
    printData = (e) => {
        e.preventDefault();
        window.print();
    }
    render() {
        return <Fragment> 
            {this.props.control.isLoading ? <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('wait')} width={20} />{' '}<strong><Translate value="wait" /></strong></div> : ''}
            {renderTableResult(this.props.control.controlResult, this.excelDataCSV, this.fileName, this.printData)}
        </Fragment>;
    }
}
function renderTableResult(controlResult, excelDataCSV, fileName, printData) {
    if (controlResult !== undefined && controlResult.protocol !== undefined) {
        if (controlResult.notExistData) {
            return <h5 style={{ textAlign: "center" }}><strong className="text-danger"><Translate value="noDataForm" /></strong></h5>;
        }
        return (<Fragment>
            <ButtonGroup bsSize="xsmall">
            <Button id='bt' type="button" bsStyle="default"onClick={printData}>Печать</Button>
                <ExportCSV csvData={excelDataCSV()} fileName={fileName()} />
            </ButtonGroup>
            <div className="row"><div className="col-12 text-center">Ведомость контроля и анализа отчета по форме <strong>{controlResult.kf} {controlResult.typeContrName}</strong></div></div>
            <div className="row"><div className="col-12 text-center">за <strong>{controlResult.periodName}</strong></div></div>
            <div className="row"><div className="col-12 text-center">Объект <strong>{controlResult.objName}</strong></div></div>
            <div className="row"><div className="col-12 text-center">Разрез <strong>{controlResult.razName}</strong></div></div>
            <div className="row"><div className="col-12 text-center">Дата и время контроля <strong>{controlResult.dtEval}</strong></div></div>
            <Table striped bordered condensed hover>
            <thead>
                <tr>
                    <th>№ условия</th>
                    <th>Код формулы</th>
                    <th>Ошибка</th>
                    <th>Формула контроля</th>
                    <th>№ строки</th>
                    <th>№ графы</th>
                    <th>Левая часть выражения</th>
                    <th>Правая часть выражения</th>
                    <th>Результат сравнения</th>
                </tr>
            </thead>
                <tbody>
                    {controlResult.protocol.map((r,i) => <tr key={i}><td>{r.nusl}</td><td>{r.p}</td><td>{r.ider} {r.zamech}</td><td>{r.formul === 'noerror' ? <strong>{I18n.t('noerror')}</strong> : r.formul}</td><td>{r.nstr}</td><td>{r.ngr}</td><td>{r.leftch}</td><td>{r.rightch}</td><td>{r.result}</td></tr>)}
                </tbody></Table></Fragment>);
    }
    return '';
}