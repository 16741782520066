import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';
import { actionCreators as actionCreatorsAdminUsers } from '../../store/AdminUsers';

import { Alert, Row, Modal, Button, Col, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';

import { Translate, I18n } from 'react-redux-i18n';

import { getNameLocalize } from '../Constants';

import { UserItemList } from './UserItemList';
import UserEdit from './UserEdit';
import loadingImg from '../../images/loading.gif';//'../images/loading.gif';

const AdminChildsUsers = (props) => {
    const [] = useState(() => {
        if (props.nsi.allobls.length === 0) {
            props.getNs('allobls');
        }
        if (props.nsi.allraions.length === 0) {
            props.getNs('allraions');
        }
        return true;
    }, props.nsi.allobls.length > 0);
    useEffect(() => {
        const getChilds = async () => {
            const orgs = await props.getChildsOrgsByParent(props.userInfo.user.kodOrg);
            if (orgs.length > 0) {
                setChilds(orgs);
                let kodOrg = orgs[0].kod;
                setSelChild(kodOrg);
                await props.requestSearchUsersChildOrgs({
                    searchText: null,
                    startPage: 1,
                    sizePage: 100,
                    tobj: 4,
                    kobl: null,
                    kraion: null,
                    korg: kodOrg,
                });
            }
        }
        getChilds();
    }, [props.userInfo.user.kodOrg]);
    const [childs, setChilds] = useState([]);
    const [selChild, setSelChild] = useState();
    const [selectedUserId, setSelectedUserId] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [password, setPassword] = useState();
    const [passwordConfirm, setPasswordConfirm] = useState();

    const selectUser = (userId) => {
        props.ClearUserUpdateResult();
        let selUser = undefined;
        if (props.adminUsers.usersResult) {
            let findUs;
            if (!Array.prototype.find) //iE11
                findUs = props.adminUsers.usersResult.users.filter(u => u.id === userId)[0];
            else
                findUs = props.adminUsers.usersResult.users.find(u => u.id === userId);
            if (findUs !== undefined) {
                selUser = { ...findUs, isNew: false, password: undefined, passwordConfirm: undefined };
            }
        }
        setSelectedUserId(userId);
        setSelectedUser(selUser);
        setPassword(null);
        setPasswordConfirm(null);
    }
    const selectChild = (kod) => {
        setSelChild(kod);
        setSelectedUser(null);
        //load child users
        props.requestSearchUsersChildOrgs({
            searchText: null,
            startPage: 1,
            sizePage: 100,
            tobj: 4,
            kobl: null,
            kraion: null,
            korg: kod,
        });
    }

    const renderUpdateResult = (userUpdateResult) => {
        if (userUpdateResult) {
            if (userUpdateResult.result) {
                return <div className='text-success'>
                    <div className="alert alert-success alert-dismissible" role="alert">
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <strong>Данные сохранены!</strong>
                    </div>
                </div>;
            } else {
                return <div className="alert alert-warning alert-dismissible" role="alert">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <strong>Ошибка сохранения!</strong> {userUpdateResult.error}.
                    </div>;
                //<div className='text-danger'>Произошла ошибка: <span>{userUpdateResult.error}</span></div>
            }
        }
        return '';
    }
    const updateUser = (user) => {
        //using add or update store
        props.requestInsertOrUpdateUserChildOrgs(user);
        setSelectedUser(user);
    }
    const changePassUser = (username, oldPassword, newPassword) => {
        props.changePassUserChildOrgs({ username, oldPassword, newPassword });
    }
    const changeUserName = (data) => {
        props.changeUserNameChildOrgs(data);
    }
    const [showModalDelete, setShowModalDelete] = useState();
    const [userDelete, setUserDelete] = useState();
    const deleteUser = (user) => {
        setUserDelete(user)
        setShowModalDelete(true);
    }
    const handleCloseModalDeleteUser = () => {
        setShowModalDelete(false)
        setUserDelete(null);
    }
    const handleDeleteUserFromModal = () => {
        setShowModalDelete(false);
        setSelectedUserId(null);
        setSelectedUser(null);
        props.deleteUserChildOrgs(userDelete.id);
        setUserDelete(null);
    }
    const AddOnClick = (event) => {
        event.preventDefault();
        props.ClearUserUpdateResult();
        setSelectedUserId(null);
        let org = childs.filter(f => f.kod === selChild)[0];
        setSelectedUser({
            id: undefined,
            accessLevel: 4,
            typeUser: 5,
            username: '',
            email: '',
            fio: '',
            obl: props.userInfo.user.kodObl ?
                { kod: props.userInfo.user.kodObl, nameRu: props.userInfo.user.nameObl, nameKz: props.userInfo.user.nameOblKz }
                : { kod: '75', nameKz: 'Алматы  қ.', nameRu: 'г.Алматы' },
            org: { kod: org.kod, nameRu: org.nameRu, nameKz: org.nameKz }/*props.userInfo.user.kodOrg ?
                { kod: props.userInfo.user.kodOrg, nameRu: props.userInfo.user.nameOrg, nameKz: props.userInfo.user.nameOrgKz }
                : { kod: '028W', nameKz: 'ГККП "ГОРОДСКАЯ ПОЛИКЛИHИКА N7"', nameRu: 'ГККП "ГОРОДСКАЯ ПОЛИКЛИHИКА N7"' }*/,
            raion: props.userInfo.user.kodRaion ?
                { kod: props.userInfo.user.kodRaion, nameRu: props.userInfo.user.nameRaion, nameKz: props.userInfo.user.nameRaionKz }
                : { kod: '751110000', nameKz: 'Алмалинский район', nameRu: 'Алмалинский район' },
            roles: ["UserEditData"],
            isNew: true,
            password: '',//undefined,
            passwordConfirm: '',//undefined,
            oldPassword: '',//undefined,
            newPassword: '',//undefined,
        });
    }
    return <>
        <h4 className="text-center text-info"><strong><Translate value="childOrgs.users" /></strong></h4>
        <FormGroup controlId="formControlsSelect">
            <ControlLabel><Translate value="childOrgs.childOrg" /></ControlLabel>
            <FormControl componentClass="select" defaultValue={selChild}
                onChange={(evt) => selectChild(evt.target.value)}>
                {childs.map(m => <option value={m.kod} key={m.kod}>{getNameLocalize(props.i18n, m)}</option>)}
            </FormControl>
        </FormGroup>
        {props.adminUsers.isLoading ? <Alert bsStyle="info"><img src={loadingImg} width={20} alt={I18n.t('wait')} />{' '}<strong>Подождите</strong>Идет загрузка данных...</Alert> : ''}
        <Row><Col sm={6} md={4} lg={3}>
            <Row>
                <Col sm={8}>
                    <ControlLabel><Translate value="childOrgs.users2" /></ControlLabel>
                </Col>
                <Col sm={4}>
                    <button type='button' className='btn btn-success btn-sm' onClick={AddOnClick}>
                        <span className='glyphicon glyphicon-plus'></span></button>
                </Col>
            </Row>
        </Col>
        </Row>
        <Row>
            <Col sm={6} md={4} lg={3}>
                <div className="panel panel-default" style={{ width: 'auto' }}>
                    <div className="panel-body">
                        <ul className="list-group">
                            {props.adminUsers.usersResult ? props.adminUsers.usersResult.users.map((user,i) =>
                                <UserItemList key={i} user={user} selectedUserId={selectedUserId} selectUser={selectUser} deleteUser={deleteUser} />
                            ) : ''}
                        </ul>
                    </div>
                </div>
            </Col>
            <Col sm={6} md={8} lg={9}>
                {renderUpdateResult(props.adminUsers.userUpdateResult)}
                {selectedUser ? <UserEdit user={selectedUser} updateUser={updateUser} changeUserName={changeUserName} noEdit={true}
                    changePass={changePassUser} errorsChangeUserName={props.adminUsers.errorsChangeUserName} userNameUpdateResult={props.adminUsers.userNameUpdateResult}
                    userNameNew={props.adminUsers.userNameNew} passUpdateResult={props.adminUsers.passUpdateResult} errorsChangePass={props.adminUsers.errorsChangePass} isAdminAdmin={false} /> : ''}
            </Col>
        </Row>
        <Modal show={showModalDelete} onHide={handleCloseModalDeleteUser}>
            <Modal.Header closeButton>
                <Modal.Title>Удаление пользователя</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Вы дествительно хотите удалить пользователя {userDelete != undefined ? userDelete.fio : ''}?
                    </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleCloseModalDeleteUser}>Закрыть</Button>
                <Button onClick={handleDeleteUserFromModal}>Удалить</Button>
            </Modal.Footer>
        </Modal>
    </>
}
export default connect(
    state => state,
    dispatch => bindActionCreators(Object.assign({}, actionCreatorsAdminUsers, actionCreatorsNsi), dispatch)
)(AdminChildsUsers);