import { I18n } from 'react-redux-i18n';
const requestLoginType = 'REQUEST_LOGIN';
const receiveLoginType = 'RECEIVE_LOGIN';
const errorLoginType = 'ERROR_LOGIN';
//const requestRefreshTokenType = 'REQUEST_REFRESH_TOKEN';
//const receiveRefreshTokenType = 'RECEIVE_REFRESH_TOKEN';
/*export*/ const LogoutType = 'LOGOUT';

let user = undefined;
let loggingIn = false;
let userJson = localStorage.getItem('user_medstat');
if (userJson !== null) {
    let usr = JSON.parse(userJson);
    let expidred = new Date(usr.tokenExpires);
    let now = new Date();
    if (expidred.getTime() - now.getTime() > 0) {
        user = usr;
        loggingIn = true;
    }
}

const initialState = { user: user, isLoading: false, loggingIn, error: undefined };

export const getToken = async (dispatch, userInfo) => {
    if (userInfo === undefined || userInfo.user === undefined) {
        return;
    }
    let expidred = new Date(userInfo.user.tokenExpires);
    let now = new Date();
    let secondExpired = (expidred.getTime() - now.getTime())/1000; //сколько секунд осталось 
    if (secondExpired < 0) {
        dispatch({ type: LogoutType });
        return undefined;
    }
    var token = userInfo.user.token;
    if (secondExpired < 30) { //токену осталось меньше пол минуты, обновить если надо
        //обновить токен
        await refreshToken(dispatch,token);
    }
    //старый токен
    return token;
};
var refreshToken = async (dispatch, token) =>  {
    //dispatch({ type: requestRefreshTokenType });
    const url = "api/Users/RefreshToken";
    const response = await fetch(url, {
        credentials: 'include',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    var status = response.status;
    const user = await response.json();
    dispatch({ type: receiveLoginType, user, status });
};
export const actionCreators = {
    login: (params) => async (dispatch, getState) => {
        dispatch({ type: requestLoginType });

        //const url = `api/SampleData/WeatherForecasts?startDateIndex=${startDateIndex}`;
        //const response = await fetch(url);
        let json = JSON.stringify(params);
        try {
            const response = await fetch("api/Users/Authenticate", {
                //credentials: 'include',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: json
            }).catch(error => {
                //console.dir(error);
                dispatch({ type: errorLoginType, error: I18n.t("login.userNamePassInvalid")});
                return;
            });
            var status = response.status;
            const user = await response.json();
            dispatch({ type: receiveLoginType, user, status });
        } catch (err) {
            //console.dir(err);
            dispatch({ type: errorLoginType, error: I18n.t("login.userNamePassInvalid") });
        };
    },
    //refreshToken: () => async (dispatch, getState) => {
    //    let userInfo = getState().userInfo;
    //    const url = "api/Users/RefreshToken";
    //    const response = await fetch(url, {
    //        credentials: 'include',
    //        headers: {
    //            'Authorization': `Bearer ${userInfo.token}`
    //        }
    //    });
    //    var status = response.status;
    //    const user = await response.json();
    //    dispatch({ type: receiveLoginType, user, status });
    //},
    logout: () => ({ type: LogoutType }),
  /*
    logout: () => async (dispatch) => {
         await fetch("api/Users/SignOut", {
            //credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).catch(error => {
            console.dir(`SignOut error:${error}`);
        });
    
        //var status = response.status;
        dispatch({ type: LogoutType });
    },
    */
    searchOrg: (oblKod, orgStr) => async(dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        const url = `api/ManageUsers/searchOrg?obl=${oblKod}&strOrg=${orgStr}`;
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }});
        /*const data =*/return await response.json();
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === requestLoginType) {
        localStorage.removeItem('user_medstat');
        return {
            ...state,
            user: undefined,
            error: undefined,
            loggingIn: false,
            isLoading: true
        };
    }

    if (action.type === receiveLoginType) {
        let user = action.status === 200 ? action.user : undefined;
        let loggingIn = false;
        let error = undefined;
        if (user !== undefined) {
            user.serverDt = user.serverDt != null ? new Date(user.serverDt) : null;
            if (user.isPaid === true) {
                localStorage.setItem('user_medstat', JSON.stringify(user));
                loggingIn = true;
            } else {
                user = undefined;
                localStorage.removeItem('user_medstat');
                loggingIn = false;
                error = I18n.t("login.notPaid");
            }
        }
        return {
            ...state,
            user: user,
            loggingIn: loggingIn,
            error: error,
            isLoading: false
        };
    }

    if (action.type === LogoutType) {
        localStorage.removeItem('user_medstat');
        return {
            ...state,
            user: undefined,
            error: undefined,
            loggingIn: false,
            isLoading: false
        };
    }
    if (action.type === errorLoginType) {
        //localStorage.removeItem('user_medstat');
        return {
            ...state,
            error: action.error,
            isLoading: false
        };
    }

    return state;
};
