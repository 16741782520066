import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Alert, Row, Table, Button, Col, Panel } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';
import SelectObject from '../common/SelectObject';
import { getNameLocalize } from '../Constants';

class AdminChildsOrg extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tobj: 4,//props.userInfo.user.accessLevel, // тип обьекта 1 – РК; 2 – область; 3 - район; 4 - организация (доступ зависит от пользователя)
            obl: undefined,
            raion: undefined,
            org: undefined,
        }
        this.showRow = this.showRow.bind(this);
        this.addChild = this.addChild.bind(this);
        this.removeChild = this.removeChild.bind(this);
    }
    componentWillMount() {
        // This method runs when the component is first added to the page
        this.props.requestGetChildsOrgs();
    }
    removeChild(evt) {
        evt.preventDefault();
        let kod = evt.currentTarget.id.substr(5);
        this.props.removeChildOrg(kod);
    }
    showRow(r) {
        return <tr key={r.kod}><td>{r.kod}</td><td>{getNameLocalize(this.props.i18n, r)}</td><td><Button id={`btDel${r.kod}`} bsSize="small" onClick={this.removeChild} className='btn btn-danger text-center' type="button"><Translate value="childOrgs.del" /></Button></td></tr>;
    }
    addChild() {
        if (this.state.org.korg !=null && this.state.org.korg != this.props.userInfo.user.kodOrg) {
            this.props.addChildOrg(this.state.org.korg);
        }
    }
    render() {
        return (<div className='small'>
            <Row>
                <Col md={12}><h4 className='h4 text-info'>
                    <Translate value="childOrgs.title" /></h4>
                </Col>
            </Row>
            <Panel bsSize="small" default>
                <Panel.Heading><Translate value="childOrgs.addChild" /></Panel.Heading>
                <Panel.Body>
                    <Row bsSize="sm">
                        <Col md={12}>
                            <SelectObject hideTorg={true}
                                tobj={this.state.tobj}
                                obl={this.state.obl}
                                raion={this.state.raion}
                                org={this.state.org}
                                setTobj={(tobj) => this.setState({ tobj })}
                                setObl={(obl) => this.setState({ obl })}
                                setRaion={(raion) => this.setState({ raion })}
                                setOrg={(org) => this.setState({ org })} disabled={this.props.nsi.isLoading === true} />
                            <Button bsSize="small" className='btn btn-primary text-center' type="button" onClick={this.addChild}><Translate value="childOrgs.addChild" /></Button>
                        </Col>
                    </Row>
                    {this.props.nsi.updateStatus!=null?
                    <Row>
                        <Col md={12}>
                                {this.props.nsi.updateStatus.successed === true ?
                                    <Alert bsStyle= "success" > <Translate value={this.props.nsi.updateStatus.message} /></Alert>
                                    : <Alert bsStyle="danger"><Translate value={this.props.nsi.updateStatus.message} /></Alert>}
                        </Col>
                        </Row> : ''}
                </Panel.Body>
            </Panel>
            <Row>
                <Col md={12}>
                    <Table striped bordered condensed hover>
                        <thead>
                            <tr>
                                <th><Translate value="childOrgs.kodOrg" /></th>
                                <th><Translate value="childOrgs.nameOrg" /></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.nsi.childsOrgs.map(r => this.showRow(r))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </div>);
    }
}
export default connect(
    state => state,
    dispatch => bindActionCreators(actionCreatorsNsi, dispatch)
)(AdminChildsOrg);