import React, { Fragment } from 'react';


export default function CheckBox50pers(props) {
    const on50pers = (vl) => {
        props.onChange({ kod: props.kod, is50pers: vl === true? 1: 0});
    }
    return <div className="obj_name" style={{ paddingLeft: "6px", fontWeight: "bold" }}>
        <span className={props.checked === true ? "label label-primary" : "text-default"}><i className={`glyphicon glyphicon-${props.checked === true ? "check" : "unchecked"}`}
            onClick={() => on50pers(!props.checked)} style={{ cursor: "pointer" }}></i></span>
    </div>;
}