import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ListGroup, ListGroupItem, FormGroup, FormControl, ControlLabel, Row, Col } from 'react-bootstrap';
import { Translate/*, I18n*/ } from 'react-redux-i18n';

import { actionCreators } from '../../store/Medinfo/FormulMedinfo';
class EditParts extends Component {
    constructor(props) {
        super(props);
        var self = this;
        this.state = {
            parts: [],
            selectedPart: undefined
        };
        this.showListParts = this.showListParts.bind(this);
        this.showSelectPart = this.showSelectPart.bind(this);
        this.handleChangePartNameRu = this.handleChangePartNameRu.bind(this);
        this.handleChangePartNameKz = this.handleChangePartNameKz.bind(this);
        this.handleChangeListPages = this.handleChangeListPages.bind(this);
        this.onClickPart = this.onClickPart.bind(this);
        this.refreshSelectedPart = this.refreshSelectedPart.bind(this);
        this.props.getParts().finally(() => {
            let selectedPart = (self.props.parts!=null && self.props.parts.length > 0) ? self.props.parts[0] : undefined;
            self.setState({ parts: self.props.parts, selectedPart});
        });
    }
    onClickPart = (e) => {
        let partId = parseInt(e.target.id, 10);
        let selectedPart = this.state.parts.filter(t => t.partId === partId)[0];
        this.setState({selectedPart});
    }
    refreshSelectedPart(selectedPart) {
        let index = this.state.parts.map(e => e.partId).indexOf(selectedPart.partId);
        this.state.parts.splice(index, 1, selectedPart);
        this.setState({ selectedPart });
    }
    handleChangePartNameRu = async (e) => {
        let selectedPart = { ...this.state.selectedPart };
        selectedPart.nameRu = e.target.value;
        //update part
        await this.props.updatePart(selectedPart);
        this.refreshSelectedPart(selectedPart);
    }
    handleChangePartNameKz = async (e) => {
        let selectedPart = { ...this.state.selectedPart };
        selectedPart.nameKz = e.target.value;
        //update part
        await this.props.updatePart(selectedPart);
        this.refreshSelectedPart(selectedPart);
    }
    handleChangeListPages = async (e) => {
        let selectedPart = { ...this.state.selectedPart };
        selectedPart.listPages = e.target.value;
        //update part
        await this.props.updatePart(selectedPart);
        this.refreshSelectedPart(selectedPart);
    }

    showListParts() {
        if (this.state.parts != null && this.state.parts.length > 0) {
            // style={{ maxHeight: 600, overflowY: "auto" }}
            return <ListGroup>
                {this.state.parts.map(p => {
                    if (this.state.selectedPart != null && this.state.selectedPart.partId === p.partId)
                        return <ListGroupItem key={p.partId} id={p.partId} active
                            onClick={this.onClickPart}>{p.kod} {p.nameRu} </ListGroupItem>;
                    else
                        return <ListGroupItem key={p.partId} id={p.partId}
                            onClick={this.onClickPart}>{p.kod} {p.nameRu}</ListGroupItem>;
                })}
            </ListGroup>;
        }
        return '';
    }
    showSelectPart() {
        if (this.state.selectedPart == null) {
            return '';
        }
        return <Fragment>
            <FormGroup controlId="part-name-ru">
                <Col sm={12}><ControlLabel>Наименование(рус){':'}</ControlLabel></Col>
                <Col sm={12}><FormControl
                    componentClass="textarea"
                    value={this.state.selectedPart.nameRu}
                    placeholder="Enter text"
                    onChange={this.handleChangePartNameRu}
                /></Col>
            </FormGroup>
            <FormGroup controlId="part-name-kz">
                <Col sm={12}><ControlLabel>Наименование(каз){':'}</ControlLabel></Col>
                <Col sm={12}><FormControl
                    componentClass="textarea"
                    value={this.state.selectedPart.nameKz}
                    placeholder="Enter text"
                    onChange={this.handleChangePartNameKz}
                /></Col>
            </FormGroup>
            <FormGroup controlId="part-list-pages-kz">
                <Col sm={12}><ControlLabel>Список страниц{':'}</ControlLabel></Col>
                <Col sm={12}><FormControl
                    componentClass="textarea"
                    value={this.state.selectedPart.listPages}
                    placeholder="Enter text"
                    onChange={this.handleChangeListPages}
                /></Col>
            </FormGroup>
         </Fragment>;
    }
    render() {
        return <Fragment>
            <h4><Translate value="editPartsMedinfo" /></h4>
            <Row>
                <Col md={12}>
                    {this.showListParts()}
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    {this.showSelectPart()}
                </Col>
            </Row>
        </Fragment>;
    }
}
export default connect(
    state => state.formulmedinfo,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(EditParts);