import { getToken } from './UserInfo';
const requestNsiMetadataAction = 'REQUEST_NSI_METADATA';
const receiveNsiMetadataAction = 'RECEIVE_NSI_METADATA';
const requestGetDataTableAction = 'REQUEST_NSI_GET_DATA_TABLE';
const receiveGetDataTableAction = 'RECEIVE_NSI_GET_DATA_TABLE';
const requestSaveDataTableAction = 'REQUEST_NSI_SAVE_DATA_TABLE';
const receiveSaveDataTableAction = 'RECEIVE_NSI_SAVE_DATA_TABLE';
const requestNs = 'REQUEST_NS';
const receiveNs = 'RECEIVE_NS';
const requestChildsOrgsAction = 'REQUEST_CHILDS_ORGS';
const receiveChildsOrgsAction = 'RECEIVE_CHILDS_ORGS';
const requestAddChildOrgAction = 'REQUEST_ADD_CHILD_ORG';
const receiveAddChildOrgAction = 'RECEIVE_ADD_CHILD_ORG';
const requestRemoveChildOrgAction = 'REQUEST_REMOVE_CHILD_ORG';
const receiveRemoveChildOrgAction = 'RECEIVE_REMOVE_CHILD_ORG';
const requestGetOrgsByOblKodName = 'REQUEST_GETORGSBYOBLKODNAME';
const receiveGetOrgsByOblKodName = 'RECEIVE_GETORGSBYOBLKODNAME';

const initialState = {
    isLoading: false, nsimeta: [], dataTable: undefined, updateStatus: undefined, errors: undefined,
    periods: [], sprraz: [], sprform: [], sprrazdel:[], allsprtab: [], sprtab: [], torg: [], nomenkl:[],
    allobls: [], allraions: [], orgs: [], childsOrgs: [], versions_history: []
}

export const actionCreators = {
    getNs: (ns, params) => async (dispatch, getState) => {
        if (getState().nsi[ns] !== undefined && getState().nsi[ns].length > 0 && ns.startsWith('all'))
            return;
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestNs, ns });
        let url = `api/SprApi/${ns}`;
        if (params !== undefined) {
            for (var i = 0; i < params.length; i++) {
                url += (i === 0 ? "?" : "&") + params[i].name + "=" + params[i].value;
            }
        }
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        dispatch({ type: receiveNs, data, ns });
    },
    getClassMkb: (kf, ktab) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        const url = `api/SprApi/GetClassMkb/${kf}/${ktab}`;
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.status === 204)//no content
            return null;
        return await response.json();
    },
    getOrgByKod: (kod) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        const url = `api/SprApi/orgByKod?kodOrg=${kod}`;
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.status === 204)//no content
            return null;
        return await response.json();
    },
    getOrgsByOblKodName: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestGetOrgsByOblKodName });
        let json = JSON.stringify(params);
        const url = "api/SprApi/orgsByOblKodName";
        const response = await fetch(url, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        const listOrgs = await response.json();
        dispatch({ type: receiveGetOrgsByOblKodName, listOrgs });
    },
    getOrgsParentKodAndKodName: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        let json = JSON.stringify(params);
        const url = "api/SprApi/getOrgsParentKodAndKodName";
        const response = await fetch(url, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        return await response.json();
    },
    checkKodOrg: (kod) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        const url = `api/SprApi/checkKodOrg?kodOrg=${kod}`;
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok === true) {
            return await response.json();
        } else {
            alert("error checkKodOrg");
        }
    },
    getSubOrgs: () => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        const url = 'api/SprApi/getSubOrgs';
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok === true) {
            return await response.json();
        } else {
            alert("error requestGetChildsOrgs");
        }
    },
    getChildsOrgsByParent: (kodParentOrg) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        const url = `api/SprApi/getChildsOrgsByParent?kodParentOrg=${kodParentOrg}`;
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok === true) {
            return await response.json();
        } else {
            alert("error requestGetChildsOrgs");
        }
    },
    requestGetChildsOrgs: () => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestChildsOrgsAction });
        const url = 'api/SprApi/getChildsOrgs';
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok === true) {
            const data = await response.json();
            dispatch({ type: receiveChildsOrgsAction, list: data });
            //return data;
        } else {
            alert("error requestGetChildsOrgs");
        }
        //return [];
    },
    addChildOrg: (korg) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestAddChildOrgAction });
        const url = `api/SprApi/addChildOrg?korg=${korg}`;
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok === true) {
            const data = await response.json();
            dispatch({ type: receiveAddChildOrgAction, list: data.list, updateStatus: data.status });
        } else {
            alert("error requestAddChildOrg");
        }
    },
    addChildOrgByParent: (korg, parentKod) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        const url = `api/SprApi/addChildOrgByParent?korg=${korg}&parentKod=${parentKod}`;
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok === true) {
            return await response.json();
        } else {
            alert("error requestAddChildOrg");
        }
    },
    removeChildOrg: (korg) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestRemoveChildOrgAction });
        const url = `api/SprApi/removeChildOrg?korg=${korg}`;
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok === true) {
            const data = await response.json();
            dispatch({ type: receiveRemoveChildOrgAction, list: data.list, updateStatus: data.status });
        } else {
            alert("error requestAddChildOrg");
        }
    },
    removeChildOrgByParent: (korg, parentKod) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        const url = `api/SprApi/removeChildOrgByParent?korg=${korg}&parentKod=${parentKod}`;
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok === true) {
            return await response.json();
        } else {
            alert("error removeChildOrgByParent");
        }
    },
    requestNsiMetadata: () => async (dispatch, getState) => {
        if (getState().nsi.nsimeta.length > 0) return; 
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestNsiMetadataAction });
        const url = 'api/Nsi/nsimetadata';
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        dispatch({ type: receiveNsiMetadataAction, nsimeta: data });
    },
    getDataTable: (table) => async (dispatch, getState) => {
        //if (getState().nsi.nsimeta.length > 0) return;
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestGetDataTableAction });
        const url = `api/Nsi/datatable/${table}`;
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        dispatch({ type: receiveGetDataTableAction, data });
    },
    saveDataTable: (table, dataTable) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestSaveDataTableAction });
        let json = JSON.stringify(dataTable);
        const url = `api/Nsi/datatable/${table}`;
        const response = await fetch(url, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        const status = await response.json();
        dispatch({ type: receiveSaveDataTableAction, status });
    },
    searchOrg: (oblKod, orgStr) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        const url = `api/ManageUsers/searchOrg?obl=${oblKod}&strOrg=${orgStr}`;
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        /*const data =*/return await response.json();
    },
    //let url = `api/SprApi/${ns}`;
    searchOrgRaion: (raionKod, orgStr) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        const url = `api/ManageUsers/searchOrgRaion?raion=${raionKod}&strOrg=${orgStr}`;
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        /*const data =*/return await response.json();
    },
    getOkpolus: (kobl) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        const url = `api/EditOkpoluApi/GetOkpolus?kodObl=${kobl}`;
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok === true) {
            return await response.json();
        } else {
            alert("error  getOkpolus");
        }
    },
    updateOkpoluItem: org => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        let json = JSON.stringify(org);
        const response = await fetch('api/EditOkpoluApi/UpdateOkpoluItem', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            return await response.json();
        } else {
            return {isOk: false, error:"Ошибка при сохранении"};
        }
    },
    addOkpoluItem: org => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        let json = JSON.stringify(org);
        const response = await fetch('api/EditOkpoluApi/AddOkpoluItem', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            return await response.json();
        } else {
            return { isOk: false, error: "Ошибка при сохранении" };
        }
    },
    editNameOkpoluItem: org => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        let json = JSON.stringify(org);
        const response = await fetch('api/EditOkpoluApi/EditNameOkpoluItem', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            return await response.json();
        } else {
            return { isOk: false, error: "Ошибка при сохранении" };
        }
    },
    getNaselRaion: (kodRaion) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        //console.info(`call getFullObjTe(${te})`);
        const url = `api/SprApi/getNaselRaion?kodRaion=${kodRaion}`;
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return await response.json();
    },
}

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === requestNs) {
        let nsstate= {
            ...state,
            isLoading: true
        };
        nsstate[action.ns] = [];
        return nsstate
    }
    if (action.type === receiveNs) {
        let nsstate = {
            ...state,
            isLoading: false,
        };
        nsstate[action.ns] = action.data;
        return nsstate
    }

    if (action.type === requestChildsOrgsAction) {
        let nsstate = {
            ...state,
            childsOrgs:[],
            isLoading: true
        };
        nsstate[action.ns] = [];
        return nsstate
    }
    if (action.type === receiveChildsOrgsAction) {
        return {
            ...state,
            childsOrgs: action.list,
            isLoading: false,
        };
    }

    if (action.type === requestAddChildOrgAction) {
        let nsstate = {
            ...state,
            updateStatus: undefined,
            isLoading: true
        };
        nsstate[action.ns] = [];
        return nsstate
    }
    if (action.type === receiveAddChildOrgAction) {
        return {
            ...state,
            updateStatus: action.updateStatus,
            childsOrgs: action.list,
            isLoading: false,
        };
    }

    if (action.type === requestRemoveChildOrgAction) {
        let nsstate = {
            ...state,
            updateStatus: undefined,
            isLoading: true
        };
        nsstate[action.ns] = [];
        return nsstate
    }
    if (action.type === receiveRemoveChildOrgAction) {
        return {
            ...state,
            updateStatus: action.updateStatus,
            childsOrgs: action.list,
            isLoading: false,
        };
    }

    if (action.type === requestNsiMetadataAction) {
        return {
            ...state,
            isLoading: true,
            nsimeta: []
        };
    }
    if (action.type === receiveNsiMetadataAction) {
        return {
            ...state,
            isLoading: false,
            nsimeta: action.nsimeta,
        };
    }

    if (action.type === requestGetDataTableAction) {
        return {
            ...state,
            isLoading: true,
            dataTable: undefined,
            updateStatus: undefined
        };
    }
    if (action.type === receiveGetDataTableAction) {
        return {
            ...state,
            isLoading: false,
            dataTable: action.data,
        };
    }

    if (action.type === requestSaveDataTableAction) {
        return {
            ...state,
            isLoading: true,
            updateStatus: undefined,
            errors: undefined
        };
    }
    if (action.type === receiveSaveDataTableAction) {
        return {
            ...state,
            isLoading: false,
            updateStatus: action.status,
        };
    }
    if (action.type === requestGetOrgsByOblKodName) {
        return {
            ...state,
            isLoading: true,
            orgs: [],
            errors: undefined
        };
    }
    if (action.type === receiveGetOrgsByOblKodName) {
        return {
            ...state,
            isLoading: false,
            orgs: action.listOrgs,
        };
    }
    return state;
}