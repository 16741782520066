import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import * as FileSaver from 'file-saver';
import { Row, Col, FormGroup, FormControl, ControlLabel, Form, Button, Table } from 'react-bootstrap';

import { actionCreators as actionCreatorsWork } from '../../store/Work';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';

import SelectObject from '../common/SelectObject';

import { years, getNameLocalize, getCurrentYear } from '../Constants';
import FilterRazOptions from '../common/FilterRazOptions';
import CheckBoxStatus from '../common/CheckBoxStatus';

class StatusForm extends Component {
    constructor(props) {
        super(props);
        let nowYear = getCurrentYear();
        this.state = {
            year: props.year !== undefined ? props.year : nowYear,
            kper: 4,//период
            kraz: 1,//this.props.userInfo.user.accessLevel !== 4 ? 1 : 3, //разрез
            tobj: props.userInfo.user.accessLevel,
            obl: undefined,
            raion: undefined,
            org: undefined,
            kodNameSearch: undefined
        };
        this.showStatusData = this.showStatusData.bind(this);
        this.renderRowStatus = this.renderRowStatus.bind(this);
        this.onChangeStatusKf = this.onChangeStatusKf.bind(this);
        this.printExcel = this.printExcel.bind(this);
        this.filtrOrg = this.filtrOrg.bind(this);
        if (props.nsi.periods.length === 0) props.getNs('periods');
        if (props.nsi.sprraz.length === 0) props.getNs('sprraz');
        if (props.nsi.sprform.length === 0) props.getNs('sprform');
    }

    onSelectYear = (e) => {
        let year = parseInt(e.target.value, 10);
        this.setState({ year });
    }
    onSelectKper = (e) => {
        let kper = parseInt(e.target.value, 10);
        this.setState({ kper });
    }
    onSelectKraz = (e) => {
        let kraz = parseInt(e.target.value, 10);
        this.setState({ kraz });
    }
    onOpenStatus = (e) => {
        e.preventDefault();
        let kobj = "";
        switch (this.state.tobj) {
            case 1://РК
                kobj = "00";
                break;
            case 2://Область
                kobj = this.state.obl.kobl;
                break;
            case 3://Район
                kobj = this.state.raion.kraion;
                break;
            case 4://Организация
                kobj = this.state.org.korg;
                break;
            default:
                break;
        }
        this.props.getStatusInfoData({
            year: this.state.year,
            period: this.state.kper,
            raz: this.state.kraz,
            tobj: this.state.tobj,
            kobj
        });
    }
    getObjectTypeName(tobj) {
        switch (tobj) {
            //case 1://РК
            //    return "Республика:"f;
            case 2://Область
                return `${I18n.t("obl")}:`;
            case 3://Район
                return `${I18n.t("raion")}:`;
            case 4://Организация
                return `${I18n.t("org")}:`;
            default:
                break;
        }
        return "";
    }
    filtrOrg(org) {
        let kodName = this.state.kodNameSearch;
        if (kodName == null || kodName === "") return true;
        if (kodName != '' && (org.kod != kodName && !org.nameRu.toLowerCase().includes(kodName.toLowerCase()) && !org.nameKz.toLowerCase().includes(kodName.toLowerCase()))) return false;
        return true;
    }
    showStatusData() {
        if (this.props.work.statusInfo === undefined) return '';
        let statusInfo = this.props.work.statusInfo;
        //let statusInfo = {
        //    namePeriod: "за 2021 год",
        //    nameRaz: "Село",
        //    dtRun:"18.01.2022 12:00",
        //    rows: [{kod:"0286", nameRu: "Поликлиника 10", nameKz: "Поликлинника 10", status: [{ kf: '12', status: 1 }, { kf: '30', status: 2 }] }]
        //    };
        return <Fragment>
            <Row>
                <Col xs={12} sm={6}><h5 style={{ textAlign: "center" }}><strong><Translate value='statusForm.title' /></strong></h5></Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}><strong>Период: {statusInfo.namePeriod}</strong></Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}><strong>Разрез: {statusInfo.nameRaz}</strong></Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}><strong>Дата и время создания: {statusInfo.dtRun}</strong></Col>
            </Row>
            {statusInfo.rows.length > 0 ? <Fragment><Row>
                <Col xs={12} sm={6}><Button bsSize='small' onClick={this.printExcel}><Translate value="infoData.printExcel" /></Button></Col>
            </Row>
                {this.state.tobj < 4 ? <Form inline style={{ fontSize: "9pt" }}>
                    <FormGroup bsSize="small">
                        <ControlLabel><Translate value="org" /> <Translate value="editOrgs.kodName" /></ControlLabel>
                        <FormControl type="text" value={this.state.kodNameSearch} disabled={this.props.work.isLoading === true} style={{ width: "400px" }}
                            onChange={(evt) => this.setState({ kodNameSearch: evt.target.value != null ? evt.target.value : '' })}
                        />
                    </FormGroup>
                    <Button type="button" onClick={() => this.setState({ kodNameSearch: "" })} disabled={this.props.work.isLoading === true}
                        bsSize="sm"><Translate value="editOrgs.clearFilter" /></Button>
                </Form> : ""}
            </Fragment> : ""}
            <Table bordered>
                <thead>
                    <tr>
                        <th><Translate value='statusForm.tit2' />
                            <span style={{ marginLeft: "30px" }}><span className="label label-default" style={{ paddingTop: "7px" }}><i style={{ fontSize: '11pt' }} className="glyphicon glyphicon-unchecked"></i></span>{' '}<Translate className="text-deafult" value='statusForm.titFormUncheck' /></span>
                            <span style={{ marginLeft: "25px" }}><span className="label label-primary" style={{ paddingTop: "7px" }}><i style={{ fontSize: '11pt' }} className="glyphicon glyphicon-check"></i></span>{' '}<Translate className="text-primary" value='statusForm.titFormCheck' /></span></th>
                    </tr>
                </thead>
                <tbody>
                    {statusInfo.rows.filter(f => this.filtrOrg(f)).map(r => this.renderRowStatus(r))}
                </tbody>
            </Table>
        </Fragment>;
    }
    renderRowStatus(r) {
        return <tr key={r.kod}><td><strong>{r.kod}</strong> {getNameLocalize(this.props.i18n, r)}<br />
            {r.status.map(s => <CheckBoxStatus key={s.kf} korg={r.kod} kf={s.kf} checked={s.status} onChange={this.onChangeStatusKf} />)}
        </td></tr>
    }
    onChangeStatusKf(d) {
        //console.dir(d);
        this.props.updateStatusInfoData({ year: this.state.year, kraz: this.state.kraz, kper: this.state.kper, kod: d.korg, kf: d.kf, status: d.status });
    }
    printExcel = async () => {
        let kobj = "";
        let strObj = 0;
        switch (this.state.tobj) {
            case 1://РК
                kobj = "00";
                break;
            case 2://Область
                kobj = this.state.obl.kobl;
                strObj = 1;
                break;
            case 3://Район
                kobj = this.state.raion.kraion;
                break;
            case 4://Организация
                kobj = this.state.org.korg;
                break;
            default:
                break;

        }
        let absoluteUrl = `${document.location.protocol}//${document.location.host}/generate/Excel/InputInfo/${this.state.year}/${this.state.kper}/${this.state.kraz}/${this.state.tobj}/${kobj}/${strObj}`;
        let fileName = `inf_${this.state.year}_${this.state.kper}_${this.state.kraz}_${this.state.tobj}_${kobj}_${strObj}.xlsx`;
        var token = this.props.userInfo.user.token;
        const response = await fetch(absoluteUrl, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const blob = await response.blob();
            FileSaver.saveAs(blob, fileName);
            //this.setState({ isLoading: false });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    }
    render() {
        let isReadOnLy = this.props.userInfo.user.roles.filter(r => r === 'UserReadData').length > 0;
        if (isReadOnLy === true)
            return <Translate value="noAccess"/>
        else
        return <Fragment>
            <Form onSubmit={this.onOpenStatus}>
                <Row>
                    <Col xs={12} sm={12}><h5 style={{ textAlign: "center" }}><strong><Translate value='statusForm.title' /></strong></h5></Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12}>
                        <FormGroup controlId="formYear" className='input-group-sm'>
                            <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='year' />{':'}</ControlLabel></Col>
                            <Col xs={12} sm={10}>
                                <FormControl className='input-sm' componentClass="select" placeholder="select"
                                    defaultValue={this.state.year} onChange={this.onSelectYear}>
                                    {years.map(it => <option key={it} value={it}>{it}</option>)}
                                </FormControl></Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12}>
                        <FormGroup controlId="formPer" className='input-group-sm'>
                            <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='period' />{':'}</ControlLabel></Col>
                            <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={`${this.state.kper}`}
                                onChange={this.onSelectKper} style={{ minWidth: '100%' }}>
                                {this.props.nsi.periods.map(it => <option key={it.kper} value={it.kper}>{it.nameRu}</option>)}
                            </FormControl></Col>
                        </FormGroup></Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12}>
                        <FormGroup controlId="formRaz" className='input-group-sm'>
                            <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='razres' />{':'}</ControlLabel></Col>
                            <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={this.state.kraz}
                                onChange={this.onSelectKraz} style={{ minWidth: '100%' }}>
                                <FilterRazOptions />
                            </FormControl></Col>
                        </FormGroup></Col>
                </Row>

                <SelectObject
                    tobj={this.state.tobj}
                    obl={this.state.obl}
                    raion={this.state.raion}
                    org={this.state.org}
                    setTobj={(tobj) => this.setState({ tobj, strObj: 0 })}
                    setObl={(obl) => this.setState({ obl })}
                    setRaion={(raion) => this.setState({ raion })}
                    setOrg={(org) => this.setState({ org })}
                    isNotOrg={true}
                    year={this.state.year}
                />
                {this.props.work.isLoading === true ? <Row>
                    <Col smOffset={2} sm={10}>
                        <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('waitsvod')} width={20} />{' '}<strong><Translate value="waitsvod" /></strong></div>
                    </Col>
                </Row> : ''}
                <Button className='btn btn-primary text-center' type="submit" disabled={this.props.work.isLoading === true}><Translate value="msgButtons.Load" /></Button>
            </Form>
            {this.showStatusData()}
        </Fragment>;
    }
}
// isNotOrg={true}
export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionCreatorsWork }, dispatch)
)(StatusForm);
/*
 

 <Row>
                    <Col xs={12} sm={6}>
                        <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='obl' />{':'}</ControlLabel></Col>
                        <Col xs={12} sm={2} className="text-left">{this.props.i18n.locale[0] === 'r' ? this.props.userInfo.user.nameObl : this.props.userInfo.user.nameOblKz}</Col>
                    </Col>
                </Row>
 */