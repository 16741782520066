import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Row, Col, FormGroup, FormControl, ControlLabel, Form, Button, Panel, Alert } from 'react-bootstrap';
import { actionCreators as actionClosingPeriod } from '../../store/ClosingPeriod';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';
import { years, periods, getNameLocalize } from '../Constants';
import SelectObject from '../common/SelectObject';

class ClosingOrgPeriod extends Component {
    constructor(props) {
        super(props);
        var self = this;
        let dtNow = new Date();
        let yeCyr = dtNow.getFullYear();
        let yePr = yeCyr - 1;
        let month = dtNow.getMonth() + 1;
        this.state = {
            tobj: 4,//props.userInfo.user.accessLevel,
            obl: { kobl: props.userInfo.user.kodObl, nameRu: props.userInfo.user.nameObl, nameKz: props.userInfo.user.nameOblKz },
            raion: { kraion: props.userInfo.user.kodRaion.substring(0, 4), nameRu: props.userInfo.user.nameRaion, nameKz: props.userInfo.user.nameRaionKz },
            org: { korg: props.userInfo.user.kodOrg, nameRu: props.userInfo.user.nameOrg, nameKz: props.userInfo.user.nameOrgKz },
            yearClose: `${yePr}`,
            yearCloseMonth: `${yeCyr}`,
            monthClose: `${month}`,
            updateStatus: null,
            isLoading: false,
            requiredSave: false
        }
        this.onLoadDataOrg = this.onLoadDataOrg.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
        this.showStatus = this.showStatus.bind(this);
        this.showRequiredSave = this.showRequiredSave.bind(this);
       // this.getData(props, self);
        props.getNs('orgs', [{ name: "obl", value: props.userInfo.user.kodObl }]);
    }
    componentWillMount() {
        this.getData(this.props, this);
    }
    getData(props, self) {
        const fetchData = async () => {
            let ret = await props.getCloseOrg(self.state.org.korg);
            let cl = ret.closingOrg;
            if (cl != null) {
                self.setState({ yearClose: `${cl.yearClose}`, yearCloseMonth: `${cl.yearCloseMonth}`, monthClose: `${cl.monthClose}`, updateStatus: null, isLoading: false, requiredSave: false });
            } else {
                self.setState({ isLoading: false, requiredSave: true });
            }
        }
        self.setState({ isLoading: true });
        fetchData();
    }
    onLoadDataOrg(evt) {
        evt.preventDefault();
        if (this.state.org == null) return;
        var self = this;
        this.getData(this.props, self);
    }
    onSubmitForm(evt) {
        evt.preventDefault();
        var self = this;
        self.setState({ updateStatus: undefined, isLoading: true });
        const fechUpdate = async () => {
            let updateStatus = await self.props.saveCloseOrg(self.state.org.korg, self.state.yearClose, self.state.yearCloseMonth, self.state.monthClose);
            if (updateStatus != null) {
                self.setState({ updateStatus, isLoading: false, requiredSave: updateStatus.successed===false });
            } else {
                self.setState({ isLoading: false, requiredSave: true });
            }
        }
        fechUpdate();
    }
    showStatus() {
        if (this.state.updateStatus.successed === true) {
            return <Row>
                <Col sm={10}>
                    <Alert bsStyle="success"><Translate value='dataSaved' /></Alert>
                </Col>
            </Row>;
        } else {
            return <Row>
                <Col sm={10}>
                    <Alert bsStyle="danger"><Translate value='error' />{':'}{this.state.updateStatus.error} </Alert>
                </Col>
            </Row>;
        }
    }
    showRequiredSave() {
        return <Row>
            <Col sm={10}>
                <Alert bsStyle="warning"><Translate value='showRequiredSave' /></Alert>
            </Col>
        </Row>;
    }
    render() {
        let disabledBt = this.state.isLoading === true || this.state.org == null;
        return <Fragment>
            <h4><Translate value='closingOrgPeriod' /></h4>
            <Row><Col sm={12}>
                <SelectObject
                    tobj={this.state.tobj}
                    obl={this.state.obl}
                    raion={this.state.raion}
                    org={this.state.org}
                    setTobj={(tobj) => this.setState({ tobj })}
                    setObl={(obl) => this.setState({ obl })}
                    setRaion={(raion) => this.setState({ raion })}
                    setOrg={(org) => this.setState({ org })}
                    hideTorg={true}
                    year={this.state.yearClose}
                />
            </Col>
            </Row>
            <Button type="button" bsStyle="primary" bsSize="large" onClick={this.onLoadDataOrg} disabled={disabledBt === true}><Translate value='load' /></Button>

            <Panel bsStyle="primary" style={{ marginTop: '10px' }}>
                <Panel.Heading>{getNameLocalize(this.props.i18n, this.state.org)}</Panel.Heading>
                <Panel.Body>
                    <Form horizontal>


                        <Row><Col xs={12} sm={12}>
                            <FormGroup controlId="formControlsSelect">
                                <Col xs={12} sm={8} className="text-right"><ControlLabel><Translate value='yearClose' />{':'}</ControlLabel></Col>
                                <Col xs={12} sm={4}><FormControl className='input-sm' componentClass="select" value={this.state.yearClose}
                                    onChange={(evt) => this.setState({ yearClose: evt.target.value })} style={{ minWidth: '100%' }} disabled={disabledBt === true}>
                                    {years.map(ye => <option value={ye} key={ye}>{ye}</option>)}
                                </FormControl></Col>
                            </FormGroup></Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col xs={12} sm={12}>
                                <FormGroup controlId="formPer" className='input-group-sm'>
                                    <Col xs={12} sm={8} className="text-right"><ControlLabel><Translate value='yearCloseMonth' />{':'}</ControlLabel></Col>
                                    <Col xs={12} sm={4}><FormControl className='input-sm' componentClass="select" value={this.state.yearCloseMonth}
                                        onChange={(evt) => this.setState({ yearCloseMonth: evt.target.value })} style={{ minWidth: '100%' }} disabled={disabledBt === true}>
                                        {years.map(ye => <option value={ye} key={ye}>{ye}</option>)}
                                    </FormControl></Col>
                                </FormGroup></Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12}>
                                <FormGroup controlId="formPer" className='input-group-sm'>
                                    <Col xs={12} sm={8} className="text-right"><ControlLabel><Translate value='monthClose' />{':'}</ControlLabel></Col>
                                    <Col xs={12} sm={4}>
                                        <select className="form-control" value={this.state.monthClose}
                                            onChange={(evt) => this.setState({ monthClose: evt.target.value })} style={{ minWidth: '100%' }} disabled={disabledBt === true}>
                                            {periods.filter(f => f.kper > 100).map(it => <option key={it.kper - 100} value={it.kper - 100}>{I18n.t(it.nameT)}</option>)}
                                        </select>
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button type="button" bsStyle="primary" onClick={this.onSubmitForm} bsSize="large" disabled={disabledBt === true}><Translate value='save' /></Button>
                    </Form>
                    {this.state.requiredSave===true?this.showRequiredSave():""}
                    {this.state.updateStatus!=null?this.showStatus():''}
                </Panel.Body>
            </Panel></Fragment>
    }
}
export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionClosingPeriod }, dispatch)
)(ClosingOrgPeriod);
/*
  <Row><Col></
            <div className="text-primary">{getNameLocalize(this.props.i18n, this.state.org)}</div>
 */