import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import * as FileSaver from 'file-saver';
import { Row, Col, FormGroup, FormControl, ControlLabel, Form, Button, ButtonGroup, Panel } from 'react-bootstrap';

import { actionCreators as actionCreatorsWork } from '../../store/Work';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';
import SelectObject from '../common/SelectObject';
import './InputInfor.css';

import { years, getNameLocalize, getCurrentYear } from '../Constants';
import FilterRazOptions from '../common/FilterRazOptions';

class InputInfor extends Component {
    constructor(props) {
        super(props);
        let nowYear = getCurrentYear();
        this.state = {
            year: props.year !== undefined ? props.year : nowYear,
            kper: 4,//период
            kraz: 1,//this.props.userInfo.user.accessLevel !== 4 ? 1 : 3, //разрез
            tobj: props.userInfo.user.accessLevel, // тип обьекта 1 – РК; 2 – область; 3 - район; 4 - организация (доступ зависит от пользователя)
            obl: undefined,
            raion: undefined,
            org: undefined,
            strObj: 0, //0- районы, 1- организации, построчку показывать в разрезе организаций, иначе в разрезе районов, только для tobj=2 - область  
            isShowParams: true,
            kfFiltr: ""
        };
        this.showInfoData = this.showInfoData.bind(this);
        this.showKf = this.showKf.bind(this);
        this.printExcel = this.printExcel.bind(this);
        this.filtrKf = this.filtrKf.bind(this);
        if (props.nsi.sprform.length === 0) {
            props.getNs('sprform');
        }
        if (props.nsi.periods.length === 0) {
            props.getNs('periods');
        }
        if (props.nsi.sprraz.length === 0) {
            props.getNs('sprraz');
        }
    }

    onSelectYear = (e) => {
        let year = parseInt(e.target.value, 10);
        this.setState({ year });
    }
    onSelectKper = (e) => {
        let kper = parseInt(e.target.value, 10);
        this.setState({ kper });
    }
    onSelectKraz = (e) => {
        let kraz = parseInt(e.target.value, 10);
        this.setState({ kraz });
    }
    onOpenTable = (e) => {
        e.preventDefault();
        let kobj = "";
        switch (this.state.tobj) {
            case 1://РК
                kobj = "00";
                break;
            case 2://Область
                kobj = this.state.obl.kobl;
                break;
            case 3://Район
                kobj = this.state.raion.kraion;
                break;
            case 4://Организация
                kobj = this.state.org.korg;
                break;
            default:
                break;

        }
        this.props.getInfoData({
            year: this.state.year,
            period: this.state.kper,
            raz: this.state.kraz,
            tobj: this.state.tobj,
            kobj,
            strObj: this.state.strObj
        });
    }
    getObjectTypeName(tobj) {
        switch (tobj) {
            //case 1://РК
            //    return "Республика:";
            case 2://Область
                return `${I18n.t("obl")}:`;
            case 3://Район
                return `${I18n.t("raion")}:`;
            case 4://Организация
                return `${I18n.t("org")}:`;
            default:
                break;
        }
        return "";
    }
    // <Button bsSize='small' onClick={this.printExcel}><Translate value="infoData.printExcel" /></Button>
    filtrKf(r) {
        let kodKf = this.state.kfFiltr;
        if (kodKf == null || kodKf === "" || kodKf === "all") return true;
        if (r.forms.filter(f => f.kf === kodKf).length === 0) return false;
        return true;
    }
    showInfoData() {
        if (this.props.work.dataInfo === undefined) return '';
        let dataInfo = this.props.work.dataInfo;
        return <Fragment>
            <ButtonGroup style={{ marginTop: '5px' }}>
                <Button bsSize='small' onClick={this.printData} style={{ marginRight: '10px' }}><Translate value="infoData.print" /></Button>
                <Button bsSize='small' onClick={this.printExcel}><Translate value="infoData.printExcel" /></Button>
            </ButtonGroup>
            <h4 id="print-h4"><strong><Translate value="infoData.title" /></strong></h4>
            <Row>
                <Col md={12}><strong><Translate value="infoData.period" /> {dataInfo.namePeriod}</strong></Col>
            </Row>
            <Row>
                <Col md={12}><strong><Translate value="infoData.raz" /> {dataInfo.nameRaz}</strong></Col>
            </Row>
            <Row>
                <Col md={12}><strong><Translate value="infoData.dt" /> {dataInfo.strCreateDateTime}</strong></Col>
            </Row>
            {this.state.strObj === 1 || this.state.tobj === 4 ? <Fragment><Row>
                <Col md={6}>
                    <div style={{ display: "inline-block", width: "20px", height: "20px", backgroundColor: "#777777" }}></div><div style={{ display: "inline-block", height: "20px", paddingLeft: "5px", borderRadius: "0.25em" }}><Translate className="text-deafult" value='statusForm.titFormUncheck' /></div>
                </Col>
                <Col md={6}>
                    <div style={{ display: "inline-block", width: "20px", height: "20px", backgroundColor: "#337ab7" }}></div><div style={{ display: "inline-block", height: "20px", paddingLeft: "5px", borderRadius: "0.25em" }}><Translate className="text-primary" value='statusForm.titFormCheck' /></div>
                </Col>
            </Row></Fragment> : ""}
            <Form horizontal>
                <FormGroup bsSize="small">
                    <Col componentClass={ControlLabel} sm={2}><Translate value="filtrForma" /></Col>
                    <Col sm={6}>
                        <FormControl className='input-sm' componentClass="select" placeholder="select" value={this.state.kfFiltr}
                            onChange={(evt) => this.setState({ kfFiltr: evt.target.value })} style={{ minWidth: '100%' }}>
                            <option value={"all"} key={"all"}></option>
                            {this.props.nsi.sprform.map(it => <option key={it.kf} value={it.kf}>{it.kf} {getNameLocalize(this.props.i18n, it)}</option>)}
                        </FormControl>
                    </Col>
                    <Col sm={2}>
                        <Button type="button" onClick={() => this.setState({ kfFiltr: "all" })} disabled={this.props.work.isLoading === true}
                            bsSize="sm"><Translate value="editOrgs.clearFilter" /></Button>
                    </Col>
                </FormGroup>
            </Form>
            <hr style={{ marginTop: "4px", marginBottom: "4px" }} />
            {dataInfo.rows.filter(f => this.filtrKf(f)).map(r => <Fragment key={r.obj.kod}>
                <Row>
                    <Col md={12}><strong>{this.getObjectTypeName(r.obj.typeObj)} {r.obj.kod}</strong> {getNameLocalize(this.props.i18n, r.obj)}</Col>
                </Row>
                <Row>
                    <Col md={12}>{this.showKf(r)}</Col>
                </Row>
            </Fragment>)}
        </Fragment>;
    }
    showKf(r) {
        if (r.obj.typeObj === 4/* && this.props.userInfo.user.accessLevel===2 */)
            return r.forms.map(f => f.status === 2 ? <Fragment key={r.obj.kod + f.kf}><span className="label label-primary" style={{ fontSize: '11pt' }} title={I18n.t("checked")}>{f.kf}</span>{' '}</Fragment> : <Fragment key={r.obj.kod + f.kf}><span className="label label-default" title={I18n.t("noChecked")} style={{ fontSize: '11pt' }}>{f.kf}</span>{' '}</Fragment>)
        else
            return r.forms.map(f => <Fragment key={r.obj.kod + f.kf}><span style={{ fontSize: '11pt' }} key={r.obj.kod + f.kf}>{f.kf}</span>{' '}</Fragment>)
    }
    printData = () => {
        window.print();
    }
    printExcel = async () => {
        let kobj = "";
        switch (this.state.tobj) {
            case 1://РК
                kobj = "00";
                break;
            case 2://Область
                kobj = this.state.obl.kobl;
                break;
            case 3://Район
                kobj = this.state.raion.kraion;
                break;
            case 4://Организация
                kobj = this.state.org.korg;
                break;
            default:
                break;

        }
        let absoluteUrl = `${document.location.protocol}//${document.location.host}/generate/Excel/InputInfo/${this.state.year}/${this.state.kper}/${this.state.kraz}/${this.state.tobj}/${kobj}/${this.state.strObj}`;
        let fileName = `inf_${this.state.year}_${this.state.kper}_${this.state.kraz}_${this.state.tobj}_${kobj}_${this.state.strObj}.xlsx`;
        var token = this.props.userInfo.user.token;
        const response = await fetch(absoluteUrl, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const blob = await response.blob();
            FileSaver.saveAs(blob, fileName);
            //this.setState({ isLoading: false });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    }
    render() {
        return <Fragment><Panel bsClass='small'>
            <Panel.Body>
                <Form onSubmit={this.onOpenTable}>
                    <h5 style={{ textAlign: "center" }}><strong><Translate value='inputInfor' /></strong></h5>
                    <Row>
                        <Col xs={12} sm={12}>
                            <FormGroup controlId="formYear" className='input-group-sm'>
                                <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='year' />{':'}</ControlLabel></Col>
                                <Col xs={12} sm={10}>
                                    <FormControl className='input-sm' componentClass="select" placeholder="select"
                                        defaultValue={this.state.year} onChange={this.onSelectYear}>
                                        {years.map(it => <option key={it} value={it}>{it}</option>)}
                                    </FormControl></Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12}>
                            <FormGroup controlId="formPer" className='input-group-sm'>
                                <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='period' />{':'}</ControlLabel></Col>
                                <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={`${this.state.kper}`}
                                    onChange={this.onSelectKper} style={{ minWidth: '100%' }}>
                                    {this.props.nsi.periods.map(it => <option key={it.kper} value={it.kper}>{it.nameRu}</option>)}
                                </FormControl></Col>
                            </FormGroup></Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12}>
                            <FormGroup controlId="formRaz" className='input-group-sm'>
                                <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='razres' />{':'}</ControlLabel></Col>
                                <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={this.state.kraz}
                                    onChange={this.onSelectKraz} style={{ minWidth: '100%' }}>
                                    <FilterRazOptions />
                                </FormControl></Col>
                            </FormGroup></Col>
                    </Row>
                    <SelectObject
                        tobj={this.state.tobj}
                        obl={this.state.obl}
                        raion={this.state.raion}
                        org={this.state.org}
                        setTobj={(tobj) => this.setState({ tobj, strObj: 0 })}
                        setObl={(obl) => this.setState({ obl })}
                        setRaion={(raion) => this.setState({ raion })}
                        setOrg={(org) => this.setState({ org })}
                        year={this.state.year}
                    />
                    {this.state.tobj === 2 ? <Row>
                        <Col xs={12} sm={12}>
                            <FormGroup controlId="formStrObj" className='input-group-sm' onChange={(e) => this.setState({ strObj: parseInt(e.target.value, 10) })}>
                                <Col xs={12} sm={2} className="text-right"><ControlLabel style={{ marginTop: 3 }} > <Translate value='strocs' />{':'}</ControlLabel></Col>
                                <Col xs={12} sm={10}>
                                    <input type="radio" value={0} name="StrObj" checked={this.state.strObj === 0} />&nbsp;<Translate value='raions' />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <input type="radio" value={1} name="StrObj" checked={this.state.strObj === 1} />&nbsp;<Translate value='orgs' />&nbsp;&nbsp;&nbsp;&nbsp;
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row> : ''}
                    {(this.state.tobj === 3 && this.props.userInfo.user.accessLevel === 3) ? <Row>
                        <Col xs={12} sm={12}>
                            <FormGroup controlId="formStrObj" className='input-group-sm' onChange={(e) => (e.target.checked === true ? this.setState({ strObj: 2 }) : this.setState({ strObj: 0 }))}>
                                <Col xs={12} sm={2} className="text-right"><ControlLabel style={{ marginTop: 3 }} > <Translate value='strocs' />{':'}</ControlLabel></Col>
                                <Col xs={12} sm={10}>
                                    <input type="checkbox" value={2} name="StrObj" checked={this.state.strObj === 2} />&nbsp;<Translate value='childOrgs.title' />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row> : ''}
                    <Button className='btn btn-primary text-center' type="submit"><Translate value='msgButtons.Load' /></Button>
                </Form></Panel.Body></Panel>
            {this.showInfoData()}
        </Fragment>;
    }
}
// isNotOrg={true}
export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionCreatorsWork }, dispatch)
)(InputInfor);