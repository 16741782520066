import React, { Component/*, Fragment*/ } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button, FormGroup, FormControl, ControlLabel, Row, Col, Alert, Checkbox } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import NumberFormat from 'react-number-format';
import * as FileSaver from 'file-saver';
import { actionCreators } from '../../store/Medinfo/FormulMedinfo';
//import SelectMultiObjects from '../common/SelectMultiObjects'
import SelectObject from '../common/SelectObject';
import { arTypePers, arTypeDiagram } from '../Constants';
class GenMedinfoTable extends Component {
     constructor(props) {
        super(props);
        this.state = {
            type_page: 1,//1,2
            selectedTable: undefined,
            seletedPage: undefined,
            type_per: 1,
            begYear: 2017,
            endYear: 2018,
            //isCustomYears: false,//если используется строка годов
            yearsExprStrings: "2017-2018",//строка годов (например - 2017-2018,2021)
            years: [2017,2018], //массив годов из строки yearsExprStrings 
            tobj: props.userInfo.user.accessLevel, // тип обьекта 1 – РК; 2 – область; 3 - район; 4 - организация (доступ зависит от пользователя)
            obl: undefined,
            raion: undefined,
            org: { korg: this.props.userInfo.user.kodOrg },//, undefined,
            //kobls: [],
            //kraions: [],
            //korgs: [],
            isCreateDiagram: false,
            type_diagram: 1,
            isLoading: false
        };
        this.onClickTable = this.onClickTable.bind(this);
        this.onSelectTypePage = this.onSelectTypePage.bind(this);
        this.onClickPage = this.onClickPage.bind(this);
        this.showListPages = this.showListPages.bind(this);
        this.begYearChange = this.begYearChange.bind(this);
        this.endYearChange = this.endYearChange.bind(this);
        this.onGenerateTable = this.onGenerateTable.bind(this);
         this.yearsExprStringsChange = this.yearsExprStringsChange.bind(this);
         this.yearsList = this.yearsList.bind(this);
        this.props.getTablesPages(this.state.type_page).finally(() => {
             let selectedTable = this.props.formulmedinfo.tablesPages.tables[0];
             this.setState({
                 selectedTable: selectedTable, selectedPage: selectedTable.pages[0]
             });
        });
    }
    onSelectTypePage = async (e) => {
        let type_page = parseInt(e.target.value, 10);
        await this.props.getTablesPages(type_page);
        let selectedTable = this.props.formulmedinfo.tablesPages.tables[0];
        this.setState({
            type_page, selectedTable: selectedTable, selectedPage: selectedTable.pages[0]
        });
    }
    onClickTable = (e) => {
        let tableId = parseInt(e.target.value, 10);
        let selectedTable = this.props.formulmedinfo.tablesPages.tables.filter(t => t.tableId === tableId)[0];
        this.setState({
            selectedTable,
            selectedPage: selectedTable.pages[0]
        });
    }
    showListTables() {
        if (this.props.formulmedinfo.tablesPages == null) return '';
        return <FormGroup controlId="formTables" className='input-group-sm'>
            <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="table" />{':'}</ControlLabel></Col>
            <Col xs={12} sm={8}>
                <FormControl className='input-sm' componentClass="select" placeholder="select" style={{ width: "auto" }}
                    defaultValue={this.state.selectedTable} onChange={this.onClickTable}>
                    {this.props.formulmedinfo.tablesPages.tables.map(l => <option value={l.tableId} key={l.tableId}>{l.kod} {l.nameRu}</option>)}
                </FormControl></Col>
        </FormGroup>;
    }
    onClickPage = (e) => {
        let pageId = parseInt(e.target.value, 10);
        let selectedPage = this.state.selectedTable.pages.filter(t => t.pageId === pageId)[0];
        this.setState({selectedPage});
    }
    showListPages() {
        if (this.state.selectedTable == null) return '';
        return <FormGroup controlId="formTypePage" className='input-group-sm'>
            <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="page" />{':'}</ControlLabel></Col>
            <Col xs={12} sm={8}>
                <FormControl className='input-sm' componentClass="select" placeholder="select" style={{ width: "auto" }}
                    defaultValue={this.state.selectedPage} onChange={this.onClickPage}>
                    {this.state.selectedTable.pages.map(p => <option value={p.pageId} key={p.pageId}>{p.kod} {p.nameRu}</option>)}
                </FormControl></Col>
        </FormGroup>;
    }
    begYearChange(values) {
        let vl = parseInt(values.value, 10); //values.intValue === undefined ? 2017 : values.intValue;
        this.setState({ begYear:vl });
    }
    endYearChange(values) {
        let vl = parseInt(values.value, 10); // values.intValue === undefined ? 2018 : values.intValue;
        this.setState({ endYear: vl });
    }
    validYearsMessage() {
        if (this.state.type_per > 1) return '';
        if (this.state.begYear >= this.state.endYear) {
            return <div className="text-danger">Первый год не должен быть равен или больше чем второй</div>;
        }
        if ( this.state.endYear - this.state.begYear > 10) {
            return <div className="text-danger">Количество лет не должно быть больше 10</div>;
        }
    }
    validYearsExprStrings() {
        if (this.state.type_per > 1) return '';
        if (this.state.yearsExprStrings == null || this.state.yearsExprStrings.length === 0)
            return <div className="text-danger">Введите выражение</div>;
        //проверка регулярного выражения
        //let regexp = /(\d{4}(,)?|\d{4}-\d{4}(,)?)+/;
        let regexp = /\d{4,4}-\d{4,4}|\d{4,4}/gi;
        let test = regexp.test(this.state.yearsExprStrings);
        if (!test)
            return <div className="text-danger">Неправильное выражение</div>;
        if (this.state.years.length>10)
            return <div className="text-danger">Количество годов больше 10</div>;
        //let ar =this.state.yearsExprStrings.match(regexp);
        //return <div className="text-danger">test={test?'true':'false'}  {ar.join(',')}</div>
    }
    yearsList() {
        if (this.state.years.length > 0)
            return <strong className="text-primary">{this.state.years.join(',')}</strong>
    }
    yearsExprStringsChange = (e) => {
        //получить масив годов
        let yearsExprStrings = e.target.value;
        let years = [];
        let regexp = /(\d{4,4}-\d{4,4})|(\d{4,4})/gi;
        let test = regexp.test(yearsExprStrings);
        if (test) {
            let ar = yearsExprStrings.match(regexp);
            for (var i = 0; i < ar.length; i++) {
                let it = ar[i];
                if (it.indexOf('-')!==-1) {
                    let ar1 = it.split('-');
                    let yy1 = parseInt(ar1[0], 10);
                    let yy2 = parseInt(ar1[1], 10);
                    //eslint-disable-next-line
                    for (var yi = yy1; yi <= yy2; yi++) {
                        //eslint-disable-next-line
                        if (years.filter(y1 => y1 === yi).length === 0) {
                            years.push(yi);
                        }
                    }
                } else {
                    let ye = parseInt(it, 10);
                    if (years.filter(y1 => y1 === ye).length === 0) {
                        years.push(ye);
                    }
                }
            }
            years=years.sort();
        }
        this.setState({ yearsExprStrings, years});
    }
    showRangeYears() {
        if (this.state.type_per === 1) {
            return <Row>
                <Col xs={12} sm={6}>
                    <FormGroup controlId="formYearBeg" className='input-group-sm'>
                        <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="yearsExprStrings" />{':'}</ControlLabel></Col>
                        <Col xs={12} sm={8}>
                            <input value={this.state.yearsExprStrings} onChange={this.yearsExprStringsChange} />&nbsp;
                            {this.yearsList()}
                            {this.validYearsExprStrings()}
                        </Col>
                    </FormGroup>
                </Col>
            </Row>;
            /*
            return <Fragment>
                <Row>
                    <Col xs={12} sm={6}>
                        <FormGroup controlId="formYearBeg" className='input-group-sm'>
                            <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="yearBeg" />{':'}</ControlLabel></Col>
                            <Col xs={12} sm={8}>
                                <NumberFormat value={this.state.begYear} onValueChange={(values) => this.begYearChange(values)} /></Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6}>
                        <FormGroup controlId="formYearEnd" className='input-group-sm'>
                            <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="yearEnd" />{':'}</ControlLabel></Col>
                            <Col xs={12} sm={8}>
                                <NumberFormat value={this.state.endYear} onValueChange={(values) => this.endYearChange(values)} /></Col>
                        </FormGroup>
                        {this.validYearsMessage()}
                    </Col>
                </Row></Fragment>;
            */
        } else {
            return <Row>
                <Col xs={12} sm={6}>
                    <FormGroup controlId="formYearBeg" className='input-group-sm'>
                        <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="year" />{':'}</ControlLabel></Col>
                        <Col xs={12} sm={8}>
                            <NumberFormat value={this.state.begYear} onValueChange={(values) => this.begYearChange(values)} /></Col>
                    </FormGroup>
                </Col>
            </Row>;
        }
    }
    onGenerateTable = async (e) => {
        e.preventDefault();
        let kobjs = null;
        switch (this.state.tobj) {
            case 1:
                kobjs = ["00"];
                break;
            case 2:
               // if (this.props.userInfo.user.accessLevel === 2) {
                    kobjs = [];
                    kobjs.push(this.state.obl.kobl);
               // } else {
               //     kobjs = this.state.kobls;
               // }
                break;
            case 3:
                //if (this.props.userInfo.user.accessLevel === 3) {
                    kobjs = [];
                    kobjs.push(this.state.raion.kraion);
                //} else {
                //    kobjs = this.state.kraions;
                //}
                break;
            case 4:
                kobjs = [];
                kobjs.push(this.state.org.korg/*this.props.userInfo.user.kodOrg*/);
                break;
            default:
        }
        if (this.state.type_per === 1 && this.state.years.length === 0) {
            alert("Выберте года");
            return;
        } else if (this.state.begYear == null || this.state.begYear===0) {
            alert("Выберте год");
            return;
        }
        if (kobjs.length === 0) {
            alert("Выберте обьекты");
            return;
        }
        let body = {
            typePage: this.state.type_page,//1,2
            tableId: this.state.selectedTable.tableId,
            pageId: this.state.selectedPage.pageId,
            typePer: this.state.type_per,
            years: this.state.years,
            begYear: this.state.begYear,
            endYear: this.state.endYear,
            tobj: this.state.tobj,
            kobjs,
            isCreateDiagram: this.state.isCreateDiagram,
            typeDiagram: this.state.type_diagram
        }; 
        this.setState({ isLoading: true });
        let absoluteUrl = `${document.location.protocol}//${document.location.host}/Medinfo/generate/Excel`;
        let fileName = `${this.state.begYear}_${this.state.type_page}_${this.state.selectedTable.kod}_${this.state.selectedPage.kod}_${this.state.type_per}_${this.state.tobj}.xlsx`;
        var token = this.props.userInfo.user.token;
        const response = await fetch(absoluteUrl, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const blob = await response.blob();
            FileSaver.saveAs(blob, fileName);
            this.setState({ isLoading: false });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    }
    showTypesDiagram(){
        if (this.state.isCreateDiagram === false) return '';
        return <Row>
            <Col xs={12} sm={6}>
                <FormGroup controlId="formTypeDiagr" className='input-group-sm'>
                    <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="type_diagram" />{':'}</ControlLabel></Col>
                    <Col xs={12} sm={8}>
                        <FormControl className='input-sm' componentClass="select" placeholder="select" style={{ width: "auto" }}
                            defaultValue={this.state.type_diagram} onChange={(e) => this.setState({ type_diagram: parseInt(e.target.value, 10) })}>
                            {arTypeDiagram.filter(f => !(this.state.type_page === 2 && f.id === 5) && this.state.tobj<3).map(it => <option key={it.id} value={it.id}>{I18n.t(it.nameT)}</option>)}
                        </FormControl></Col>
                </FormGroup>
            </Col>
        </Row>;
    }
    render() {
        return <Form onSubmit={this.onGenerateTable} horizontal>
            <h4><Translate value="generateTableMedinfo" /></h4>
            {this.props.formulmedinfo.isLoading === true ? <Row>
                <Col smOffset={2} sm={10}>
                    <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('wait')} width={20} />{' '}<strong><Translate value="wait" /></strong></div>
                </Col>
            </Row> : ''}
            <Row>
                <Col xs={12} sm={6}>
                    <FormGroup controlId="formTypePage" className='input-group-sm'>
                        <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="type_table" />{':'}</ControlLabel></Col>
                        <Col xs={12} sm={8}>
                            <FormControl className='input-sm' componentClass="select" placeholder="select" style={{ width: "auto" }}
                                defaultValue={this.state.type_page} onChange={this.onSelectTypePage}>
                                <option value={1}>{1}</option>
                                <option value={2}>{2}</option>
                            </FormControl></Col>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}>
                    {this.showListTables()}
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}>
                    {this.showListPages()}
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}>
                    <FormGroup controlId="formTypePer" className='input-group-sm'>
                        <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="type_per" />{':'}</ControlLabel></Col>
                        <Col xs={12} sm={8}>
                            <FormControl className='input-sm' componentClass="select" placeholder="select" style={{ width: "auto" }}
                                defaultValue={this.state.type_per} onChange={(e) => this.setState({ type_per: parseInt(e.target.value, 10) })}>
                                {arTypePers.map(it => <option key={it.id} value={it.id}>{I18n.t(it.nameT)}</option>)}
                            </FormControl></Col>
                    </FormGroup>
                </Col>
            </Row>
            {this.showRangeYears()}
            <SelectObject
                tobj={this.state.tobj}
                obl={this.state.obl}
                raion={this.state.raion}
                org={this.state.org}
                setTobj={(tobj) => this.setState({ tobj })}
                setObl={(obl) => this.setState({ obl })}
                setRaion={(raion) => this.setState({ raion })}
                setOrg={(org) => this.setState({ org })}
            />
           
            <Row>
                <Col xs={12} sm={6}>
                    <FormGroup controlId="formisCreateDiagram" className='input-group-sm'>
                        <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="createDiagram" />{':'}</ControlLabel></Col>
                        <Col xs={12} sm={8}>
                            <Checkbox checked={this.state.isCreateDiagram === true} onChange={(e) => this.setState({ isCreateDiagram: e.target.checked })}></Checkbox>
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
            {this.showTypesDiagram()}
            {this.state.isLoading === true ? <Row>
                <Col smOffset={2} sm={10}>
                    <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('wait')} width={20} />{' '}<strong><Translate value="wait" /></strong></div>
                </Col>
            </Row> : ''}
            {this.props.formulmedinfo.error != null ? <Row>
                <Col smOffset={2} sm={10}>
                    <Alert bsStyle="danger">{this.props.formulmedinfo.error}</Alert>
                </Col>
            </Row> : ''}
            <Row>
                <FormGroup>
                    <Col smOffset={2} sm={10}>
                        <Button className='btn btn-primary text-center' type="submit"><Translate value="btGenMedinfoTable" /></Button>
                    </Col>
                </FormGroup>
            </Row>
        </Form>;
    }
}//
export default connect(
    state => state,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(GenMedinfoTable);