const requestRegisterType = 'REQUEST_REGISTER';
const receiveRegisterType = 'RECEIVE_REGISTER';
const initialState = { result: undefined, isLoading: false };

export const actionCreators = {
    register: (data) => async (dispatch) => {
        dispatch({ type: requestRegisterType });
        let json = JSON.stringify(data);
        const url = 'api/Users/Register';
        const response = await fetch(url, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: json
        });
        const status = await response.json();
        dispatch({ type: receiveRegisterType, status });
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === requestRegisterType) {
        return {
            ...state,
            result: undefined,
            isLoading: true
        };
    }

    if (action.type === receiveRegisterType) {
        return {
            ...state,
            result: action.status,
            isLoading: false
        };
    }

    return state;
};
