import React, { Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormGroup, ControlLabel, Row, Col, Checkbox } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';

//import SelectOrgModal from '../Modals/SelectOrgModal';
//import { Typeahead } from 'react-bootstrap-typeahead';
import { getNameLocalize } from '../Constants';
import { actionCreators } from '../../store/Nsi';

class SelectMultiForms extends Component {
    constructor(props) {
        super(props);
        this.state = { kforms: [] };
        this.onCheckForm = this.onCheckForm.bind(this);
        this.getForms = this.getForms.bind(this);
        if (props.nsi.sprform.length === 0) props.getNs('sprform');
    }
    onCheckForm = (e) => {
        let checked = e.target.checked;
        let kf = e.target.value;
        let kforms = [...this.state.kforms];
        if (checked) {
            kforms.push(kf);
        } else {
            let ind = kforms.indexOf(kf);
            kforms.splice(ind, 1);
        }
        this.setState({ kforms });
        this.props.setForms(kforms);
    }
    getValidationStateForms() {
        const length = this.state.kforms.length;
        if (length > 0)
            return 'success';
        if (length === 0) {
            return 'error';
        }
        return null;
    }
    getForms = () => {
        if (this.props.no47 != null && this.props.no47 === true) {
            if (this.props.isNotMonthWithInc != null && this.props.isNotMonthWithInc === true) {
                return this.props.nsi.sprform.filter(f => f.monthWithInc === 1 && f.kf !== '47' && f.kf !== '01').map(it => <Checkbox onChange={this.onCheckForm} key={it.kf} value={it.kf} inline>{it.kf} {getNameLocalize(this.props.i18n, it)}</Checkbox>);
            }else {
                return this.props.nsi.sprform.filter(f => f.kf !== '47' && f.kf !== '01').map(it => <Checkbox onChange={this.onCheckForm} key={it.kf} value={it.kf} inline>{it.kf} {getNameLocalize(this.props.i18n, it)}</Checkbox>);
            }
        } else {
            if (this.props.isNotMonthWithInc != null && this.props.isNotMonthWithInc === true) {
                return this.props.nsi.sprform.filter(f => f.monthWithInc === 1).map(it => <Checkbox onChange={this.onCheckForm} key={it.kf} value={it.kf} inline>{it.kf} {getNameLocalize(this.props.i18n, it)}</Checkbox>);
            } else {
                return this.props.nsi.sprform.map(it => <Checkbox onChange={this.onCheckForm} key={it.kf} value={it.kf} inline>{it.kf} {getNameLocalize(this.props.i18n, it)}</Checkbox>);
            }
        }
    }
    render() {
        return (<Row>
            <Col sm={12}>
                <FormGroup bsSize='small' validationState={this.getValidationStateForms()}>
                    <Col sm={2} className="text-right"><ControlLabel><Translate value='forma' />{':'}</ControlLabel></Col>
                    <Col sm={10}>
                        {this.getForms()}
                    </Col>
                </FormGroup></Col>
        </Row>);
    }
}
export default connect(
    state => state,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(SelectMultiForms);