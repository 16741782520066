import React, { useEffect, useState, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as FileSaver from 'file-saver';
import { Row, Col, FormGroup, FormControl, ControlLabel, Form, Button, Table } from 'react-bootstrap';

import { Translate, I18n } from 'react-redux-i18n';

import { actionCreators as actionCreatorsWork } from '../../store/Work';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';

import { years, getNameLocalize, getCurrentYear } from '../Constants';
import FilterRazOptions from '../common/FilterRazOptions';
import CheckBoxStatus from '../common/CheckBoxStatus';

const StatusTableObl = (props) => {
    useState(() => {
        if (props.nsi.periods.length === 0) props.getNs('periods');
        if (props.nsi.sprraz.length === 0) props.getNs('sprraz');
        if (props.nsi.sprform.length === 0) props.getNs('sprform');
        //if (props.nsi.allsprtab.length === 0) props.getNs('allsprtab');
    });
    const [year, setYear] = useState(props.year !== undefined ? props.year : getCurrentYear());
    const [kper, setKper] = useState(4);
    const [kraz, setKraz] = useState(1);
    const [kf, setKf] = useState("01");
    const [kodNameSearch, setKodNameSearch] = useState();
    const filtrOrg=(org) => {
        let kodName = kodNameSearch;
        if (kodName == null || kodName === "") return true;
        if (kodName != '' && (org.kod != kodName && !org.nameRu.toLowerCase().includes(kodName.toLowerCase()) && !org.nameKz.toLowerCase().includes(kodName.toLowerCase()))) return false;
        return true;
    }
    const showStatusData = () => {
        if (props.work.statusInfoTablesObl === undefined) return '';
        let statusInfoTablesObl = props.work.statusInfoTablesObl;
        return <Fragment>
            <Row>
                <Col xs={12} sm={6}><h5 style={{ textAlign: "center" }}><strong><Translate value='statusTable.title' /></strong></h5></Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}><strong><Translate value="obl" />: {props.i18n.locale[0] === 'r' ? props.userInfo.user.nameObl : props.userInfo.user.nameOblKz}</strong></Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}><strong><Translate value="period" />: {statusInfoTablesObl.namePeriod}</strong></Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}><strong><Translate value="razres" />: {statusInfoTablesObl.nameRaz}</strong></Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}><strong><Translate value="infoData.form" /> {statusInfoTablesObl.nameForm}</strong></Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}><strong><Translate value="infoData.dt" /> {statusInfoTablesObl.dtRun}</strong></Col>
            </Row>
            {statusInfoTablesObl.rows.length > 0 ? <Fragment><Row>
                <Col xs={12} sm={6}><Button bsSize='small' onClick={printExcel}><Translate value="infoData.printExcel" /></Button></Col>
            </Row>
            </Fragment> : ""}
            <Form inline style={{ fontSize: "9pt" }}>
                <FormGroup bsSize="small">
                    <ControlLabel><Translate value="org" /> <Translate value="editOrgs.kodName" /></ControlLabel>
                    <FormControl type="text" value={kodNameSearch} disabled={props.work.isLoading === true} style={{ width: "400px" }}
                        onChange={(evt) => evt.target.value != null ? setKodNameSearch(evt.target.value) : setKodNameSearch("")}
                />
                </FormGroup>
                <Button type="button" onClick={() => setKodNameSearch("")} disabled={props.work.isLoading === true}
                    bsSize="sm"><Translate value="editOrgs.clearFilter" /></Button>
            </Form>
            <Table bordered>
                <thead>
                    <tr>
                        <th><Translate value='statusForm.tit2' />
                            <span style={{ marginLeft: "30px" }}><span className="label label-default" style={{ paddingTop: "7px" }}><i style={{ fontSize: '11pt' }} className="glyphicon glyphicon-unchecked"></i></span>{' '}<Translate className="text-deafult" value='statusForm.titFormUncheckRk' /></span>
                            <span style={{ marginLeft: "25px" }}><span className="label label-primary" style={{ paddingTop: "7px" }}><i style={{ fontSize: '11pt' }} className="glyphicon glyphicon-check"></i></span>{' '}<Translate className="text-primary" value='statusForm.titFormCheckRk' /></span></th>
                    </tr>
                </thead>
                <tbody>
                    {statusInfoTablesObl.rows.filter(f => filtrOrg(f)).map(r => renderRowStatus(r))}
                </tbody>
            </Table>
        </Fragment>;
    }
    const renderRowStatus = (r) => {
        return <tr key={r.kod}><td><strong>{r.kod}</strong> {getNameLocalize(props.i18n, r)}<br />
            {r.status.map((s, i) => <CheckBoxStatus key={`${r.kod}_${i}`} korg={r.kod} kf={s.kf} checked={s.status} onChange={onChangeStatusKt} />)}
        </td></tr>
    }
    const onChangeStatusKt = (d) => {
        //console.dir(d);
        props.updateStatusInfoDataTablesObl({ year, kraz, kper, kod: d.korg, kf: kf, kt: d.kf, status: d.status });
    }
    const printExcel = async () => {
        let absoluteUrl = `${document.location.protocol}//${document.location.host}/generate/Excel/InputInfoTablesObl/${year}/${kper}/${kraz}/${kf}`;
        let fileName = `infTabObl_${year}_${kper}_${kraz}_${kf}_${props.userInfo.user.kodObl}.xlsx`;
        var token = props.userInfo.user.token;
        const response = await fetch(absoluteUrl, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const blob = await response.blob();
            FileSaver.saveAs(blob, fileName);
            //this.setState({ isLoading: false });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    }
    const onOpenStatus = (e) => {
        e.preventDefault();
        props.getStatusInfoDataTablesObl({
            year: year,
            period: kper,
            raz: kraz,
            kobl: props.userInfo.user.kodObl,
            kf
        });
    }
    if (props.userInfo.user.accessLevel != 2 || props.userInfo.user.roles.filter(r => r === 'UserReadData').length > 0) return <div className="text-danger"><Translate value="noAccess" /></div>;
    return <Fragment>
        <Form onSubmit={onOpenStatus}>
            <Row>
                <Col xs={12} sm={12}><h5 style={{ textAlign: "center" }}><strong><Translate value='statusTable.title' /></strong></h5></Col>
            </Row>
            <Row>
                <Col xs={12} sm={12}>
                    <FormGroup controlId="formYear" className='input-group-sm'>
                        <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='year' />{':'}</ControlLabel></Col>
                        <Col xs={12} sm={10}>
                            <FormControl className='input-sm' componentClass="select" placeholder="select"
                                defaultValue={year} onChange={(e) => setYear(parseInt(e.target.value, 10))}>
                                {years.map(it => <option key={it} value={it}>{it}</option>)}
                            </FormControl></Col>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={12}>
                    <FormGroup controlId="formPer" className='input-group-sm'>
                        <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='period' />{':'}</ControlLabel></Col>
                        <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={`${kper}`}
                            onChange={(e) => setKper(parseInt(e.target.value, 10))} style={{ minWidth: '100%' }}>
                            {props.nsi.periods.map(it => <option key={it.kper} value={it.kper}>{it.nameRu}</option>)}
                        </FormControl></Col>
                    </FormGroup></Col>
            </Row>
            <Row>
                <Col xs={12} sm={12}>
                    <FormGroup controlId="formRaz" className='input-group-sm'>
                        <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='razres' />{':'}</ControlLabel></Col>
                        <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={kraz}
                            onChange={(e) => setKraz(parseInt(e.target.value, 10))} style={{ minWidth: '100%' }}>
                            <FilterRazOptions />
                        </FormControl></Col>
                    </FormGroup></Col>
            </Row>
            <Row>
                <Col xs={12} sm={12}>
                    <FormGroup controlId="formKf" className='input-group-sm'>
                        <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='forma' />{':'}</ControlLabel></Col>
                        <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={kf}
                            onChange={(e) => setKf(e.target.value)} style={{ minWidth: '100%' }}>
                            {props.nsi.sprform.map(it => <option key={it.kf} value={it.kf}>{it.kf} {getNameLocalize(props.i18n, it)}</option>)}
                        </FormControl></Col>
                    </FormGroup></Col>
            </Row>
            {props.work.isLoading === true ? <Row>
                <Col smOffset={2} sm={10}>
                    <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('waitsvod')} width={20} />{' '}<strong><Translate value="waitsvod" /></strong></div>
                </Col>
            </Row> : ''}
            <Row bsSize="small" style={{ marginTop:"5px" }}>
                <Col xs={12} smOffset={2} sm={10}>
                    <Button className='btn btn-primary text-center' type="submit" disabled={props.work.isLoading === true}><Translate value="msgButtons.Load" /></Button>
                </Col>
            </Row>
        </Form>
        {showStatusData()}
    </Fragment>;
}
export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionCreatorsWork }, dispatch)
)(StatusTableObl);