import React, { Component, Fragment } from 'react';
import { Panel, Button, Form, Row, Col, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actionCreators } from '../../store/Nsi';
import SelectObject from '../common/SelectObject';


import { years, getCurrentYear, getFirstRaz, getFirstFormTable, getRazItems, filterForms  } from '../Constants';
//import FilterRazOptions from '../common/FilterRazOptions';

class FromExcel extends Component {
    constructor(props) {
        super(props);
        let nowYear = getCurrentYear();
        let kper = 4;
        let raz = getFirstRaz(kper, props);
        this.state = {
            year: nowYear,//props.year !== undefined ? props.year : nowYear,
            kper,//период
            kraz: raz != null ? raz : 1,//разрез
            tobj: props.userInfo.user.accessLevel, // тип обьекта 1 – РК; 2 – область; 3 - район; 4 - организация (доступ зависит от пользователя)
            obl: undefined,
            raion: undefined,
            org: undefined,
            form: kper === 4 ? props.userInfo.user.firstFormTable.formGod : props.userInfo.user.firstFormTable.formMes,//{ kf: "01", nameRu: "Численность постоянного населения" },
            isLoading: false,
            isSuccess: null,
            error: null
        }
        this.onUploadFileExcel = this.onUploadFileExcel.bind(this);
        this.onSelectForm = this.onSelectForm.bind(this);
        if (props.nsi.sprraz.length === 0) props.getNs('sprraz');
        if (props.nsi.sprform.length === 0) props.getNs('sprform');
        if (props.nsi.periods.length === 0) props.getNs('periods');
    }
    async onUploadFileExcel(e) {
        e.preventDefault();
        this.setState({ isSuccess: null, error: null });
        var kobj = "";
        switch (this.state.tobj) {
            case 1:
                kobj = "00";
                break;
            case 2:
                kobj = this.state.obl.kobl;
                break;
            case 3:
                kobj = this.state.raion.kraion;
                break;
            case 4:
                kobj = this.state.org.korg;
                break;
            default:
                kobj = "";
        }
        //let absoluteUrl = `${document.location.protocol}//${document.location.host}/UploadExcel/${this.state.year}/${this.state.kper}/${this.state.kraz}/${this.state.tobj}/${kobj}/${this.state.form.kf}`;
        let absoluteUrl = `${document.location.protocol}//${document.location.host}/UploadExcel`;
        let input = document.getElementById('fileinput');
        if (input.files.length === 0) {
            this.setState({
                error: I18n.t('selectExcelFile')
            });
            return;
        }
        //let formFile = document.getElementById('formFile');
        let formData = new FormData();
        // let formData = new FormData(formFile);
        formData.append("year", this.state.year);
        formData.append("kper", this.state.kper);
        formData.append("kraz", this.state.kraz);
        formData.append("tobj", this.state.tobj);
        formData.append("kobj", kobj);
        formData.append("kf", this.state.form.kf);
        formData.append("fileExcel", input.files[0]);
        var token = this.props.userInfo.user.token;
        this.setState({ isLoading: true });
        fetch(`${absoluteUrl}?year=${this.state.year}&kper=${this.state.kper}&kraz=${this.state.kraz}&tobj=${this.state.tobj}&kobj=${this.state.kobj}&kf=${this.state.kf}`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                //'Content-Type': undefined
                //"Content-Type": "multipart/form-data"
                //"Content-Type":"application/x-www-form-urlencoded"
            },
            body: formData
        }).then(response => response.json()).then(
            result => {
                this.setState({ isLoading: false, isSuccess: result.successed, error: result.successed === true ? null : result.error });
            }
        ).catch(
            error => {
                console.log(error);
                alert(error);
                this.setState({ isLoading: false, isSuccess: false, error });
            }
        );
    }
    onSelectForm(e) {
        let kf = e.target.value;
        let form = this.props.nsi.sprform.filter(spr => spr.kf === kf)[0];
        this.setState({ form });
    }
    onSelectYear = (e) => {
        let year = parseInt(e.target.value, 10);
        this.setState({ year });
    }
    onSelectKper = (e) => {
        let kper = parseInt(e.target.value, 10);
        let kraz = getFirstRaz(kper, this.props);
        let { form, table } = getFirstFormTable(kper, this.props);
        if (kraz == null) {
            if (form == null) {
                this.setState({ kper });
            } else {
                this.setState({ kper, form });
            }
        } else {
            if (form == null) {
                this.setState({ kper, kraz });
            } else {
                this.setState({ kper, kraz, form });
            }
        }
    }
    onSelectKraz = (e) => {
        let kraz = parseInt(e.target.value, 10);
        this.setState({ kraz });
    }
    render() {
        let isReadOnLy = this.props.userInfo.user.roles.filter(r => r === 'UserReadData').length > 0;
        if (isReadOnLy === true)
            return <Translate value="noAccess" />
        else
        return (<Fragment>
            <h5 style={{ textAlign: "center" }}><strong><Translate value='fromExcel' /></strong></h5>
            <FormGroup controlId="formYear" bsSize='small'>
                <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='year' />{':'}</ControlLabel></Col>
                <Col xs={12} sm={10}>
                    <FormControl className='input-sm' componentClass="select" placeholder="select"
                        defaultValue={this.state.year} onChange={this.onSelectYear} disabled={this.state.isLoading === true}>
                        {years.filter(f=>f>2016).map(it => <option key={it} value={it}>{it}</option>)}
                    </FormControl></Col>
            </FormGroup>
            <FormGroup controlId="formPer" bsSize='small'>
                <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='period' />{':'}</ControlLabel></Col>
                <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={`${this.state.kper}`}
                    onChange={this.onSelectKper} style={{ minWidth: '100%' }} disabled={this.state.isLoading === true}>
                    {this.props.nsi.periods.map(it => <option key={it.kper} value={it.kper}>{it.nameRu}</option>)}
                </FormControl></Col>
            </FormGroup>
            <FormGroup controlId="formRaz" bsSize='small'>
                <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='razres' />{':'}</ControlLabel></Col>
                <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={this.state.kraz}
                    onChange={this.onSelectKraz} style={{ minWidth: '100%' }} disabled={this.state.isLoading === true}>
                    {getRazItems(this.props, this.state.kper)}
                </FormControl></Col>
            </FormGroup>
            <FormGroup controlId="formKf" bsSize='small'>
                <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='forma' />{':'}</ControlLabel></Col>
                <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={this.state.form.kf}
                    onChange={this.onSelectForm} style={{ minWidth: '100%' }} disabled={this.state.isLoading === true}>
                    {this.props.nsi.sprform.filter(f => filterForms(this.props.userInfo.user.accessForm, f.kf, this.state.kper)).map(it => <option key={it.kf} value={it.kf}>{it.kf} {it.nameRu}</option>)}
                </FormControl></Col>
            </FormGroup>
            <SelectObject
                tobj={this.state.tobj}
                obl={this.state.obl}
                raion={this.state.raion}
                org={this.state.org}
                setTobj={(tobj) => this.setState({ tobj })}
                setObl={(obl) => this.setState({ obl })}
                setRaion={(raion) => this.setState({ raion })}
                setOrg={(org) => this.setState({ org })} disabled={this.state.isLoading === true}
                year={this.state.year}
            />
            <Row>
                <Col smOffset={2} sm={10}>
                    <a href="downloads/formatParamsExcel.pdf" style={{ textDecoration: "underline" }} download><strong>Требования к файлу Excel</strong></a>
                </Col>
            </Row>
            <form encType="multipart/form-data" id="formFile">
                <FormGroup controlId="fileinput" bsSize='small'>
                    <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='fileExcel' />{':'}</ControlLabel></Col>
                    <Col xs={12} sm={10}><FormControl name="fileExcel" className='input-sm' type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" style={{ minWidth: '100%' }} disabled={this.state.isLoading === true}>
                    </FormControl></Col>
                </FormGroup>
            </form>
            <FormGroup bsSize='small'>
                <Col smOffset={2} sm={10}>
                    <Button type="button" bsStyle="primary" bsSize="large" onClick={this.onUploadFileExcel} disabled={this.state.isLoading === true}><Translate value="loadDataFromExcel" /></Button>
                </Col>
            </FormGroup>
            {this.state.isLoading ? <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('wait')} width={20} />{' '}<strong><Translate value="fromExcel2" /></strong></div> : ''}
            {this.state.isSuccess === true ? <div className='text-center text-success'><Translate value="resultFromExcel2Ok" fileExcel={this.sta} /></div> : ''}
            {this.state.error != null ? <div className='text-center text-danger'><Translate value="error" /> {this.state.error}</div> : ''}
        </Fragment>);
    }
}
/*   
 *   {this.state.error != null? <div className='text-center text-danger'><Translate value="error" /> {this.state.error}</div> : ''}
 *    */
export default connect(
    state => state,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(FromExcel);