import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormGroup, FormControl, ControlLabel, Row, Col, Checkbox, Radio } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';

//import SelectOrgModal from '../Modals/SelectOrgModal';
import { Typeahead } from 'react-bootstrap-typeahead';

import { actionCreators } from '../../store/Nsi';
//import { getNameLocalize } from '../Constants';

class SelectMultiObjects extends Component {
    constructor(props) {
        super(props);
        let kobl, kraion, korg;
        let accessLevel = props.userInfo.user.accessLevel;
        kobl = props.userInfo.user.kodObl;
        props.setObl({ kobl, nameRu: props.userInfo.user.nameObl });
        kraion = props.userInfo.user.kodRaion.substring(0, 4);
        props.setRaion({ kraion, nameRu: props.userInfo.user.nameRaion });
        let korgs = [];
        let org = null;
        //if (props.userInfo.user.accessLevel === 4) {
            korgs.push(props.userInfo.user.kodOrg);
            org = { korg: props.userInfo.user.kodOrg, nameRu: props.userInfo.user.nameOrg };
            if (props.setOrg != null) props.setOrg(org);
            if (props.setOrgs != null) props.setOrgs(korgs);
        //}
        this.state = {
            kobl,
            kraion,
            kobls:[],
            kraions:[],
            korgs: korgs,
            selectOrg: org,
            accessLevel,
            tobj: props.userInfo.user.accessLevel,// тип обьекта 1 – РК; 2 – область; 3 - район; 4 - организация (доступ зависит от пользователя)
            isSelectAllObls: false,
            isSelectAllRaions:false
        }
        this.typeahead = null;
        this.onSelectTobj = this.onSelectTobj.bind(this);
        this.onSelectKobl = this.onSelectKobl.bind(this);
        this.onSelectKraion = this.onSelectKraion.bind(this);
        this.onCheckObl = this.onCheckObl.bind(this);
        this.onCheckRaion = this.onCheckRaion.bind(this);
        this.onChangeIsAllObls = this.onChangeIsAllObls.bind(this);
        this.onChangeIsAllRaions = this.onChangeIsAllRaions.bind(this);
        this.onChangeOrg = this.onChangeOrg.bind(this);
        this.filterRaion = this.filterRaion.bind(this);
        if (props.nsi.allobls.length === 0) props.getNs('allobls');
        if (props.nsi.allraions.length === 0) props.getNs('allraions');
        if (props.userInfo.user.accessLevel < 4) {
            //загрузить организации
            if (props.userInfo.user.nomenklKod === "008" || props.userInfo.user.nomenklKod === "049" || props.userInfo.user.nomenklKod === "043") {
                props.getNs('orgs', [{ name: "obl", value: kobl }, { name: "parent_kod", value: props.userInfo.user.kodOrg }]);
            } else {
                props.getNs('orgs', [{ name: "obl", value: kobl }]);
            }
        }
    }
    componentWillReceiveProps(nextProps) {
        // This method runs when incoming props (e.g., route params) change
        if (this.typeahead != null)
            this.typeahead.getInstance().clear();
    }
    onSelectTobj(e) {
        let tobj = parseInt(e.target.value, 10);
        let kobls = [];
        let kraions = [];
        let korgs = [];
        let org = null;
        if (tobj === 2 && this.state.isSelectAllObls === true) {
            let obls = this.props.nsi.allobls.filter(o => o.kod !== '01');
            for (var i in obls) {
                kobls.push(obls[i].kod)
            }
            this.props.setObls(kobls);
        }
        if (tobj === 3 && this.state.isSelectAllRaions === true) {
            let raions = this.props.nsi.allraions.filter(r => r.obl === this.state.kobl);
            for (var i in raions) {
                kraions.push(raions[i].idRaion)
            }
            this.props.setRaions(kraions);
        }
        if (tobj === 4) {
            korgs.push(this.props.userInfo.user.kodOrg);
            org = { korg: this.props.userInfo.user.kodOrg, nameRu: this.props.userInfo.user.nameOrg };
            if (this.props.setOrg != null) {
                this.props.setOrg(org);
            }
        }
        this.setState({ tobj, kobls, kraions, korgs, selectOrg: org });
        this.props.setTobj(tobj);
    }
    onSelectKobl(e) {
        let kobl = e.target.value;
        let first_raion = this.props.nsi.allraions.filter(r => r.obl === kobl);
        let kraions = [];
        if (this.state.isSelectAllRaions === true) {
            for (var i in first_raion) {
                kraions.push(first_raion[i].idRaion);
            }
            this.props.setRaions(kraions);
        }
        this.setState({
            kobl,
            kraion: first_raion === undefined || first_raion.length === 0 ? undefined : first_raion[0].idRaion,
            kobls: [], kraions
        });
        let nameRu = this.props.nsi.allobls.filter(r => r.kod === kobl)[0].nameRu;
        this.props.setObl({ kobl, nameRu });
        if (this.state.tobj > 3) {
            this.typeahead.getInstance().clear();
            //загрузить организации
            if (this.props.userInfo.user.nomenklKod === "008" || this.props.userInfo.user.nomenklKod === "049" || this.props.userInfo.user.nomenklKod === "043") {
                this.props.getNs('orgs', [{ name: "obl", value: kobl }, { name: "parent_kod", value: this.props.userInfo.user.kodOrg }]);
            } else {
                this.props.getNs('orgs', [{ name: "obl", value: kobl }]);
            }
        }
    }
    onSelectKraion(e) {
        let kraion = e.target.value;
        this.setState({
            kraion,
            korg: undefined
        });
        if (this.state.tobj > 3) {
            this.typeahead.getInstance().clear();
        }
        let nameRu = this.props.nsi.allraions.filter(r => r.idRaion === kraion)[0].nameRu;
        this.props.setRaion({ kraion, nameRu });
    }
    getValidationStateObls() {
        const length = this.state.kobls.length;
        if (length > 0)
            return 'success';
        if (length === 0) {
            return 'error';
        }
        return null;
    }
    onCheckObl = (e) => {
        let checked = e.target.checked;
        let kobl = e.target.value;
        let kobls = [...this.state.kobls];
        if (checked) {
            kobls.push(kobl);
        } else {
            let ind = kobls.indexOf(kobl);
            kobls.splice(ind, 1);
        }
        this.setState({ kobls });
        this.props.setObls(kobls);
    }
    getValidationStateRaions() {
        const length = this.state.kraions.length;
        if (length > 0)
            return 'success';
        if (length === 0) {
            return 'error';
        }
        return null;
    }
    onCheckRaion = (e) => {
        let checked = e.target.checked;
        let kraion = e.target.value;
        let kraions = [...this.state.kraions];
        if (checked) {
            kraions.push(kraion);
        } else {
            let ind = kraions.indexOf(kraion);
            kraions.splice(ind, 1);
        }
        this.setState({ kraions });
        this.props.setRaions(kraions);
    }
    onChangeIsAllObls(e) {
        let vl = e.target.value;
        let checked = e.target.checked;
        if (vl === "1" && checked)
            this.setState({ isSelectAllObls: false, kobls: [] });
        else {
            let kobls = [];
            let obls = this.props.nsi.allobls.filter(o => o.kod !== '01');
            for (var i in obls) {
                kobls.push(obls[i].kod);
            }
            this.setState({ isSelectAllObls: true, kobls });
            this.props.setObls(kobls);
        }
    }
    onChangeIsAllRaions(e) {
        let vl = e.target.value;
        let checked = e.target.checked;
        if (vl === "1" && checked)
            this.setState({ isSelectAllRaions: false, kraions: [] });
        else {
            let kraions = [];
            let raions = this.props.nsi.allraions.filter(r => r.obl === this.state.kobl);
            for (var i in raions) {
                kraions.push(raions[i].idRaion);
            }
            this.setState({ isSelectAllRaions: true, kraions });
            this.props.setRaions(kraions);
        }
    }
    onChangeOrg(selected) {
        if (selected.length > 0) {
            let korg = selected[0].kobj;
            let selectOrg = { korg, nameRu: selected[0].nameRu }
            let nameRu = this.props.nsi.allraions.filter(r => r.idRaion === selected[0].raion)[0].nameRu;
            this.setState({ korg, selectOrg, korgs: [selectOrg], kraion: selected[0].raion });
            this.props.setRaion({ kraion: selected[0].raion, nameRu });
            if (this.props.setOrg != null) this.props.setOrg(selectOrg);
            if (this.props.setOrgs != null) this.props.setOrgs([korg]);
            return;
        }
        this.setState({ korg: undefined, selectOrg: undefined });
        if (this.props.setOrg != null) this.props.setOrg(undefined);
        if (this.props.setOrgs != null) this.props.setOrgs([]);
    }
    showSelectObl() {
        let cssDispl = this.state.tobj > 1 ? "block" : "none";
        if (this.state.tobj === 2) 
            return <Row style={{ display: cssDispl }}>
                <Col xs={12} sm={12}>
                    <FormGroup bsSize='small' validationState={this.getValidationStateObls()}>
                        <Col sm={2} className="text-right"><ControlLabel><Translate value='obl' />{':'}</ControlLabel></Col>
                        <Col sm={10}>
                            {this.state.accessLevel > 1 ? <div>{this.state.kobl} {this.props.userInfo.user.nameObl}</div> :
                                <Fragment>
                                    <FormGroup onChange={this.onChangeIsAllObls}>
                                        <Radio name="radioOblGroup" value={1} defaultChecked={this.state.isSelectAllObls === false} inline><Translate value='selectObls' /></Radio>{' '}
                                        <Radio name="radioOblGroup" value={2} defaultChecked={this.state.isSelectAllObls === true} inline><Translate value='selectAllObls' /></Radio>{' '}
                                    </FormGroup>
                                    {this.state.isSelectAllObls === true || this.props.nsi.allobls.filter(o=>o.kod!=='01').map(it =>
                                 <Checkbox onChange={this.onCheckObl} key={it.kod} value={it.kod} inline>{it.nameRu}</Checkbox>)}</Fragment>}
                        </Col>
                    </FormGroup></Col>
            </Row>;
        else
        return <Row style={{ display: cssDispl }}>
            <Col xs={12} sm={12}>
                <FormGroup bsSize='small'>
                    <Col sm={2} className="text-right"><ControlLabel><Translate value='obl' />{':'}</ControlLabel></Col>
                        <Col sm={10}>
                        {this.state.accessLevel > 1 ? <div>{this.state.kobl} {this.props.userInfo.user.nameObl}</div> :
                            <FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={this.state.kobl}
                                onChange={this.onSelectKobl} style={{ width: "auto" }}>
                                {this.props.nsi.allobls.filter(o => o.kod !== '01').map(it => <option key={it.kod} value={it.kod}>{it.nameRu}</option>)}
                            </FormControl>}
                        </Col>
                 </FormGroup></Col>
        </Row>;
    }
    filterRaion(rai, year) {
        if (year == null) return true;
        let ye = typeof year === 'string' ? parseInt(year, 10) : year;
        let dtBeg = rai.dtBeg == null ? null : new Date(rai.dtBeg);
        if (dtBeg != null && dtBeg.getFullYear() > ye) return false;
        let dtEnd = rai.dtEnd == null ? null : new Date(rai.dtEnd);
        if (dtEnd != null && ye >= dtEnd.getFullYear()) return false;
        return true;
    }
    showSelectRaions() {
        if (this.state.tobj < 3 || this.state.tobj===4)
            return ' ';
            return <Row>
                <Col xs={12} sm={12}>
                    <FormGroup bsSize='small' validationState={this.getValidationStateRaions()}>
                        <Col sm={2} className="text-right"><ControlLabel><Translate value='raion' />{':'}</ControlLabel></Col>
                        <Col sm={10}>
                            {this.props.userInfo.user.accessLevel > 2 ? <div>{this.state.kraion} {this.props.userInfo.user.nameRaion}</div> :
                                <Fragment>
                                    <FormGroup onChange={this.onChangeIsAllRaions}>
                                        <Radio name="radioOblGroup" value={1} defaultChecked={this.state.isSelectAllRaions === false} inline><Translate value='selectRaions' /></Radio>{' '}
                                        <Radio name="radioOblGroup" value={2} defaultChecked={this.state.isSelectAllRaions === true} inline><Translate value='selectAllRaions' /></Radio>{' '}
                                    </FormGroup>
                                    {this.state.isSelectAllRaions === true || this.props.nsi.allraions.filter(r => r.obl === this.state.kobl && this.filterRaion(r, this.props.year)).map(it =>
                                        <Checkbox onChange={this.onCheckRaion} key={it.kod} value={it.idRaion} inline>{it.nameRu}</Checkbox>
                                    )}
                                </Fragment>}
                        </Col>
                    </FormGroup></Col>
            </Row>;
    }
    showSelectOrg() {
        if (this.state.tobj < 4) {
            return ' ';
        }
        if (this.state.tobj === 4 && this.props.userInfo.user.accessLevel < 4) {
            //показать выбор организации
            let cssDispl = (this.state.tobj > 3 || this.state.accessLevel > 3) ? "block" : "none";
            let korg = this.state.korgs[0];
            return <Row style={{ display: cssDispl }}>
                <Col xs={12} sm={12}>
                    <FormGroup bsSize='small'>
                        <Col sm={2} className="text-right"><ControlLabel><Translate value='org' />{':'}</ControlLabel></Col>
                        <Col sm={10}>
                            {this.state.accessLevel > 3 ? <div>{korg} {this.props.i18n.locale[0] === 'r' ? this.props.userInfo.user.nameOrg : this.props.userInfo.user.nameOrgKz}</div> :
                                <Fragment><Typeahead id="formOrgs"
                                    ref={(ref) => this.typeahead = ref}
                                    labelKey={this.props.i18n.locale[0] === 'r' ? "nameRu" : "nameKz"}
                                    options={(this.props.isSelOrg === true && this.props.userInfo.user.accessLevel < 3) || this.props.userInfo.user.nomenklKod === "043" || this.state.isAllRaions === true ? this.props.nsi.orgs : this.props.nsi.orgs.filter(o => o.raion === this.state.kraion)}
                                    placeholder={`${I18n.t("selectOrg")}...`}
                                    onChange={this.onChangeOrg} /><p>{this.state.selectOrg == null ? "" : this.state.selectOrg.nameRu/*getNameLocalize(this.props.i18n, this.state.selectOrg)}*/}</p></Fragment>
                            }
                        </Col>
                    </FormGroup></Col>
            </Row>;
        }else
            return <Row>
                    <Col xs={12} sm={12}>
                <FormGroup controlId="formOrg" bsSize='small'>
                    <Col sm={2} className="text-right"><ControlLabel><Translate value='org' />{':'}</ControlLabel></Col>
                    <Col sm={10}>{this.props.userInfo.user.kodOrg} {this.props.userInfo.user.nameOrg}</Col>
                    </FormGroup>
                </Col>
        </Row>;
    }
    render() {
        return (
            <Fragment>
                {this.props.userInfo.user.accessLevel !== 4 ? <Row>
                    <Col xs={12} sm={12}>
                        <FormGroup controlId="formTobj" bsSize='small'>
                            <Col sm={2} className="text-right"><ControlLabel><Translate value='obj' />{':'}</ControlLabel></Col>
                            <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={this.state.tobj}
                                onChange={this.onSelectTobj} style={{ width: "auto" }}>
                                {this.props.userInfo.user.accessLevel === 1 ? <option value="1">РК</option> : ''}
                                {this.props.userInfo.user.accessLevel < 3 ? <option value="2">{I18n.t('obl')}</option> : ''}
                                {this.props.userInfo.user.accessLevel < 4 ? <option value="3">{I18n.t('raion')}</option> : ''}
                                {this.props.isSelOrg === true && (this.props.userInfo.user.accessLevel === 1 || this.props.userInfo.user.accessLevel === 2)?<option value="4">{I18n.t('org')}</option> : ''}
                            </FormControl></Col>
                        </FormGroup></Col>
                </Row> : ''}
                {this.showSelectObl()}
                {this.showSelectRaions()}
                {this.showSelectOrg()}
            </Fragment>
        );
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(SelectMultiObjects);