import React, { Component } from 'react';
//import { createBrowserHistory } from 'history';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import ChangeLanguageLink from './ChangeLanguageLink';
import { Nav, NavItem, Navbar, MenuItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import { actionCreators } from '../store/UserInfo';
import { version } from '../components/Constants';
import './UserInfo.css';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
/*
const history = createBrowserHistory({ basename: baseUrl });
const location = {
    pathname: baseUrl,
    state: { fromDashboard: true }
}
*/
class UserInfo extends Component {
    handleOnClick(e) {
        e.preventDefault();
        this.props.logout();
        //history.push(location);
       // history.replace(location);
        //history.location = { pathname: '/' };
        window.location.href = baseUrl;
        //window.location.reload();
    }
    render() {
        return (
            <Nav className="navbar-right">
                <LinkContainer to={'/versionsHistory'} className="">
                    <NavItem><Translate value="versionInfo"/>{/*<Translate value="version2" version={version} />*/}</NavItem>
                </LinkContainer>
                <NavItem>{this.props.user !== undefined ? <Translate value="hello" fio={this.props.user.fio} /> : ''}</NavItem>
                <Navbar.Brand><ChangeLanguageLink lang="ru" /></Navbar.Brand>
                <Navbar.Brand><ChangeLanguageLink lang="kk" /></Navbar.Brand>
                <NavItem><button id="btLogout" type='button' className="btn btn-link navbar-btn navbar-link" onClick={e => this.handleOnClick(e)}><Translate value="exit" /></button></NavItem>
            </Nav>
        );
    }
}


export default connect(
    state => state.userInfo,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(UserInfo);

/*
 style={{ textDecoration: "none", color: "#777", backgroundColor: "#ffffff" }}

 <div className="navbar-form navbar-right" id='user_data'>
                <p className="navbar-text">{this.props.user !== undefined ? <Translate value="hello" fio={this.props.user.fio} />:''}</p>
                <p className="navbar-text"><ChangeLanguageLink lang="ru" /></p>
                <p className="navbar-text"><ChangeLanguageLink lang="kk" /></p>
                <button type='button' className="btn btn-link navbar-btn navbar-link" onClick={e => this.handleOnClick(e)}><Translate value="exit" /></button>
            </div>
 */