import React, { Component } from 'react'
import { connect } from 'react-redux';
import { setLocale } from 'react-redux-i18n'
import { setDefaultLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import kk from 'date-fns/locale/kk';

//import './ChangeLanguageLink.css';

class ChangeLanguageLink extends Component {
    constructor(props) {
        super(props)
        this.changeLanguage = (e) => {
            e.preventDefault() // don't navigate
            this.props.changeLanguage(this.props.lang);
            if (this.props.lang[0] === 'r') {
                setDefaultLocale(ru);
            } else {
                setDefaultLocale(kk);
            }
        }
    }

    render() {
        return (
            <button type='button' className="btn btn-link navbar-btn navbar-link" onClick={this.changeLanguage}>
                {this.props.lang.toUpperCase()}
            </button>
        )
    }
}

//ChangeLanguageLink.propTypes = {
//    lang: PropTypes.string.isRequired
//}

// this will make this.props.changeLanguage available
const mapDispatchToProps = dispatch => {
    // dispatch the react-redux-i18n setLocale action
    const changeLanguage = lang => dispatch(setLocale(lang))
    return { changeLanguage }
}

// using connect makes store.dispatch available for mapDispatchToProps
// and makes sure changeLanguage is added to the component props
export default connect(null, mapDispatchToProps)(ChangeLanguageLink); 
//@connect(null, mapDispatchToProps)
//export class ChangeLanguageLinkContainer extends ChangeLanguageLink { }