import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate} from 'react-redux-i18n';
import { Button, Col, FormGroup, FormControl, ControlLabel, Form} from 'react-bootstrap';

import { actionCreators as actionCreatorsControl } from '../../store/ControlForm';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';
import SelectObject from '../common/SelectObject';
import ControlResultTable from './ControlResultTable'


import { years, getCurrentYear } from '../Constants';
import FilterRazOptions from '../common/FilterRazOptions';

class ControlForm extends Component {
    constructor(props) {
        super(props);
        let nowYear = getCurrentYear();
        let pathname = this.props.location.pathname, typeContr;
        if (pathname === '/controlInYear') {
            typeContr = { kod: 3, name: "Межгодовой контроль" };//Межгодовой контроль
        }else if (pathname === '/controlAllForm'){
            typeContr = { kod: 2, name: "Межформенный контроль" };//Межформенный контроль
        } else {
            typeContr = { kod: 1, name:"Внутриформенный контроль" };//Внутриформенный контроль
        }
        this.state = {
            year: props.year !== undefined ? props.year : nowYear,
            kper: 4,//период
            kraz: 1,//this.props.userInfo.user.accessLevel !== 4?1:3, //разрез
            form: { kf: "01", nameRu: "Численность постоянного населения РК" },
            tobj: props.userInfo.user.accessLevel, // тип обьекта 1 – РК; 2 – область; 3 - район; 4 - организация (доступ зависит от пользователя)
            obl: undefined,
            raion: undefined,
            org: undefined,
            typeContr,
        };
        if (props.nsi.sprform.length === 0) {
            props.getNs('sprform');
        }
        if (props.nsi.periods.length === 0) {
            props.getNs('periods');
        }
        if (props.nsi.sprraz.length === 0) {
            props.getNs('sprraz');
        }
        this.onControlForm = this.onControlForm.bind(this)
        this.onSelectForm = this.onSelectForm.bind(this);
    }
    onControlForm = (e) => {
        e.preventDefault();
        let kobj = null;
        switch (this.state.tobj) {
            case 1:
                kobj = "00";
                break;
            case 2:
                kobj = this.state.obl.kobl;
                break;
            case 3:
                kobj = this.state.raion.kraion;
                break;
            case 4:
                kobj = this.state.org.korg;
                break;
            default:
        }
        this.props.controlForm({
            year: this.state.year,
            period: this.state.kper,
            raz: this.state.kraz,
            tobj: this.state.tobj,
            kobj,
            kf: this.state.form.kf,
            typeContr: this.state.typeContr.kod
        });
    }
    onSelectYear = (e) => {
        let year = parseInt(e.target.value, 10);
        this.setState({ year });
    }
    onSelectKper = (e) => {
        let kper = parseInt(e.target.value, 10);
        this.setState({ kper });
    }
    onSelectKraz = (e) => {
        let kraz = parseInt(e.target.value, 10);
        this.setState({ kraz });
    }
    onSelectForm(e) {
        let kf = e.target.value;
        let form = this.props.nsi.sprform.filter(spr => spr.kf === kf)[0];
        this.setState({ form });
    }
    render() {
        let isReadOnLy = this.props.userInfo.user.roles.filter(r => r === 'UserReadData').length > 0;
        if (isReadOnLy === true)
            return <Translate value="noAccess" />
        else
        return <Fragment><Form onSubmit={this.onControlForm} horizontal>
            <h5 style={{ textAlign: "center" }}><strong>{this.state.typeContr.name}</strong></h5>
            <FormGroup controlId="formYear" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='year' />{':'}</Col>
                <Col sm={10}>
                    <FormControl className='input-sm' componentClass="select" placeholder="select"
                        defaultValue={this.state.year} onChange={this.onSelectYear} bsSize="small" style={{ width: "auto" }}>
                        {years.filter(y=>y>2007).map(it => <option key={it} value={it}>{it}</option>)}
                    </FormControl></Col>
            </FormGroup>
            <FormGroup controlId="formPer" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='period' />{':'}</Col>
                <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={`${this.state.kper}`}
                    onChange={this.onSelectKper} bsSize='small' style={{ width: "auto" }}>
                    {this.props.nsi.periods.map(it => <option key={it.kper} value={it.kper}>{it.nameRu}</option>)}
                </FormControl></Col>
            </FormGroup>
            <FormGroup controlId="formRaz" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='razres' />{':'}</Col>
                <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={this.state.kraz}
                    onChange={this.onSelectKraz} bsSize='small' style={{ width: "auto" }}>
                    <FilterRazOptions />
                </FormControl></Col>
            </FormGroup>
            <SelectObject setTobj={(tobj) => this.setState({ tobj })}
                setObl={(obl) => this.setState({ obl })}
                setRaion={(raion) => this.setState({ raion })}
                setOrg={(org) => this.setState({ org })} org={this.state.org}
                year={this.state.year} />
            {this.state.typeContr.kod !== 2 ?
                <FormGroup controlId="formKf" className='input-group-sm'>
                    <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='forma' />{':'}</ControlLabel></Col>
                    <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={this.state.form.kf}
                        onChange={this.onSelectForm} style={{ width: 'auto' }}>
                        {this.props.nsi.sprform.map(it => <option key={it.kf} value={it.kf}>{it.kf} {it.nameRu}</option>)}
                    </FormControl></Col>
                </FormGroup> : ''}
            <FormGroup>
                <Col smOffset={2} sm={10}>
                    <Button className='btn btn-primary text-center' type="submit">Принять</Button>
                </Col>
            </FormGroup>
        </Form>
            <ControlResultTable control={this.props.control}></ControlResultTable>
         </Fragment>;
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionCreatorsControl }, dispatch)
)(ControlForm);