import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Button, Col, FormGroup, FormControl, ControlLabel, Form, Row, Table } from 'react-bootstrap';

//import './ReportFin.css';
import * as FileSaver from 'file-saver';

import { actionCreators as actionFinPaidData } from '../../store/FinPaidData';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';
//import { years, getCurrentYear } from '../Constants';
import { getNameLocalize } from '../Constants';

class RepSubordinates extends Component {
    constructor(props) {
        super(props);
        if (props.nsi.allobls.length === 0) {
            props.getNs('allobls');
        }
        this.state = {
            kobl: /*props.userInfo.user.kodObl*/"all",
            lang: "ru"
        }
        this.onGenerateRep = this.onGenerateRep.bind(this);
        this.onSelectKobl = this.onSelectKobl.bind(this);
        this.onSelectLang = this.onSelectLang.bind(this);
    }
    onSelectKobl(e) {
        this.setState({ kobl: e.target.value });
    }
    onSelectLang = (e) => {
        let lang = e.target.value;
        this.setState({ lang });
    }
    onGenerateRep = async (e) => {
        e.preventDefault();
        let body = {
            Obl: this.state.kobl === "all" ? null : this.state.kobl,
            Lang: this.state.lang
        };
        this.setState({ isLoading: true });
        let absoluteUrl = `${document.location.protocol}//${document.location.host}/Medinfo/generate/Excel/Subordinates`;
        //let fileName = `${this.state.begYear}_${this.state.type_page}_${this.state.selectedTable.kod}_${this.state.selectedPage.kod}_${this.state.type_per}_${this.state.tobj}.xlsx`;
        let fileName = "orgSubordinates.xlsx"
        var token = this.props.userInfo.user.token;
        const response = await fetch(absoluteUrl, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const blob = await response.blob();
            FileSaver.saveAs(blob, fileName);
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
        this.setState({ isLoading: false });
    }
    render() {
        return <Form onSubmit={this.onGenerateRep} horizontal>
            <h4 className="text-center"><Translate value='orgSubordinates' /></h4>
            <Row>
                <FormGroup controlId="formObl" bsSize='small'>
                    <Col sm={4} className="text-right"><ControlLabel><Translate value='obl' />{':'}</ControlLabel></Col>
                    <Col sm={8}>
                        {this.state.accessLevel > 1 ? <div>{this.state.kobl} {this.props.i18n.locale[0] === 'r' ? this.props.userInfo.user.nameObl : this.props.userInfo.user.nameOblKz}</div> :
                            <FormControl className='input-sm' componentClass="select" placeholder="select" value={this.state.kobl}
                                onChange={this.onSelectKobl} style={{ width: "auto" }} disabled={this.props.isLoading === true}>
                                <option value={"all"}>{I18n.t("all")}</option>
                                {this.props.nsi.allobls.map(it => <option key={it.kod} value={it.kod}>{getNameLocalize(this.props.i18n, it)}</option>)}
                            </FormControl>}
                    </Col>
                </FormGroup>
            </Row>
            <Row>
                <FormGroup controlId="formLang" bsSize='small'>
                    <Col sm={4} className="text-right"><ControlLabel><Translate value="lang" />{':'}</ControlLabel></Col>
                    <Col sm={8}>
                        <FormControl className='input-sm' componentClass="select" placeholder="select" value={this.state.lang}
                            onChange={this.onSelectLang} style={{ width: "auto" }} disabled={this.props.isLoading === true}>
                            <option key={'kk'} value={'kk'}>Қаз</option>)
                                 <option key={'ru'} value={'ru'}>Рус</option>)
                            </FormControl>
                    </Col>
                </FormGroup>
            </Row>
            {this.state.isLoading === true ? <Row>
                <Col smOffset={2} sm={10}>
                    <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('wait')} width={20} />{' '}<strong><Translate value="wait" /></strong></div>
                </Col>
            </Row> : ''}
            <Row>
                <Col sm={12} className="text-center">
                    <Button type="submit" bsStyle="primary" bsSize="large" disabled={this.props.isLoading === true}><Translate value="create" /></Button>
                </Col>
            </Row>
        </Form>
    }
}
export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionFinPaidData }, dispatch)
)(RepSubordinates);
