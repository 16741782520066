import { getToken } from './UserInfo';
const requestGetClosingDataAction = 'REQUEST_GET_CLOSING_DATA';
const receiveGetClosingDataAction = 'RECEIVE_GET_CLOSING_DATA';
const requestSetClosingDataAction = 'REQUEST_SET_CLOSING_DATA';
const receiveSetClosingDataAction = 'RECEIVE_SET_CLOSING_DATA';
const initialState = {
    isLoading: false,
    dataClosing: undefined,
    statusUpdate: undefined
}
export const actionCreators = {
    getCloseOrg: (korg) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        const response = await fetch(`api/ClosingPeriodApi/GetClosingOrg?korg=${korg}`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            let ret = await response.json();
            return ret;
        } else {
            alert("Ошибка HTTP: " + response.status);
            return null;
        }
    },
    saveCloseOrg: (korg, yearClose, yearCloseMonth, monthClose) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestSetClosingDataAction });
        let json = JSON.stringify({ Korg: korg, YearClose: parseInt(yearClose, 10), YearCloseMonth: parseInt(yearCloseMonth, 10), MonthClose: parseInt(monthClose,10)});
        const response = await fetch('api/ClosingPeriodApi/SetClosingOrg', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если 
            let ret = await response.json();
            return ret;
        } else {
            alert("Ошибка HTTP: " + response.status);
            return null;
        }
    },
    getClosingData: () => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestGetClosingDataAction });
        const response = await fetch('api/ClosingPeriodApi/GetClosingData', {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveGetClosingDataAction, data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    setClosingData: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestSetClosingDataAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/ClosingPeriodApi/SetClosingData', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const status = await response.json();
            dispatch({ type: receiveSetClosingDataAction, status});
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    }
}
export const reducer = (state, action) => {
    state = state || initialState;
    //get data closing
    if (action.type === requestGetClosingDataAction) {
        return {
            ...state,
            isLoading: true,
            dataClosing: undefined,
        };
    }
    if (action.type === receiveGetClosingDataAction) {
        return {
            ...state,
            isLoading: false,
            dataClosing: action.data,
        };
    }
    //set data closing
    if (action.type === requestSetClosingDataAction) {
        return {
            ...state,
            isLoading: true,
            statusUpdate: undefined,
        };
    }
    if (action.type === receiveSetClosingDataAction) {
        return {
            ...state,
            isLoading: false,
            statusUpdate: action.status
        };
    }
    return state;
}