import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import * as FileSaver from 'file-saver';
import { Translate, I18n } from 'react-redux-i18n';
import { Modal, Button, Col, FormGroup, FormControl, ControlLabel, Form, Row, Alert, ListGroup, ListGroupItem, Checkbox } from 'react-bootstrap';

import { actionCreators as actionCreatorsSvod } from '../../store/Svod';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';

import FilterRazOptions from '../common/FilterRazOptions';
import { years, getNameLocalize, getCurrentYear } from '../Constants';

const EditFormOrgRaz = (props) => {
    useState(() => {
        if (props.nsi.allobls.length === 0) props.getNs('allobls');
        if (props.nsi.sprraz.length === 0) props.getNs('sprraz');
        if (props.nsi.sprform.length === 0) props.getNs('sprform');
        if (props.nsi.periods.length === 0) props.getNs('periods');
        if (props.nsi.allraions.length === 0) props.getNs('allraions');
        //let year = new Date().getFullYear();
        //setYearsCopy([year - 1, year]);
        //setSelYear(year - 1);
    });
    const [year, setYear] = useState(() => getCurrentYear());
    const [kper, setKper] = useState(4);
    const [krazSrc, setKrazSrc] = useState(1);
    const [kraz, setKraz] = useState(1);
    const [tobj, setTobj] = useState(props.userInfo.user.accessLevel); // тип обьекта 1 – РК; 2 – область; 3 - район; 4 - организация (доступ зависит от пользователя)
    const [kobl, setKobl] = useState(props.userInfo.user.kodObl);
    const [kraion, setKraion] = useState(props.userInfo.user.kodRaion.substring(0, 4));
   // const [isAllRaions, setIsAllRaions] = useState(false);
    const [kf, setKf] = useState("01");
    const [orgs, setOrgs] = useState([]);
    const [selOrgs, setSelOrgs] = useState([]);
    const [isNotdata, setIsNotdata] = useState(false);
    const [isSvodComplete, setIsSvodComplete] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const setParam = (param, value) => {
        setSelOrgs([]);
        setOrgs([]);
        switch (param) {
            case "year":
                setYear(value);
                let rai = props.nsi.allraions.filter(r => r.obl === kobl && filterRaion(r, year));
                if (rai.length > 0) {
                    setKraion(rai[0].idRaion);
                }
                break;
            case "kper":
                setKper(value);
                break;
            case "krazSrc":
                setKrazSrc(value);
                break;
            case "tobj":
                setTobj(value);
                break;
            case "kobl":
                setKobl(value);
                let ra = props.nsi.allraions.filter(r => r.obl === value && filterRaion(r, year));
                if (ra.length > 0) {
                    setKraion(ra[0].idRaion);
                }
                break;
            case "kraion":
                setKraion(value);
                break;
            //case "isAllRaions":
            //    setIsAllRaions(value);
            //    break;
            case "kf":
                setKf(value);
                break;
            default:
        }
    }
    const filterObl = (f) => {
        if (year == null) return true;
        let ye = typeof year === 'string' ? parseInt(year, 10) : year;
        let dtBeg = f.dtBeg == null ? null : new Date(f.dtBeg);
        if (dtBeg != null && dtBeg.getFullYear() > ye) return false;
        let dtEnd = f.dtEnd == null ? null : new Date(f.dtEnd);
        if (dtEnd != null && ye >= dtEnd.getFullYear()) return false;
        return true;
    }
    const onGetListOrgs = (e) => {
        e.preventDefault();
        setOrgs([]);
        setSelOrgs([]);
        setIsNotdata(false);
        const loadList = async () => {
            let ret = await props.loadListOrgData({
                year, kper, kraz: krazSrc, kobl, kraion, kf, isAllRaions: tobj === 2 ? true : false
            });
            if (ret.successed === true) {
                setOrgs(ret.orgs);
                if (ret.orgs.length === 0) setIsNotdata(true);
            } else
                alert(ret.error);
        }
        loadList();
    }
    const onClickOrg = (korg, check) => {
        let sel = cloneDeep(selOrgs);
        if (check === true) {
            if (sel.filter(f => f == korg).length === 0) {
                sel.push(korg);
                setSelOrgs(sel);
            }
        } else {
            let ind = sel.map(m => m).indexOf(korg);
            if (ind > -1) {
                sel.splice(ind, 1);
                setSelOrgs(sel);
            }
        }
    }
    const getListItem = (m) => {
        if (selOrgs.filter(f => f === m.kod).length > 0)
            return <ListGroupItem key={m.kod} bsStyle="success"><Checkbox value={m.kod} disabled={props.svod.isLoading} onClick={(evt) => onClickOrg(evt.target.value, evt.target.checked)}>{m.kod} {getNameLocalize(props.i18n, m)}</Checkbox></ListGroupItem>
        else
            return <ListGroupItem key={m.kod}><Checkbox value={m.kod} disabled={props.svod.isLoading} onClick={(evt) => onClickOrg(evt.target.value, evt.target.checked)}>{m.kod} {getNameLocalize(props.i18n, m)}</Checkbox></ListGroupItem>
    }
    const handleConfirmClose = (evt) => {
        evt.preventDefault();
        setShowConfirm(false);
    }
    const getSvodParams = () => {
        if (selOrgs.length === 0) {
            alert(I18n.t("selectOrgs2"));
            return null;
        }
        if (kf === null || kf.length===0) {
            alert(I18n.t("selectForm"));
            return null;
        }
        if (krazSrc === kraz) {
            alert(I18n.t("razSrcEqDest"));
            return null;
        }
        setIsSvodComplete(false);
        return {
            year, 
            kper, 
            krazSrc,
            kraz,
            orgs: selOrgs,
            kf
        };
    }
    const onSvodFormCheck = async  (e) => {
        e.preventDefault();
        setIsSvodComplete(false);
        let param = getSvodParams();
        if (param == null) return;
        let retData = await props.isExistSvodOrgRaz(param);
        if (retData.successed === true) {
            if (retData.isExists === true) {
                //данные уже есть в базе данных, спросить создать свод?
                setShowConfirm(true);
                return;
            } else {
                await props.svodFormOrgRaz3(param);
                setShowConfirm(false);
                setIsSvodComplete(true);
            }
        } else {
            setIsSvodComplete(true);
        }
    }
    const onSvodForm = async (e) => {
        e.preventDefault();
        let param = getSvodParams();
        await props.svodFormOrgRaz3(param);
        setShowConfirm(false);
        setIsSvodComplete(true);
    }
    const filterRaion=(rai, year) => {
        if (year == null) return true;
        let ye = typeof year === 'string' ? parseInt(year, 10) : year;
        let dtBeg = rai.dtBeg == null ? null : new Date(rai.dtBeg);
        if (dtBeg != null && dtBeg.getFullYear() > ye) return false;
        let dtEnd = rai.dtEnd == null ? null : new Date(rai.dtEnd);
        if (dtEnd != null && ye >= dtEnd.getFullYear()) return false;
        return true;
    }
    let isReadOnLy = props.userInfo.user.roles.filter(r => r === 'UserReadData').length > 0;
    if (isReadOnLy === true)
        return <Translate value="noAccess" />
    else
    return <><Form onSubmit={onGetListOrgs} horizontal>
        <h5 style={{ textAlign: "center" }}><strong><Translate value="editFormOrgRaz" /></strong></h5>
        <FormGroup controlId="formYear" bsSize='small'>
            <Col sm={2} componentClass={ControlLabel}><Translate value='year' />{':'}</Col>
            <Col sm={10}>
                <FormControl className='input-sm' componentClass="select" placeholder="select" disabled={props.svod.isLoading}
                    defaultValue={year} onChange={(e) => setParam("year",parseInt(e.target.value, 10))} bsSize="small" style={{ width: "auto" }}>
                    {years.map(it => <option key={it} value={it}>{it}</option>)}
                </FormControl></Col>
        </FormGroup>
        <FormGroup controlId="formPer" bsSize='small'>
            <Col sm={2} componentClass={ControlLabel}><Translate value='period' />{':'}</Col>
            <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={`${kper}`} disabled={props.svod.isLoading}
                onChange={(e) => setParam("kper",parseInt(e.target.value, 10))} bsSize='small' style={{ width: "auto" }}>
                {props.nsi.periods.map(it => <option key={it.kper} value={it.kper}>{it.nameRu}</option>)}
            </FormControl></Col>
        </FormGroup>
        <FormGroup controlId="formRaz" bsSize='small'>
            <Col sm={2} componentClass={ControlLabel}><Translate value='razSourceOne' />{':'}</Col>
            <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={krazSrc} disabled={props.svod.isLoading}
                onChange={(e) => setParam("krazSrc",parseInt(e.target.value, 10))} bsSize='small' style={{ width: "auto" }}>
                <FilterRazOptions />
            </FormControl></Col>
        </FormGroup>
        <FormGroup controlId="formTobj" bsSize='small'>
            <Col sm={2} className="text-right"><ControlLabel><Translate value='obj' />{':'}</ControlLabel></Col>
            <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={tobj}
                onChange={(e) => setParam("tobj", parseInt(e.target.value))} style={{ width: "auto" }}>
                {props.userInfo.user.accessLevel < 3 ? <option value="2">{I18n.t('obl')}</option> : ''}
                {props.userInfo.user.accessLevel < 4 ? <option value="3">{I18n.t('raion')}</option> : ''}
            </FormControl></Col>
        </FormGroup>
        {tobj > 1 ? <FormGroup controlId="formObl" bsSize='small'>
            <Col sm={2} componentClass={ControlLabel}><Translate value='obl' />{':'}</Col>
            <Col xs={12} sm={10}>
                {props.userInfo.user.accessLevel > 1 ? <div>{kobl} {props.i18n.locale[0] === 'r' ? props.userInfo.user.nameObl : props.userInfo.user.nameOblKz}</div> :
                    <FormControl className='input-sm' componentClass="select" placeholder="select" value={kobl} disabled={props.svod.isLoading}
                        onChange={(e) => setParam("kobl", e.target.value)} style={{ width: "auto" }}>
                        {props.nsi.allobls.filter(f => filterObl(f)).map(it => <option key={it.kod} value={it.kod}>{getNameLocalize(props.i18n, it)}</option>)}
                    </FormControl>}
            </Col>
        </FormGroup> : ""}
        {tobj > 2 ? <FormGroup controlId="formRaion" bsSize='small'>
            <Col sm={2} className="text-right"><ControlLabel><Translate value='raion' />{':'}</ControlLabel></Col>
            <Col sm={8} bsSize='small'>
                {props.userInfo.user.accessLevel > 2 ? <span>{kraion} {props.i18n.locale[0] === 'r' ? props.userInfo.user.nameRaion : props.userInfo.user.nameRaionKz}</span> : <>
                    <FormControl className='input-sm' componentClass="select" placeholder="select" value={kraion} disabled={props.svod.isLoading/* || isAllRaions === true*/}
                        onChange={(e) => setParam("kraion", e.target.value)} style={{ width: "auto" }} style={{ display: "inline-block", width: "auto", marginRight: "5px" }}>
                        {props.nsi.allraions.filter(r => r.obl === kobl && filterRaion(r, year)).map(it => <option key={it.kod} value={it.idRaion}>{getNameLocalize(props.i18n, it)}</option>)}
                    </FormControl>
                    {/*<Checkbox onChange={(e) => setParam("isAllRaions", e.target.checked)} checked={isAllRaions} inline><Translate value="allRaions" /></Checkbox>*/}
                </>}
            </Col>
        </FormGroup> : ""}
        <FormGroup controlId="formKf" bsSize='small'>
            <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='forma' />{':'}</ControlLabel></Col>
            <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={kf} disabled={props.svod.isLoading}
                onChange={(e) =>  setParam("kf",e.target.value)} style={{ minWidth: '100%' }}>
                {props.nsi.sprform.map(it => <option key={it.kf} value={it.kf}>{it.kf} {getNameLocalize(props.i18n, it)}</option>)}
            </FormControl></Col>
        </FormGroup>
        <FormGroup>
            <Col smOffset={2} sm={10}>
                <Button className='btn btn-primary text-center' type="submit" disabled={props.svod.isLoading === true}><Translate value="svodSpecificOrg.btGetListOrgs" /></Button>
            </Col>
        </FormGroup>
    </Form>
        {props.svod.isLoading === true ? <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('wait')} width={20} />{' '}<strong><Translate value="waitsvod" /></strong></div> : ''}
        {isNotdata === true ? <Alert bsStyle="warning"><Translate value="noDataForm" /></Alert> : ""}
        {orgs.length > 0 ? <Form onSubmit={onSvodFormCheck} horizontal>
            <div><strong className="text-primary"><Translate value="selectOrgs" /></strong></div>
            <ListGroup bsSize="sm" className="ulListSpecOrg">{orgs.map(m => getListItem(m))}</ListGroup>
            <FormGroup controlId="formRazDest" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='razDest' />{':'}</Col>
                <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={kraz} disabled={props.svod.isLoading === true || selOrgs.length === 0}
                    onChange={(e) => setKraz(parseInt(e.target.value, 10))} bsSize='small' style={{ width: "auto" }}>
                    <FilterRazOptions />
                </FormControl></Col>
            </FormGroup>
            {isSvodComplete === true && props.svod.statusSvod != null && props.svod.statusSvod.successed === true ? <Row>
                <Col smOffset={2} sm={10}>
                    <Alert bsStyle="success"><Translate value="razChanged"/></Alert>
                </Col>
            </Row> : ''}
            {isSvodComplete === true && props.svod.statusSvod != null && props.svod.statusSvod.error != null ? <Row>
                <Col smOffset={2} sm={10}>
                    <Alert bsStyle="danger">{props.svod.statusSvod.error}</Alert>
                </Col>
            </Row> : ''}
            <FormGroup>
                <Col smOffset={2} sm={10}>
                    <Button className='btn btn-primary text-center' type="submit" disabled={props.svod.isLoading === true || selOrgs.length === 0}><Translate value="changeRazrez" /></Button>
                </Col>
            </FormGroup>
        </Form> : ""}
        <Modal show={showConfirm} onHide={handleConfirmClose}>
            <Modal.Header closeButton>
                <Modal.Title><Translate value="warning" /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert bsStyle="warning">
                    <strong><Translate value="warning" /></strong> <Translate value="razEditConfirmMsg" />
                </Alert>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onSvodForm}><Translate value="editRazBtCreate" /></Button>
                <Button onClick={handleConfirmClose}><Translate value="editRazBtCancel" /></Button>
            </Modal.Footer>
        </Modal>
    </>
}
export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionCreatorsSvod }, dispatch)
)(EditFormOrgRaz);