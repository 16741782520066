import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import * as FileSaver from 'file-saver';
import { Translate, I18n } from 'react-redux-i18n';
import { Button, Col, FormGroup, FormControl, ControlLabel, Form, Row, Alert, Checkbox} from 'react-bootstrap';

import { actionCreators as actionCreatorsSvod } from '../../store/Svod';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';

import FilterRazOptions from '../common/FilterRazOptions';
import { years, getNameLocalize, getCurrentYear } from '../Constants';

//import SelectMultiObjects from '../common/SelectMultiObjects';
import SelectMultiForms from '../common/SelectMultiForms';
import SelectObject from '../common/SelectObject';

const SvodFormOrgRaz2 = (props) => {
    useState(() => {
        if (props.nsi.allobls.length === 0) props.getNs('allobls');
        if (props.nsi.sprraz.length === 0) props.getNs('sprraz');
        if (props.nsi.sprform.length === 0) props.getNs('sprform');
        if (props.nsi.periods.length === 0) props.getNs('periods');
        if (props.nsi.allraions.length === 0) props.getNs('allraions');
    });
    const [year, setYear] = useState(() => getCurrentYear());
    const [tobj, setTobj] = useState(props.userInfo.user.accessLevel);
    const [kper, setKper] = useState(4);
    const [kraz_src, setKraz_src] = useState([]);
    const [kraz, setKraz] = useState(1);
    const [obl, setObl] = useState();
    const [raion, setRaion] = useState();
    const [org, setOrg] = useState();
    const [kforms, setKforms] = useState([]);
    //const [errors, setErrors] = useState([]);
    const [isSvodComplete, setIsSvodComplete] = useState(false)
    /*
    const getValidationStateRaz= () =>{
        const length = kraz_src.length;
        setErrors([]);
        let er=[]
        if (length > 0)
            return 'success';
        if (length === 0) {
            er.push("Выберите разрезы источника");
            setErrors(er);
            return 'error';
        }
        return null;
    }
    */
    const onSelectKrazSrc = (e) => {
        let checked = e.target.checked;
        let kraz = parseInt(e.target.value, 10);
        let kraz_s = cloneDeep(kraz_src);
        if (checked) {
            kraz_s.push(kraz);
        } else {
            let ind = kraz_s.indexOf(kraz);
            kraz_s.splice(ind, 1);
        }
        setKraz_src(kraz_s);
    }
    const getSvodParams = () => {
        let kobjs = null;
        switch (tobj) {
            case 1:
                kobjs = ["00"];
                break;
            case 2: //по области
                if (props.userInfo.user.accessLevel === 2) 
                    kobjs = [props.userInfo.user.kodObl];
                else
                    kobjs = [obl.kod];
                break;
            case 3: //по району
                if (props.userInfo.user.accessLevel === 3) 
                    kobjs = [props.userInfo.user.kodRaion];
                 else 
                    kobjs=[raion.kraion];
                break;
            case 4://организация
                if (props.userInfo.user.accessLevel === 4) 
                    kobjs = [props.userInfo.user.kodOrg];
                else
                    kobjs = [org.korg];
                break;
            default:
        }
        if (kobjs.length === 0) {
            alert(I18n.t("selectObjs"));
            return null;
        }
        if (kforms.length === 0) {
            alert(I18n.t("selectForms"));
            return null;
        }
        if (kraz_src.length === 0) {
            alert(I18n.t("selectRazSrc"));
            return null;
        }
        if (kraz_src.filter(f => f === kraz).length > 0) {
            alert(I18n.t("razSrcEqDest"));
            return null;
        }
        //this.setState({ isSvodComplete: false });
        return {
            year: year,
            kper: kper,
            krazSrc: kraz_src,
            kraz,
            tobj,
            kobjs,
            kforms
        };
    }
    const onSvodFormOrgRaz = async (e) => {
        e.preventDefault();
        setIsSvodComplete(false);
        let param = getSvodParams();
        if (param == null) return;
        await props.svodFormOrgRaz2(param);
        setIsSvodComplete(true);
    }
    let isReadOnLy = props.userInfo.user.roles.filter(r => r === 'UserReadData').length > 0;
    if (isReadOnLy === true)
        return <Translate value="noAccess" />
    else
    return <><Form onSubmit={onSvodFormOrgRaz} horizontal>
        <h5 style={{ textAlign: "center" }}><strong><Translate value="svodFormOrgRaz.tit" /></strong></h5>
        <FormGroup controlId="formYear" bsSize='small'>
            <Col sm={2} componentClass={ControlLabel}><Translate value='year' />{':'}</Col>
            <Col sm={10}>
                <FormControl className='input-sm' componentClass="select" placeholder="select" disabled={props.svod.isLoading}
                    defaultValue={year} onChange={(e) => setYear(parseInt(e.target.value, 10))} bsSize="small" style={{ width: "auto" }}>
                    {years.map(it => <option key={it} value={it}>{it}</option>)}
                </FormControl></Col>
        </FormGroup>
        <FormGroup controlId="formPer" bsSize='small'>
            <Col sm={2} componentClass={ControlLabel}><Translate value='period' />{':'}</Col>
            <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={`${kper}`} disabled={props.svod.isLoading}
                onChange={(e) => setKper(parseInt(e.target.value, 10))} bsSize='small' style={{ width: "auto" }}>
                {props.nsi.periods.map(it => <option key={it.kper} value={it.kper}>{it.nameRu}</option>)}
            </FormControl></Col>
        </FormGroup>
        <FormGroup controlId="formRazSel" bsSize='small'>
            <Col sm={2} componentClass={ControlLabel}><Translate value='razres' />{':'}</Col>
            <Col sm={10}>
                <div style={{ float: "left", width: "auto" }}><ControlLabel><Translate value="razSource" /></ControlLabel>
                    {props.nsi.sprraz/*.filter(f => this.props.userInfo.user.accessLevel !== 4 || f.kod === 3 || f.kod === 4 || f.kod === 5 || f.kod === 6 || f.kod === 12 || f.kod === 23 || f.kod === 24 || f.kod === 25)*/
                        .map(it => <Checkbox onChange={onSelectKrazSrc} key={it.kod} value={it.kod}>{it.nameRu}</Checkbox>)}
                </div>
                <div><ControlLabel><Translate value="razDest" /></ControlLabel>
                    <FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={kraz}
                        onChange={(e) => setKraz(parseInt(e.target.value, 10))} bsSize='small' style={{ width: "auto" }}>
                        <FilterRazOptions />
                    </FormControl>
                </div>
            </Col>
        </FormGroup>
        <SelectObject
            tobj={tobj}
            obl={obl}
            raion={raion}
            org={org}
            setTobj={setTobj}
            setObl={setObl}
            setRaion={setRaion}
            setOrg={setOrg} disabled={props.svod.isLoading}
            year={year}
            />
        <SelectMultiForms setForms={(kforms) => setKforms(kforms)} />
        {isSvodComplete === true && props.svod.statusSvod != null && props.svod.statusSvod.successed === true ? <Row>
            <Col smOffset={2} sm={10}>
                <Alert bsStyle="success"><Translate value="svodComplete" /></Alert>
            </Col>
        </Row> : ''}
        {isSvodComplete === true && props.svod.statusSvod != null && props.svod.statusSvod.error != null ? <Row>
            <Col smOffset={2} sm={10}>
                <Alert bsStyle="danger">{props.svod.statusSvod.error}</Alert>
            </Col>
        </Row> : ''}
        <FormGroup>
            <Col smOffset={2} sm={10}>
                <Button className='btn btn-primary text-center' disabled={props.svod.isLoading} type="submit"><Translate value="createSvodRaz" /></Button>
            </Col>
        </FormGroup>
    </Form></>
}
export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionCreatorsSvod }, dispatch)
)(SvodFormOrgRaz2);