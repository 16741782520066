import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import { Translate/*, I18n*/ } from 'react-redux-i18n';
import NumberFormat from 'react-number-format';

import { actionCreators } from '../../store/Medinfo/FormulMedinfo';
import './editBokMkb.css';

class EditBokMkb extends Component {
    constructor(props) {
        super(props);
        this.props.getBokMkb();
    }
    nppChange = (values, bokMkbId) => {
        //let vl = parseInt(values.value);
        this.props.updateFieldBokMkb({ bokMkbId, field: 'Npp', value: values.value });
    }
    /*
    nameRuChange = (nameRu, bokMkbId) => {
        this.props.updateFieldBokMkb({ bokMkbId, field: 'NameRu', value: nameRu });
    }
    nameKzChange = (nameKz, bokMkbId) => {
        this.props.updateFieldBokMkb({ bokMkbId, field: 'NameKz', value: nameKz });
    }
    */
    nameChange = (name, bokMkbId, fld) => {
        this.props.updateFieldBokMkb({ bokMkbId, field: fld, value: name });
    }
    showTable = () => {
        if (this.props.bokMkb === undefined) {
            return '';
        }
        return <Fragment>
            {this.props.error !== undefined ? <div className='text-danger text-center'><strong>{this.props.error}</strong></div> : ''}
            <Table striped bordered condensed hover className="bok-mkb-table">
                <thead>
                    <tr>
                        <th>№ п/п</th>
                        <th><Translate value="nameRu" /></th>
                        <th><Translate value="shortNameRu" /></th>
                        <th><Translate value="nameKz" /></th>
                        <th><Translate value="shortNameKz" /></th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.bokMkb.map(b =>
                        <tr key={b.bokMkbId}>
                            <td> <NumberFormat onValueChange={(values) => this.nppChange(values, b.bokMkbId)} 
                                key={b.bokMkbId} ref={b.bokMkbId} id={b.bokMkbId} value={b.npp} />
                            </td>
                            <td><input value={b.nameRu} onChange={(e) => this.nameChange(e.target.value, b.bokMkbId, 'NameRu')} /></td>
                            <td><input value={b.nameShortRu} onChange={(e) => this.nameChange(e.target.value, b.bokMkbId, 'NameShortRu')} /></td>
                            <td><input value={b.nameKz} onChange={(e) => this.nameChange(e.target.value, b.bokMkbId, 'NameKz')} /></td>
                            <td><input value={b.nameShortKz} onChange={(e) => this.nameChange(e.target.value, b.bokMkbId, 'NameShortKz')} /></td>
                        </tr>
                    )}
                  </tbody>
            </Table>
        </Fragment>;
    }
    render() {
        return <Fragment>
            <h4><Translate value="editBokMkbMedinfo" /></h4>
            {this.showTable()}
        </Fragment>;
    }
}
export default connect(
    state => state.formulmedinfo,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(EditBokMkb);