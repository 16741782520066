import React, { useState, Fragment, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import * as FileSaver from 'file-saver';
import { Translate, I18n } from 'react-redux-i18n';
import { Button, ButtonGroup, Table, Modal, Row, Col, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';

//import * from 'bootstrap-icons';
//import { LinkContainer } from 'react-router-bootstrap';
import NumberFormat from 'react-number-format';

import { actionCreators as actionUsrInfo } from '../../store/UserInfo';
import { actionCreators as actionNsi } from '../../store/Nsi';
import { getNameLocalize } from '../Constants';

import './TableView.css';

function TableView2(props) {
    const [mode, setMode] = useState('view');
    const [activeTabKey, setActiveTabKey] = useState('data');
    const [isLoading, setIsLoading] = useState(false);
    const [dataStore, setDataStore] = useState();
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [isAutoCalc, setIsAutoCalc] = useState(true);
    //const [showWait, setShowWait] = useState(false);
    useEffect(() => {
        setDataStore(props.storeData);
        setIsAutoCalc(props.storeData.kf != "70" && props.storeData.kf != "71" && props.storeData.kf != "72" && props.storeData.kf != "73" && props.storeData.kf != "74"); 
    }, [props.tiks]);

    const setEditMode = (e) => {
        e.preventDefault();
        setMode("edit");
        setActiveTabKey('data');
    }
    const saveData = (e) => {
        e.preventDefault();
        props.saveData(dataStore);
        setMode("view");
    }
    const cancelData = (e) => {
        e.preventDefault();
        setMode("view");
        setDataStore(cloneDeep(props.storeData));
        // this.setState({ mode: 'view', dataStore: this.cloneRows(this.props.storeData) });
    }
    const printData = (e) => {
        e.preventDefault();
        if (activeTabKey === 'data') {
            //печать данных формы
            props.printData();
        } else {
            //печать результатов контроля
            props.printControl();
        }
    }
    const createExcelForm = (e) => {
        e.preventDefault();
        fetchExcel(dataStore);
    }
    const fetchExcel = async (p) => {
        setIsLoading(true);
        let absoluteUrl = `${document.location.protocol}//${document.location.host}/generate/Excel/${p.year}/${p.period}/${p.raz}/${p.tobj}/${p.kobj}/${p.kf}/${props.i18n.locale.substr(0, 2)}`;
        let fileName = `${p.year}_${p.period}_${p.raz}_${p.tobj}_${p.kobj}_${p.kf}.xlsx`;
        const response = await fetch(absoluteUrl, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${props.userInfo.user.token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const blob = await response.blob();
            FileSaver.saveAs(blob, fileName);
            setIsLoading(false);
        } else {
            alert("Ошибка HTTP: " + response.status);
            setIsLoading(false);
        }
    }
    const statusUpdate = () => {
        if (props.storeData.statusUpdate !== undefined) {
            if (props.storeData.statusUpdate.successed)
                return (<div className="text-info small"><strong><Translate value="dataSaved" /></strong></div>);
            else
                return (<div className="text-danger"><strong><Translate value="error" />{':'}</strong>{props.storeData.statusUpdate.error}</div>);
        }
        return ('');
    }
    const statusDelete = () => {
        if (props.work.statusDelete != null) {
            if (props.work.statusDelete.successed===true)
                return ('')//(<div className="text-info small"><strong><Translate value="deletedData" /></strong></div>);
            else
                return (<div className="text-danger"><strong><Translate value="error" />{props.work.statusDelete.error}</strong></div>);
        }
        return ('');
    }

    const confirmDelete = (e) => {
        e.preventDefault();
        setShowConfirmDelete(true);
    }
    const handleCloseConfirmDelete = (e) => {
        e.preventDefault();
        setShowConfirmDelete(false);
    }
    const deleteData = (e) => {
        e.preventDefault();
        props.deleteData();
        setShowConfirmDelete(false);
    }

    const renderPostr = () => {
        return (<Table striped bordered condensed hover>
            <thead>
                <tr>
                    <th>Строка {mode === 'edit' && dataStore != null && dataStore.controlCells.length > 0 ? <><br />
                        <Button type="button" bsStyle="default" bsSize="xsmall" onClick={recalcItogs} style={{ marginBottom: "5px" }}><Translate value="recalcItogs" /></Button></> : ""}
                        {dataStore != null && dataStore.controlCells.length > 0 ? <><br/>
                            <span className="text-success" style={{ backgroundColor: "white", padding: "3px" }}><i className="bi bi-file-fill" style={{ color: "lightgreen" }}></i> - Итог правильно</span>
                            <span className="text-danger" style={{ backgroundColor: "white", padding: "3px", marginLeft: "4px" }}><i className="bi bi-file-fill text-danger"></i> - Итог не правильно</span></>:""}
                    </th>
                    <th>Колонка</th>
                    <th>Значение</th>
                </tr>
            </thead>
            <tbody>
                {dataStore.rows.map(r => renderRowPostr(r))}
            </tbody>
        </Table>);
    }
    const renderRowPostr = (r) => {
        let strs = dataStore.strs;
        let str = strs.filter((s) => r.kstr === s.kstr)[0];
        let isNotHeader = str.kstr !== '   ' && str.kstr !== '  ' && str.kstr !== ' ';
        if (isNotHeader === true) {
            let countCol = r.values.length;
            return r.values.map((v, i) => renderColPostr(v, i, str, countCol));
        } else
            return (<tr key={`${str.kstr}`}><th colSpan={3}>{`${str.nstr == null ? " " : str.nstr + " "}(${str.kstr})`} {getNameLocalize(props.i18n, str)}</th></tr>)
    }
    const renderColPostr = (v, i, str, countCol) => {
        let gr = dataStore.grs.filter((g) => g.kgr === v.kgr)[0];
        return (<tr key={`${str.kstr}_${v.kgr}`}>{i === 0 ? <td rowSpan={countCol}>{`${str.nstr == null ? " " : str.nstr + " "}(${str.kstr})`} {getNameLocalize(props.i18n, str)}</td> : undefined}<td>{gr.kgr} {getNameLocalize(props.i18n, gr)}</td>
            <td>{getControlNumber(v, str.kstr, v.kgr)}</td></tr>);
    }

    const renderTable = () => {
        return (<div className="containerDiv">
            <div className="rowDivHeader2">
                <div className="cellDivHeaderLong"><Translate value="pokazateli" />
                    {mode === 'edit' && dataStore != null && dataStore.controlCells.length > 0 ? <><br />
                        <Button type="button" bsStyle="default" bsSize="xsmall" style={{ marginBottom:"5px" }} onClick={recalcItogs}><Translate value="recalcItogs" /></Button></> : ""}
                    {dataStore != null && dataStore.controlCells.length > 0 ? <><br/>
                        <span className="text-success" style={{ backgroundColor: "white", padding: "3px" }}><i className="bi bi-file-fill" style={{ color: "lightgreen" }}></i> - Итог правильно</span>
                        <span className="text-danger" style={{ backgroundColor: "white", padding: "3px", marginLeft: "4px" }}><i className="bi bi-file-fill text-danger"></i> - Итог не правильно</span></> : ""}
                </div>
                <div className="cellDivHeader"><Translate value="kodstr" /></div>
                {dataStore.grs.map((g) => {
                    let name = getNameLocalize(props.i18n, g);
                    return <div key={g.kgr} className="cellDivHeader" data-toggle="tooltip" title={name}>{g.kgr} {name}</div>
                })}
            </div>
            {dataStore.rows.map(r => renderRowTable(r))}
        </div>);
    }
    const recalcItogs = (evt) => {
        evt.preventDefault();
        //setShowWait(true);
        calculateAllItogs();
        //setShowWait(false);
    }
    const calculateAllItogs = () => {
        //первый проход
        let ds = cloneDeep(dataStore);//this.cloneRows(this.state.dataStore);
        /*ds.rows = *///clearItogs(ds);
        /*ds.rows = */recalcPart(ds);
        setDataStore(ds);
    }
    /*
    const clearItogs = (ds) => {
        for (var i in ds.rows) {
            let row = ds.rows[i];
            for (var j in row.values.filter(f => f.controlCell != null && f.controlCell.length > 0)) {
                let val = row.values[j];
                val.vl = 0;
            }
        }
    }
    */
    const recalcPart = (ds) => {
        for (var i in ds.controlCells) {
            let cont = ds.controlCells[i];
            let s = 0;
            for (var j in cont.srcCells) {
                let cell = cont.srcCells[j];
                let dr = ds.rows.filter(r => r.kstr === cell.r);
                if (dr.length > 0) {
                    let dc = dr[0].values.filter(c => c.kgr === cell.c);
                    if (dc.length > 0 && dc[0].vl > 0) {
                        if (cell.s==="-")
                            s = s - Number(dc[0].vl);
                        else
                            s = s + Number(dc[0].vl);
                    }
                }
            }
            let dr = ds.rows.filter(r => r.kstr === cont.cellItog.r);
            s = s.toFixed(3);
            if (dr.length > 0) {
                let dc = dr[0].values.filter(c => c.kgr === cont.cellItog.c);
                if (dc.length > 0)
                    dc[0].vl = s;
                else
                    dc.push({ vl: s, originalVl: s, kstr: cont.cellItog.r, kgr: cont.cellItog.c, controlCells: cont });
            } else {
                ds.rows.push({
                    kstr: cont.cellItog.r,
                    values: [{ vl: s, originalVl: s, kstr: cont.cellItog.r, kgr: cont.cellItog.c, controlCells: cont }]
                });
            }
        }
    }

    const renderRowTable = (r) => {
        let strs = dataStore.strs;
        let str = strs.filter((s) => r.kstr === s.kstr)[0];
        let isNotHeader = str.kstr !== '   ' && str.kstr !== '  ' && str.kstr !== ' ';
        return (<div className="rowDiv" key={`${str.kstr}`}>
            {isNotHeader === true ? <div className="cellDivLong clip" data-toggle="tooltip" title={getNameLocalize(props.i18n, str)}>{getNameLocalize(props.i18n, str)}</div> :
                <div className="cellDivLongH clip" data-toggle="tooltip" title={str.nstr}><strong>{str.nstr}</strong></div>}
            {isNotHeader === true ? <div className="cellDiv">{`${str.nstr == null ? " " : str.nstr + " "}(${str.kstr})`}</div> : <div className="cellDivH">{`${str.nstr == null ? " " : str.nstr + " "}(${str.kstr})`}</div>}
            {dataStore.grs.map((gr) => {
                let agraf = r.values.filter(f => f.str === str.kstr && f.kgr === gr.kgr);
                if (agraf != null && agraf.length > 0) {
                    let v = agraf[0];
                    return <div className="cellDiv" key={`${str.kstr}_${v.kgr}`}>{getControlNumber(v, str.kstr, v.kgr)}</div>;
                } else
                    return (<div className="cellDivH" key={`${str.kstr}_${gr.kgr}`}></div>);
            })}
        </div>);
    }

    const getControlNumber = (v, str, kgr) => {
        let keyinput = `${str}_${kgr}`;
        //игнорировать блокируемые ячейки
        if (dataStore.blockCells.filter(f => f.r === str && f.c === kgr).length > 0) {
            return <strong>X</strong>;
        }
        let style = { color: v.vl === v.originalVl ? 'black' : 'blue' };
        if (v.controlCell == null || v.controlCell.length === 0) {
            return NumberCtrl(v.vl, keyinput, style);
        } else {
            let noError = true;
            var itogs = calcItog(v.controlCell);
            let nv = v.vl === null ? 0 : Number(v.vl).toFixed(3);
            let aSylesFormuls = [];
            for (var i in itogs) {
                if (itogs[i] != nv) {
                    noError = false;
                    aSylesFormuls.push({ color: "white", backgroundColor: "red" });
                } else {
                    aSylesFormuls.push({ color: "black", backgroundColor: "lightgreen" });
                }
            }
            //let cls = itogs[i] === v.vl || (v.vl == null && itogs[i]===0)? "tooltip-success" : "tooltip-danger";
            if (noError === false)
                style = { color: "white", backgroundColor: "red" };
            else
                style = { color: "black", backgroundColor: "lightgreen" };
            return  <OverlayTrigger placement="top"
                overlay={<Tooltip id={`tooltip_${keyinput}`} bsStyle="default" style={{ padding: 0 }}>
                    <div style={{ margin: 0, minWidth: "400px" }}>
                        {v.controlCell.map((m, i) => <div key={i} style={{ borderTop: "solid black" }}><div style={aSylesFormuls[i]}>{m.k2}</div><div style={aSylesFormuls[i]}>{m.formul}</div>
                            {nv != itogs[i] ? <div style={{ color: "red", backgroundColor: "white" }}><strong>{nv}!={itogs[i]}</strong></div> : ""}
                        </div>)}
                    </div>
                </Tooltip>}>
                {NumberCtrl(v.vl, keyinput, style)}
            </OverlayTrigger>
        }
    }
    const NumberCtrl = (vl, keyinput, style) => {
        if (mode === "edit") {
            if (isAutoCalc === true)
                return <NumberFormat className="inputData" autoComplete="off" thousandSeparator={' '}
                    onValueChange={(values) => valueChange(values, keyinput)} onKeyDown={handleKeyPress}
                    key={keyinput} id={keyinput} value={vl} style={style} />
            else
                return <NumberFormat className="inputData" autoComplete="off"
                    onBlur={(evt) => onBlur(evt, keyinput)} onKeyDown={handleKeyPress}
                key={keyinput} id={keyinput} value={vl} style={style} />
        } else {
            return <div key={keyinput} id={keyinput} style={style}>{vl > 0 ? new Intl.NumberFormat("ru-RU").format(vl):vl}</div> //{/*<NumberFormat key={keyinput} id={keyinput} className="inputData" thousandSeparator={' '} value={vl} displayType={'text'} style={style}/>*/ };
        }
        //thousandSeparator={' '}
    }
    const calcItog = (controlCell, data) => {
        let sum = [];
        let ds = data != null ? data : dataStore;
        for (var j in controlCell) {
            let srcCells = controlCell[j].srcCells;
            let s = 0;
            for (var i in srcCells) {
                let cell = srcCells[i];
                let dr = ds.rows.filter(r => r.kstr === cell.r);
                if (dr.length > 0) {
                    let dc = dr[0].values.filter(c => c.kgr === cell.c);
                    if (dc.length > 0 && dc[0].vl > 0)
                        if (cell.s === "-")
                            s = s - Number(dc[0].vl);
                        else
                            s = s + Number(dc[0].vl);
                }
            }
            sum.push(s.toFixed(3));
        }
        return sum;
    }
    const onBlur = (evt, id) => {
        let vstr = evt.target.value == null ? "0.0" : evt.target.value.trim();
        var vl = parseFloat(vstr);
        let ar = id.split('_');
        let kstr = ar[0];
        let kgr = ar[1];
        let ds = cloneDeep(dataStore);
        let row;
        let col;
        row = ds.rows.filter((r) => r.kstr === kstr)[0];
        col = row.values.filter((c) => c.kgr === kgr && c.str === kstr)[0];
        col.vl = vl;
        if (isAutoCalc === true && (col.controlCell == null || col.controlCell.length === 0)) {
            recalcPart(ds);
        }
        setDataStore(ds);
    }
    const valueChange = (values, id) => {
        let vl = values.floatValue === undefined ? 0.0 : values.floatValue;
        let ar = id.split('_');
        let kstr = ar[0];
        let kgr = ar[1];
        let ds = cloneDeep(dataStore);//this.cloneRows(this.state.dataStore);
        let row;
        let col;
        row = ds.rows.filter((r) => r.kstr === kstr)[0];
        col = row.values.filter((c) => c.kgr === kgr && c.str === kstr)[0];
        col.vl = vl;
        if (isAutoCalc===true && (col.controlCell == null || col.controlCell.length === 0)) {
            recalcPart(ds);
        }
        setDataStore(ds);
    }
    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault(); // Prevent form submission if button present
            let next = e.target.parentNode.nextSibling;
            if (next != null) {
                e.target.blur();
                next.firstChild.focus();
                return;
            } else {
                let nextRow = e.target.parentNode.parentNode.nextSibling;
                if (nextRow != null) {
                    if (props.isPostr === true) {
                        if (nextRow.children[1].children[0] != null) {
                            if (nextRow.children[1].children[0].focus != null) {
                                e.target.blur();
                                nextRow.children[1].children[0].focus();
                                return;
                            }
                        } else if (nextRow.children[2].children[0] != null) {
                            if (nextRow.children[2].children[0].focus != null) {
                                e.target.blur();
                                nextRow.children[2].children[0].focus();
                                return;
                            }
                        }/*else {

                        }*/
                    } else {
                        if (nextRow.children[2].firstChild.focus != null) {
                            e.target.blur();
                            nextRow.children[2].firstChild.focus();
                            return;
                        } /*else {

                        }*/
                    }
                }
            }
        }
    }
    const IsVisibleEdit = (ds) => {
        switch (props.userInfo.user.accessLevel) {
            case 1://РК
                return ds.statusRk != 2 && ds.statusTableRk != 2 && ds.statusTableObl != 2;
            case 2://Область
                if (ds.tobj === 4 && ds.kobj === props.userInfo.user.kodOrg)
                    return ds.statusRk != 2 && ds.statusTableRk != 2 && ds.statusTableObl != 2;
                else
                    return ds.statusRk != 2 && ds.statusTableRk != 2 && (ds.statusTableObl === 2 || ds.statusObl === 2);
            default:
                return ds.statusRk != 2 && ds.statusTableRk != 2 && ds.statusTableObl != 2;
        }
        return true;
    }
    if (dataStore == null || dataStore.rows == undefined)
        return ('');
    let isAdminData = props.userInfo.user.roles.filter(r => r === 'AdminEditData').length > 0 ? true : false;
    let isReadData = props.userInfo.user.roles.filter(r => r === 'UserReadData').length > 0 ? true : dataStore.readOnly;
    let visibleEdit = IsVisibleEdit(dataStore);

    return <div>
        {statusUpdate()}
        {statusDelete()}
        <ButtonGroup bsSize="xsmall">
            {mode === 'view' ? <Fragment>
                <Button type="button" bsStyle="info" disabled={mode !== 'view' || isLoading === true} onClick={() => props.selectParam()}><Translate value="select" /></Button>{' '}
                {isReadData === false && visibleEdit === true ? <Button type="button" bsStyle="primary" disabled={mode !== 'view' || isLoading === true} onClick={setEditMode}><Translate value="edit" /></Button> : ''}
                {' '}</Fragment> : ''}
            {mode === 'edit' ? <Fragment>
                <Button type="button" bsStyle="success" disabled={mode !== 'edit' || isLoading === true} onClick={saveData}><Translate value="save" /></Button>{' '}
                <Button type="button" disabled={mode !== 'edit' || isLoading === true} onClick={cancelData}><Translate value="cancel" /></Button></Fragment> : ''}
        </ButtonGroup>
        <ButtonGroup bsSize="xsmall">
            {((props.isAvariableDelete === true && visibleEdit === true) || (isAdminData === true && mode === 'view')) ? <Button id='bt' type="button" bsStyle="danger" disabled={mode !== 'view' || isLoading === true} onClick={confirmDelete}><Translate value="delete" /></Button> : ''}
            {mode === 'view' ? <Fragment>
                <Button id='bt' type="button" bsStyle="default" disabled={mode !== 'view' || isLoading === true} onClick={printData}><Translate value="print" /></Button>
                {dataStore.year > 2016 ? <Button id='bt' type="button" bsStyle="default" disabled={mode !== 'view' || isLoading === true} onClick={createExcelForm}><Translate value="toExcel2" /></Button> : ""}
                {/*<LinkContainer to={'/toexcel'}><span className="btn btn-default"><Translate value='toExcel' /></span></LinkContainer>*/}
            </Fragment> : ''}
        </ButtonGroup>
        {isReadData === false && dataStore.statusTableRk === 2 && props.userInfo.user.accessLevel!=1 ? <><Translate className="bg-warning" style={{ marginLeft: "5px", padding: "5px" }} value="blockTableRk" /><br/></> : ""}
        {isReadData === false && dataStore.statusTableObl === 2 && props.userInfo.user.accessLevel != 2 ? <><Translate className="bg-warning" style={{ marginLeft: "5px", padding: "5px" }} value="blockTableObl" /><br /></> : ""}
        {isReadData === false && dataStore.statusRk === 2 && props.userInfo.user.accessLevel != 1? <Translate className="bg-warning" style={{ marginLeft: "5px", padding: "5px" }} value="blockRk" /> : ""}
        {isLoading ? <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('wait')} width={20} />{' '}<strong><Translate value="generateExcel" /></strong></div> : ''}
        {/*{props.storeData.statusUpdate != null && props.storeData.statusUpdate.error != null ? <Row>
            <Col>
                <Alert bsStyle="danger">{props.storeData.statusUpdate.error}</Alert>
            </Col>
        </Row> : ''}
        {props.storeData.statusDelete != null && props.storeData.statusDelete.error != null ? <Row>
            <Col>
                <Alert bsStyle="danger">{props.storeData.statusDelete.error}</Alert>
            </Col>
        </Row> : ''}*/}
        {props.isPostr === true ? renderPostr() : renderTable()}
        <Modal show={showConfirmDelete} onHide={handleCloseConfirmDelete}>
            <Modal.Header closeButton>
                <Modal.Title><Translate value="deleteDataForm" /></Modal.Title>
            </Modal.Header>
            <Modal.Body bsClass='small'>
                <Row><Col smOffset={4}><strong><Translate value="deleteQuestion" table={dataStore.kt} form={dataStore.kf} kobj={dataStore.kobj} /></strong></Col></Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleCloseConfirmDelete}><Translate value="close" /></Button>
                <Button bsStyle="danger" onClick={deleteData}><Translate value="delete" /></Button>
            </Modal.Footer>
        </Modal>
        {/*{showWait ? <Modal
            size="sm"
            show={true}
            backdrop="static"
            keyboard={false}
            dialogClassName="modal-sm-dialog-scrollable"
            centered
        >
            <Modal.Body>
                <Alert style={{ marginBottom: 0 }}><div style={{ display: "inline-block", paddingLeft: "5px" }}><img src="../images/loading.gif" alt={I18n.t('wait')} width={20} /><Translate value={wait} /></div></Alert>
            </Modal.Body>
        </Modal> : ""}*/}
    </div>
}
export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionUsrInfo, ...actionNsi }, dispatch)
)(TableView2);