import React, { Component, Fragment } from 'react';
import './License.css';
export default class License extends Component {
    render() {
        return <Fragment>
            <div className="background-image"></div>
            <div className="cont">
                <div className="panel">
                    <div className="panel-heading">Оформление лицензионного соглашения</div>
                    <div className="panel-body">
                        Для заключения договора о лицензионном соглашении с ТОО “Мединформ” необходимо:
                        <ol>
                            <li>Скачать, заполнить и подписать <a href="downloads/license.pdf" style={{ fontWeight: "bold", textDecoration: "underline" }} download>договор о лицензионном соглашении</a>;</li>
                            <li>Скачать <a href="downloads/author.pdf" style={{ fontWeight: "bold", textDecoration: "underline" }} download>авторское свидетельство</a>;</li>
                            <li>Оплатить стоимость Годовой Лицензии;</li>
                            <li>
                                Отправить по электронной почте <a href="mailto:office@medinfo.kz" style={{ fontWeight: "bold", textDecoration: "underline" }}>office@medinfo.kz</a> электронные документы:
                                <ul>
                                    <li>Копию подписанного договора о лицензионном соглашении;</li>
                                    <li>Документ, подтверждающий оплату Годовой Лицензии(платежное поручение, банковская квитанция, чек или выписка из интернет-банкинга);</li>
                                </ul>
                            </li>
                            <li>
                                Для заключения договора на портале Государственных закупок :
                                <ul>
                                    <li><a href="downloads/ТС на госзакуп «Медстат».docx" download style={{ fontWeight: "bold", textDecoration:"underline"}}>Техническая спецификация</a></li>
                                    <li><a href="downloads/КП_медстат_2025 год.pdf" download style={{ fontWeight: "bold", textDecoration: "underline" }}>Коммерческое предложение</a></li>
                                    <li>КТРУ 620230.000.000001 Услуги по сопровождению и технической поддержке информационной системы</li>
                                </ul>
                            </li>
                        </ol>
                        <div>Доступ к порталу "Медстат" будет предоставлен после оплаты Годовой Лицензии</div>
                        <div>Контактные телефоны: 8-727-241-13-07, 8-727-241-12-16</div>
                        <div style={{ marginTop: "10px" }}><strong>Вход в портал <a href="/" style={{ fontWeight: "bold", textDecoration: "underline" }}>https://medstat.minzdrav.kz/</a></strong></div>
                        <div style={{ marginTop: "10px" }}><strong>Скачать инструкцию по работе в портале "Медстат", "Мединфо" <a href="downloads/instruction.pdf" style={{ fontWeight: "bold", textDecoration: "underline" }} download>https://medstat.minzdrav.kz/downloads/instruction.pdf</a></strong></div>
                    </div>
                </div>
            </div>
        </Fragment>;
    }
}
////     <div style={{ position: "absolute"; bottom: 5px; right: 5px">ТОО "Мединформ"</div>
