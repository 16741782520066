import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/FinPaidData';

import ObjsTree from './ObjsTree';
import PaidsTable from './PaidsTable';
const fye = (new Date()).getFullYear();
const years = [`${fye}`, `${fye+1}`];
class FinPaidData extends Component {
    constructor(props) {
        super(props);
        this.onSelect = this.onSelect.bind(this);
        this.onChangeYear = this.onChangeYear.bind(this);
    }
    componentWillMount() {
        let year =years[0];
        // This method runs when the component is first added to the page
        this.setState({
            selYear: year,
            selectedObj: undefined,
            selectedParentObj: undefined,
            selectTypeObj: undefined, //1-область, 2- район, 3- организация
        });

    }
    /*
    componentWillReceiveProps(nextProps) {
        // This method runs when incoming props (e.g., route params) change
    }
    */
    onSelect(selectedObj) {
        var selectedParentObj = undefined;
        if (selectedObj.id.length === 4) {
            //организация
            var parents = this.props.obj_data.filter(p => p.id === selectedObj.parentId);
            if (parents.length > 0) {
                selectedParentObj = parents[0];
            }
            this.setState({ selectedObj, selectedParentObj });
            this.props.getOrgsPaid(selectedObj.id, this.state.selYear);

        } else if (selectedObj.id.length === 2) {
            //область
            selectedParentObj = {
                "id": "00",
                "parentId": null,
                "label": "Республика казахстан",
            };
            this.setState({selectedObj, selectedParentObj});
            this.props.getOrgsPaid(selectedObj.id, this.state.selYear);
        } else {
            this.setState({ selectedObj: undefined, selectedParentObj: undefined });
        }
    }
    onChangeYear(year) {
        this.setState({ selYear: year })
        if (this.state.selectedObj != null) {
            let selectedObj = this.state.selectedObj;
            if (selectedObj.id.length === 4 || selectedObj.id.length === 2) {
                this.props.getOrgsPaid(selectedObj.id, year);
            }
        }
    }
    render() {
        return (
            <Fragment>
                <h1>Учет оплаты за использование портала год:{' '}<select defaultValue={years[0]} onChange={(evt) => this.onChangeYear(evt.target.value)} > { years.map(y => <option key={y} value={y}>{y}</option>) }</select></h1>
                <p>Укажите организации, которые оплатили доступ на портал по кварталам</p>
                <div className="row">
                    <div className="col-md-4">
                        <ObjsTree onSelectObj={this.onSelect} year={this.state.selYear}/>
                    </div>
                    <div className="col-md-8">
                        {this.state.selectedObj !== undefined ?
                            <PaidsTable selectedObj={this.state.selectedObj} selectedParentObj={this.state.selectedParentObj} />
                            : <div className="text-success"><strong>Выберите область, район</strong></div>}
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default connect(
    state => state.finPaidData,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(FinPaidData);
