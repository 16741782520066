import React, { Component } from 'react';
import { Button, Modal, Form, Table } from 'react-bootstrap';

export default class NsiModalFilter extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = { filter: this.props.filter || this.initFilter(props) }
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ filter: nextProps.filter || this.initFilter(nextProps) });
    }
    initFilter = (props) => {
        let filtr = [];
        for (var i = 0; i < props.meta.filter.length; i++) {
            filtr.push(undefined);
        }
        return filtr;
    }
    onSubmit = (e) => {
        e.preventDefault();
        if (e.target.id === 'btSetFilter')
            this.props.setFilter(this.state.filter);
        else
            this.props.clearFilter();
    }
    getFunc = (f) => {
        switch (f) {
            case 1:
                return 'точное совпадение';
            case 2:
                return 'совпадение с начала';
            case 3:
                return 'вхождение строки';
            default:
                return undefined;
        }
    }
    onChangeVal = (e) => {
        let ind = parseInt(e.target.id, 10);
        let val = e.target.value;
        let filter = Object.assign(this.state.filter);
        filter.splice(ind, 1, val);
        this.setState(filter);
    }
    render() {
        return (<Form id='addKredModalForm'>
            <Modal show={this.props.isShowModalFilter} onHide={this.props.hideModalFilter} bsSize='large'>
                <Modal.Header closeButton>
                    <Modal.Title>Установка фильтра справочника {this.props.meta.nameRu}</Modal.Title>
                </Modal.Header>
                <Modal.Body bsClass='small'>
                    <Table striped bordered condensed hover>
                        <thead>
                            <tr><th>Колонка</th><th>Значение</th></tr>
                        </thead>
                        <tbody>
                            {this.props.meta.filter.map((cl, ind) => {
                                return (<tr key={`c_${ind}`}><td>{cl.col} ({this.getFunc(cl.func)})</td>
                                    <td><input id={`${ind}`} style={{ minWidth: '100%' }} type='text' value={this.state.filter[ind]} onChange={this.onChangeVal} /></td></tr>);
                            })}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.hideModalFilter}>Отменить</Button>
                    <Button onClick={this.onSubmit} id='btSetFilter' type='submit' bsStyle="primary">Установить фильтр</Button>
                    <Button onClick={this.onSubmit} id='btClearFilter' type='submit'>Очистить фильтр</Button>
                </Modal.Footer>
            </Modal></Form>);
    }
}
//onSubmit={this.onSubmit}