import React, { Fragment, useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, ControlLabel, FormControl, Row, Col, Button, FormGroup } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { actionCreators } from '../../store/Nsi';
import SelectOrgModal from './SelectOrgModal'
import { getNameLocalize } from '../Constants';

function SelectOrg(props) {
    const [org, setOrg] = useState({});
    const [show, setShow] = useState(false);
    /*
    const [isReadOnly, setIsReadOnly] = useState(false);
    useEffect(async () => {
       setIsReadOnly(props.isReadOnly === true);
    }, [props.isReadOnly]);
    */
    useEffect(() => {
        let fetch = async () => {
            if (props.org != null) {
                let org1 = await props.getOrgByKod(props.org.kod);
                if (org1 != null) {
                    setOrg({ kod: org1.kobj, nameRu: org1.nameRu, nameKz: org1.nameKz, nomenklKod: org1.nomenklKod, parentKod: org1.parentKod });
                    return;
                }
            }
            setOrg({});
        }
        fetch();
    }, [props.org]);
    const handleChangeKod = async (evt) => {
        let kod = evt.target.value.trim();
        if (kod.length === 4) {
            //todo search org by kod
            let org1 = await props.getOrgByKod(kod);
            if (org1 != null) {
                let o = { kod: org1.kobj, nameRu: org1.nameRu, nameKz: org1.nameKz, nomenklKod: org1.nomenklKod, parentKod: org1.parentKod };
                //setOrg(org);
                props.setOrg(o);
            } else {
                //setOrg({});
                props.setOrg(null);
            }
        } else if (kod.length > 4) {
            evt.preventDefault();
        } else {
            ///setOrg({});
            props.setOrg(null);
        }
    }
    const handleCloseDlg = (data) => {
        if (data.isNotCancel === true) {
            let org1 = { kod: data.org.kobj, nameRu: data.org.nameRu, nameKz: data.org.nameKz, nomenklKod: data.org.nomenklKod, parentKod:data.org.parentKod};
            setShow(false);
            setOrg(org1);
            props.setOrg(org1);
        } else
            setShow(false);
    }
    return <Fragment>
        <Form inline>
                <FormGroup controlId="formKod">
                    <FormControl type="text" size="4" value={org.kod} onChange={handleChangeKod} readOnly={props.isReadOnly === true} />
                    <ControlLabel><Translate value="kod" /></ControlLabel>{' '}
                </FormGroup>
                <Button variant="outline-primary" size="sm" onClick={() => setShow(true)} style={{ height: '30px' }} disabled={props.isReadOnly === true}>...</Button>
                <ControlLabel style={{ fontSize: 10, width: '80px', padding: 0 }} className="text-center"><Translate value="nameOrgBr" /></ControlLabel>
                <ControlLabel style={{ fontWeight: 'bold', fontSize: 11, width: '570px', padding: 0 }}>{org.kod != null ? getNameLocalize(props.i18n, org) : ''}</ControlLabel>
        </Form>
        <SelectOrgModal show={show} handleCloseDlg={handleCloseDlg} />
    </Fragment>
}
export default connect(
    state => state,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(SelectOrg);