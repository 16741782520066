import React, { Fragment, useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, ControlLabel, FormControl, Row, Col, Button, FormGroup } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { actionCreators } from '../../store/Nsi';
//import { getNameLocalize } from '../Constants';

function SelectClassMkb(props) {
    const [classMkb, setClassMkb] = useState();
    const [listClassMkbs, setListClassMkbs] = useState([]);
    useEffect(() => {
        let fetch = async () => {
            if (props.kf != null && props.ktab != null) {
                let clMkbs = await props.getClassMkb(props.kf,props.ktab);
                if (clMkbs != null && clMkbs.length>0) {
                    setListClassMkbs(clMkbs);
                    setClassMkb(clMkbs[0]);
                    props.selectMkbClass(clMkbs[0]);
                    return;
                }
            }
            setListClassMkbs([]);
            setClassMkb(null);
            props.selectMkbClass(null);
        }
        fetch();
    }, [props.kf, props.ktab]);
    const onSelectClassMkb = (evt) => {
        let classMkb = evt.target.value;
        setClassMkb(classMkb);
        props.selectMkbClass(classMkb);
    }
    return <Row>
        <Col xs={12} sm={12}>
            <FormGroup controlId="formClassMkb" className='input-group-sm'>
                <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='classMkb' />{':'}</ControlLabel></Col>
                <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={classMkb}
                    onChange={onSelectClassMkb} style={{ minWidth: '100%' }}>
                    {listClassMkbs.map((c) => <option key={c} value={c}>{c}</option>)}
                </FormControl></Col>
            </FormGroup></Col>
    </Row>
}
export default connect(
    state => state,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(SelectClassMkb);