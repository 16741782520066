import { cloneDeep } from 'lodash';
import { getToken } from '../UserInfo';
const requestMedinfoGetTablesPagesAction = 'REQUEST_MEDINFO_GET_TABLES_PAGES';
const receiveMedinfoGetTablesPagesAction = 'RECEIVE_MEDINFO_GET_TABLES_PAGES';
const requestMedinfoGetTablesPagesDrAction = 'REQUEST_MEDINFO_GET_TABLES_PAGES_DR';
const receiveMedinfoGetTablesPagesDrAction = 'RECEIVE_MEDINFO_GET_TABLES_PAGES_DR';
const requestMedinfoGetFormulsAction = 'REQUEST_MEDINFO_GET_FORMULS';
const receiveMedinfoGetFormulsAction = 'RECEIVE_MEDINFO_GET_FORMULS';
const requestMedinfoGetFormulYearsAction = 'REQUEST_MEDINFO_GET_FORMUL_YEARS';
const receiveMedinfoGetFormulYearsAction = 'RECEIVE_MEDINFO_GET_FORMUL_YEARS';
const requestMedinfoUploadTemplateAction = 'REQUEST_MEDINFO_UPLOAD_TEMPLATE_YEARS';
const receiveMedinfoUploadTemplateAction = 'RECEIVE_MEDINFO_UPLOAD_TEMPLATE_YEARS';
const requestMedinfoUploadTemplateOneYearAction = 'REQUEST_MEDINFO_UPLOAD_TEMPLATE_ONE_YEAR';
const receiveMedinfoUploadTemplateOneYearAction = 'RECEIVE_MEDINFO_UPLOAD_TEMPLATE_ONE_YEAR';
const requestUpdatePageInfoAction = 'REQUEST_MEDINFO_UPDATE_TABLE_INFO';
const receiveUpdatePageInfoAction = 'RECEIVE_MEDINFO_UPDATE_TABLE_INFO';
const requestUpdateFormulAction = 'REQUEST_MEDINFO_UPDATE_FORMUL';
const receiveUpdateFormulAction = 'RECEIVE_MEDINFO_UPDATE_FORMUL';
const requestCopyOrInsertFormulYearAction = 'REQUEST_MEDINFO_COPY_OR_INSERT_FORMUL_YEAR';
const receiveCopyOrInsertFormulYearAction = 'RECEIVE_MEDINFO_COPY_OR_INSERT_FORMUL_YEAR';
const requestUpdateFormulYearAction = 'REQUEST_MEDINFO_UPDATE_FORMUL_YEAR';
const receiveUpdateFormulYearAction = 'RECEIVE_MEDINFO_UPDATE_FORMUL_YEAR';
const requestGetBokMkbAction = 'REQUEST_MEDINFO_GET_BOKMKB';
const receiveGetBokMkbAction = 'RECEIVE_MEDINFO_GET_BOKMKB';
const requestUpdateFieldBokMkbAction = 'REQUEST_MEDINFO_UPDATE_FIELD_BOKMKB';
const receiveUpdateFieldBokMkbAction = 'RECEIVE_MEDINFO_UPDATE_FIELD_BOKMKB';
const requestGetBokDrAction = 'REQUEST_MEDINFO_GET_BOKDR';
const receiveGetBokDrAction = 'RECEIVE_MEDINFO_GET_BOKDR';
const requestUpdateFieldBokDrAction = 'REQUEST_MEDINFO_UPDATE_FIELD_BOKDR';
const receiveUpdateFieldBokDrAction = 'RECEIVE_MEDINFO_UPDATE_FIELD_BOKDR';
const requestGetPartsAction = 'REQUEST_MEDINFO_PARTS';
const receiveGetPartsAction = 'RECEIVE_MEDINFO_PARTS';
const requestUpdatePartAction = 'REQUEST_MEDINFO_UPDATE_PART';
const receiveUpdatePartAction = 'RECEIVE_MEDINFO_UPDATE_PART';
const requestGetPagesOfPartAction = 'REQUEST_MEDINFO_GET_PAGES_OF_PART';
const receiveGetPagesOfPartAction = 'RECEIVE_MEDINFO_GET_PAGES_OF_PART';


const initialState = {
    isLoading: false,
    type_page:1,
    tablesPages: undefined,
    formuls: undefined,
    formulYears: undefined,
    error: undefined,
    bokMkb: undefined,
    bokDr: undefined,
    parts: [],
    partPages: undefined
}
export const actionCreators = {
    getDataOrgsByObl: (param) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        let json = JSON.stringify(param);
        const response = await fetch("api/MedinfoApi/GetDataOrgsByObl", {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            return await response.json();
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    getPagesOfPart: (partId) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestGetPagesOfPartAction, partId });
        //const response = await fetch(`api/MedinfoApi/GetTablesPages?type_page=${type_page}`, {
        const response = await fetch(`api/MedinfoApi/GetPagesOfPart?partId=${partId}`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveGetPagesOfPartAction, data });
            return data;
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    getParts: () => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestGetPartsAction });
        const response = await fetch("api/MedinfoApi/GetParts", {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveGetPartsAction, data });
            return data.parts;
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    updatePart: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestUpdatePartAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/MedinfoApi/UpdatePart', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveUpdatePartAction, status: data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    DownloadTemplate: (pageId) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        const response = await fetch(`api/MedinfoApi/DownloadTemplate/${pageId}`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            return await response.blob();
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
        return null;
    },
    UploadTemplate: (formData, pageId) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestMedinfoUploadTemplateAction});
        const response = await fetch(`api/MedinfoApi/UploadTemplate?pageId=${pageId}`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                // "Content-Type": "multipart/form-data"
            },
            body: formData
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveMedinfoUploadTemplateAction, data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    DownloadTemplateOneYear: (pageId) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        const response = await fetch(`api/MedinfoApi/DownloadTemplateOneYear/${pageId}`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            return await response.blob();
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
        return null;
    },
    UploadTemplateOneYear: (formData, pageId) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestMedinfoUploadTemplateOneYearAction });
        const response = await fetch(`api/MedinfoApi/UploadTemplateOneYear?pageId=${pageId}`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                // "Content-Type": "multipart/form-data"
            },
            body: formData
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveMedinfoUploadTemplateOneYearAction, data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    getTablesPages: (type_page) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestMedinfoGetTablesPagesAction, type_page });
        const response = await fetch(`api/MedinfoApi/GetTablesPages?type_page=${type_page}`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveMedinfoGetTablesPagesAction, data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    getTablesPagesOnlyBokDr: () => async (dispatch, getState) => {
            var token = await getToken(dispatch, getState().userInfo);
            if (token === undefined) return;
        dispatch({ type: requestMedinfoGetTablesPagesDrAction, type_page:2 });
            const response = await fetch("api/MedinfoApi/GetTablesPagesOnlyBokDr", {
                credentials: 'include',
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.ok) { // если HTTP-статус в диапазоне 200-299
                // получаем тело ответа (см. про этот метод ниже)
                const data = await response.json();
                dispatch({ type: receiveMedinfoGetTablesPagesDrAction, data });
            } else {
                alert("Ошибка HTTP: " + response.status);
            }
    },
    getFormuls: (pageId) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestMedinfoGetFormulsAction });
        const response = await fetch(`api/MedinfoApi/GetFormuls?pageId=${pageId}`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveMedinfoGetFormulsAction, data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    getFormulYears: (formulId) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestMedinfoGetFormulYearsAction });
        const response = await fetch(`api/MedinfoApi/GetFormulYears?formulId=${formulId}`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveMedinfoGetFormulYearsAction, data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    updatePageInfo: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestUpdatePageInfoAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/MedinfoApi/UpdatePageInfo', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveUpdatePageInfoAction, status: data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    updateFormul: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestUpdateFormulAction });
        let json = JSON.stringify(params/*{
            PageId: params.pageId,
            FormulId: params.formulId,
            Npp: params.npp,
            NumberAfterPoint: params.numberAfterPoint,
            NameRu: params.nameRu,
            NameKz: params.nameKz
        }*/);
        const response = await fetch('api/MedinfoApi/UpdateFormul', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveUpdateFormulAction, status: data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    copyOrInsertFormulYear: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestCopyOrInsertFormulYearAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/MedinfoApi/CopyOrInsertFormulYear', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveCopyOrInsertFormulYearAction, status: data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    updateFormulYear: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestUpdateFormulYearAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/MedinfoApi/UpdateFormulYear', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveUpdateFormulYearAction, status: data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    getBokMkb: () => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestGetBokMkbAction});
        const response = await fetch("api/MedinfoApi/GetBokMkb", {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveGetBokMkbAction, data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    updateFieldBokMkb: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestUpdateFieldBokMkbAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/MedinfoApi/UpdateFieldBokMkb', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveUpdateFieldBokMkbAction, status: data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    getBokDr: (idPage) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestGetBokDrAction });
        const response = await fetch(`api/MedinfoApi/GetBokDr?idPage=${idPage}`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveGetBokDrAction, data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
    updateFieldBokDr: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestUpdateFieldBokDrAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/MedinfoApi/UpdateFieldBokDr', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveUpdateFieldBokDrAction, status: data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    },
}
export const reducer = (state, action) => {
    state = state || initialState;
    //get pages of part
    if (action.type === requestGetPagesOfPartAction) {
        return {
            ...state,
            error: undefined,
            isLoading: true,
            partPages: undefined
        };
    }
    if (action.type === receiveGetPagesOfPartAction) {
        return {
            ...state,
            isLoading: false,
            partPages: action.data
        };
    }
    //get parts
    if (action.type === requestGetPartsAction) {
        return {
            ...state,
            error: undefined,
            isLoading: true,
            parts: undefined
        };
    }
    if (action.type === receiveGetPartsAction) {
        return {
            ...state,
            isLoading: false,
            parts: action.data.parts
        };
    }
    //update part
    if (action.type === requestUpdatePartAction) {
        return {
            ...state,
            error: undefined,
            isLoading: true,
        };
    }
    if (action.type === receiveUpdatePartAction) {
        if (action.status.error == null) {
            let parts = cloneDeep(state.parts);
            let index = parts.map(e => e.tableId).indexOf(action.status.part.partId);
            parts.splice(index, 1, cloneDeep(action.status.part));
            return {
                ...state,
                parts,
                isLoading: false
            };
        } else {
            return {
                ...state,
                error: action.status.error,
                isLoading: false
            };
        }

    }
    //get tables pages
    if (action.type === requestMedinfoGetTablesPagesAction) {
        return {
            ...state,
            error: undefined,
            isLoading: true,
            type_page: action.type_page,
            tablesPages: undefined,
            formuls: undefined,
            formulYears: undefined
        };
    }
    if (action.type === receiveMedinfoGetTablesPagesAction) {
        return {
            ...state,
            isLoading: false,
            tablesPages: action.data
        };
    }
    //get tables pages dr
    if (action.type === requestMedinfoGetTablesPagesDrAction) {
        return {
            ...state,
            error: undefined,
            isLoading: true,
            type_page: action.type_page,
            tablesPages: undefined,
            formuls: undefined,
            formulYears: undefined
        };
    }
    if (action.type === receiveMedinfoGetTablesPagesDrAction) {
        return {
            ...state,
            isLoading: false,
            tablesPages: action.data
        };
    }
    //get formuls
    if (action.type === requestMedinfoGetFormulsAction) {
        return {
            ...state,
            error: undefined,
            isLoading: true,
            formuls: undefined,
            formulYears: undefined
        };
    }
    if (action.type === receiveMedinfoGetFormulsAction) {
        return {
            ...state,
            isLoading: false,
            formuls: action.data,
        };
    }
    //get years formul
    if (action.type === requestMedinfoGetFormulYearsAction) {
        return {
            ...state,
            error: undefined,
            isLoading: true,
            formulYears: undefined,
        };
    }
    if (action.type === receiveMedinfoGetFormulYearsAction) {
        return {
            ...state,
            isLoading: false,
            formulYears: action.data,
        };
    }
    //upload template
    if (action.type === requestMedinfoUploadTemplateAction) {
        return {
            ...state,
            error: undefined,
            isLoading: true,
        };
    }
    if (action.type === receiveMedinfoUploadTemplateAction) {
        let tablesPages = cloneDeep(state.tablesPages);
        let index = tablesPages.tables.map(e => e.tableId).indexOf(action.data.tableId);
        let index2 = tablesPages.tables[index].pages.map(m => m.pageId).indexOf(action.data.pageId);
        let page = cloneDeep(tablesPages.tables[index].pages[index2]);
        page.fileTemplate = action.data.templateTitle;
        tablesPages.tables[index].pages.splice(index2, 1, page);
        return {
            ...state,
            tablesPages,
            isLoading: false,
        };
    }
    //upload template one year
    if (action.type === requestMedinfoUploadTemplateOneYearAction) {
        return {
            ...state,
            error: undefined,
            isLoading: true,
        };
    }
    if (action.type === receiveMedinfoUploadTemplateOneYearAction) {
        let tablesPages = cloneDeep(state.tablesPages);
        let index = tablesPages.tables.map(e => e.tableId).indexOf(action.data.tableId);
        let index2 = tablesPages.tables[index].pages.map(m => m.pageId).indexOf(action.data.pageId);
        let page = cloneDeep(tablesPages.tables[index].pages[index2]);
        page.fileTemplateOneYear = action.data.templateTitle;
        tablesPages.tables[index].pages.splice(index2, 1, page);
        return {
            ...state,
            tablesPages,
            isLoading: false,
        };
    }
    //update page info
    if (action.type === requestUpdatePageInfoAction) {
        return {
            ...state,
            error: undefined,
            isLoading: true,
        };
    }
    if (action.type === receiveUpdatePageInfoAction) {
        if (action.status.error == null) {
            /*let tablesPages = cloneDeep(state.tablesPages);
            let index = tablesPages.tables.map(e => e.tableId).indexOf(action.status.page.tableId);
            let index2 = tablesPages.tables[index].pages.map(m => m.pageId).indexOf(action.status.page.pageId);
            tablesPages.tables[index].pages.splice(index2, 1, action.status.page);
            */
            let tablesPages = cloneDeep(state.tablesPages);
            let index = tablesPages.tables.map(e => e.tableId).indexOf(action.status.page.tableId);
            let index2 = tablesPages.tables[index].pages.map(m => m.pageId).indexOf(action.status.page.pageId);
            tablesPages.tables[index].pages.splice(index2, 1, cloneDeep(action.status.page));
            return {
                ...state,
                tablesPages,
                isLoading: false
            };
        } else {
            return {
                ...state,
                error: action.status.error,
                isLoading: false
            };
        }
        
    }
    //update formul
    if (action.type === requestUpdateFormulAction) {
        return {
            ...state,
            error: undefined,
            isLoading: true,
        };
    }
    if (action.type === receiveUpdateFormulAction) {
        if (action.status.error == null) {
            let formuls = cloneDeep(state.formuls);
            let index = formuls.formuls.map(e => e.formulId).indexOf(action.status.formul.formulId);
            formuls.formuls.splice(index, 1, action.status.formul);
            return {
                ...state,
                formuls,
                isLoading: false
            };
        } else {
            return {
                ...state,
                error: action.status.error,
                isLoading: false
            };
        }
    }
    //insert or copy formul year
    if (action.type === requestCopyOrInsertFormulYearAction) {
        return {
            ...state,
            error: undefined,
            isLoading: true,
        };
    }
    if (action.type === receiveCopyOrInsertFormulYearAction) {
        if (action.status.error == null) {
            let formulYears = cloneDeep(state.formulYears);
            let index = formulYears.years.map(e => e.yearFormulId).indexOf(action.status.formulYear.yearFormulId);
            if (index === -1) {
                formulYears.years.push(action.status.formulYear);
                formulYears.years.sort((a, b) => (a.year > b.year) ? 1 : ((b.year > a.year) ? -1 : 0));
            }
            return {
                ...state,
                formulYears,
                isLoading: false
            };
        } else {
            return {
                ...state,
                error: action.status.error,
                isLoading: false
            };
        }

    }
    //update formul year
    if (action.type === requestUpdateFormulYearAction) {
        return {
            ...state,
            error: undefined,
            isLoading: true,
        };
    }
    if (action.type === receiveUpdateFormulYearAction) {
        if (action.status.error == null) {
            let formulYears = cloneDeep(state.formulYears);
            let index = formulYears.years.map(e => e.yearFormulId).indexOf(action.status.formulYear.yearFormulId);
            formulYears.years.splice(index, 1, action.status.formulYear);
            return {
                ...state,
                formulYears,
                isLoading: false
            };
        } else {
            return {
                ...state,
                error: action.status.error,
                isLoading: false
            };
        }
    }
    //get bokMkb
    if (action.type === requestGetBokMkbAction) {
        return {
            ...state,
            error: undefined,
            isLoading: true,
            bokMkb: undefined,
        };
    }
    if (action.type === receiveGetBokMkbAction) {
        return {
            ...state,
            isLoading: false,
            bokMkb: action.data,
        };
    }
    //update  field bokMkb
    if (action.type === requestUpdateFieldBokMkbAction) {
        return {
            ...state,
            error: undefined,
            isLoading: true,
        };
    }
    if (action.type === receiveUpdateFieldBokMkbAction) {
        if (action.status.error == null) {
            let bokMkb = cloneDeep(state.bokMkb);
            let index = bokMkb.map(e => e.bokMkbId).indexOf(action.status.bokMkb.bokMkbId);
            bokMkb.splice(index, 1, action.status.bokMkb);
            return {
                ...state,
                isLoading: false,
                bokMkb
            };
        } else {
            return {
                ...state,
                error: action.status.error,
                isLoading: false
            };
        }
    }
    //get bokDr
    if (action.type === requestGetBokDrAction) {
        return {
            ...state,
            error: undefined,
            isLoading: true,
            bokDr: undefined,
        };
    }
    if (action.type === receiveGetBokDrAction) {
        return {
            ...state,
            isLoading: false,
            bokDr: action.data,
        };
    }
    //update  field bokDr
    if (action.type === requestUpdateFieldBokDrAction) {
        return {
            ...state,
            error: undefined,
            isLoading: true,
        };
    }
    if (action.type === receiveUpdateFieldBokDrAction) {
        if (action.status.error == null) {
            let bokDr = cloneDeep(state.bokDr);
            let index = bokDr.map(e => e.bokMkbId).indexOf(action.status.bokMkb.bokMkbId);
            bokDr.splice(index, 1, action.status.bokMkb);
            return {
                ...state,
                isLoading: false,
                bokDr
            };
        } else {
            return {
                ...state,
                error: action.status.error,
                isLoading: false
            };
        }
    }
    return state;
}