import { getToken } from './UserInfo';
const requestControlAction = 'REQUEST_CONTROL_DATA';
const receiveControlAction = 'RECEIVE_CONTROL_DATA';

const initialState = { isLoading: false, controlResult: undefined }

export const actionCreators = {
    controlForm: (params) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestControlAction });
        let json = JSON.stringify(params);
        const response = await fetch('api/ControlApi/ControlForm', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const data = await response.json();
            dispatch({ type: receiveControlAction, data });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    }
}
export const reducer = (state, action) => {
    state = state || initialState;
    if (action.type === requestControlAction) {
        return {
            ...state,
            isLoading: true,
            controlResult: undefined,
        };
    }
    if (action.type === receiveControlAction) {
        return {
            ...state,
            isLoading: false,
            controlResult: action.data,
        };
    }
    return state;
};