import React, { Component } from 'react';
import './UserItemList.css';

export class UserItemList extends Component {
    constructor(props) {
        super(props);
        this.selectClick = this.selectClick.bind(this);
        this.deleteOnClick = this.deleteOnClick.bind(this);
    }
    selectClick(event){
        this.props.selectUser(this.props.user.id);
    }
    deleteOnClick(event) {
        this.props.deleteUser({ id: this.props.user.id, fio: this.props.user.fio });
    }
    render() {
        return (<li key={this.props.user.id} onClick={this.selectClick} 
            className={'list-group-item' + (this.props.selectedUserId === this.props.user.id ? ' list-group-item-success' : '')}>
            <div className='user_fio'>{this.props.user.fio}</div>{this.props.user.username != 'adminData' && this.props.user.username != 'adminUsers' ? <div className="user_bt_del"><button type ='button' className='btn btn-danger btn-sm' onClick={this.deleteOnClick}>
                <span className='glyphicon glyphicon-remove'></span></button></div>:""}</li>);
    }
}