import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    Form, Button, FormGroup, FormControl, ControlLabel/*, Panel*/, Modal, Row, Col,
    Checkbox, /*Glyphicon,*/ ListGroup/*, ListGroupItem*/
} from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';

import { actionCreators as formulActions } from '../../store/EditControl';
import { actionCreators as nsiActions } from '../../store/Nsi';
import { ContrItemlist } from './ContrltemList';
import { years, getCurrentYear } from '../Constants';

const divStyle = {
    height: "600px",
    overflowY: "auto"
};
class EditControl extends Component {
    constructor(props, context) {
        super(props, context);
        let nowYear = getCurrentYear();
        this.state = {
            kf: '01', isShowEdit: false, isShowConfirmRemove: false, indexRemove: undefined, selectControl: undefined,
            isMejform: false,
            curCmd: 1, inputErrors: undefined, inputErrors2: undefined, newFormul: { k1: "", k2: "", p: "" },
            year: nowYear
        };
        // this.onSelectFormul = this.onSelectFormul.bind(this);
        this.onChangeSelControlFormul = this.onChangeSelControlFormul.bind(this);
        this.onChangeSelControlP = this.onChangeSelControlP.bind(this);
        this.onChangeIsMejform = this.onChangeIsMejform.bind(this);
        this.onAddFormul = this.onAddFormul.bind(this);
        this.onChangeNewFormulK1 = this.onChangeNewFormulK1.bind(this);
        this.onChangeNewFormulK2 = this.onChangeNewFormulK2.bind(this);
        this.onChangeNewFormulP = this.onChangeNewFormulP.bind(this);
        this.onAddFormul = this.onAddFormul.bind(this);
        this.onSelectYear = this.onSelectYear.bind(this);
        props.getNs('sprform');
    }
    onSelectYear = (e) => {
        let year = parseInt(e.target.value, 10);
        this.setState({ year });
    }
    onChangeNewFormulK1 = (e) => {
        e.preventDefault();
        let newFormul = Object.assign(this.state.newFormul);
        newFormul.k1 = e.target.value;
        this.setState({ newFormul });
    }
    onChangeNewFormulK2 = (e) => {
        e.preventDefault();
        let newFormul = Object.assign(this.state.newFormul);
        newFormul.k2 = e.target.value;
        this.setState({ newFormul });
    }
    onChangeNewFormulP = (e) => {
        e.preventDefault();
        let newFormul = Object.assign(this.state.newFormul);
        newFormul.p = e.target.checked ? "g" : "";
        this.setState({ newFormul });
    }
    onAddFormul = async (e) => {
        e.preventDefault();
        this.setState({ inputErrors2: undefined });
        let inputErrors = [];
        if (this.state.newFormul.k1 === undefined || this.state.newFormul.k1 === '')
            inputErrors.push('Введите код формулы');
        if (this.state.newFormul.k2 === undefined || this.state.newFormul.k2 === '')
            inputErrors.push('Введите формулу');
        if (inputErrors.length > 0) {
            this.setState({ inputErrors2: inputErrors });
            return;
        }
        let status = await this.props.AddFormul({ ...this.state.newFormul, kform: this.state.kf, year: this.state.year });
        if (status.successed === false) {
            inputErrors.push(status.error);
            this.setState({ inputErrors2: inputErrors });
        }
    }
    onSelectForm = (e) => {
        let kf = e.target.value;
        this.setState({ kf });
    }
    handleSelFormSubmit = (e) => {
        e.preventDefault();
        this.props.getFormul(this.state.year, this.state.kf, this.state.isMejform);
    }
    clickAdd = (e) => {
        e.preventDefault();
        //this.setState({ selectControl: { uid: undefined, lexpr: '', rexpr: '', fu: 1 }, isShowEdit: true, curCmd: 1 });
    }
    clickEdit = (e) => {
        e.preventDefault();
        let ind = parseInt(e.target.id, 10);
        let frml = this.props.editFormul.controlData[ind];
        this.setState({ selectControl: { uid: frml.uid, lexpr: frml.lexpr, rexpr: frml.rexpr, fu: frml.fu }, isShowEdit: true, curCmd: 2 });
    }
    clickRemove = (e) => {
        e.preventDefault();
        this.setState({ isShowConfirmRemove: true, indexRemove: parseInt(e.target.id, 10) });
    }
    hideConfirmRemove = () => {
        this.setState({ isShowConfirmRemove: true, indexRemove: undefined });
    }
    renderConfirmDelete = () => {
        if (this.state.indexRemove === undefined)
            return undefined;
        let frml = this.props.editFormul.controlData[this.state.indexRemove];
        return (<Form><Modal show={this.state.isShowConfirmRemove === true} onHide={this.hideConfirmRemove} bsSize='large'>
            <Modal.Header closeButton>
                <Modal.Title>Удалить формулу контроля</Modal.Title>
            </Modal.Header>
            <Modal.Body bsClass='small'>
                <p>Вы действительно хотите удалить формулу контроля:<br /><strong>{frml.lexpr} {this.getFunc(frml.fu)} {frml.rexpr}</strong>?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button type='button' bsSize='small' onClick={this.hideConfirmRemove}>Отменить</Button>
                <Button type='button' onClick={this.handleRemoveSubmit} bsSize='small' bsStyle="danger">Удалить</Button>
            </Modal.Footer></Modal></Form>);
    }
    /*
    onSelectFormul = (e) => {
        e.preventDefault();
        let ind = parseInt(e.target.id, 10);
        let frml = this.props.editFormul.controlData[ind];
        this.setState({ selectControl: { k1: frml.k1, k2: frml.k2, p: frml.p }, isShowEdit: true, curCmd: 2 });
    }
    */
    onChangeSelControlFormul = (e) => {
        let selectControl = Object.assign(this.state.selectControl);
        selectControl.k2 = e.target.value;
        this.setState(selectControl)
        this.props.UpdateFormul({
            k1: this.state.selectControl.k1,
            k2: selectControl.k2,
            p: this.state.selectControl.p,
            year: this.state.year
        });
    }
    onChangeSelControlP = (e) => {
        let selectControl = Object.assign(this.state.selectControl);
        selectControl.p = e.target.checked ? "g" : "";
        this.setState(selectControl)
        this.props.UpdateFormul({
            k1: this.state.selectControl.k1,
            k2: this.state.selectControl.k2,
            p: selectControl.p,
            year: this.state.year
        });
    }
    onChangeIsMejform = (e) => {
        this.setState({ isMejform: e.target.checked })
    }
    editFormul = () => {
        if (this.state.selectControl === undefined) {
            return '';
        }
        return (<form>
            <FormGroup controlId="formControlsTextarea">
                <ControlLabel>Формула контроля: {this.state.selectControl.k1}</ControlLabel><br />
                <textarea value={this.state.selectControl.k2} onChange={this.onChangeSelControlFormul} cols="100" rows="5"></textarea>
            </FormGroup>
            {/*<Checkbox checked={this.state.selectControl.p === "g"} onChange={this.onChangeSelControlP}>Формула для межгодового контроля</Checkbox>*/}
        </form>);
    }// <FormControl componentClass="textarea" defaultValue={this.state.selectControl.k2} onChange={this.onChangeSelControlFormul}/>
    renderTableControl = () => {
        let formuls = this.props.editFormul.controlData;
        if (formuls === undefined || formuls.length === 0)
            return undefined;
        var title;
        if (this.state.isMejform) {
            title = "Межформенный контроль";
        } else {
            let form = this.props.nsi.sprform.filter(f => f.kf === this.state.kf)[0]
            title = `Формулы контроля форма: ${form.kf} ${form.nameRu}`;
        }
        //<br /><Button type='button' bsStyle='info' bsSize='small' onClick={this.clickAdd}>Добавить</Button>
        return (<Fragment>
            <Row>
                <Col md={12}>
                    <h4 className="text-primary"><strong>{title}</strong></h4>
                </Col>
            </Row>
            <Row><Col>
                {this.renderStatusUpdate()}{this.renderStatusRemove()}</Col>
            </Row>
            <Row>
                <Col md={4}>
                    <div style={divStyle}>
                        <ListGroup>
                            {formuls.map((f) => <ContrItemlist key={f.k1} control={f} selectedControl={this.selectControl}
                                selectControl={(ctrl) => this.setState({ selectControl: { k1: ctrl.k1, k2: ctrl.k2, p: ctrl.p } })}></ContrItemlist>)}
                        </ListGroup>
                    </div>
                </Col>
                <Col md={8}>
                    {this.editFormul()}
                </Col>
            </Row>
            <Row>
                <Col md={4} className="text-right"><strong>Добавить формулу контроля:</strong></Col>
                <Col md={8}><Form horizontal onSubmit={this.onAddFormul}>
                    <FormGroup>
                        <ControlLabel>Формула контроля: <input defaultValue={this.state.newFormul.k1} onChange={this.onChangeNewFormulK1}></input></ControlLabel><br />
                    </FormGroup>
                    <FormGroup controlId="formControlsTextarea">
                        <textarea value={this.state.newFormul.k2} onChange={this.onChangeNewFormulK2} cols="100" rows="5"></textarea>
                    </FormGroup>
                    {/*<FormGroup>
                        <Checkbox checked={this.state.newFormul.p === "g"} onChange={this.onChangeNewFormulP}>Формула для межгодового контроля</Checkbox>
                    </FormGroup>*/}
                    {this.state.inputErrors2 !== undefined ? <ul>{this.state.inputErrors2.map((er, i) => <ul className='text-danger' key={i.toString()}>{er}</ul>)}</ul> : undefined}
                    <Button type="submit">Добавить</Button>
                </Form></Col>
            </Row>
        </Fragment>);
    }
    /*
     <Panel bsStyle="primary">
            <Panel.Heading>
                <Panel.Title componentClass="h3">{title}</Panel.Title>
            </Panel.Heading>
            <Panel.Body>
                {this.renderStatusUpdate()}{this.renderStatusRemove()}
                <Row>
                    <Col md={4}>
                        <div style={divStyle}>
                            <ListGroup>
                            {formuls.map((f) => <ContrItemlist key={f.k1} control={f} selectedControl={this.selectControl}
                                    selectControl={(ctrl) => this.setState({ selectControl: { k1: ctrl.k1, k2:ctrl.k2, p: ctrl.p }})}></ContrItemlist>)}
                            </ListGroup>
                        </div>
                    </Col>
                    <Col md={8}>
                        {this.editFormul()}
                    </Col>
                </Row>
             </Panel.Body>
            </Panel>
    */
    getFunc = (fu) => {
        switch (fu) {
            case 1:
                return '=';
            case 2:
                return '<';
            case 3:
                return '>';
            case 4:
                return '<=';
            case 5:
                return '>=';
            default:
                return '';
        }
    }
    hideDialogEdit = () => {
        this.setState({ isShowEdit: false, selectControl: undefined });
    }
    handleFormulSubmit = (e) => {
        e.preventDefault();
        let inputErrors = [];
        if (this.state.selectControl.lexpr === undefined || this.state.selectControl.lexpr === '')
            inputErrors.push('Введите левое выражение');
        if (this.state.selectControl.rexpr === undefined || this.state.selectControl.rexpr === '')
            inputErrors.push('Введите правое выражение');
        if (inputErrors.length > 0) {
            this.setState({ inputErrors });
            return;
        }
        if (this.state.curCmd === 1)
            this.props.AddFormul({ ...this.state.selectControl, kform: this.state.kf, year: this.state.year });
        else
            this.props.UpdateFormul({ ...this.state.selectControl, kform: this.state.kf, year: this.state.year });
        this.setState({ isShowEdit: false, selectControl: undefined, curCmd: 1, inputErrors: undefined });
    }
    handleRemoveSubmit = (e) => {
        e.preventDefault();
        let frml = this.props.editFormul.controlData[this.state.indexRemove];
        this.props.RemoveFormul({ uid: frml.uid, index: this.state.indexRemove, year: this.state.year  });
        this.setState({ indexRemove: undefined });
    }
    changeRexpr = (e) => {
        let selectControl = Object.assign(this.state.selectControl);
        selectControl.rexpr = e.target.value;
        this.setState(selectControl)
    }
    changeLexpr = (e) => {
        let selectControl = Object.assign(this.state.selectControl);
        selectControl.lexpr = e.target.value;
        this.setState(selectControl)
    }
    changeFu = (e) => {
        let selectControl = Object.assign(this.state.selectControl);
        selectControl.fu = parseInt(e.target.value, 10);
        this.setState(selectControl)
    }
    renderModalEdit = () => {
        if (this.state.selectControl === undefined)
            return '';
        return (<Form><Modal show={this.state.isShowEdit === true} onHide={this.hideDialogEdit} bsSize='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{this.state.curCmd === 1 ? 'Добавить' : 'Редактировать'} формулу контроля</Modal.Title>
            </Modal.Header>
            <Modal.Body bsClass='small'>
                <FormGroup controlId="formLexpr" className='input-group-sm'>
                    <Col md={4} lg={2}><ControlLabel>Левое выражение</ControlLabel></Col>
                    <Col md={8} lg={10}><FormControl componentClass='input' defaultValue={this.state.selectControl.lexpr} onChange={this.changeLexpr} /></Col>
                </FormGroup>
                <FormGroup controlId="formLexpr" className='input-group-sm'>
                    <Col md={4} lg={2}><ControlLabel>Функция</ControlLabel></Col>
                    <Col md={8} lg={10}><FormControl componentClass='select' defaultValue={this.state.selectControl.fu} onChange={this.changeFu} >
                        <option value={1}>=</option>
                        <option value={2}>&lt;</option>
                        <option value={3}>&gt;</option>
                        <option value={4}>&lt;=</option>
                        <option value={5}>&gt;=</option>
                    </FormControl></Col>
                </FormGroup>
                <FormGroup controlId="formRexpr" className='input-group-sm'>
                    <Col md={4} lg={2}><ControlLabel>Правое выражение</ControlLabel></Col>
                    <Col md={8} lg={10}><FormControl componentClass='input' defaultValue={this.state.selectControl.rexpr} onChange={this.changeRexpr} /></Col>
                </FormGroup>
                {this.state.inputErrors !== undefined ? <ul>{this.state.inputErrors.map((er, i) => <ul className='text-danger' key={i.toString()}>{er}</ul>)}</ul> : undefined}
            </Modal.Body>
            <Modal.Footer>
                <Button type='button' bsSize='small' onClick={this.hideDialogEdit}>Отменить</Button>
                <Button type='button' onClick={this.handleFormulSubmit} bsSize='small' bsStyle="primary">{this.state.curCmd === 1 ? 'Добавить' : 'Сохранить'}</Button>
            </Modal.Footer></Modal></Form>);
    }
    renderStatusUpdate = () => {
        if (this.props.editFormul.statusUpdate === undefined) return undefined;
        if (this.props.editFormul.statusUpdate.successed === true) {
            if (this.props.editFormul.statusUpdate.isAdd === true)
                return (<div><strong className='text-info'>Формула контроля добавлена!</strong></div>)
            else
                return (<div><strong className='text-info'>Формула контроля обновлена!</strong></div>)
        } else {
            return (<div><strong className='text-danger'>{this.props.editFormul.statusUpdate.error}</strong></div>)
        }
    }
    renderStatusRemove = () => {
        //statusRemove
        if (this.props.editFormul.statusRemove === undefined) return undefined;
        if (this.props.editFormul.statusRemove.successed === true) {
            return (<div><strong className='text-warning'>Формула контроля удалена!</strong></div>)
        } else {
            return (<div><strong className='text-danger'>{this.props.editFormul.statusRemove.error}</strong></div>)
        }
    }
    render() {
        return (<Fragment><h4 className='text-info'><Translate value='formul.header' /></h4>
            <Form onSubmit={this.handleSelFormSubmit}>
                <Row>
                    <Col xs={12} sm={12}>
                        <FormGroup controlId="formYear" className='input-group-sm'>
                            <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='year' />{':'}</ControlLabel></Col>
                            <Col xs={12} sm={10}>
                                <FormControl className='input-sm' componentClass="select" placeholder="select"
                                    defaultValue={this.state.year} onChange={this.onSelectYear}>
                                    {years.map(it => <option key={it} value={it}>{it}</option>)}
                                </FormControl></Col>
                        </FormGroup></Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12}>
                        <FormGroup controlId="form" className='input-group-sm'>
                            <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='formul.selform' /></ControlLabel></Col>
                            <Col xs={12} sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={this.state.kf} onChange={this.onSelectForm}
                        disabled={this.state.isMejform}>
                                {this.props.nsi.sprform.map(it => <option key={it.kf} value={it.kf}>{it.kf} {it.nameRu}</option>)}
                            </FormControl></Col>
                    </FormGroup></Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12}>
                        <FormGroup controlId="isMejform" className='input-group-sm'>
                            <Checkbox checked={this.state.isMejform} onChange={this.onChangeIsMejform}>Межформенный контроль</Checkbox>
                        </FormGroup>
                    </Col>
                </Row>
                <Button type='submit' bsSize='xs' bsStyle="primary"><Translate value='formul.loadFormuls' /></Button>
            </Form>
            {this.renderTableControl()}
            {this.renderConfirmDelete()}
        </Fragment>);
    }
}
export default connect(
    state => state,
    dispatch => bindActionCreators({ ...formulActions, ...nsiActions }, dispatch)
)(EditControl);