import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
//import { Col, Grid, Row } from 'react-bootstrap';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';

import IdleTimer from 'react-idle-timer';

import { actionCreators } from '../store/UserInfo';
import LoginPage from './LoginPage';
import License from './License';
//import Register from './Register';
import UserInfo from './UserInfo';
import NavMenu2 from './NavMenu2';
import './Layout.css';
import { getTextVersion } from './Constants';

class Layout extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = null;
        this.onIdle = this._onIdle.bind(this);
    }
    _onIdle(e) {
        this.props.logout();
    }
    /*
    login(data) {
        this.props.login(data);
    }
    logout() {
        //this.props.logout();
        localStorage.removeItem('user_medstat');
    }*/
    goToLoginOrLicense() {
        //console.dir(document.location.pathname);
        if (document.location.pathname === "/license") {
            return <License />;
        } /*else if (document.location.pathname === "/register") {
            return <Register />;
        }
        */
        return <LoginPage />;
    }
    render() {
        let loggingIn = false;
        let userJson = localStorage.getItem('user_medstat');
        let user = undefined;
        if (userJson !== null) {
            user = JSON.parse(userJson);
            let expidred = new Date(user.tokenExpires);
            let now = new Date();
            if (expidred.getTime() - now.getTime() > 0) {
                loggingIn = true;
            } else {
                this.props.logout();
            }
        }

        //this.props.loggingIn
        return (<IdleTimer ref={ref => { this.idleTimer = ref }}
            element={document}
            onIdle={this.onIdle}
            timeout={1000 * 60 * 29}>
            {loggingIn === true ?
                <Fragment>
                    <Navbar collapseOnSelect allowFullScreen>
                        <Navbar.Header>
                            <Navbar.Brand>
                                <Link to={'/'}><Translate value='home' /></Link>
                            </Navbar.Brand>
                            <Navbar.Toggle />
                        </Navbar.Header>
                        <Navbar.Collapse>
                            <NavMenu2 />
                            <UserInfo />
                        </Navbar.Collapse>
                    </Navbar>
                    <div id='content' className="container-fluid">
                        {this.props.children}
                    </div>
                    <footer id='footer' className="footer">
                        <div className="container">
                        <div className="row">
                                <div className="col-md-8 col-sm-12"><div className="pull-left">
                                    {user !== undefined ? <span><Translate value='userOrg' />{user.kodOrg} {user.nameOrg}</span> : ''}</div>
                                </div>
                            <div className="col-md-4 col-sm-12">
                                        <div className="pull-right">{getTextVersion()}</div>
                            </div>
                            </div>
                        </div>
                    </footer>
                </Fragment> :
                <div id='content' className='container'>{this.goToLoginOrLicense()}</div>}
        </IdleTimer>);
    }
}
export default connect(
    state => state.userInfo,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Layout);