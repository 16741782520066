import { getToken } from './UserInfo';
const requestGetFormulControlAction = 'REQUEST_GET_FORMUL_CONTROL_DATA';
const receiveGetFormulControlAction = 'RECEIVE_GET_FORMUL_CONTROL_DATA';
const requestAddFormulControlAction = 'REQUEST_ADD_FORMUL_CONTROL_DATA';
const receiveAddFormulControlAction = 'RECEIVE_ADD_FORMUL_CONTROL_DATA';
const requestRemoveFormulControlAction = 'REQUEST_REMOVE_FORMUL_CONTROL_DATA';
const receiveRemoveFormulControlAction = 'RECEIVE_REMOVE_FORMUL_CONTROL_DATA';
const requestUpdateFormulControlAction = 'REQUEST_UPDATE_FORMUL_CONTROL_DATA';
const receiveUpdateFormulControlAction = 'RECEIVE_UPDATE_FORMUL_CONTROL_DATA';


const initialState = { isLoading: false, controlData: undefined, statusUpdate: undefined, statusRemove: undefined }

export const actionCreators = {
    getFormul: (year, kform, isMejform) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestGetFormulControlAction });
        const url = `api/FormulApi/GetData/${kform}/${year}?isMejForm=${isMejform}`;
        const response = await fetch(url, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        dispatch({ type: receiveGetFormulControlAction, data });
    },
    AddFormul: (formul) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestAddFormulControlAction });
        let json = JSON.stringify(formul);
        const url = 'api/FormulApi/AddFormul';
        const response = await fetch(url, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        const status = await response.json();
        dispatch({ type: receiveAddFormulControlAction, status, formul });
        return status;
    },
    RemoveFormul: (param) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestRemoveFormulControlAction });
        const url = `api/FormulApi/RemoveFormul/${param.uid}/${param.year}`;
        const response = await fetch(url, {
            credentials: 'include',
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const status = await response.json();
        dispatch({ type: receiveRemoveFormulControlAction, status, param });
    },
    UpdateFormul: (formul) => async (dispatch, getState) => {
        var token = await getToken(dispatch, getState().userInfo);
        if (token === undefined) return;
        dispatch({ type: requestUpdateFormulControlAction });
        let json = JSON.stringify(formul);
        const url = 'api/FormulApi/UpdateFormul';
        const response = await fetch(url, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: json
        });
        const status = await response.json();
        dispatch({ type: receiveUpdateFormulControlAction, status, formul });
    }
}

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === requestGetFormulControlAction) {
        return {
            ...state,
            isLoading: true,
            controlData: undefined,
            statusUpdate: undefined,
            statusRemove: undefined
        };
    }
    if (action.type === receiveGetFormulControlAction) {
        return {
            ...state,
            isLoading: false,
            controlData: action.data
        };
    }
    if (action.type === requestAddFormulControlAction) {
        return {
            ...state,
            isLoading: true,
            statusUpdate: undefined,
            statusRemove: undefined
        };
    }
    if (action.type === receiveAddFormulControlAction) {
        if (action.status.successed === true)
        {
            let controlData = Object.assign(state.controlData);
            controlData.push({ ...action.formul, uid: action.status.newUidKontr });
            return {
                ...state,
                isLoading: false,
                controlData,
                statusUpdate: { ...action.status, isAdd: true },
                statusRemove: undefined
            };
        }else
        return {
            ...state,
            isLoading: false,
            statusUpdate: action.status,
            statusRemove: undefined
        };
    }
    if (action.type === requestRemoveFormulControlAction) {
        return {
            ...state,
            isLoading: true,
            statusUpdate: undefined,
            statusRemove: undefined
        };
    }
    if (action.type === receiveRemoveFormulControlAction) {
        if (action.status.successed === true) {
            let controlData = Object.assign(state.controlData);
            controlData.splice(action.param.index, 1);
            return {
                ...state,
                isLoading: false,
                controlData,
                statusRemove: action.status,
                statusUpdate: undefined
            };
        } else
            return {
                ...state,
                isLoading: false,
                statusRemove: action.status,
                statusUpdate: undefined
            };
    }
    if (action.type === requestUpdateFormulControlAction) {
        return {
            ...state,
            isLoading: true,
            statusUpdate: undefined,
            statusRemove: undefined
        };
    }
    if (action.type === receiveUpdateFormulControlAction) {
        if (action.status.successed === true) {
            let controlData = Object.assign(state.controlData);
            let ctrl = controlData.filter(f => f.uid === action.formul.uid)[0];
            ctrl.lexpr = action.formul.lexpr;
            ctrl.rexpr = action.formul.rexpr;
            ctrl.fu = action.formul.fu;
            //controlData.push({ ...action.formul, uid: action.status.newUidKontr });
            return {
                ...state,
                isLoading: false,
                controlData,
                statusUpdate: { ...action.status, isAdd: false },
                statusRemove: undefined
            };
        } else
            return {
                ...state,
                isLoading: false,
                statusUpdate: action.status,
                statusRemove: undefined
            };
    }
    return state;
}