import React, { Component } from 'react';
import Tree from '@naisutech/react-tree'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/FinPaidData';
const myTheme = {
    'my-theme': {
        text: '#000',
        bg: '#fff',
        highlight: 'lightgray', // the colours used for selected and hover items
        decal: 'darkgray', // the colours used  for open folder indicators and icons
        accent: '#999' // the colour used for row borders and empty file indicators
    }
}
/*
const data = [
    {
        "id": "00",
        "parentId": null,
        "label": "Республика казахстан",
    },
    {
        "id": "01",
        "label": "Респ. организации г.Алматы",
        "parentId": "00"
    },
    {
        "id": "11",
        "label": "Акмолинская",
        "parentId": "00",
        "items": [
            { "id": "1110", "label": "Кокшетау Г.А.", "parentId": "11" },
            { "id": "1118", "label": "Степногорск Г.А.", "parentId": "11" },
            { "id": "1132", "label": "Аккольский район", "parentId": "11" }
        ]
    },
    {
        "id": "15",
        "label": "Актюбинская",
        "parentId": "00",
        "items": [
            { "id": "1510", "label": "Кокшетау Г.А.", "parentId": "15" },
            { "id": "1532", "label": "Степногорск Г.А.", "parentId": "15" },
            { "id": "1534", "label": "Аккольский район", "parentId": "15" }
        ]
    }
];
*/
class ObjsTree extends Component {
    constructor(props) {
        super(props);
        this.onSelect = this.onSelect.bind(this);
    }
    componentWillMount() {
        this.props.getAllObjects(this.props.year);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.year != this.props.year)
            nextProps.getAllObjects(nextProps.year/*parseInt(nextProps.year,10)*/);
    }
    onSelect(selectedNode) {
        this.props.onSelectObj(selectedNode);
    }
    render() {
        return (
            <Tree nodes={this.props.obj_data} onSelect={this.onSelect} theme={'my-theme'} customTheme={myTheme} />
        );
    }
}
export default connect(
    state => state.finPaidData,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ObjsTree);