import React, { Fragment } from 'react';
import { Row, Col} from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { getNameLocalize } from '../Constants';

import './InfoSelectedForEdit.css';

const InfoSelectedForEdit = props => {
    let nameObj = '';
    switch (props.tobj.kod) {
        case 1:
            nameObj = "РК";
            break;
        case 2:
            nameObj = getNameLocalize(props.i18n, props.obl);
            break;
        case 3:
            nameObj = `${getNameLocalize(props.i18n, props.obl)}, ${getNameLocalize(props.i18n, props.raion)}`;
            break;
        case 4:
            nameObj = `${getNameLocalize(props.i18n, props.obl)}, ${getNameLocalize(props.i18n, props.raion)}, ${getNameLocalize(props.i18n, props.org)}`;
            break;
        default:
    }
    return <Row>
        <Col xs={12} sm={12}>
            <span><Translate value='year' />:</span><strong>{props.year}</strong>
            {props.period != null ? <Fragment><span><Translate value='period' />:</span><strong>{getNameLocalize(props.i18n, props.period)}</strong></Fragment>: ''}
            {props.raz != null ? <Fragment><span><Translate value='razres' />:</span><strong>{getNameLocalize(props.i18n, props.raz)}</strong></Fragment> : ''}
            {props.tobj != null ? <Fragment><span>{getNameLocalize(props.i18n, props.tobj)}:</span><strong>{nameObj}</strong></Fragment> : ''}
            <Translate value='viewData' />:<strong>{props.isPostr ? <Translate value='pstr' /> : <Translate value='table' />}</strong><br />
            {props.form != null ? <Fragment><span><Translate value='forma' />:</span><strong>{props.form.kf} {getNameLocalize(props.i18n, props.form)}</strong></Fragment> : ''}
            {props.table != null ? <Fragment><span><Translate value='table' />:</span><strong>{props.table.ktab} {getNameLocalize(props.i18n, props.table)}</strong></Fragment> : ''}
            
        </Col>
    </Row>;
};
export default InfoSelectedForEdit;