import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col} from 'react-bootstrap';
import { Translate} from 'react-redux-i18n';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';
import SelectOrg from '../common/SelectOrg';
import ListChildsOrgs from './ListChildsOrgs';

const AdminEditChildOrg = (props) => {
    const [org, setOrg] = useState();
    return <>
        <Row>
            <Col md={12} className="text-center"><h4 className='h4 text-info'>
                <Translate value="childOrgs.title" /></h4>
            </Col>
        </Row>
        <Row size="sm">
            <Col md={4} className="text-right"><strong><Translate value="childOrgs.parentOrg" /></strong></Col>
            <Col md={8}><SelectOrg setOrg={setOrg} org={org} /></Col>
        </Row>
        {org != null ? <ListChildsOrgs kodParentOrg={org.kod}/>: ""}
    </>
}
export default connect(
    state => state,
    dispatch => bindActionCreators(actionCreatorsNsi, dispatch)
)(AdminEditChildOrg);